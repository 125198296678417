import { Component } from '@angular/core';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import AmplitudeEvents from '../../../../../types/amplitude.enum';

@Component({
  selector: 'roma-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent {
  constructor(private analyticsService: AnalyticsService) {}
  whatsappLink =
    'https://api.whatsapp.com/send/?phone=%2B34644119707&text&type=phone_number&app_absent=0';

  openContactLink(): void {
    this.analyticsService.trackEvent({
      eventName: AmplitudeEvents.pricingModal_contact,
      sources: ['amplitude'],
    });
    window.open(this.whatsappLink, '_blank');
  }
}
