import { EventEmitter, Injectable, Input, Output } from '@angular/core';
import {
  IBudgetsSetting,
  SequenceTypes,
  TotalBudget,
  TypeBudget,
  numbers_format,
} from '@tacliatech/types';
import { BudgetExpiration } from './budget.enum';
import { getDateStrResum } from '@web-frontend/shared/utils';
import { FormGroup } from '@angular/forms';
import { formatNumber } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BudgetUtilisService {
  @Output()
  refreshItems = new EventEmitter<unknown>();

  @Output()
  refreshAdditionalInfo = new EventEmitter<unknown>();

  @Output()
  refreshBudget = new EventEmitter<unknown>();

  @Output()
  refreshTotals = new EventEmitter<unknown>();

  @Output()
  refreshBudgetSettings = new EventEmitter<unknown>();

  @Output()
  updateTotal = new EventEmitter<TotalBudget>();

  @Input()
  settings: IBudgetsSetting;

  formatDates(budgetHeaderForm: FormGroup): { date: string; dueDate: string } {
    const date = budgetHeaderForm.value?.date;
    const dueDate = budgetHeaderForm.value?.dueDate;
    let dueDateObj = dueDate;
    if (typeof dueDate === 'string') {
      dueDateObj = this.resolveDueDate(dueDate as BudgetExpiration, date);
    }
    const dateObj = getDateStrResum(date);

    if (dueDateObj instanceof Date && !isNaN(dueDateObj.getTime())) {
      dueDateObj = getDateStrResum(dueDateObj);
    }

    return {
      date: dateObj.formattedDate,
      dueDate: dueDateObj?.formattedDate || dueDate,
    };
  }

  resolveDueDate(value: BudgetExpiration, baseDate: Date): string {
    let dueDate;
    switch (value) {
      case BudgetExpiration.ONE_WEEK:
        dueDate = new Date(baseDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        break;
      case BudgetExpiration.TWO_WEEK:
        dueDate = new Date(baseDate.getTime() + 14 * 24 * 60 * 60 * 1000);
        break;
      case BudgetExpiration.ONE_MONTH:
        dueDate = new Date(baseDate);
        dueDate = new Date(dueDate.setMonth(dueDate.getMonth() + 1));
        break;
      case BudgetExpiration.TWO_MONTH:
        dueDate = new Date(baseDate);
        dueDate = new Date(dueDate.setMonth(dueDate.getMonth() + 2));
        break;
      default:
        dueDate = value;
    }
    return dueDate;
  }

  budgetTypeToSequenceType(budgetType: string): SequenceTypes {
    switch (budgetType) {
      case TypeBudget.BILL:
        return SequenceTypes.BILL;
      case TypeBudget.BUDGET:
        return SequenceTypes.BUDGET;
      case TypeBudget.PROFORM:
        return SequenceTypes.PROFORM;
      case TypeBudget.WAL:
        return SequenceTypes.WAYBILLS;
      default:
        return SequenceTypes.ADD_BUDGETS;
    }
  }

  formatNumber(
    settings: IBudgetsSetting,
    number: number,
    currency = ''
  ): string {
    const number_format = numbers_format.find(
      (item) => item.id === settings?.budgetPreferences?.number_format
    )?.decimal_separator;
    const decimals = settings?.budgetPreferences?.decimals?.toString();

    const locale = number_format === ',' ? 'es-AR' : 'en-US';

    const digitsInfo = `1.${decimals}-${decimals}`;
    return formatNumber(number, locale, digitsInfo) + currency;
  }
}
