import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services';
import { User } from '../../class';

@Component({
  selector: 'roma-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
  @Input()
  user: User & { title?: string };

  @Input()
  width = 32;

  @Input()
  height = 32;

  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {
    if (!this.user) {
      this.authService.user$.subscribe((res: User) => {
        this.user = res;
      });
    }
  }

  get name(): string {
    const name = this.user.name ?? this.user.title ?? '';
    return name[0]?.toUpperCase() ?? '';
  }
}
