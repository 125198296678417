import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Subject, Subscription } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';

import {
  Company,
  Deal,
  DynamicPropertyRef,
  Feature,
  FeatureUser,
  IAsset,
  ICustomer,
  ICustomProperty,
  IEquipmentNote,
  IFinal,
  IInternalVendor,
  IProject,
  IStatus,
  IUser,
  MapperFinal,
  ValidateAllFormFields,
} from '@tacliatech/types';

import { Clipboard } from '@angular/cdk/clipboard';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { EquipmentService } from '@web-frontend/shared/services/equipment';
import { UserService } from '@web-frontend/shared/services/users';

import {
  ActivitySearchService,
  ActivityService,
  AssetService,
  DealSearchService,
  DealService,
  DynamicPropertyService,
  FinalService,
  InternalVendorService,
  ProjectService,
  SandboxService,
  StatusService,
  StorageService,
} from '@web-frontend/shared/services';

import { DateAdapter } from '@angular/material/core';
import { environment } from '@web-frontend/environments';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { SelectRecurrenceOutput } from '@web-frontend/shared/components/selector-recurrence';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { CompanyModuleService } from '@web-frontend/shared/services/company/company-module.service';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { ToastData } from '@web-frontend/shared/services/toast/utils/toast-config';

import { CustomDateAdapter } from '@web-frontend/shared/utils/custom-date-adapter';
import { EditCustomPropertiesComponent } from '../edit-custom-properties';
import { InfoService } from '../info/info.service';
import { PARSER_EQUIPMENT } from './create-activity.conts';
import { DataParamsActivity } from './create-activity.types';

import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';

import { CreateFinalV2Service } from '../create-final-v2';
import { CreatePersonService } from '../create-persons';
import { DeleteBySelectionModalComponent } from '../delete-by-selection-modal/delete-by-selection-modal.component';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

@Component({
  selector: 'roma-create-activity',
  templateUrl: './create-activity.component.html',
  styleUrls: ['./create-activity.component.scss'],
  providers: [{ provide: DateAdapter, useExisting: CustomDateAdapter }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateActivityComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('customerVendor')
  customerVendorSelect: NgSelectComponent;

  @ViewChild('leftCol') private myScrollContainer: ElementRef;

  @ViewChild(EditCustomPropertiesComponent)
  editCustomProperties: EditCustomPropertiesComponent;

  @ViewChild('dealSelect')
  dealSelect: NgSelectComponent;

  @Input()
  charge = false;

  @Input()
  type2: '';

  @Input()
  type: 'ADD' | 'EDIT' = 'ADD';

  form: FormGroup = this.fb.group({
    title: new FormControl(null, [Validators.required]),
    description: new FormControl(null),
    dateTime: new FormControl(null),
    hour: new FormControl(null),
    deal: new FormControl(null),
    owner: new FormControl(null),
    createdBy: new FormControl(StorageService.UserId, [Validators.required]),
    customer: new FormControl(null),
    hasBeenNotified: new FormControl(false),
    asset: new FormControl(null),
    vendor: new FormControl(null),
    idEquipment: new FormControl(null),
    idStatus: new FormControl(null),
    idFinal: new FormControl(null),
    idProject: new FormControl(null),
    idInternalVendor: new FormControl(null),
    idSupervisor: new FormControl(null),
    comments: [[]],
    customProperties: [[]],
    files: [],
    deleted: new FormControl(false),
  });

  featureRef = Feature;
  featureRefUser = FeatureUser;
  showDealsInput = false;
  showProjectInput = false;
  showAssetInput = false;
  showHourInput = false;
  showVendorInput = false;
  showFinalsSelect = false;
  selectedHour = '';
  deals: any[] = [];
  dealsSelectItems: any[] = [];
  selectedDeal: any[] = [];
  dealsCharged = false;
  dealsLoading = false;
  users: IUser[] = [];
  allUsers: IUser[] = [];
  allUsersSelectItems: any[] = [];
  allUsersCharged = false;
  allUsersLoading = false;
  assetList: IAsset[] = [];
  assetSelectItems: any[] = [];
  selectedAsset: any[] = [];
  assetsCharged = false;
  assetsLoading = false;
  equipmentsList = [];
  equipmentsSelectItems: any[] = [];
  selectedEquipment: any[] = [];
  equipmentsCharged = false;
  equipmentsLoading = false;
  finalsList: IFinal[] = [];
  finalsSelectItems: any[] = [];
  selectedFinal: any[] = [];
  finalsCharged = false;
  finalsLoading = false;
  projectsList: IProject[] = [];
  projectsSelectItems: any[] = [];
  selectedProject: any[] = [];
  projectsCharged = false;
  projectsLoading = false;
  internalVendorList: IInternalVendor[] = [];
  internalVendorSelectItems: any[] = [];
  selectedInternalVendor: any[] = [];
  internalVendorCharged = false;
  internalVendorLoading = false;
  customerVendorRef: ICustomer;
  markAsDone = false;
  typePropertyRef = DynamicPropertyRef;
  disableSubmit = false;
  selectedOwner: any[] = [];
  selectedSupervisor: any[] = [];
  activityComments: any = [];
  idActiveModules$ = this.companyModuleService.idActiveModules$;
  resize$ = this.sandBoxService.screenBusChannel$;

  idModuleRef = Company.IdModule;
  isLoading = false;
  propertiesIsLoading = false;
  customProperties: ICustomProperty[] = [];
  initChildForm = false;
  parentFormSubmit = false;
  invalidEditCustomFields = false;
  public innerWidth: number;
  private sub$ = new Subscription();
  private refreshModalOnClose = false;
  inputDateTime = null;
  showCustomProperties = true;
  showDateInput = false;
  typeOfStatus = 'activity';
  status: IStatus[] = [];
  statusLoading = false;
  selectedStatus: string = null;
  selectedStatusTitle: string = null;
  statusNames: { id?: string; title: string; value: string }[] = [];
  statusCharged = false;
  initialForm: any = {};
  customPropertiesLoaded = false;

  hasFeatureHandleActivityCustomProperty = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  get haveChanges() {
    return Object.entries(this.form.value).some(
      ([key, value]) => this.initialForm[key] !== value
    );
  }

  private saveFiles = new Subject<boolean>();
  saveFiles$ = this.saveFiles.asObservable();

  showDivImg = true;
  showDivComments = true;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private dealSearchService: DealSearchService,
    private dealService: DealService,
    private activityService: ActivityService,
    private activitySearchService: ActivitySearchService,
    private changeDetectionRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CreateActivityComponent>,
    private i18n: TranslateService,
    private userService: UserService,
    public assetService: AssetService,
    private equipmentService: EquipmentService,
    private finalService: FinalService,
    private projectService: ProjectService,
    private internalVendorService: InternalVendorService,
    private infoService: InfoService,
    private amplitudeService: AmplitudeService,

    private readonly brazeService: BrazeService,
    private companyModuleService: CompanyModuleService,
    private statusService: StatusService,
    @Inject(MAT_DIALOG_DATA) public data: DataParamsActivity,
    private dynamicPropertyService: DynamicPropertyService,
    private clipboard: Clipboard,
    private toastService: ToastService,
    private permissionService: PermissionService,
    private sandBoxService: SandboxService,
    private createFinalV2Service: CreateFinalV2Service,
    private createPersonService: CreatePersonService,
    private dialog: MatDialog,
    private analyticsService: AnalyticsService
  ) {}

  get controls() {
    return this.form.controls;
  }

  get activityEditRef() {
    return this?.data?.activity;
  }

  get featureUserScreen() {
    if (this.type == 'ADD') {
      return FeatureUser.Activity.create;
    } else {
      return FeatureUser.Activity.update;
    }
  }
  localStorageCheckBoxName = `createactivities.${StorageService.UserId}.checkboxs`;

  ngOnInit(): void {
    this.resolveEdit();
    this.initialCharge();
    this.innerWidth = window.innerWidth;
    this.resolveDeals();
    this.reloadCustomProperties();
    this.watchPermissions();

    let parms: any = localStorage.getItem(this.localStorageCheckBoxName);
    if (parms) {
      try {
        parms = JSON.parse(parms);
        this.showDealsInput = parms.showDealsInput;
        this.showProjectInput = parms.showProjectInput;
        this.showAssetInput = parms.showAssetInput;
        this.showHourInput = parms.showHourInput;
        this.showVendorInput = parms.showVendorInput;
      } catch (e) {}
    }

    this.initialForm = { ...this.form.value };

    /*this.form.valueChanges.subscribe((newValue) => {
      console.log('Nuevo valor:', newValue);
    });*/
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onBackdropClick());
  }

  public async onBackdropClick(result?: string): Promise<void> {
    if (this.haveChanges) {
      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data: {
          title: this.i18n.instant('general.withoutSave'),
          confirmLabel: this.i18n.instant('general.buttonExit'),
          showBody: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'EXECUTE') {
          this.close();
        }
      });
    } else {
      this.close();
    }
  }

  private requestStatus() {
    const id = StorageService.CompanyId;
    this.statusLoading = true;
    this.sub$.add(
      this.statusService
        .findByUser({ id: id, type: this.typeOfStatus, deleted: false })
        .pipe(map((res) => [...res]))
        .subscribe((res) => {
          this.statusLoading = false;
          this.status = res;
          this.statusNames = this.status.map((x) => ({
            title: x.name.toString().toLocaleLowerCase(),
            value: x._id,
            id: x._id,
            color: x.color,
          }));
          this.statusNames.unshift({
            title: this.i18n.instant('kanban.withoutColumnTextTask'),
            value: null,
            id: null,
          });
          this.draw();
        })
    );
  }

  eventDelete() {
    if (this.type == 'EDIT') {
      this.sub$.add(
        this.activityService.removeOne(this.data.activity._id).subscribe(() => {
          this.refreshModalOnClose = true;
          this.toastService.show({
            text: 'activity.deleteTaskMessage',
            type: 'error',
            msDuration: 4000,
            icon: 'assets/icons/kanban/delete.svg',
            class: '',
          });
          this.close();
        })
      );
    }
  }

  shareLink() {
    let url = window.location.href.split('activities')[0];
    url += 'activities/' + this?.data?.activity?._id;
    //url += 'activities?type=activity?id=' + this?.data?.activity?._id;
    this.clipboard.copy(url);
    this.toastService.show({
      text: 'activity.copyLink',
      type: 'success',
      msDuration: 4000,
      class: '',
    });
  }

  initialCharge() {
    this.findEquipments();
    this.requestStatus();
    this.statusCharged = true;

    if (this.charge) {
      this.getPersons();
      this.allUsersCharged = true;
      this.resolveDeals();
      this.dealsCharged = true;
      this.findAssets();
      this.assetsCharged = true;
      this.findFinals();
      this.finalsCharged = true;
      this.findProjects();
      this.projectsCharged = true;
      this.findInternalVendors();
      this.internalVendorCharged = true;
      this.findEquipments();
      this.equipmentsCharged = true;
    }
  }

  onClickSelect(item: string) {
    switch (item) {
      case 'owners':
        if (!this.allUsersCharged) {
          this.allUsersCharged = true;
          this.getPersons();
        }
        break;
      case 'finals':
        if (!this.finalsCharged) {
          this.finalsCharged = true;
          this.findFinals();
        }
        break;
      case 'assets':
        if (!this.assetsCharged) {
          this.assetsCharged = true;
          this.findAssets();
        }
        break;
      case 'deals':
        if (!this.dealsCharged) {
          this.dealsCharged = true;
          this.resolveDeals();
        }
        break;
      case 'projects':
        if (!this.projectsCharged) {
          this.projectsCharged = true;
          this.findProjects();
        }
        break;
      case 'internalVendors':
        if (!this.internalVendorCharged) {
          this.internalVendorCharged = true;
          this.findInternalVendors();
        }
        break;
      case 'equipments':
        if (!this.equipmentsCharged) {
          this.equipmentsCharged = true;
          this.findEquipments();
        }
        break;
      case 'status':
        if (!this.statusCharged) {
          this.statusCharged = true;
          this.requestStatus();
        }
        break;
    }
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.data?.deal) {
        this.selectedDeal.push(this.data.deal);
        this.selectedDeal = this.mapDealsSelect(this.selectedDeal);
        //this.dealSelect.setDisabledState(true);
      }

      if (this.data?.customer) {
        this.selectedFinal.push(this.data?.customer);
        this.selectedFinal = this.mapDealsSelect(this.selectedFinal);
        this.showFinalsSelect = true;
        this.form.patchValue({
          idFinal: this.selectedFinal[0].value,
        });
        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: AmplitudeEvents.final_card_task_start,
        });
      }
    });
  }

  changeSelectItem(evt, type) {
    if (['idSupervisor', 'owner'].includes(type)) {
      this.controls[type].patchValue(
        evt?.length > 0 ? evt.map((it) => it.value) : null
      );
      //this.controls[type + 'Obj'].patchValue(evt?.length > 0 ? evt : null);
    } else {
      this.controls[type].patchValue(evt[0] ? evt[0].value : null);
    }
    switch (type) {
      case 'deal':
        this.selectedDeal = evt;
        break;
      case 'idFinal':
        this.selectedFinal = evt;
        break;
      case 'idInternalVendor':
        this.selectedInternalVendor = evt;
        break;
      case 'idEquipment':
        this.selectedEquipment = evt;
        break;
      case 'idProject':
        this.selectedProject = evt;
        break;
      case 'asset':
        this.selectedAsset = evt;
        break;
      case 'owner':
        this.selectedOwner = evt;
        this.brazeService.sendEvent({
          event: BrazeEventType.task_card_user_assigned,
          eventProperties: {
            assignedUserIds: this.selectedOwner.map((item) => item.id),
          },
        });
        break;
      case 'idSupervisor':
        this.selectedSupervisor = evt;
        this.brazeService.sendEvent({
          event: BrazeEventType.task_card_owner_assigned,
          eventProperties: {
            assignedUserIds: this.selectedSupervisor.map((item) => item.id),
          },
        });
        break;
      case 'idStatus':
        this.selectedStatus = evt[0].value;
        this.selectedStatusTitle = evt[0].title;
        break;
    }
  }

  initialChargeEvent() {
    this.initialForm = { ...this.form.value };
  }

  changeDeal(evt: Deal.Schema) {
    if (evt) {
      const { vendor, customer, _id } = evt;

      this.form.patchValue({
        deal: _id,
      });

      if (customer) {
        this.form.patchValue({
          customer: customer,
        });
      }

      if (vendor) {
        this.form.patchValue({
          vendor: vendor._id,
        });
      }
    }

    this.draw();
  }

  changeMarkAsDone(evt) {
    this.markAsDone = evt;
    const toastParms: ToastData = {
      text: 'activity.finishTaskMessage',
      type: 'success',
      msDuration: 4000,
      class: '',
    };
    if (!evt) {
      toastParms.text = 'activity.unfinishTaskMessage';
    }
    this.toastService.show(toastParms);
    this.form.patchValue({
      hasBeenNotified: evt,
    });
  }

  restore() {
    this.sub$.add(
      this.activityService.removeOne(this.data.activity._id).subscribe(() => {
        this.toastService.show({
          text: 'activity.restore',
          type: 'success',
          msDuration: 4000,
          class: '',
        });
        this.close();
      })
    );
  }

  hasBeenNotifiedClick(): void {
    this.amplitudeService.sendEvent({ event: 'task_card_check' });
  }

  addingNotes(evt: IEquipmentNote[]) {
    const comments = evt;
    this.form.patchValue({
      comments: comments,
    });

    if (this.type == 'EDIT') {
      const value = { ...this.initialForm };

      if (!value?.dateTime) {
        value.dateTime = null;
      }

      if (typeof value?.customer == 'object') {
        value.customer = value.customer?._id;
      }

      value.comments = JSON.parse(JSON.stringify(this.form.value?.comments));

      delete value?.deleted;
      const obs$ = this.activityService.updateOne(
        this.data?.activity?._id,
        value
      );

      this.sub$.add(obs$.subscribe((res) => {}));
    }
  }

  updateLoading(evt) {
    this.isLoading = evt;
    this.draw();
  }

  private checkValidators() {
    ValidateAllFormFields(this.form);
    return this.form.valid;
  }

  close() {
    const refresh = this.refreshModalOnClose ? true : null;
    this.dialogRef.close(refresh);
  }

  changeDatetime($evt: SelectRecurrenceOutput) {
    if ($evt.date) {
      this.form.patchValue({
        dateTime: $evt.date?.split('T')[0],
      });
    } else {
      this.form.patchValue({
        dateTime: $evt.date?.split('T')[0],
      });
      this.inputDateTime = null;
      this.showDateInput = false;
    }
  }

  private resolveDeals() {
    this.dealsLoading = true;
    this.sub$.add(
      this.dealService // .search({ optimized: true, applyPaginate: false })
        .findAllDealsByCompany_SELECT()
        .subscribe((res) => {
          this.deals = res;
          this.dealsSelectItems = this.mapDealsSelect(this.deals);
          this.dealsLoading = false;

          setTimeout(() => {
            this.resolveAutoSelect();
          }, 500);

          this.draw();
        })
    );
  }

  mapDealsSelect(deals) {
    return deals
      .map((res) => {
        let title = res.name?.toString().toLocaleLowerCase();
        const lastName = res.lastName?.toString().toLocaleLowerCase();

        if (!res.name || res.name === 'undefined' || res.name === null) {
          if (res.dateCreated) {
            title =
              res.final?.name +
              ' - ' +
              new Date(res.dateCreated)
                ?.toISOString()
                .slice(0, 16)
                .replace('T', ' ');
          } else {
            title = this.i18n.instant('tasks.not_title');
          }
        }

        return {
          title: `${title} ${lastName ? lastName : ''}`,
          value: res._id,
          id: res._id,
          img: res?.img,
        };
      })
      .sort((a, b) => (a.title > b.title ? 1 : -1));
  }

  mapDealsSelectOutput(deals) {
    return deals.map((res) => {
      return {
        name: res?.title,
        _id: res.id,
      };
    });
  }

  parseDate(initialDate) {
    if (initialDate) {
      const date = new Date(initialDate);
      date.setTime(date.getTime() + 1 * 60 * 60 * 1000);
      return date.toISOString().replace('Z', '');
    } else {
      return '';
    }
  }

  private resolveEdit() {
    if (this.data?.activity) {
      this.type = 'EDIT';
      const { activity } = this.data;

      this.inputDateTime = new Date(
        activity?.dateTime?.toString().replace('Z', '')
      );

      this.form.patchValue({
        ...activity,
        title: activity?.title,
        dateTime: activity?.dateTime,
        hour: activity?.hour ? activity.hour : '',
        deal: activity?.dealObj?._id,
        customer: activity?.customerObj?._id,
        createdBy: activity?.createdByObj?._id
          ? activity?.createdByObj?._id
          : activity?.createdByObj,
        asset: activity?.assetObj?._id,
        idEquipment: activity?.equipmentObj?._id,
        idStatus: activity?.idStatus,
        idFinal: activity?.finalObj?._id,
        idProject: activity?.projectObj?._id,
        idInternalVendor: activity?.internalVendorObj?._id,
        files: activity?.files,
        deleted: activity?.deleted == null ? false : activity?.deleted,
        idSupervisor: Array.isArray(activity?.supervisorObj)
          ? activity?.supervisorObj?.map((it) => it._id)
          : activity?.supervisorObj?._id,
        owner: Array.isArray(activity?.ownerObj)
          ? activity?.ownerObj?.map((it) => it._id)
          : activity?.ownerObj?._id,
      });

      if (activity) {
        if (this.finalsList?.length == 0 && activity?.finalObj)
          this.finalsList.push(activity?.finalObj);
        if (this.allUsers?.length == 0 && activity?.ownerObj) {
          if (Array.isArray(activity?.ownerObj)) {
            this.allUsers = activity?.ownerObj;
          } else {
            this.allUsers.push(activity?.ownerObj);
          }
        }
        if (this.deals?.length == 0 && activity?.dealObj)
          this.deals.push(activity?.dealObj);
        if (this.assetList?.length == 0 && activity?.assetObj)
          this.assetList.push(activity?.assetObj);
        if (this.equipmentsList?.length == 0 && activity?.equipmentObj)
          this.equipmentsList.push(activity?.equipmentObj);
        if (this.projectsList?.length == 0 && activity?.projectObj)
          this.projectsList.push(activity?.projectObj);
        if (this.internalVendorList?.length == 0 && activity?.internalVendorObj)
          this.internalVendorList.push(activity?.internalVendorObj);

        if (activity?.dateTime) {
          this.showDateInput = true;
        }

        if (activity?.hour) {
          this.selectedHour = activity.hour;
        }

        if (activity?.comments) {
          this.activityComments = activity.comments;
        }

        if (activity?.idStatus) {
          this.selectedStatus = activity.statusObj?._id;
          this.selectedStatusTitle = activity.statusObj?.name;
        }

        if (activity?.dealObj) {
          this.selectedDeal.push(activity?.dealObj);
          this.selectedDeal = this.mapDealsSelect(this.selectedDeal);
        }

        if (activity?.finalObj) {
          this.selectedFinal.push(activity?.finalObj);
          this.selectedFinal = this.mapDealsSelect(this.selectedFinal);
        }

        if (activity?.internalVendorObj) {
          this.selectedInternalVendor.push(activity?.internalVendorObj);
          this.selectedInternalVendor = this.mapDealsSelect(
            this.selectedInternalVendor
          );
        }

        if (activity?.equipmentObj) {
          this.selectedEquipment.push(activity?.equipmentObj);
          this.selectedEquipment = this.selectedEquipment
            .map((res) => {
              return {
                title: res.ref?.toString().toLocaleLowerCase(),
                value: res._id,
                id: res._id,
              };
            })
            .sort((a, b) => (a.title > b.title ? 1 : -1));
        }

        if (activity?.projectObj) {
          this.selectedProject.push(activity?.projectObj);
          this.selectedProject = this.mapDealsSelect(this.selectedProject);
        }

        if (activity?.assetObj) {
          this.selectedAsset.push(activity?.assetObj);
          this.selectedAsset = this.mapDealsSelect(this.selectedAsset);
        }

        if (activity?.ownerObj) {
          if (Array.isArray(activity?.ownerObj)) {
            this.selectedOwner = activity?.ownerObj?.map((el) => {
              return {
                ...el,
              };
            });
          } else {
            this.selectedOwner.push({
              ...activity?.ownerObj,
            });
          }
          this.selectedOwner = this.mapDealsSelect(this.selectedOwner);
        }

        if (activity?.supervisorObj) {
          if (Array.isArray(activity?.supervisorObj)) {
            this.selectedSupervisor = activity?.supervisorObj?.map((el) => {
              return {
                ...el,
              };
            });
          } else {
            this.selectedSupervisor.push({
              ...activity?.supervisorObj,
            });
          }
          this.selectedSupervisor = this.mapDealsSelect(
            this.selectedSupervisor
          );
        }
      }

      if (activity?.hasBeenNotified === null || !activity?.hasBeenNotified) {
        this.markAsDone = false;
      } else {
        this.markAsDone = true;
      }

      this.changeDeal(activity?.dealObj);
      this.draw();
    }

    if (this.data?.deal) {
      const { deal } = this.data;
      this.form.patchValue({
        ...deal,
        asset: deal?.asset,
        owner: deal?.reporter,
        idEquipment: deal?.idEquipments[0],
        createdBy: deal?.createdBy,
        idFinal: deal?.idFinal,
        idProject: deal?.idProject,
      });
      this.draw();
    }
  }

  private resolveAutoSelect() {
    if (this.data?.deal) {
      this.changeDeal(this.data.deal);
    }
  }

  private getPersons() {
    const id = StorageService.CompanyId;
    this.allUsersLoading = true;

    this.sub$.add(
      this.userService
        // .findAllByCompany(id)
        .findAllUsersByCompany_SELECT()
        .subscribe((resp) => {
          this.allUsers = resp;
          this.allUsersSelectItems = this.mapDealsSelect(this.allUsers);
          this.allUsersLoading = false;
          this.draw();
        })
    );
  }

  findAssets(refresh = false) {
    this.assetsLoading = true;
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Assets)))
        .subscribe(() => {
          const id = StorageService.CustomerId;

          this.sub$.add(
            this.assetService
              // .getAssetsCustomer(id)
              .findAllAssetsByCompany_SELECT()
              .subscribe((res: IAsset[]) => {
                this.assetList = res;
                this.assetSelectItems = this.mapDealsSelect(this.assetList);
                this.assetsLoading = false;
                this.draw();
              })
          );
        })
    );
  }

  changeCustomProperty() {
    this.refreshModalOnClose = true;
    this.reloadCustomProperties();
  }

  private reloadCustomProperties() {
    this.sub$.add(
      this.dynamicPropertyService
        .findDynamicProperties(
          StorageService.CompanyId,
          DynamicPropertyRef.Activities
        )
        .subscribe((res) => {
          this.customProperties = res;
          this.customPropertiesLoaded = true;
          this.draw();
        })
    );
  }

  private draw() {
    try {
      this.changeDetectionRef.detectChanges();
      this.changeDetectionRef.markForCheck();
    } catch (err) {}
  }

  private findEquipments() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Equipments)))
        .subscribe(() => {
          this.draw();

          this.equipmentService
            // .search({ applyPaginate: false, limit: 1 })
            .findAllEquipmentsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.draw();
              })
            )
            .subscribe((res) => {
              // this.equipmentsList = (res?.docs || []).map(PARSER_EQUIPMENT);
              this.equipmentsList = (res || []).map(PARSER_EQUIPMENT);
              this.equipmentsSelectItems = this.equipmentsList
                .map((res) => {
                  return {
                    title: res.name.toString().toLocaleLowerCase(),
                    value: res.id,
                    id: res.id,
                  };
                })
                .sort((a, b) => (a.title > b.title ? 1 : -1));
              this.draw();
            });
        })
    );
  }

  private findProjects() {
    this.projectsLoading = true;
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Projects)))
        .subscribe(() => {
          this.draw();

          this.projectService
            // .search({ applyPaginate: false, limit: 1 })
            .findAllProjectsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.projectsLoading = false;
                this.draw();
              })
            )
            .subscribe((res) => {
              // this.projectsList = res?.docs;
              this.projectsList = res;
              this.projectsSelectItems = this.mapDealsSelect(this.projectsList);
              this.draw();
            });
        })
    );
  }

  private findInternalVendors() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.InternalVendors)))
        .subscribe(() => {
          this.internalVendorLoading = true;
          this.draw();

          this.internalVendorService // .search({ applyPaginate: false })
            .findAllInternalVendorsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.internalVendorLoading = false;
                this.draw();
              })
            )
            .subscribe((res) => {
              // this.internalVendorList = res?.docs;
              this.internalVendorList = res;
              this.internalVendorSelectItems = this.mapDealsSelect(
                this.internalVendorList
              );
              this.draw();
            });
        })
    );
  }

  private findFinals() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.finalsLoading = true;
          this.draw();

          this.finalService
            // .search({ applyPaginate: false, limit: 1 })
            .findAllFinalsByCompany_SELECT()
            .pipe(
              // map((res) => res.docs),
              map((res) => MapperFinal(res)),
              finalize(() => {
                this.finalsLoading = false;
                this.draw();
              })
            )
            .subscribe((res) => {
              this.finalsList = res;
              this.finalsSelectItems = this.mapDealsSelect(this.finalsList);
              this.draw();
            });
        })
    );
  }

  info(type2: string) {
    this.infoService.open(type2);
  }

  checkEmptyDateAndTransform() {
    // If dateTime value is an empty string backend will crash because
    // validations need a date or a null value in dateTime property
    if (!this.form.value.dateTime) {
      this.form.patchValue({
        dateTime: null,
      });
    }
  }

  customPropertiesFormChange($event) {
    this.customProperties = $event;
    this.setCustomProperties();
  }

  private watchPermissions() {
    this.sub$.add(
      this.permissionService
        .hasFeatureFn(Feature.Activity.HandleCustomProperties)
        .subscribe((hasFeatureHandleActivityCustomProperty) => {
          this.hasFeatureHandleActivityCustomProperty = hasFeatureHandleActivityCustomProperty;
        })
    );
  }

  private setCustomProperties() {
    this.sub$.add(
      this.permissionService
        .hasFeatureFn(Feature.Activity.HandleCustomProperties)
        .subscribe((hasFeatureHandleActivityCustomProperty) => {
          this.hasFeatureHandleActivityCustomProperty = hasFeatureHandleActivityCustomProperty;
          const isFieldRequired = (index: number) => {
            return !hasFeatureHandleActivityCustomProperty ? index < 2 : true;
          };
          for (let i = 0; i < this.customProperties?.length; i++) {
            if (this.customProperties[i].isRequired) {
              this.form.addControl(
                this.customProperties[i]._id,
                new FormControl(
                  null,
                  isFieldRequired(i) ? [Validators.required] : []
                )
              );
            }
          }
          this.initChildForm = true;
          this.parentFormSubmit = false;
        })
    );
  }

  fetchPropertyValue(propertyId) {
    this.form.controls[propertyId].setValue(propertyId);
  }

  propertiesRequired(value) {
    this.invalidEditCustomFields = value;
  }

  propertiesLoading(evt) {
    this.propertiesIsLoading = evt;
  }

  saveInputsCheckBox() {
    const parms: any = {};
    parms.showDealsInput = this.showDealsInput;
    parms.showProjectInput = this.showProjectInput;
    parms.showAssetInput = this.showAssetInput;
    parms.showHourInput = this.showHourInput;
    parms.showVendorInput = this.showVendorInput;
    localStorage.setItem(this.localStorageCheckBoxName, JSON.stringify(parms));
  }

  changeInputsCheckBox(evt, type) {
    switch (type) {
      case 'showDealsInput':
        this.showDealsInput =
          this.showDealsInput == null || this.showDealsInput == false
            ? true
            : false;
        break;
      case 'showProjectInput':
        this.showProjectInput =
          this.showProjectInput == null || this.showProjectInput == false
            ? true
            : false;
        break;
      case 'showAssetInput':
        this.showAssetInput =
          this.showAssetInput == null || this.showAssetInput == false
            ? true
            : false;
        break;
      case 'showHourInput':
        this.showHourInput =
          this.showHourInput == null || this.showHourInput == false
            ? true
            : false;
        break;
      case 'showVendorInput':
        this.showVendorInput =
          this.showVendorInput == null || this.showVendorInput == false
            ? true
            : false;
        break;
    }
    this.saveInputsCheckBox();
  }

  hourChange(hour) {
    // FORMAT HOUR
    this.selectedHour = hour;
    const hourString = this.selectedHour.substring(
      0,
      this.selectedHour.indexOf(':')
    );

    if (hourString?.length === 1 || hourString == '2_' || hourString == '1_') {
      this.selectedHour = '0' + this.selectedHour;
    }

    //FORMAT MINUTES
    /**
     *check that the time is not sent with an invalid character, '__:__' or 'XX:__'
     * this happens if the input is touched but the time is not written
     */

    if (hour === '__:__') {
      this.selectedHour = '00:00';
    } else if (this.selectedHour.includes('__')) {
      this.selectedHour = this.selectedHour.split(':')[0] + ':00';
    } else if (this.selectedHour.includes('_')) {
      this.selectedHour = this.selectedHour.split(':')[0] + ':00';
    }
  }

  /*********************************SUBMITS*********************************************** */

  submit() {
    this.isLoading = true;
    this.draw();

    this.parentFormSubmit = true;

    const isValid = this.checkValidators();

    if (isValid) {
      this.saveFiles.next(true);
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      this.isLoading = false;
    }
  }

  goToBottom(behavior = 'smooth') {
    this.myScrollContainer?.nativeElement.scroll({
      top: this.myScrollContainer.nativeElement.scrollHeight,
      behavior: behavior,
    });
  }

  editSubmit() {
    let isInvalid = false;
    this.editCustomProperties.EditableFieldComponent.toArray().forEach(
      (child, index) => {
        if (
          child.checkValidOnSubmit(
            this.editCustomProperties.isAvailable[index].isAvailable
          )
        ) {
          isInvalid = true;
        }
      }
    );

    if (!isInvalid) {
      this.submit();
    } else {
      this.goToBottom();
    }
  }

  eventValueChanged(evt) {
    this.form.patchValue({ customProperties: [...evt] });
  }

  eventAllDataSaved(
    evt: [
      {
        fileName: string;
        extension: string;
        fileFirebaseUrl?: string;
      }
    ]
  ) {
    this.checkEmptyDateAndTransform();
    this.form.get('files').setValue(evt);

    const value = this.form.value;

    if (typeof value.customer == 'object') {
      value.customer = value.customer?._id;
    }

    const parms = this.form.value;
    delete parms.deleted;
    const obs$ =
      this.type === 'ADD'
        ? this.activityService.create(value)
        : this.activityService.updateOne(this.data?.activity?._id, parms);

    let eventData;
    if (this.type === 'ADD') {
      eventData = {
        event: 'task_create',
      };
    } else {
      eventData = {
        event: 'task_edit',
      };
    }

    this.isLoading = true;
    this.draw();

    this.sub$.add(
      obs$.subscribe(
        (res) => {
          this.refreshModalOnClose = true;
          this.isLoading = false;
          this.draw();

          this.close();
          this.parentFormSubmit = false;
          const message =
            this.type === 'ADD'
              ? 'activity.create.success'
              : 'activity.edit.success';

          this.toastService.show({
            text: this.i18n.instant(message),
            type: 'success',
            msDuration: 4000,
          });
          this.amplitudeService.sendEvent(eventData);
          this.brazeService.sendEvent(eventData);
        },
        () => {
          this.parentFormSubmit = false;
          this.isLoading = false;
          this.draw();

          this.toastService.show({
            text: this.i18n.instant(this.i18n.instant('activity.error')),
            type: 'error',
            msDuration: 4000,
          });
        }
      )
    );
  }

  toggleDivImg() {
    this.showDivImg = !this.showDivImg;
  }

  toggleDivComments() {
    this.showDivComments = !this.showDivComments;
  }

  showTooltipSave() {
    if (!this.form.get('title').value) {
      return this.i18n.instant('activity.create.form.saveTooltip');
    }
    return null;
  }

  addFinals() {
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.deal_card_editFinal_start,
    });
    this.sub$.add(
      this.createFinalV2Service.open().subscribe((data) => {
        this.refreshFinals(data);
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_editFinal_create,
        });
      })
    );
  }

  private refreshFinals(idAdded: string) {
    this.sub$.add(
      this.companyModuleService.idActiveModules$
        .pipe(filter((res) => res.includes(Company.IdModule.Finals)))
        .subscribe(() => {
          this.finalsLoading = true;
          this.draw();

          this.finalService
            .findAllFinalsByCompany_SELECT()
            .pipe(
              finalize(() => {
                this.finalsLoading = false;
                this.draw();
              })
            )
            .subscribe((res) => {
              this.finalsSelectItems = res.map((res) => {
                return {
                  title: `${res.name} ${res.lastName ? res.lastName : ''}`,
                  value: res._id,
                  id: res._id,
                };
              });

              this.updateSelectedContact(idAdded);
            });
        })
    );
  }

  updateSelectedContact(idContactAdded: string) {
    // search selectedContact
    this.selectedFinal = [
      this.finalsSelectItems.find((c) => c.id === idContactAdded),
    ];

    if (this.selectedFinal.length > 0) {
      // update Form
      this.form.patchValue({
        idFinal: this.selectedFinal[0].value,
      });
      this.draw();
    }
  }

  public addOwner(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.deal_card_editOwner_start,
    });

    this.createPersonService.open().subscribe((data) => {
      if (!data) {
        return;
      }

      this.refreshOwners(data.id);
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_card_editOwner_create,
      });
    });
  }

  private refreshOwners(idAdded: string): void {
    this.allUsersLoading = true;
    this.draw();

    this.sub$.add(
      this.userService
        .findAllUsersByCompany_SELECT()
        .pipe(
          finalize(() => {
            this.allUsersLoading = false;
            this.allUsersCharged = true;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.allUsers = res;
          this.allUsersSelectItems = this.mapDealsSelect(this.allUsers);
          const newOwner = this.allUsersSelectItems.find(
            (c) => c.id === idAdded
          );

          this.selectedOwner = [...this.selectedOwner, newOwner];

          if (this.selectedOwner.length > 0) {
            this.form.patchValue({
              owner: this.selectedOwner.map((x) => x.id),
            });
          }
        })
    );
  }

  public addSupervisor(): void {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.deal_card_editSupervisor_start,
    });
    this.createPersonService.open().subscribe((data) => {
      if (!data) {
        return;
      }

      this.refreshSupervisors(data.id);
      this.amplitudeService.sendEvent({
        event: AmplitudeEvents.deal_card_editSupervisor_create,
      });
    });
  }

  private refreshSupervisors(idAdded: string): void {
    this.allUsersLoading = true;
    this.draw();

    this.sub$.add(
      this.userService
        .findAllUsersByCompany_SELECT()
        .pipe(
          finalize(() => {
            this.allUsersLoading = false;
            this.allUsersCharged = true;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.allUsers = res;
          this.allUsersSelectItems = this.mapDealsSelect(this.allUsers);

          const newSupervisor = this.allUsersSelectItems.find(
            (c) => c.id === idAdded
          );

          this.selectedSupervisor = [...this.selectedSupervisor, newSupervisor];

          if (this.selectedSupervisor.length > 0) {
            this.form.patchValue({
              idSupervisor: this.selectedSupervisor.map((x) => x.id),
            });
            this.draw();
          }
        })
    );
  }
}
