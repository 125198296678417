import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment-timezone';

import { Capacitor } from '@capacitor/core';

import {
  BudgetPreferences,
  BudgetsSetting,
  CatchmentChannels,
  ClockAbsence,
  ClockHour,
  DealType,
  Feature,
  FeatureUser,
  FreeLimitType,
  GetFinalName,
  GlobalStatus,
  IActivity,
  IAsset,
  ICustomProperty,
  IDeal,
  IFinal,
  IFinalOut,
  IInternalVendor,
  IOwnTeam,
  IStatus,
  IUser,
  IVertical,
  Lang,
  numbers_format,
  TypeFinalsClients,
  TypeRol,
} from '@tacliatech/types';

import { AuthService } from '@web-frontend/shared/services/auth/auth.service';
import { TableSortEvent } from './table.types';

import {
  FinalService,
  FreeLimitService,
  InternalVendorService,
  SalesService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalAssetsComponent } from '../modal-assets';

import { QrLegendService } from '../qr';
import { InfoService } from '../info/info.service';
import { TypeExpenseService } from '@web-frontend/shared/services/type-expense';
import { formatNumber } from '@angular/common';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { ModalUsersComponent } from '../modal-users';
import { fromEvent, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, finalize, map } from 'rxjs/operators';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { SelectionModel } from '@angular/cdk/collections';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { CreateSaleService } from '@web-frontend/shared/components/create-sale';
import { UsersListResumeComponent } from '../users-list-resume/users-list-resume.component';
import { MatDialog } from '@angular/material/dialog';
import { SaleStoreService } from '../create-sale/create-sale.store.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import { ABSENCE_CLASS_MAP } from '../clock-hours/constants';
import { User } from '../../class';
import { environment } from '@web-frontend/environments';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'roma-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit {
  currencys = this.budgetService.findAllCurrencys();
  DEFAULT_CURRENCY_SYMBOL = '€';
  @ViewChild('menuTrigger') trigger;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  @Input()
  allUsers!: IUser[];

  @Input()
  showActions = true;

  @Input()
  showVerticalMenu = true;

  @Input()
  showTotal = false;

  @Input()
  showCustomProperties = true;

  @Input()
  totalCount!: number;

  @Input()
  subtotalCount!: number;

  @Input()
  downloadSpinner!: boolean;

  @Input()
  showOpenIcon!: boolean;
  set showOpenIcons(value: boolean) {
    this.showIcon = value;
    this.resolveColumns();
  }

  @Input()
  set showDealActions(value: boolean) {
    this.showDealAction = value;
    this.resolveColumns();
  }

  @Input()
  set displayedColumns(value: Array<string>) {
    this.columnsDisplayed = value;
    this.resolveColumns();
  }

  get displayedColumns() {
    return this.columnsDisplayed;
  }

  @Input()
  // @ts-ignore
  idModule: string;

  @Input()
  title = null;

  @Input()
  type = '';

  _data: any;
  @Input()
  set data(data: any) {
    this._data = data;
    this.selection.clear();
  }
  get data() {
    return this._data;
  }

  @Input()
  statusData: IStatus[] = [];

  @Input()
  statusNames: { _id?: string; name: string; color?: string }[] = [];

  private _customFields: ICustomProperty[] = [];
  @Input() set customFields(value: ICustomProperty[]) {
    this._customFields = value;
    const customFieldMap = value.map((it) => it.name);
    const customPropertiesMap = this.customProperties.map((it) => it.name);
    for (let i = 0; i < customPropertiesMap?.length; i++) {
      if (!customFieldMap.includes(customPropertiesMap[i])) {
        this.customFieldsMap.delete(customPropertiesMap[i]);
      }
    }

    if (this._customFields.length) {
      this.resolveColumns();
    }
  }

  get customFields(): ICustomProperty[] {
    return this._customFields;
  }

  @Input()
  set moreFields(value: { key: string; title: string }[]) {
    this._moreFields = value;
  }

  get moreFields() {
    return this._moreFields;
  }

  private _moreFields: { key: string; title: string }[] = [];

  @Input()
  tagNotAvailable = 'general.propertyNotAvailable';

  @Input()
  markAction = false;

  @Input()
  set appendSettings(value: 'YES' | 'NO') {
    this.attachSettings = value === 'YES' ? true : false;
    //this.resolveColumns();
  }

  @Input()
  set appendInfo(value: 'YES' | 'NO') {
    this.attachInfo = value === 'YES' ? true : false;
    //this.resolveColumns();
  }

  @Input()
  assetList: IAsset[] = [];

  @Input()
  // @ts-ignore
  qrParserUrl: (item: Record<string, any>) => string;

  @Input()
  titleTag = 'table.colum.title';

  // @ts-ignore
  @Input() settings: BudgetPreferences = null;

  @Output()
  changeSort = new EventEmitter<TableSortEvent>();

  @Output()
  rowClick = new EventEmitter<unknown>();

  @Output()
  wantEdit = new EventEmitter<unknown>();

  @Output()
  wantApprove = new EventEmitter<unknown>();

  @Output()
  openNewTab = new EventEmitter<unknown>();

  @Output()
  wantDelete = new EventEmitter<unknown>();

  @Output()
  wantRestore = new EventEmitter<unknown>();

  @Output()
  wantDeleteDeal = new EventEmitter<unknown>();

  @Output()
  wantDeleteDealNoModal = new EventEmitter<unknown>();

  @Output()
  wantAproveDeal = new EventEmitter<unknown>();

  @Output()
  wantOpenModealDeleteDeal = new EventEmitter<unknown>();

  @Output()
  wantFinishDeal = new EventEmitter<{ _id: string }>();

  @Output()
  wantRestoreFinishDeal = new EventEmitter<{ _id: string }>();

  @Output()
  wantRestoreCancelDeal = new EventEmitter<{ _id: string }>();

  @Output()
  wantChangeStateDeal = new EventEmitter<unknown>();

  @Output()
  wantMark = new EventEmitter<unknown>();

  @Output()
  checkRowChanges = new EventEmitter<unknown>();

  @Output()
  watchProfile = new EventEmitter<unknown>();

  @Output()
  scrollBottom = new EventEmitter<boolean>();

  @Output()
  wantDownloadTicket = new EventEmitter<unknown>();

  @Output()
  wantResendInvitation = new EventEmitter<unknown>();

  @Output()
  changeStatusEvt = new EventEmitter<{
    _id: string;
    name: string;
    item: IActivity;
  }>();

  @Output()
  finishTaskEvt = new EventEmitter<unknown>();

  columnPrefix = '(--^--)';

  exampleDatabase: any | null;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  columns: Array<string> = [];
  dealsTypeRef = DealType;
  attachSettings = false;
  attachInfo = false;
  showDealAction = false;
  showIcon = false;
  eventClockHourLimit = 2;
  selection = new SelectionModel(true, []);
  // @ts-ignore
  selectedLine: number = null;
  ticketSelected = '';

  //frontend ordination
  firstOrdination = true;
  firstOrdinationData;

  private columnsDisplayed: Array<string> = [];
  private customMoreFieldsMap = new Map<
    string,
    { key: string; title: string }
  >();
  private customFieldsMap = new Map<string, ICustomProperty>();
  private lang = StorageService.GetItem('USER_LANG') as Lang;

  isPrinting = false;
  isVendor$ = this.authService.isVendor$;
  user$ = this.authService.user$;
  typeExpenses = this.typeExpenseService.findAll();
  statusExpenses = this.typeExpenseService.findAllStatusExpenses();

  vendor;
  isAdmin;
  user;
  dealTypeRef = DealType;
  ownTeam: IUser[] = [];
  finals: IFinal[] = [];
  users: IUser[] = [];

  auxSort = { active: null, direction: null };

  defaultSettings = new BudgetsSetting();

  company_date_format = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';
  defaultPreferences = this.defaultSettings.budgetPreferences;

  dates_formats = this.budgetService.findAllDateFormats();

  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);

  isMobileDeviceApplication = false;
  today;
  sortedData;
  private sub$ = new Subscription();
  featureRef = Feature;
  featureRefUser = FeatureUser;

  clockHourStatusRef = ClockHour.Status;
  tooltipAddMore = false;

  dateColumnTitle = '';
  customColumns = [];
  freeLimit: FreeLimitType.Response;
  isLoadingFreeLimits = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private authService: AuthService,
    private typeExpenseService: TypeExpenseService,
    private internalVendorService: InternalVendorService,
    private createSaleService: CreateSaleService,
    private salesService: SalesService,
    private userService: UserService,
    private infoService: InfoService,
    private modalService: NgbModal,
    private i18n: TranslateService,
    private budgetService: BudgetService,
    private qrLegendService: QrLegendService,
    private amplitudeService: AmplitudeService,
    private finalService: FinalService,
    public dialog: MatDialog,
    private saleStorageService: SaleStoreService,
    private freeLimitService: FreeLimitService,
    private analyticsService: AnalyticsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer
  ) {
    this.isVendor$.subscribe((res) => (this.vendor = res));
    this.user$.subscribe((res: any) => {
      if (res !== null && res !== undefined) {
        this.user = res;
        this.isAdmin = res.role && res.role.includes(TypeRol.ADMIN_ROLE);
      }
    });
    this.matIconRegistry.addSvgIcon(
      'holidayIcon',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        'assets/icons/party-horn.svg'
      )
    );
  }

  get customProperties() {
    return Array.from(this.customFieldsMap.values());
  }

  get customParamsFeatureByType() {
    switch (this.type) {
      case 'deal':
        return this.featureRef.Deal.CustomPropertiesTable;
      case 'activities':
      case 'activity':
        return this.featureRef.Activity.CustomPropertiesTable;
      case 'finals':
        return this.featureRef.Final.CustomPropertiesTable;
      default:
        return this.featureRef.SystemPermission.DefaultAllow;
    }
  }

  get featureUsedDeleteByType() {
    switch (this.type.toLowerCase()) {
      case 'deal':
        return this.featureRefUser.Deal.delete;
      case 'activities':
        return this.featureRefUser.Activity.delete;
      case 'finals':
        return this.featureRefUser.Final.delete;
      case 'projects':
        return this.featureRefUser.Project.delete;
      case 'assets':
        return this.featureRefUser.Address.delete;
      case 'expenses':
        return this.featureRefUser.Expense.delete;
      case 'vendor':
        return this.featureRefUser.InternalVendor.delete;
      case 'catalogues':
        return this.featureRefUser.Catalogue.delete;
      case 'equipments':
        return this.featureRefUser.Equipment.delete;
      default:
        return this.featureRef.SystemPermission.DefaultAllow;
    }
  }

  get featureUsedUpdateByType() {
    switch (this.type.toLowerCase()) {
      case 'deal':
        return this.featureRefUser.Deal.update;
      case 'activities':
        return this.featureRefUser.Activity.update;
      case 'finals':
        return this.featureRefUser.Final.update;
      case 'projects':
        return this.featureRefUser.Project.update;
      case 'assets':
        return this.featureRefUser.Address.update;
      case 'expenses':
        return this.featureRefUser.Expense.update;
      case 'vendor':
        return this.featureRefUser.InternalVendor.update;
      case 'catalogues':
        return this.featureRefUser.Catalogue.update;
      case 'equipments':
        return this.featureRefUser.Equipment.update;
      case 'person':
        return this.featureRefUser.User.update;
      default:
        return this.featureRef.SystemPermission.DefaultAllow;
    }
  }

  getTypeExpense(id: number) {
    const expense = this.typeExpenses.find(function (ex) {
      return ex.id == id;
    });
    // @ts-ignore
    return expense.translate;
  }

  getStatusExpense(id: number) {
    const expense = this.statusExpenses.find(function (ex) {
      return ex.id == id;
    });
    // @ts-ignore
    return expense.translate;
  }

  ngOnInit(): void {
    this.resolveMobileDeviceApplication();
    this.requestUsers();
    this.resolveStorageCustomProperties();
    this.resolveStorageOfMoreFieldTable();
    this.setSortData();
    this.setDateColumnTitle();

    // If I'm in the admin clock-hours
    if (this.type === 'adminClockHours') {
      // @ts-ignore
      const sort = JSON.parse(localStorage.getItem('adminClockHours-sort'));
      if (sort?.direction && sort?.active) {
        this.sortData(sort);
      }
    }
  }

  ngAfterViewInit(): void {
    try {
      if (document.querySelectorAll('.containerTable').item(0)) {
        const obs$ = fromEvent(
          document.querySelectorAll('.containerTable').item(0),
          'scroll'
        ).pipe(
          distinctUntilChanged(),
          debounce(() => timer(100))
        );

        if (obs$) {
          obs$.subscribe((res) => {
            const target = res.target as any;
            const SCROLL_MARGIN_DIFFERENCE = -200;
            const { clientHeight, scrollHeight, scrollTop } = target;

            if (
              clientHeight + scrollTop - scrollHeight >=
              SCROLL_MARGIN_DIFFERENCE
            ) {
              this.scrollBottom.next(true);
            } else {
              this.scrollBottom.next(false);
            }
          });
        }
      }
    } catch (err) {}
  }

  private resolveMobileDeviceApplication() {
    this.isMobileDeviceApplication = ['android', 'ios'].includes(
      Capacitor.getPlatform()
    );
  }

  getEntrances(clockHour: any) {
    if (clockHour?.histories?.length) {
      const items = clockHour.histories
        .map((history, index) => {
          const some = `${history?.startDate} - ${
            history?.endDate ? history?.endDate : '--:--'
          }`;

          return some;
        })
        .join('<br>');
      return items;
    } else {
      return '-';
    }
  }
  setSortData() {
    if (this.data?.length > 0 || !this.data) {
      this.data = this.data?.slice();
      this.draw();
    } else {
      setTimeout(() => {
        this.setSortData();
      }, 100);
    }
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  toLocalTimeFormatted(date: Date) {
    let formattedDate = null;

    if (date) {
      // @ts-ignore
      formattedDate =
        date.getUTCDate() +
        '/' +
        this.completeValue(date.getUTCMonth() + 1) +
        '/' +
        this.completeValue(date.getUTCFullYear()) +
        ' ' +
        this.completeValue(date.getUTCHours()) +
        ':' +
        this.completeValue(date.getUTCMinutes());
    }

    return formattedDate;
  }

  private completeValue(value) {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  }

  isExpired(dateTime: Date) {
    if (!dateTime) {
      return false;
    }
    const auxDate = new Date(dateTime);
    return moment(
      this.toLocalTimeFormatted(auxDate),
      'DD/MM/YYYY hh:mm'
    ).isBefore(new Date());
  }

  sortData(sort) {
    sort.active = sort.active.replace(this.columnPrefix, '');
    if (
      this.type === 'adminClockHours' ||
      this.type === 'clockHours' ||
      this.type === 'clockHoursDetail' ||
      this.type === 'clockHoursAbsence' ||
      this.type == 'person'
    ) {
      // Storing the sort column and mode on localStorage
      this.auxSort = { ...sort };
      if (this.type != 'person') {
        localStorage.setItem('adminClockHours-sort', JSON.stringify(sort));
      }
      let data;
      if (this.type != 'person' || !this.data._data) {
        data = this.data.slice();
      } else {
        data = this.data._data._value;
        this.data = data;
      }

      if (this.firstOrdination) {
        this.firstOrdinationData = this.data;
        this.firstOrdination = false;
      }

      if (!sort.active || sort.direction === '') {
        this.data = this.firstOrdinationData;
        return;
      }

      this.data = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name_person':
            return this.compare(
              a.name.toLowerCase(),
              b.name.toLowerCase(),
              isAsc
            );
          case 'email':
            return this.compare(
              a.email.toLowerCase(),
              b.email.toLowerCase(),
              isAsc
            );
          case 'phone':
            return this.compare(a.phone, b.phone, isAsc);
          case 'userName':
            return this.compare(
              a.owner?.name?.toLowerCase(),
              b.owner?.name?.toLowerCase(),
              isAsc
            );
          case 'role':
            return this.compare(
              a.role[0].toLowerCase(),
              b.role[0].toLowerCase(),
              isAsc
            );
          case 'position':
            if (this.type != 'person') {
              return this.compare(
                a.owner?.position ? a.owner?.position.toLowerCase() : '',
                b.owner?.position ? b.owner?.position.toLowerCase() : '',
                isAsc
              );
            } else {
              return this.compare(
                a.position?.toLowerCase(),
                b.position?.toLowerCase(),
                isAsc
              );
            }
          case 'status':
            return this.compare(
              a.status ? a.status : '',
              b.status ? b.status : '',
              isAsc
            );
          case 'type':
            return this.compare(
              a.type ? a.type : '',
              b.type ? b.type : '',
              isAsc
            );
          case 'date':
            return this.compare(
              new Date(a.date.year, a.date.month, a.date.day),
              new Date(b.date.year, b.date.month, b.date.day),
              isAsc
            );
          case 'initDate':
            return this.compare(
              a.dateDetail.startDate ? new Date(a.dateDetail.startDate) : '',
              b.dateDetail.startDate ? new Date(b.dateDetail.startDate) : '',
              isAsc
            );
          case 'endDate':
            return this.compare(
              a.dateDetail.endDate ? new Date(a.dateDetail.endDate) : '',
              b.dateDetail.endDate ? new Date(b.dateDetail.endDate) : '',
              isAsc
            );
          case 'entrance':
            return this.compare(
              a.histories?.length,
              b.histories?.length,
              isAsc
            );
          case 'duration':
            return this.compare(
              a?.averageHoursWorked,
              b?.averageHoursWorked,
              isAsc
            );
          case 'totalDays':
            return this.compare(a?.totalDays, b?.totalDays, isAsc);
          case 'note':
            return this.compare(
              a.note ? a.note : '',
              b.note ? b.note : '',
              isAsc
            );
          default:
            return 0;
        }
      });
    } else {
      const isAsc = sort.direction === 'asc';
      // logic that allows to order the tables by custom fields
      if (this.isCustomProperty(this.data.data, sort.active)) {
        this.data.data = this.sortByCustomProperties(sort.active, isAsc);
      } else {
        // default ordering querying the database
        this.changeSort.emit(sort);
      }
    }
  }

  sortByCustomProperties(property: string, isAsc: boolean) {
    let arrayData = this.data.data;
    // @ts-ignore
    arrayData = arrayData.sort((a, b) => {
      if (this.hasProperty(a, property) && this.hasProperty(b, property)) {
        return this.compare(
          a.customProperties[property],
          b.customProperties[property],
          isAsc
        );
      }
    });
    return arrayData;
  }

  compare(a, b, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  hasProperty(item: any = {}, propertyId: string) {
    try {
      if (
        item?.customProperties.find((property) => property._id === propertyId)
      ) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  getCustomPropertyValue(item: any = {}, propertyId: string) {
    const currentProperty = item?.customProperties.find(
      (property) => property._id === propertyId
    );

    if (currentProperty?.value) {
      return currentProperty.value;
    }

    return null;
  }

  hasKeys(obj: unknown = {}) {
    try {
      // @ts-ignore
      return Object.keys(obj)?.length > 0;
    } catch (e) {
      return false;
    }
  }

  isCustomProperty(array: any = [], columnName = '') {
    return array.some((element) => {
      return this.hasProperty(element, columnName);
    });
  }

  parserProperty() {}

  menuClick() {
    this.tooltipAddMore = true;
    this.sendAmplitudeEvent('listConfiguration');
  }
  onMenuClosed() {
    this.tooltipAddMore = false;
  }

  clickMoreFields(field: { key: string; title: string }) {
    if (this.customMoreFieldsMap.has(field.key)) {
      this.customMoreFieldsMap.delete(field.key);
      this.sendAmplitudeEvent('listRemoveField');
    } else {
      this.customMoreFieldsMap.set(field.key, field);
      this.sendAmplitudeEvent('listAddField');
    }

    if (this.idModule) {
      const values = Array.from(this.customMoreFieldsMap.values());
      const parserValue = JSON.stringify(values);

      StorageService.SetItem(
        StorageService.MoreFieldKey(this.idModule),
        parserValue
      );
    }

    this.resolveColumns();
  }

  clickCustomField(evt: MouseEvent, value: ICustomProperty) {
    evt.stopPropagation();

    if (this.customFieldsMap.has(value.name)) {
      this.customFieldsMap.delete(value.name);
      this.sendAmplitudeEvent('listRemoveCustomField');
    } else {
      this.customFieldsMap.set(value.name, value);
      this.sendAmplitudeEvent('listAddCustomField');
    }

    this.storageCustomProperties();
    this.resolveColumns();
  }

  isCheckMoreField(fieldName: string) {
    return Array.from(this.customMoreFieldsMap.keys()).includes(fieldName);
  }

  isCheckProperty(fieldName: any) {
    return Array.from(this.customFieldsMap)
      .map((it) => it[0])
      .includes(fieldName.name);
  }

  private storageCustomProperties() {
    if (this.idModule) {
      const values = Array.from(this.customFieldsMap.values());
      const parserValue = JSON.stringify(values);

      StorageService.SetItem(
        StorageService.CustomPropertyKey(this.idModule),
        parserValue
      );
    }
  }

  private resolveStorageCustomProperties() {
    try {
      if (this.idModule) {
        const customProperties = JSON.parse(
          StorageService.CustomProperty(this.idModule)
        ) as ICustomProperty[];

        if (customProperties?.length) {
          const customIds = customProperties.map((x) => {
            return x._id;
          });

          for (const id of customIds) {
            if (!this.customFields.find((element) => element._id === id)) {
              const propertyIndex = customProperties.findIndex(
                (item) => item._id === id
              );
              customProperties.splice(propertyIndex, 1);
              StorageService.SetItem(
                StorageService.CustomPropertyKey(this.idModule),
                customProperties
              );
            }
          }

          if (customProperties?.length) {
            for (const customProperty of customProperties) {
              if (!customProperty.name.startsWith(this.columnPrefix)) {
                this.customFieldsMap.set(customProperty.name, customProperty);
              }
            }
            this.resolveColumns();
          }
        }
      }
    } catch (err) {
      console.error('[table component resolveStorageCustomProperties]', err);
    }
  }
  private resolveStorageOfMoreFieldTable() {
    try {
      if (this.idModule) {
        const moreFields = JSON.parse(
          StorageService.GetItem(StorageService.MoreFieldKey(this.idModule))
        ) as { key: string; title: string }[];

        if (moreFields) {
          for (const field of moreFields) {
            this.customMoreFieldsMap.set(field.key, field);
          }
        }

        this.resolveColumns();
      }
    } catch (err) {
      console.error('[table component resolveStorageCustomProperties]', err);
    }
  }

  finishDeal(item) {
    this.wantFinishDeal.emit(item);
  }

  restoreFinishDeal(item) {
    this.wantRestoreFinishDeal.emit(item);
  }

  restoreCancelDeal(item) {
    this.wantRestoreCancelDeal.emit(item);
  }

  opdenModalDeleteDeal(item) {
    this.wantOpenModealDeleteDeal.emit(item);
  }

  changeStateDeal(item, stat) {
    this.wantChangeStateDeal.emit({ item, stat });
  }

  checkDealsApproveConditions(item) {
    if (
      this.type === 'deal' &&
      item?.status?.id === GlobalStatus.PendingApproval &&
      this.isAdmin === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkDealDeleteCondition(item) {
    const owner = item.createdBy;
    const user = this.user?._id;
    if (item?.cancelled) {
      return false;
    }

    if (this.type === 'deal' && owner === user) {
      return true;
    } else {
      return false;
    }
  }

  isBoolean(value: string) {
    return typeof value === 'boolean' || ['true', 'false'].includes(value);
  }

  isDate(value: string) {
    let date;
    try {
      date = new Date(value);
    } catch (e) {
      console.error(e.message);
    }
    if (
      (`${value}`.match(new RegExp('-'))?.length &&
        `${value}`.match(new RegExp('Z'))?.length) ||
      (`${value}`.match(new RegExp('-'))?.length && date)
    ) {
      return true;
    } else {
      return false;
    }
  }

  isNumber(value: any) {
    return !isNaN(+value);
  }

  isArray(value: any) {
    return Array.isArray(value);
  }

  contain(object: any, key: string) {
    return Object.keys(object).includes(key);
  }

  clickRow<T = any>(value: T) {
    this.rowClick.next(value);
  }

  openNewTabClick(ev, id) {
    this.sendAmplitudeEvent('list_expand');
    ev.stopPropagation();
    this.openNewTab.next(id);
  }

  editClick(ev: MouseEvent, evt: IActivity) {
    ev.stopPropagation();
    this.wantEdit.next(evt);
    this.selection.clear();
  }

  editMenuClick(ev: MouseEvent, evt: IActivity) {
    ev.stopPropagation();
    this.wantApprove.next(evt);
  }

  // @ts-ignore
  deleteClick<T = any>(value: T, numberLine: number = null) {
    if (numberLine) {
      this.selectedLine = numberLine;
      this.draw();
      setTimeout(() => {
        // @ts-ignore
        this.selectedLine = null;
      }, 2000);
    }
    this.sendAmplitudeEvent('list_delete');
    this.trigger?.closeMenu();
    this.wantDelete.next(value);
  }

  restoreClick<T = any>(value: T) {
    this.wantRestore.next(value);
  }

  deleteClickDeal<T = any>(value: T) {
    this.sendAmplitudeEvent('list_delete');
    this.wantDeleteDeal.next(value);
  }

  deleteClickDealNoModal(value: string) {
    if (value) {
      this.wantDeleteDealNoModal.next([value]);
    }
  }

  approveDealClic<T = any>(value: T) {
    this.wantAproveDeal.next(value);
  }

  markClick(ev: MouseEvent, evt: any) {
    ev.stopPropagation();
    this.wantMark.next(evt);
  }

  watchClick(ev: MouseEvent, evt: IInternalVendor) {
    ev.stopPropagation();
    this.watchProfile.next(evt);
  }

  short(value = '', limit: 5) {
    return value.slice(0, limit);
  }

  mapVerticals(verticals: IVertical[]) {
    return verticals.map((vertical) => vertical.name);
  }

  mapUsers(users: string[]) {
    return users.map((user) => this.ownTeam.find((a) => a._id === user)?.name);
  }

  mapOwnersFromExpenseEndpoint(users: IUser[]) {
    return users.map((user) => user.name);
  }

  mapFinals(finals: string[]) {
    return finals.map(
      (final) => this.finals.find((a) => a._id === final)?.name
    );
  }

  mapUsersProject(users: IUser[]) {
    return users.map((user) => user?.name);
  }

  mapUsersRates(users: any) {
    return users.map((user) => user?.name);
  }

  mapFinalsObjNameSurname(finals: any) {
    return finals.map(
      (final) => `${final?.name} ${final?.lastName ? final?.lastName : ''}`
    );
  }

  mapAssets(assets: IAsset[]) {
    return assets?.length ? assets.map((asset) => asset?.name) : [];
  }

  getNameTypeClient(item: IFinalOut) {
    const element = TypeFinalsClients.find(
      (channel) => channel.id === item.type
    );

    return element ? element[this.lang] : this.tagNotAvailable;
  }

  getPhoneNumber(item: IUser) {
    const { phoneNumber } = item;
    if (!phoneNumber?.number) return '-';

    const { prefix = '', number = '' } = phoneNumber;
    return `${prefix}${number}`;
  }

  getNameCatchmentChannel(item: IFinalOut) {
    const element = CatchmentChannels.find(
      (channel) => channel.id === item.catchmentChannel
    );

    return element ? element[this.lang] : this.tagNotAvailable;
  }

  getClockHoursDuration(clockHour: ClockHour.Output) {
    let hourDuration = ClockHour.getTotalHourFromClockHours([clockHour], 0);
    while (hourDuration.charAt(0) === '0' && hourDuration.charAt(2) === ':') {
      hourDuration = hourDuration.substring(1);
    }
    return hourDuration.replace(':', 'hr ') + 'm';
  }

  private resolveColumns() {
    const displayedColumns = this.displayedColumns.map((item) => {
      if (item != 'notified' && item != 'select') {
        return this.columnPrefix + item;
      } else {
        return 'romadefaultcolumn' + item;
      }
    });

    const customColumns = this.customProperties.length
      ? this.customProperties.map((item) => item.name)
      : [];

    const moreFieldsColumns = [...this.customMoreFieldsMap.keys()].map(
      (el) => `${this.columnPrefix}${el}`
    );

    let columns = [...displayedColumns, ...moreFieldsColumns, ...customColumns];

    this.user$.subscribe((res) => {
      const CAN_MODIFY_TABLE =
        (this.type == 'deal' && !this.vendor) || this.type !== 'deal';

      if (this.showActions && CAN_MODIFY_TABLE) {
        if (this.type === 'clockHours' || this.type === 'clockHoursDetail') {
          columns = [
            ...displayedColumns,
            ...customColumns,
            'romadefaultcolumnactions',
          ];
        } else {
          columns = [
            ...displayedColumns,
            ...moreFieldsColumns,
            ...customColumns,
            'romadefaultcolumnactions',
          ];
        }
      }

      if (this.attachSettings && CAN_MODIFY_TABLE) {
        columns = [...columns];
      }

      columns = Array.from(new Set(columns));

      this.columns = columns;
    });
  }

  private requestOwnTeam() {
    this.userService
      .findAllByCompany(StorageService.CompanyId)
      .subscribe((res) => {
        this.ownTeam = res;
      });
  }

  private requestFinals() {
    this.finalService.search({}).subscribe((res) => {
      this.finals = res?.docs || [];
    });
  }

  showModalAssets(item) {
    const assets = this.getUserAssets(item);

    const modalRef = this.modalService.open(ModalAssetsComponent, {
      size: 'lg',
    });

    (modalRef.componentInstance as ModalAssetsComponent).assets = assets;
  }

  showModalUser(item) {
    const modalRef = this.modalService.open(ModalUsersComponent, {
      size: 'lg',
    });

    (modalRef.componentInstance as ModalUsersComponent).users = item;
  }

  getVendorName(item: IDeal) {
    let resp: IInternalVendor | IOwnTeam;

    if (item?.type === this.dealTypeRef.Team) {
      // @ts-ignore
      resp = this.ownTeam.find((a) => a._id === item?.taskers[0]);
      return resp ? resp.name : '';
    }

    return '';
  }

  getUserAssets(item: IUser) {
    const assets: IAsset[] = [];
    this.assetList.forEach((element) => {
      // @ts-ignore
      if (element.users.indexOf(item._id) > -1) {
        assets.push(element);
      }
    });

    return assets;
  }

  checkCustomValue(value) {
    if (value === undefined || value === '') {
      return this.i18n.instant(this.tagNotAvailable);
    }

    return value;
  }

  openQrLegend() {
    this.sendAmplitudeEvent('QR_helpButton');
    this.qrLegendService.open().subscribe();
  }

  getFinalName(item: IFinal) {
    return `${GetFinalName(item)} ${item.lastName ? item.lastName : ''}`;
  }

  getUserName(id: string) {
    const user = this.users.find((user) => user._id === id);
    return user ? user.name : 'No disponible';
  }

  clearChecks() {
    this.selection.clear();
    this.checkRowChanges.next(this.selection.selected);
    return;
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection?.selected?.length;
    const numRows = this.data?.filteredData
      ? this.data?.filteredData?.length
      : this.data?.length;

    return numSelected >= numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.clearChecks();
      return;
    }

    this.selection.select(
      // @ts-ignore
      ...(<any>this.data.filteredData ? this.data.filteredData : this.data)
    );
    this.checkRowChanges.next(this.selection.selected);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    // @ts-ignore
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  selectRow(row) {
    // @ts-ignore
    this.selection.toggle(row);
    this.checkRowChanges.next(this.selection.selected);
  }

  info(type: string) {
    this.sendAmplitudeEvent('list_helpButton');
    this.infoService.open(type);
  }

  getFormatedCustomerNumber(num: number) {
    if (!this.settings) {
      this.settings = this.defaultPreferences;
    }

    const number_format = numbers_format.find(
      (item) => item.id === this.settings.number_format
    )?.decimal_separator;
    const decimals = (this.settings?.decimals || 1).toString();
    let locale = '';

    if (number_format === ',') {
      locale = 'es-AR';
    } else {
      locale = 'en-US';
    }

    const digitsInfo = '1.' + decimals + '-' + decimals;

    return formatNumber(num, locale, digitsInfo);
  }

  getDate(data: { day: number; month: number; year: number }) {
    moment.locale(this.i18n.currentLang);
    this.today = moment(new Date()).format('ddd, D MMM').replace('.', '');
    let date: string;
    if (this.type === 'clockHours' || this.type === 'clockHoursDetail') {
      date = moment(new Date(data.year, data.month, data.day))
        .format('ddd, D MMM')
        .replace('.', '');
    } else {
      date = moment(new Date(data.year, data.month, data.day)).format(
        StorageService.DateFormat
      );
    }
    return date;
  }

  getCurrencySimbol() {
    const format = this.currencys.find(
      (format) => format.id === this.settings?.currency
    );

    return format ? format.value : this.DEFAULT_CURRENCY_SYMBOL;
  }

  // @ts-ignore
  checkEditRow(event, row) {
    if (
      this.type === 'clockHours' ||
      this.type === 'adminClockHours' ||
      this.type === 'clockHoursDetail'
    ) {
      if (row?._id) {
        this.editClick(event, row);
        return true;
      }

      if (this.compareCurrentDay(row?.date)) {
        this.editClick(event, row);
        return true;
      }
    } else {
      this.editClick(event, row);
    }
  }

  compareCurrentDay(data: { day: number; month: number; year: number }) {
    if (!data) return false;

    moment.locale('en');
    const today = moment(new Date()).format('YYYY-MM-DD');
    const date = moment(new Date(data.year, data.month, data.day)).format(
      'YYYY-MM-DD'
    );
    if (new Date(date) <= new Date(today)) {
      return true;
    } else {
      return false;
    }
  }

  getDaysDifference(clockAbsence) {
    const { startDate, endDate } = clockAbsence.dateDetail;
    const type = clockAbsence.typeDetail.type;
    if (startDate && endDate) {
      return ClockAbsence.GetTotalDays({
        startDate: new Date(startDate).toDateString(),
        endDate: new Date(endDate).toDateString(),
        type,
      });
    } else {
      return this.i18n.instant('general.propertyNotAvailable');
    }
  }

  sendAmplitudeEvent(evt: string): void {
    if (!this.type) {
      return;
    }

    const typeMapping = {
      person: 'user',
      deal: 'service',
      activities: 'task',
      activity: 'task',
      finals: 'final',
      expenses: 'expense',
      projects: 'project',
      assets: 'address',
      equipments: 'equipment',
      catalogues: 'catalogue',
    };

    const prefix =
      typeMapping[this.type.toLowerCase()] || this.type.toLowerCase();

    this.amplitudeService.sendEvent({
      event: `${prefix}_${evt}`,
    });
  }

  isEmptyHour(date: Date, item?: any, type?: string) {
    if (type === 'deal') {
      if (item.endDate) {
        return false;
      }
    }
    return (
      typeof date === 'object' &&
      !date.getHours() &&
      !date.getMinutes() &&
      !date.getSeconds()
    );
  }

  getStatusColor(status) {
    if (this.type === 'sales') {
      return status.color;
    }

    if (status?.name) {
      const result = this.statusData.find((storedStatus) => {
        return storedStatus.name === status?.name;
      });

      return result?.color;
    }
  }

  private requestUsers() {
    if (
      [
        'deal',
        'adminClockHours',
        'clockHoursAbsence',
        'activities',
        'activity',
      ].includes(this.type)
    ) {
      this.sub$.add(
        this.userService
          .findAllByCompany(StorageService.CompanyId)
          .subscribe((res) => {
            this.allUsers = res;
            this.draw();
          })
      );
    }
  }

  changeStatus(item: IActivity, status: { _id: string; name: string }) {
    this.changeStatusEvt.emit({ ...status, item });
  }

  finishTask(item: IActivity) {
    this.finishTaskEvt.emit(item);
  }

  getUserImgByName(
    name?: string,
    defaultIcon = '/assets/icons/gl_contacto_fill.svg'
  ) {
    let img;
    if (this.allUsers?.length > 0) {
      img = this.allUsers?.find((it) => it.name == name)?.img;
    }
    return img;
  }

  toAvatarUser(item: Partial<User>) {
    if (!item.img) {
      item.img = this.getUserImgByName(item.name);
    }
    return item;
  }

  mapArray(items, field) {
    return items?.map((it) => it[field]);
  }

  openUsersDialog(users: [], title: string) {
    const modalRef = this.dialog.open(UsersListResumeComponent);
    (modalRef.componentInstance as UsersListResumeComponent).data = users;
    (modalRef.componentInstance as UsersListResumeComponent).title = this.i18n.instant(
      title
    );
  }

  setDateColumnTitle() {
    switch (this.type) {
      case 'deal':
        this.dateColumnTitle = this.i18n.instant('table.colum.dateHour');
        break;
      case 'clockHoursAbsence':
        this.dateColumnTitle = this.i18n.instant('table.colum.init');
        break;
      default:
        this.dateColumnTitle = this.i18n.instant('table.colum.dateinit');
        break;
    }
  }

  printSale(sale): void {
    this.isPrinting = true;
    const user = this.authService.user;
    this.salesService
      .getPdf({
        ids: [sale?._id],
        ticketNumber: sale?.number?.toString(),
        finalName: sale?.final?.title?.toString(),
        user: {
          _id: user._id,
          company: user.company._id,
        },
        lang: this.lang,
      })
      .toPromise()
      .then((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        const printWindow = window.open(url);
        printWindow.onload = function () {
          printWindow.print();
        };
      })
      .finally(() => {
        this.isPrinting = false;
        this.draw();
      });
  }

  openSale(item) {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_list_open,
    });
    this.createSaleService
      .open({
        position: { right: '0px' },
        data: {
          mode: 'EDIT',
          idSale: item._id,
        },
      })
      .pipe(map((res) => !res))
      .subscribe();
  }

  downloadSale(item) {
    this.analyticsService.trackEvent({
      sources: ['amplitude'],
      eventName: AmplitudeEvents.sale_list_download,
    });
    this.ticketSelected = item._id;
    this.wantDownloadTicket.next(item);
  }

  lineTooltip(item?: any) {
    if (this.type === 'clockHours' || this.type === 'clockHoursDetail') {
      if (!this.compareCurrentDay(item?.date)) {
        return this.i18n.instant(
          this.type === 'clockHoursDetail'
            ? 'table.colum.noRegisterAdmin'
            : 'table.colum.noRegister'
        );
      }
    }
    return null;
  }

  watchFreeLimit() {
    this.sub$.add(
      this.freeLimitService.freeLimitSource$.subscribe((res) => {
        this.freeLimit = res;
      })
    );

    this.isLoadingFreeLimits = true;
    this.draw();

    this.sub$.add(
      this.freeLimitService
        .getLimit(FreeLimitType.AllowedTypes.CustomProperty)
        .pipe(
          finalize(() => {
            this.isLoadingFreeLimits = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          // this.freeLimit = res;
          this.freeLimitService.setFreeLimit(res);
        })
    );
  }

  getPadStringByNumber(num: number): string {
    return num?.toString().padStart(2, '0');
  }

  parseAverageTimeWorked(clockHour: ClockHour.Calendar): string {
    if (
      !clockHour ||
      !Array.isArray(clockHour.histories) ||
      clockHour.histories.length === 0
    ) {
      return '-';
    }

    let totalHours = 0;
    let totalMinutes = 0;

    clockHour.histories.forEach((history) => {
      if (history.startHour.hour !== null && history.endHour.hour !== null) {
        const start = history.startHour;
        const end = history.endHour;

        let durationMinutes =
          end.hour * 60 + end.minute - (start.hour * 60 + start.minute);
        if (durationMinutes < 0) {
          durationMinutes += 24 * 60;
        }

        totalHours += Math.floor(durationMinutes / 60);
        totalMinutes += durationMinutes % 60;
      }
    });

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;

    const hours = this.getPadStringByNumber(totalHours);
    const minutes = this.getPadStringByNumber(totalMinutes);

    return `${hours}hr ${minutes}m`;
  }

  getClassForAbsenceType(type: number): string {
    return ABSENCE_CLASS_MAP[type] || '';
  }

  resendInvitation(item: any): void {
    this.wantResendInvitation.next(item);
  }
}
