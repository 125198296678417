<roma-modal-template [showSuccessBtn]="false" (cancel)="cancelEvt()">
  <div class="custom-modal__body video">
    <ng-container *ngIf="showLoading">
      <roma-loading *ngIf="isLoading"></roma-loading>
    </ng-container>

    <ng-container *ngIf="videoId">
      <youtube-player
        [width]="640"
        [height]="360"
        [videoId]="videoId"
        (stateChange)="playerStatus($event)"
      >
      </youtube-player>
    </ng-container>

    <ng-container *ngIf="!videoId && url">
      <iframe
        width="640"
        height="360"
        [src]="url"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      >
      </iframe>
    </ng-container>
  </div>
</roma-modal-template>
