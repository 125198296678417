<roma-mobile-clock-hour
  *ngFor="let item of source"
  [content]="{
    id: item.id,
    title: item.title,
    addTitleLabel: item.addTitleLabel,
    description: item.description,
    date: item.date,
    hour: item.hour,
    status: item.status,
    absence: item.absence,
    hasHoliday: item?.hasHoliday
  }"
  [disabled]="isGreaterThanToday(item.date)"
  (actions)="makeActions($event, item)"
></roma-mobile-clock-hour>
