import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@web-frontend/shared/services';

@Component({
  selector: 'roma-login',
  templateUrl: './impersonate.component.html',
})
export class ImpersonateComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  public async ngOnInit(): Promise<void> {
    const accessToken = this.route.snapshot.queryParamMap.get('accessToken');
    await this.authService.impersonate(accessToken);
    this.router.navigate(['/admin/settings/general']);
  }
}
