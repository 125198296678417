<div class="row">
  <!-- Stripe Snackbar -->
  <roma-shared-stripe-snackbar></roma-shared-stripe-snackbar>

  <!-- Trial Disclaimer -->
  <roma-pro-disclaimer-banner
    *ngIf="isLowerThan1450px"
    (emitRefresh)="refresh()"
    [rootModule]="'timetracking2'"
  ></roma-pro-disclaimer-banner>

  <div class="col-12" style="position: relative">
    <div class="page-header">
      <h1 class="page-title">
        {{ 'clockHour.title' | translate }}
        <div class="tooltip-container">
          <img
            width="32px"
            class="help-icon"
            [src]="iconDialog"
            (click)="showDialog()"
          />
          <div
            *ngIf="showToolTip"
            class="title-tooltip-wrapper"
            (click)="toggleTooltip(false)"
          ></div>
          <div
            class="help-tooltip 22"
            *ngIf="showToolTip"
            romaRomaToolTip
            [showToolTip]="showToolTip"
            (clicked)="toggleTooltip($event)"
          >
            <div [innerHTML]="'clockHour.tooltip' | translate"></div>
            <a
              *ngIf="academyLink?.length"
              [href]="academyLink"
              target="_blank"
              >{{ 'clockHour.tooltipUrl' | translate }}</a
            >
          </div>
        </div>
        <mat-icon
          *ngIf="(resize$ | async)?.payload?.mobile"
          class="material-icons mr-1 float-end"
          (click)="openMobileActions = true"
        >
          more_vert
        </mat-icon>
      </h1>

      <div class="page-options">
        <div *ngIf="!(resize$ | async)?.payload?.mobile" style="display: flex">
          <!-- Videotutorial button -->
          <button
            *ngIf="getLang() === 'es'"
            class="tutorial"
            [ngClass]="{
              'is-hidden': isLoading
            }"
            (click)="openVideo('clock-hours-mine')"
          >
            <strong>{{ 'tutorial.tutorial' | translate }}</strong>
            <img class="video" src="assets/icons/gl_play-tutorial.svg" />
          </button>
          <!-- Videotutorial button -->

          <roma-shared-download-btn
            *ngIf="clockHours?.length"
            [featureExcel]="featureRef.ClockHour.DownloadExcel"
            [showDownloadExcel]="true"
            [showDownloadPdf]="false"
            (download)="downloadReport($event)"
            style="margin-left: 10px; margin-right: 0; width: 140px"
            [ngClass]="{ 'is-hidden': isLoading }"
          ></roma-shared-download-btn>
        </div>

        <div
          class="chronometer-container"
          [ngClass]="{ 'active-time-tracking': start }"
        >
          <div *ngIf="start" class="time-info">
            <span class="tracking">
              {{ 'clockHour.timeTracking.clockIn' | translate }}
            </span>
            <span class="time">{{ currentTimeTracked }}</span>
          </div>

          <div *ngIf="chronoLoading" class="time-info">
            <roma-loading [size]="30"></roma-loading>
          </div>

          <div
            *ngIf="(resize$ | async)?.payload?.mobile && isLoading"
            class="loader"
          >
            <mat-spinner [diameter]="60"></mat-spinner>
          </div>

          <!-- ⭐️ pro feature " -->
          <base-button
            size="xsmall"
            [ngClass]="isLoading ? 'is-hidden' : start === true ? 'danger' : ''"
            romaProFeature
            [feature]="featureRef.ClockHour.ViewTimer"
            [featureUser]="featureUserRef.ClockHour.create"
            (clicked)="createNewEntry($event)"
          >
            <ng-container *ngIf="!start; else timeTrackingStart">
              <img class="icon-star-pro-feature" src="assets/icons/star.svg" />
              <ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
                {{ 'clockHour.timeTracking.startTracking' | translate }}
              </ng-container>
              <ng-container *ngIf="(resize$ | async)?.payload?.mobile">
                {{ 'pro.clockHour.clockAutomatic' | translate }}
              </ng-container>
              <img src="assets/icons/gl_play_v2.svg" />
            </ng-container>
            <ng-template #timeTrackingStart>
              <div>
                {{ 'clockHour.timeTracking.stopTracking' | translate }}
                <img src="assets/icons/gl_stop-icon.svg" />
              </div>
            </ng-template>
          </base-button>
          <!--/ pro feature -->

          <base-button
            *ngIf="!isLoading && !start"
            buttonClass="secondary"
            size="xsmall"
            romaProFeature
            [feature]="featureManual"
            [featureUser]="featureUserRef.ClockHour.create"
            [isLoading]="addButtonLoading"
            [ngClass]="{ mt10: (resize$ | async)?.payload?.mobile }"
            (clicked)="launchModalClockHour()"
          >
            {{ 'pro.clockHour.clockManual' | translate }}
          </base-button>
        </div>
        <!--/ feature timer -->
      </div>

      <div *ngIf="timeTrackingError" class="time-tracking-error">
        <img class="prefix-icon" src="assets/icons/gl_danger-icon.svg" />
        {{ 'clockHour.timeTracking.error' | translate }}
        <img
          class="sufix-icon"
          src="assets/icons/gl_close-red.svg"
          (click)="timeTrackingError = false"
        />
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col--12">
    <div class="total-info-header">
      <div class="clockhours-filters">
        <div class="filter-wrapper">
          <button
            class="btn sm"
            [ngClass]="{ active: view === clockHourViewRef.Week }"
            (click)="changeClockHourView(clockHourViewRef.Week)"
          >
            {{ 'clockHour.weekly' | translate }}
          </button>
          <button
            class="btn sm"
            [ngClass]="{ active: view === clockHourViewRef.Month }"
            (click)="changeClockHourView(clockHourViewRef.Month)"
          >
            {{ 'clockHour.monthly' | translate }}
          </button>
        </div>
      </div>
      <div *ngIf="isLoadingMetrics" class="col-12">
        <roma-loading></roma-loading>
      </div>
      <div *ngIf="!isLoadingMetrics" class="wrapper-total-hours">
        <div class="row">
          <div class="col-md-3 col-6 pr-6">
            <roma-time-display
              [clockHours]="userMetrics.workedHours"
              [clockHoursLabel]="'clockHour.totalHours' | translate"
              [icon]="'clock-fill'"
              [clickable]="false"
              [totalHours]="userMetrics.contractHours"
            ></roma-time-display>
          </div>
          <div class="col-md-3 col-6 pl-6">
            <roma-time-display
              [clockHours]="userMetrics.remainingHours"
              [clockHoursLabel]="
                'person.create.form.hoursToClockIn' | translate
              "
              [icon]="'to-clock-in'"
              [clickable]="userMetrics.contractHours === null"
              (updateMetrics)="updateMetrics()"
            ></roma-time-display>
          </div>
          <div class="col-md-3 col-12">
            <roma-time-display
              [clockHours]="userMetrics.extraHours"
              [clockHoursLabel]="'person.create.form.overtime' | translate"
              [icon]="'clock-overtime'"
              [clickable]="userMetrics.contractHours === null"
              [fullWidthMobile]="true"
              (updateMetrics)="updateMetrics()"
            ></roma-time-display>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="wrapper-overlay" style="display: contents">
  <div class="clockhours-filters">
    <div *ngIf="!(resize$ | async)?.payload?.mobile" class="filter-wrapper">
      <button
        class="btn"
        [ngClass]="{ active: view === clockHourViewRef.Week }"
        (click)="changeClockHourView(clockHourViewRef.Week)"
      >
        {{ 'clockHour.weekly' | translate }}
      </button>
      <button
        class="btn"
        [ngClass]="{ active: view === clockHourViewRef.Month }"
        (click)="changeClockHourView(clockHourViewRef.Month)"
      >
        {{ 'clockHour.monthly' | translate }}
      </button>
    </div>

    <div class="wrapper-pagination">
      <button
        class="btn arrow-btn prev"
        (click)="
          view === clockHourViewRef.Week
            ? changeWeek(firstWeekDay, 'prev')
            : changeMonth(selectedMonth - 1, 'prev')
        "
      >
        <img src="assets/icons/gl_arrow_left.svg" />
      </button>
      <div *ngIf="view === clockHourViewRef.Week" class="filter-info">
        {{ firstWeekDay }}
        {{
          lastWeekDay < firstWeekDay
            ? stringRemainingMonth
            : (stringMonth | titlecase)
        }}
        - {{ lastWeekDay }} {{ stringMonth | titlecase }}
      </div>
      <div *ngIf="view === clockHourViewRef.Month" class="filter-info">
        {{ stringMonth | titlecase }} {{ currentYear }}
      </div>
      <button
        class="btn arrow-btn next"
        (click)="
          view === clockHourViewRef.Week
            ? changeWeek(lastWeekDay + 1, 'next')
            : changeMonth(selectedMonth + 1, 'next')
        "
      >
        <img src="assets/icons/gl_arrow_left.svg" />
      </button>
    </div>
  </div>

  <ng-container *ngIf="(resize$ | async)?.payload?.mobile">
    <roma-list-mobile-clock-hour
      *ngIf="!isLoading && clockHours"
      [data]="clockHours"
      (actions)="makeMobileActions($event)"
    ></roma-list-mobile-clock-hour>
  </ng-container>

  <div
    id="containerTable"
    class="containerTable"
    [ngStyle]="{ 'margin-top': tableMarginTop }"
    (scroll)="checkScroll($event)"
  >
    <div class="my-4">
      <div *ngIf="isLoading" class="col-12">
        <roma-loading></roma-loading>
      </div>

      <div
        *ngIf="!(resize$ | async)?.payload?.mobile"
        class="col-12"
        [ngClass]="{ 'is-hidden': isLoading || !clockHours?.length }"
      >
        <roma-table
          class="table"
          type="clockHours"
          idModule="CLOCK_HOUR_MODULE"
          [appendSettings]="'NO'"
          [tagNotAvailable]="'activity.notAvailable' | translate"
          [data]="clockHours"
          [displayedColumns]="columns"
          [showActions]="true"
          (wantEdit)="launchModalClockHourEdit($event)"
          (wantDelete)="deleteClockHour($event)"
        >
        </roma-table>
      </div>
    </div>
  </div>
  <div *ngIf="openMobileActions" class="mobile-viewtype-wrapper mobile-actions">
    <div class="viewtype-list">
      <h4>{{ 'general.actions' | translate }}</h4>
      <div class="link-list">
        <div
          *ngIf="getLang() === 'es'"
          class="list-item"
          (click)="openVideo('clock-hours-mine'); openMobileTeamActions = false"
        >
          <span class="icon-text">
            {{ 'tutorial.tutorial' | translate }}
          </span>
          <mat-icon class="material-icons mr-1 prev-icon">
            play_arrow
          </mat-icon>
        </div>
        <div
          class="list-item"
          (click)="downloadReport(); openMobileActions = false"
        >
          <span class="icon-text">
            {{ 'general.downloadExcel' | translate }}
          </span>
          <mat-icon class="material-icons mr-1 prev-icon">
            download_alt
          </mat-icon>
        </div>
        <div class="text-center close-btn-wrapper">
          <img
            (click)="openMobileActions = false"
            class="close-btn"
            src="/assets/icons/btn_close.svg"
            alt="close"
          />
        </div>
      </div>
    </div>
  </div>
</div>
