<div
  *ngIf="(remainingDays > 0 && !proPlanActivated) || showDisclaimer"
  class="container-disclaimer"
  [ngClass]="
    isLowerThan1450px && (resize$ | async)?.payload?.mobile === false
      ? 'margin-bottom-desktop'
      : ''
  "
>
  <div
    class="roma-disclaimer warning"
    [ngClass]="{
      mobile: (resize$ | async)?.payload?.mobile
    }"
  >
    <div class="content-wrapper">
      <div class="icon top">
        <img src="assets/icons/star.svg" />
      </div>
      <div [innerHtml]="geti18nParsedString() | translate"></div>
    </div>
    <br />
    <span (click)="openBuyModel()" class="link">{{
      'pro.disclaimer.button' | translate
    }}</span>
  </div>
  <div *ngIf="!isLowerThan1450px" class="header-separator"></div>
</div>

<!-- Disclaimer for maxUsers -->
<div
  *ngIf="maxUsersDisclaimer && originalRootModule === 'timetracking2' && false"
  class="container-disclaimer"
>
  <div class="roma-disclaimer warning">
    <div class="content-wrapper">
      <div class="icon top">
        <img src="assets/icons/star.svg" />
      </div>
      <div [innerHTML]="'pro.clockHour.disclaimer.maxUsers' | translate"></div>
    </div>
    <span
      *ngIf="!(resize$ | async)?.payload?.mobile"
      romaProFeature
      [feature]="featureRef.ClockHour.DownloadExcel"
      class="link"
    >
      {{ 'pro.clockHour.disclaimer.button' | translate }}
    </span>
  </div>
  <div *ngIf="!isLowerThan1450px" class="header-separator"></div>
</div>
