<div
  *ngIf="!user"
  class="picture-image"
  [ngStyle]="
    src
      ? { 'background-image': 'url(' + src + ')' }
      : previewFile
      ? { 'background-image': 'url(' + previewFile + ')' }
      : null
  "
></div>
<roma-user-avatar
  *ngIf="user"
  [user]="user"
  width="120"
  height="120"
></roma-user-avatar>

<platform-disable-content
  allowedPlatforms="['web', 'android']"
  [disableForMobileWebBrowser]="true"
>
  <mat-icon *ngIf="showIcon" class="absolute-center pointer edit-icon">
    <img src="assets/icons/gl_edit.svg" />
  </mat-icon>

  <input
    hidden
    [disabled]="!canClick"
    #fileInput
    type="file"
    id="file"
    (change)="handleImageFile($event?.target?.files)"
  />
</platform-disable-content>
