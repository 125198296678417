import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UserAvatarComponent } from './user-avatar.component';
import { ImageModule } from '../../pipes/image';

@NgModule({
  declarations: [UserAvatarComponent],
  imports: [CommonModule, TranslateModule.forChild(), ImageModule.forRoot()],
  exports: [UserAvatarComponent],
})
export class UserAvatarModule {}
