import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@web-frontend/environments';
import { StorageService } from '@web-frontend/shared/services';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalEmptyObjectComponent } from '@web-frontend/shared/components/modal-empty-object';
import { ClickLogoutService } from '@web-frontend/shared/directives/click-logout';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { authRoutes } from '../../../core/auth/auth-routing.module';

import * as Sentry from '@sentry/angular';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private logOutService: ClickLogoutService
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request = req;
    let authorization = null;

    if (
      req.url.indexOf(':API_URL') > -1 ||
      req.url.indexOf(':API_BASE_URL') > -1
    ) {
      if (req.headers.get('Authorization') === null) {
        authorization = StorageService.Token;
      } else {
        authorization = req.headers.get('Authorization').split(' ')[1];
      }

      request = authorization
        ? req.clone({
            url: this.parseURL(req.url),
            setHeaders: {
              Authorization: authorization ? `Bearer ${authorization}` : '',
              language: StorageService.GetItem('USER_LANG'),
              timestamp: new Date().toISOString(),
              apiUrl:
                req.url.indexOf(':API_URL') > -1
                  ? environment.api.url
                  : environment.api.urlPlain,
            },
          })
        : req.clone({
            url: this.parseURL(req.url),
          });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        Sentry.captureException(err, {
          level: 'warning',
        });
        this.checkEmptyObjects(err);
        if (
          err instanceof HttpResponse &&
          err.status === 256 &&
          !localStorage.getItem('register')
        ) {
          this.logOutService.logout();
          this.router.navigateByUrl(authRoutes.login);
        }
        return throwError(err);
      })
    );
  }

  private parseURL(url: string): string {
    const apiUrl = environment.api.url;
    const apiBaseUrl = environment.api.urlPlain;

    url = url.replace(':API_URL', apiUrl);
    url = url.replace(':API_BASE_URL', apiBaseUrl);

    return url;
  }

  private checkEmptyObjects(err: HttpErrorResponse) {
    if (err instanceof HttpErrorResponse && err.status === 400) {
      switch (err?.error?.message) {
        case 'DEAL_ID_NOT_EXIST':
          this.showModalEmptyObject('deal');
          break;

        case 'ACTIVITY_ID_NOT_EXIST':
          this.showModalEmptyObject('activity');
          break;

        case 'ASSET_ID_NOT_EXIST':
          this.showModalEmptyObject('asset');
          break;
        case 'FINAL_ID_NOT_EXIST':
          this.showModalEmptyObject('final');
          break;
        case 'VENDOR_ID_NOT_EXIST':
          this.showModalEmptyObject('vendor');
          break;

        case 'EQUIPMENT_ID_NOT_EXIST':
          this.showModalEmptyObject('equipment');
          break;

        case 'PROJECT_ID_NOT_EXIST':
          this.showModalEmptyObject('project');
          break;

        default:
          break;
      }
    }
  }

  private showModalEmptyObject(type) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
    };
    const modalRef = this.modalService.open(
      ModalEmptyObjectComponent,
      ngbModalOptions
    );

    (modalRef.componentInstance as ModalEmptyObjectComponent).type = type;
  }
}
