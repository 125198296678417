import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MobileClockHour } from './mobile-clock-hour.types';
import { FeatureUser } from '@tacliatech/types';
import { ABSENCE_CLASS_MAP } from '../constants';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'roma-mobile-clock-hour',
  templateUrl: './mobile-clock-hour.component.html',
  styleUrls: ['./mobile-clock-hour.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileClockHourComponent implements OnInit {
  @HostBinding('class.card__clock__hour')
  hostClass = true;

  @HostBinding('class.bg-holiday')
  hasHoliday = false;

  @HostBinding('class.is-disabled')
  @Input()
  disabled = false;

  @Input()
  content: MobileClockHour.Content;

  @Input()
  actionOptions = MobileClockHour.defaultActionsOptions;

  @Output()
  actions = new EventEmitter<MobileClockHour.Action>();

  featureRefUser = FeatureUser;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'holidayIcon',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        'assets/icons/party-horn.svg'
      )
    );
  }

  ngOnInit(): void {
    if (this.content?.hasHoliday) {
      this.hasHoliday = true;
    }
  }

  @HostListener('click', ['$event'])
  click(evt: MouseEvent) {
    if (!this.disabled) {
      this.actions.emit({
        action: 'SIGN',
        date: this.content.date,
        id: this.content.id,
      });
    }
  }

  makeAction(action: 'SIGN' | 'DELETE') {
    this.actions.emit({ action, date: this.content.date, id: this.content.id });
  }

  getClassForAbsenceType(type: number): string {
    return ABSENCE_CLASS_MAP[type] || '';
  }
}
