<div
  class="user-avatar"
  [ngStyle]="{
    fontSize: (14 * width) / 32 + 'px',
    width: width + 'px',
    height: height + 'px'
  }"
>
  <img
    *ngIf="user?.img; else defaultAvatar"
    [src]="user?.img | imageFirebase: 'user'"
    [ngStyle]="{ width: width + 'px', height: height + 'px' }"
  />
  <ng-template #defaultAvatar>
    <span>
      {{ name }}
    </span>
  </ng-template>
</div>
