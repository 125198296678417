<roma-modal-template
  size="md"
  [showDefaultButtons]="false"
  [showHeader]="false"
  [footerTemplate]="footerCustomTemplate"
  (cancel)="close(false)"
>
  <h2 class="owner-name">
    <roma-user-avatar
      *ngIf="clockAbsence?.owner?.name"
      [user]="clockAbsence?.owner"
    ></roma-user-avatar>
    {{ clockAbsence?.owner?.name || ('clockHour.absence' | translate) }}
  </h2>
  <div class="data-flex-container">
    <div class="item item-name">
      {{ 'general.init' | translate }}
      -
      {{ 'general.end' | translate }}
    </div>
    <div class="item item-info">
      <div class="hours-range">
        <span class="clock-icon">
          <img src="assets/icons/gl_clock-outline.svg" />
        </span>
        {{ clockAbsence?.dateDetail?.startDate | dateDayMonthConvert }}
        <img src="assets/icons/gl_arrow-right.svg" style="margin: 0 6px" />
        {{ clockAbsence?.dateDetail?.endDate | dateDayMonthConvert }}
      </div>
    </div>
  </div>
  <div class="data-flex-container">
    <div class="item item-name">Total</div>
    <div class="item item-info">
      {{ getTotalDays() }}
      <ng-container *ngIf="getTotalDays() == 1">
        {{ 'general.day' | translate }}
      </ng-container>
      <ng-container *ngIf="getTotalDays() != 1">
        {{ 'general.days' | translate }}
      </ng-container>
      <span *ngIf="showDeductTime" style="color: #6a79a0"
        >({{ 'general.deductTime' | translate }})</span
      >
    </div>
  </div>
  <div class="data-flex-container">
    <div class="item item-name">
      {{ 'general.typeAbsence' | translate }}
    </div>
    <div class="item item-info">
      {{ getNameTypeAbsence() | translate }}
    </div>
  </div>
  <div class="data-flex-container">
    <div class="item item-name">
      {{ 'general.status' | translate }}
    </div>
    <div class="item item-info">
      <ng-container [ngSwitch]="clockAbsence?.status">
        <span *ngSwitchCase="'0'" class="status-wrapper warn">
          {{ 'table.content.pending' | translate }}
        </span>
        <span *ngSwitchCase="'1'" class="status-wrapper red">
          {{ 'table.content.rejected' | translate }}
        </span>
        <span *ngSwitchCase="'2'" class="status-wrapper green">
          {{ 'table.content.approved' | translate }}
        </span>
        <span *ngSwitchCase="'3'" class="status-wrapper blue">
          {{ 'table.content.others' | translate }}
        </span>
        <ng-container *ngSwitchDefault>
          {{ tagNotAvailable | translate }}
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="data-flex-container">
    <div class="item item-name">
      {{ 'general.notes' | translate }}
    </div>
    <div class="item item-info">
      {{ clockAbsence?.note }}
    </div>
  </div>
  <div *ngIf="clockAbsence?.typeDetail?.files?.length" class="file-container">
    <div class="file-container-title">
      {{ 'general.fileAttach' | translate }}
    </div>
    <div class="file-container-body">
      <div
        *ngFor="let file of clockAbsence?.typeDetail?.files"
        class="file-container-body-item"
      >
        <img
          src="assets/icons/file.svg"
          height="20"
          width="20"
          alt="icon file"
        />

        <div class="file-container-body-item-content" (click)="showFile(file)">
          {{ file }}
        </div>

        <a class="file-container-body-item-actions" (click)="deleteFile(file)">
          {{ 'table.colum.delete' | translate }}
        </a>
      </div>
    </div>
  </div>
  <ng-container *ngIf="showOptionsReasonDescription">
    <div class="container__reason">
      <label>{{ 'clockAbsence.addReason' | translate }}</label>
      <textarea
        name=""
        id=""
        [value]="''"
        cols="30"
        rows="10"
        [placeholder]="'clockAbsence.enterReason' | translate"
        (keyup)="changeReasonDescription($event.target.value)"
      >
      </textarea>
    </div>
  </ng-container>
</roma-modal-template>

<ng-template #footerCustomTemplate>
  <ng-container>
    <div class="modalFooter quickCollapse">
      <ng-container *ngIf="showOptionsReasonDescription">
        <base-button (click)="continue()">
          {{ 'general.continue' | translate }}
        </base-button>
        <base-button
          buttonClass="secondary-text-link"
          (click)="cancel()"
          style="color: #15152e !important"
        >
          {{ 'general.cancel' | translate }}
        </base-button>
      </ng-container>

      <ng-container *ngIf="!showOptionsReasonDescription">
        <div class="leftDiv">
          <base-button
            buttonClass="secondary-text-link"
            *ngIf="showButtonsOptions.showEdit"
            romaProFeature
            [featureUser]="featureUserRef.ClockHourAbsence.update"
            (clicked)="edit()"
          >
            <img src="assets/icons/gl_edit-fill.svg" />
            {{ 'general.edit' | translate }}
          </base-button>
          <base-button
            *ngIf="showButtonsOptions.showDelete"
            buttonClass="tertiary-danger"
            romaProFeature
            romaClickDelete
            [featureUser]="featureUserRef.ClockHourAbsence.delete"
            (wantDelete)="delete()"
          >
            {{ 'general.remove' | translate }}
          </base-button>
        </div>
        <div class="groupDiv">
          <base-button
            buttonClass="tertiary-danger"
            *ngIf="showButtonsOptions.showReject"
            romaProFeature
            [featureUser]="featureUserRef.ClockHourAbsenceCompany.approve"
            (clicked)="reject()"
          >
            {{ 'general.decline' | translate }}
          </base-button>
          <base-button
            *ngIf="showButtonsOptions.showApproval"
            romaProFeature
            [featureUser]="featureUserRef.ClockHourAbsenceCompany.approve"
            (clicked)="approved()"
          >
            {{ 'general.approve' | translate }}
          </base-button>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
