import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RmSelectComponent, SearchFilterPipe } from './rm-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { ImageModule } from '@web-frontend/shared/pipes/image';
import { CurrencyFormatModule } from '@web-frontend/shared/pipes/currency-format/currency-format.module';
import { PermissionsModule } from '../../permissions';
import { UserAvatarModule } from '../../user-avatar/user-avatar.module';

@NgModule({
  declarations: [RmSelectComponent, SearchFilterPipe],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatProgressSpinnerModule,
    MatCheckboxModule,
    RomaToolTipModule,
    CurrencyFormatModule,
    ImageModule.forRoot(),
    PermissionsModule,
    UserAvatarModule,
  ],
  providers: [TitleCasePipe],
  exports: [RmSelectComponent],
})
export class RmSelectModule {}
