import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileClockHourComponent } from './mobile-clock-hour.component';
import { MatMenuModule } from '@angular/material/menu';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsModule } from '../../permissions';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [MobileClockHourComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatMenuModule,
    ClickDeleteModule,
    PermissionsModule,
    MatIconModule,
  ],
  exports: [MobileClockHourComponent],
})
export class MobileClockHourModule {}
