import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DANGER = 'danger',
  TERTIARY_DANGER = 'tertiary-danger',
  SECONDARY_TEXT_LINK = 'secondary-text-link',
}

enum ButtonSize {
  MEDIUM = 'medium',
  MEDIUM_M = 'medium-margin',
  SMALL = 'small',
  XSMALL = 'xsmall',
}

@Component({
  selector: 'base-button',
  templateUrl: './baseButton.component.html',
  styleUrls: ['./baseButton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseButtonComponent implements OnInit {
  @Input()
  isLoading = false;

  @Input()
  disabled = false;

  @Input()
  loadingClass = 'blue';

  @Input()
  loadingSize = 20;

  @Input()
  icon: string;

  @Input()
  style = '';

  @Input()
  size: ButtonSize = ButtonSize.SMALL;

  @Input()
  buttonClass: ButtonType = ButtonType.PRIMARY;

  get isDisabled(): boolean {
    return this.disabled || this.isLoading;
  }

  ngOnInit(): void {
    this.changeLoadingClass();
  }

  changeLoadingClass(): void {
    const buttonClass = {
      primary: 'blue',
      secondary: 'blue',
      danger: 'white',
    };

    this.loadingClass = buttonClass[this.buttonClass];
  }
}
