/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Subscription, from, of } from 'rxjs';
import { catchError, finalize, switchMap, take } from 'rxjs/operators';

import { MatTableDataSource } from '@angular/material/table';
import {
  BudgetCommonData,
  BudgetPreferences,
  Company,
  ConvertToKeyValue,
  Feature,
  FeatureUser,
  IBIllBudget,
  IBudgetCommonData,
  IBudgetsSetting,
  IBudgetT,
  IMailSend,
  IProform,
  PaginateSort,
  SequenceTypes,
  STATUS_PROFORM_ACCEPTED,
  STATUS_BILL_PENDING,
  statusWaybill,
  TypeAmplitudeBudget,
  TypeBudget,
  TypeItemBudget,
  IItemBudget,
  IStatus,
  IBudgetSearch,
  ISummaryTotals,
} from '@tacliatech/types';

import {
  FilterItem,
  FilterItems,
} from '@web-frontend/shared/components/filter';
import {
  RemoveEmpty,
  STATUSES_BY_BUDGET_TYPE,
} from '@web-frontend/shared/utils';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import {
  AssetService,
  AuthService,
  DealService,
  FinalService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';

import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { BudgetListComponent } from '@web-frontend/shared/components/budget-list/budget-list.component';
import { BudgetPreviewerComponent } from '@web-frontend/shared/components/budget-previewer/budget-previewer.component';
import { DeleteBySelectionModalComponent } from '@web-frontend/shared/components/delete-by-selection-modal/delete-by-selection-modal.component';
import { RmFilter } from '@web-frontend/shared/components/globals/rm-filter';
import { VideoService } from '@web-frontend/shared/components/modal-video/video.service';
import { NgSelectComponent } from '@web-frontend/shared/components/ng-select/ng-select.component';
import { FileWriteService } from '@web-frontend/shared/helpers/file-write';
import {
  BudgetService,
  BudgetValidationService,
} from '@web-frontend/shared/services/budgets';
import { CompanyModuleService } from '@web-frontend/shared/services/company/company-module.service';
import { ProductService } from '@web-frontend/shared/services/products';
import { SandboxService } from '@web-frontend/shared/services/sandbox/sandbox.service';
import { Workbook } from 'exceljs';
import * as _ from 'lodash';
import { round } from 'lodash';
import { SOURCE_WEB, SOURCE_WEB_OPTIONS } from './budgets.const';
import { INIT_BUDGETS_SEARCH_PARAMS } from './budgets.types';

import { SafeResourceUrl } from '@angular/platform-browser';
import { ModalMobileInfoComponent } from '@web-frontend/shared/components/modal-mobile-info';
import { SelectSequenceComponent } from '@web-frontend/shared/components/select-sequence/select-sequence.component';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import {
  TutorialService,
  TutorialModule,
} from '@web-frontend/shared/services/tutorial';
import { ScrollService } from '@web-frontend/shared/services/scroll';
import { SequenceService } from '@web-frontend/shared/services/sequence';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';

import { CreateBudgetService } from '@web-frontend/shared/components/create-budget/create-budget.service';
import { TypeOfCRUD } from '@web-frontend/shared/enums/crud.enum';
import { DownloadType } from '@web-frontend/shared/components/download-btn/download-btn.component';
import { DownloadBtnService } from '@web-frontend/shared/components/download-btn/download-btn.service';
import {
  ConfirmationButtonClass,
  ConfirmationDialogData,
  ConfirmationOutput,
} from '@web-frontend/shared/components/confirmation-modal/confirmation-modal.component';
import { BudgetMobileListComponent } from '@web-frontend/shared/components/budget-mobile-list/budget-mobile-list.component';
import { RmFilterClearComponent } from '@web-frontend/shared/components/globals/rm-filter/rm-filter-clear/rm-filter-clear.component';
import { BillDigitalCertificateService } from '@web-frontend/shared/services/bill-digital-certificate/bill-digital-certificate.service';
import { DigitalCertificateStatusDialogComponent } from '@web-frontend/shared/components/bill-digital-certificate/digital-certificate-status-dialog/digital-certificate-status-dialog.component';
import { CertificateStatus } from 'src/types-legacy/lib/enum/certificate-status.enum';
import { InvoiceService } from '@web-frontend/invoice/invoice.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ConfirmationModalService } from '@web-frontend/shared/components/confirmation-modal/confirmation-modal.service';
import { FeatureFlagsService } from '@web-frontend/shared/services/feature-flags/feature-flags.service';
import { AnalyticsService } from '../../../shared/services/analytics/analytics.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { BudgetCalculationsService } from '@web-frontend/shared/components/create-budget/budget-calculations.service';
import { CreateFinalV2Service } from '../../../shared/components/create-final-v2';
import { CreateDealService } from '../../../shared/components/create-deal';

const documentName = {
  [TypeBudget.BUDGET]: 'budgets.title.budgets',
  [TypeBudget.PROFORM]: 'budgets.title.proforms',
  [TypeBudget.BILL]: 'budgets.title.bills',
  [TypeBudget.WAL]: 'budgets.title.waybills',
};

@Component({
  selector: 'roma-budgets',
  templateUrl: './budgets.component.html',
  styleUrls: ['./budgets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetsComponent implements OnInit, OnDestroy {
  tableMarginTop = '0px';
  distanceToTop = 0;
  summaryTotals: ISummaryTotals;

  public innerWidth = 0;
  isResponsive = false;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.tableMarginTop = '0px';
    this.calcTableDistance();
    this.innerWidth = window.innerWidth;
    this.isResponsive = this.innerWidth <= 768;
  }
  @ViewChild('items')
  dealSelect: NgSelectComponent;

  @ViewChild(BudgetListComponent)
  listComponent: BudgetListComponent;

  @ViewChild(BudgetMobileListComponent)
  mobileListComponent: BudgetMobileListComponent;

  @ViewChild(RmFilterClearComponent)
  rmFilterClearComponent!: RmFilterClearComponent;

  @HostBinding('class.roma-page')
  hostClass = true;
  searchParams = {
    limit: 50,
    page: 1,
    applyPaginate: true,
    keyword: null,
    from: null,
    to: null,
    customProperties: null,
    takeCharged: null,
    'categories[]': [],
    'customers[]': [],
    'status[]': [],
    type: null,
    deal: null,
  };

  @Input() showHeader = true;

  @Input()
  selectedItem = null;
  showTypeSelect = '';

  @Output()
  closeServiceCard = new EventEmitter<unknown>();

  scrollShow = true;
  scrollPosition = 0;

  deal: string = null;
  isDownloading = false;

  budgetMap = new Map<string, IBudgetT>();
  proformMap = new Map<string, IProform>();
  billMap = new Map<string, IBIllBudget>();
  waybillMap = new Map<string, IBIllBudget>();
  products: FilterItems = [];
  finals: FilterItems = [];
  settings: BudgetPreferences;
  AllSettings: IBudgetsSetting;

  resize$ = this.sandBoxService.screenBusChannel$;

  company_date_format = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';

  resultSearch: IBudgetSearch;
  totalPages: Array<{ page: number }> = [];

  idActiveModules$ = this.companyModuleService.idActiveModules$;
  idModuleRef = Company.IdModule;

  columns: Array<string> = [
    'select',
    'numDoc',
    'customerName',
    'date',
    'status_budget',
    'total',
    'more',
  ];

  searchParamsHasChange = false;
  isClickedBtnBill = false;
  isClickedBtnBudget = false;

  featureRef = Feature;
  featureRefUser = FeatureUser;

  sourceSearch = SOURCE_WEB;
  sourceOptions;



  scrollDown$ = this.scrollService.scrollDown$;

  academyLink = '';


  totalSelected: Array<number> = [];
  totalAmount = '-';
  currencys = this.budgetService.findAllCurrencys();

  BILL_TYPE = TypeBudget.BILL;
  WAYBILL_TYPE = TypeBudget.WAL;

  dataSnackbar: {
    type: string;
    message?: string;
    textLink?: string;
    error: 'data' | 'cert';
  } = {
    type: 'warning',
    error: 'data',
  };
  showSnackbarMexico = false;
  searchParamsObj = {};

  emptyStateTitle: string;
  emptyStateDescription: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private i18n: TranslateService,
    private budgetService: BudgetService,
    private changeDetectorRef: ChangeDetectorRef,
    public productService: ProductService,
    private finalService: FinalService,
    private dialog: MatDialog,
    private video: VideoService,
    private fileWrite: FileWriteService,
    private datePipe: DatePipe,
    private dealService: DealService,
    private sandBoxService: SandboxService,
    private amplitudeService: AmplitudeService,
    private companyModuleService: CompanyModuleService,
    private assetService: AssetService,
    private toastService: ToastService,
    public tutorialService: TutorialService,
    private scrollService: ScrollService,
    private brazeService: BrazeService,
    private readonly createBudgetService: CreateBudgetService,
    private readonly downloadBtnService: DownloadBtnService,
    private billDigitalCertificateService: BillDigitalCertificateService,
    private userService: UserService,
    private invoiceService: InvoiceService,
    private readonly budgetValidationService: BudgetValidationService,
    private _bottomSheet: MatBottomSheet,
    private readonly confirmationModalService: ConfirmationModalService,
    private readonly featureFlags: FeatureFlagsService,
    private readonly analyticsService: AnalyticsService,
    private readonly budgetCalculationsService: BudgetCalculationsService,
    private readonly createFinalService: CreateFinalV2Service,
    private readonly createDealService: CreateDealService
  ) {
    this.setSortColumn();

    this.sub$.add(
      this.resize$.subscribe((res) => {
        if (res.payload.mobile || res.payload.tablet) {
          this.isResponsive = true;
        }
      })
    );
  }

  private sub$ = new Subscription();

  activeUser$ = this.authService.user$;
  isLoading = true;
  isLoadingPaginate = true;
  isLoadingFile = false;
  isLoadingPage = false;
  displaySnackbar = false;

  get emptyStateContent(): string {
    return this._type + (this.deal || this._customerId ? 'Empty' : '');
  }

  budgetTypeAmplitude: TypeAmplitudeBudget; // Prefix to use in amplitude events
  _type: TypeBudget;
  _previousType: TypeBudget;
  set type(type: TypeBudget) {
    this.showEmptyState = false;
    this.searchParamsObj = {};
    this._previousType = this._type;
    this._type = type;
    if (type !== this._previousType) {
      if (type !== TypeBudget.BILL) {
        this.companyCanCreateBill = true;
        this.showSnackbarMexico = false;
      } else {
        this.companyCanCrBill();
        this.validateBDGData();
      }
    }

    this.getMfDocumentFeatureFlag();
    this.refreshColumns();
  }

  get type(): TypeBudget {
    return this._type;
  }

  TYPES_OF_BUDGETS = TypeBudget;
  list: MatTableDataSource<IBudgetCommonData>;
  title: string;
  buttonTitle: string;

  get types(): typeof TypeBudget {
    return TypeBudget;
  }

  get importText(): string {
    const title = documentName[this.type];

    return `${this.i18n.instant('budgets.import.button')} ${this.i18n
      .instant(title)
      .toLowerCase()}`;
  }

  get createFirstText(): string {
    return this.i18n.instant(
      `budgets.settings.create.emptyButton.${this.type}${
        this.isMobile ? '-mobile' : ''
      }`
    );
  }

  get videoHeight(): string {
    const width = window.innerWidth / 2;
    return this.isMobile ? `${width}px` : '360px';
  }

  showEmptyState = false;

  @Input()
  set typeShow(value: TypeBudget) {
    this.type = value;
    this.searchParams = {
      ...this.searchParams,
      type: this.type,
    };
    const typeSelectMapping = {
      [TypeBudget.BUDGET]: 'general.theBudget',
      [TypeBudget.PROFORM]: 'general.theProform',
      [TypeBudget.BILL]: 'general.theBill',
      [TypeBudget.WAL]: 'general.theWaybill',
    };

    if (typeSelectMapping[this.type])
      this.showTypeSelect = typeSelectMapping[this.type];

    if (this.deal) {
      this.loadData();
    }
  }

  @Input()
  set dealId(value: string) {
    this.deal = value;
    this._amplitudePrefix = 'deal_card_sale_';
    this.searchParams = {
      ...this.searchParams,
      deal: this.deal,
    };
    if (this.type) {
      this.loadData();
    }
    this.searchDealParam();
  }

  @Input()
  set customerId(value: string) {
    this._customerId = value;
    this._amplitudePrefix = 'final_card_sale_';
    this.searchParams = {
      ...this.searchParams,
      'customers[]': [this._customerId],
    };
    if (this.type) {
      this.loadData();
    }
  }

  _amplitudePrefix = '';
  get amplitudePrefix() {
    const typeToAmplitudePrefixMap = new Map<TypeBudget, string>([
      [
        TypeBudget.WAL,
        this.deal || this._customerId ? this._amplitudePrefix : '',
      ],
      [TypeBudget.BUDGET, this.deal ? 'deal_card_sale_' : ''],
      [TypeBudget.BILL, this.deal ? 'deal_card_sale_' : ''],
    ]);

    return typeToAmplitudePrefixMap.get(this.type) || '';
  }

  get isMX(): boolean {
    return this.userService.validateCountryMX();
  }

  get isAllowedByCountry() {
    return !(this.isMX && this.type === TypeBudget.BILL);
  }

  _customerId = '';

  proformsList = new MatTableDataSource<IProform>();
  budgetList = new MatTableDataSource<IBudgetT>();
  billList = new MatTableDataSource<IBIllBudget>();
  waybillList = new MatTableDataSource<IBIllBudget>();

  permissionUser: string;
  permissionUserCreate: string;
  permissionUserUpdate: string;
  permissionUserDelete: string;

  companyCanCreateBill = false;
  isLoadingBillAllow = false;

  showImportButton = false;
  featureFlagDocument = false;

  get showMfDocument(): boolean {
    return (
      this.featureFlagDocument &&
      this.type === TypeBudget.BILL &&
      !this.isMX &&
      !this.isMfMobile
    );
  }

  async ngOnInit() {
    this.getImportFeatureFlag();
    localStorage.setItem('budgetSort', null);
    this.resolveRouteParams();
    this.onResize();
    this.resolveColumnsView();
    this.getSettings();
    this.requestProducts();
    this.validateBDGData();
    this.loadSourceOptions();
  }

  private setTaxesField(
    docs: IBudgetCommonData[]
  ): IBudgetCommonData[] & { taxes?: number } {
    return docs.map((doc) => {
      const {
        totalTaxes,
      } = this.budgetCalculationsService.calculateTotalsRealTime(
        doc.items,
        this.AllSettings
      );
      return {
        ...doc,
        taxes: totalTaxes,
      };
    });
  }

  loadSourceOptions(): void {
    this.sourceOptions = SOURCE_WEB_OPTIONS({
      companyModuleService: this.companyModuleService,
      assetService: this.assetService,
      i18n: this.i18n,
      finalService: this.finalService,
      productService: this.productService,
      budgetType: this.type,
    });
  }

  getImportFeatureFlag(): void {
    this.featureFlags
      .getValue('importDocumentButton', false)
      .then((showImportButton: boolean) => {
        this.showImportButton = showImportButton;
      });
  }

  getMfDocumentFeatureFlag(): void {
    const companyCreatedAt = new Date(
      JSON.parse(StorageService.companyData).created_at
    );
    this.featureFlags
      .getValue('mf-document', false, {
        type: this.type,
        companyCreatedAt: companyCreatedAt.getTime(),
      })
      .then((showMfDocument: boolean) => {
        this.featureFlagDocument = showMfDocument;
      });
  }

  searchDealParam(): void {
    if (!this.deal) {
      return;
    }
    this.dealService
      .findOne(this.deal)
      .toPromise()
      .then((res) => {
        const typeMap = {
          [TypeBudget.BUDGET]: 'idBudget',
          [TypeBudget.PROFORM]: 'idProform',
          [TypeBudget.BILL]: 'idBill',
          [TypeBudget.WAL]: 'idWaybill',
        };

        const idKey = typeMap[this.type];
        const resId = res[idKey];
        if (resId) {
          const found = this.list.data?.find((item) => item._id === resId);
          this.selectedItem = found;
        }

        this.attachCurrent();
      });
  }

  isAcceptModalShowed = false;
  async companyCanCrBill() {
    this.isLoadingBillAllow = true;
    if (this.type === TypeBudget.BILL) {
      const result = await this.billDigitalCertificateService.isCompanyAllowedToCreateBills();
      this.companyCanCreateBill = result.isAllowed;
      if (this.companyCanCreateBill && !result.application) {
        this.isLoadingBillAllow = false;
        this.companyCanCreateBill = true;
        return;
      }
      const ls = JSON.parse(localStorage.getItem('isAcceptModalShowed'));
      if (
        ls &&
        ls.applicationId &&
        ls.applicationId === result?.application?.id
      ) {
        this.isAcceptModalShowed =
          ls.isShowed === undefined ? false : ls.isShowed;
      }
      if (result.isAllowed && !this.isAcceptModalShowed) {
        this.isAcceptModalShowed = true;
        localStorage.setItem(
          'isAcceptModalShowed',
          JSON.stringify({
            applicationId: result.application?.id,
            isShowed: true,
          })
        );

        if (window.screen.width <= 768) {
          const dialogRef = this._bottomSheet.open(
            DigitalCertificateStatusDialogComponent,
            {
              panelClass: ['bottom-sheet'],
              data: {
                status: CertificateStatus.Accepted,
              },
              disableClose: true,
            }
          );
          dialogRef.afterDismissed().subscribe((data) => {
            if (data === 'toBill') {
              this.createBudget();
            }
            this.draw();
          });
        } else {
          //open modal
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = false;
          dialogConfig.width = '600px';
          dialogConfig.height = '550px';
          dialogConfig.minWidth = '600px';
          dialogConfig.autoFocus = true;
          dialogConfig.data = {
            status: CertificateStatus.Accepted,
          };

          const dialogRef = this.dialog.open(
            DigitalCertificateStatusDialogComponent,
            dialogConfig
          );

          dialogRef.afterClosed().subscribe((data) => {
            if (data === 'toBill') {
              this.createBudget();
            }
            this.draw();
          });
        }
      }
    } else {
      this.companyCanCreateBill = true;
    }

    this.isLoadingBillAllow = false;
    this.draw();
  }

  get shouldShowBanner(): boolean {
    return (
      this.companyCanCreateBill &&
      this.showSnackbarMexico &&
      this.isMX &&
      !this.isLoadingBillAllow
    );
  }

  validateDays(application: any) {
    const expirationDate = new Date(application.expirationDate);
    // const expirationDate = new Date();
    // expirationDate.setDate(expirationDate.getDate() + 2);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 20);

    if (expirationDate <= currentDate) {
      this.showSnackbarMexico = true;
      this.dataSnackbar = {
        type: 'warning',
        message: 'budgets.error.twenty_days_warning',
        error: 'cert',
        // expirationDate: expirationDate
        //   .toLocaleDateString()
        //   .split('/')
        //   .map((value) => value.padStart(2, '0'))
        //   .join('/'),
      };
    }
  }

  validateBDGData() {
    if (this.type === TypeBudget.BILL) {
      const result = this.budgetValidationService.validateBDGData(
        this.showSnackbarMexico,
        this.dataSnackbar
      );
      this.showSnackbarMexico = result.showSnackbarMexico;
      this.dataSnackbar = result.showSnackbarMexico
        ? result.dataSnackbar
        : this.dataSnackbar;
      // TODO if showSnackbarMexico is false, validate certificate status
    }
  }

  onLinkClickSnackbar() {
    if (this.dataSnackbar.error === 'data') {
      this.router.navigate(['admin/settings'], {
        queryParams: { type: 'general-settings' },
      });
    }
  }

  validateCertificateStatus() {
    // A nivel warnings, se debe tomar como prioridad la falta de datos de la empresa antes que el estatus del certificado
    if (this.type === TypeBudget.BILL) {
      this.billDigitalCertificateService
        .isCompanyAllowedToCreateBills()
        .then((res) => {
          if (res.application) {
            this.validateDays(res.application);
          }
        });
    }
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  getLang() {
    return this.i18n.currentLang;
  }

  hasQuerySearch() {
    return (
      this.searchParams.keyword ||
      this.searchParams['ids[]'].length ||
      this.searchParams['clients[]'].length ||
      this.searchParams['owners[]'].length ||
      this.searchParams['users[]'].length ||
      this.searchParams['customers[]'].length
    );
  }

  _baseColumns: Array<string>;
  private resolveColumnsView() {
    this.sub$.add(
      this.companyModuleService.idActiveModules$.subscribe((res) => {
        if (res.length) {
          this._baseColumns = Company.FilterColumnReports({
            columnsReports: this.columns,
            nameColumnsReport: ['customerName', 'dealName'],
            idModules: [Company.IdModule.Finals, Company.IdModule.Deals],
            idActivesModules: res,
          });
          this.columns = [...this._baseColumns];

          if (!this.isAllowedByCountry) {
            this.columns.splice(this.columns.indexOf('select'), 1);
          }

          this.draw();
        }
      })
    );
  }

  private refreshColumns() {
    if (!this._baseColumns) {
      return;
    }
    this.columns = [...this._baseColumns];

    if (!this.isAllowedByCountry) {
      this.columns.splice(this.columns.indexOf('select'), 1);
    }

    this.draw();
  }

  private loadPermissionsByType(type: string): void {
    const permissionsMapping = {
      BDG: FeatureUser.Quote,
      BILL: FeatureUser.Bill,
      PROFM: FeatureUser.Proform,
      WAL: FeatureUser.Waybills,
    };

    const defaultPermissions = Feature.SystemPermission.DefaultAllow;
    const permissions = permissionsMapping[type] || defaultPermissions;

    this.permissionUser = permissions.create;
    this.permissionUserCreate = permissions.create;
    this.permissionUserUpdate = permissions.update;
    this.permissionUserDelete = permissions.delete;

    this.draw();
  }

  private async resolveRouteParams() {
    this.sub$.add(
      this.route.queryParams.subscribe(
        async (params) => {
          localStorage.setItem('budgetSort', null);
          if (params.type) {
            if (this.type !== params.type) {
              this.totalSelectedChanges([]);
              if (this.type) {
                this.rmFilterClearComponent?.emptyInputs();
                this.searchParams = { ...INIT_BUDGETS_SEARCH_PARAMS };
              }
              this.type = params.type;
              this.getModuleResources();
            }
            this.tableMarginTop = '0px';
            this.distanceToTop = 0;
          }

          const amplitudeType = {
            [TypeBudget.BUDGET]: TypeAmplitudeBudget.BUDGET,
            [TypeBudget.PROFORM]: TypeAmplitudeBudget.PROFORM,
            [TypeBudget.BILL]: TypeAmplitudeBudget.BILL,
            [TypeBudget.WAL]: TypeAmplitudeBudget.WAL,
          };
          this.budgetTypeAmplitude = amplitudeType[this.type];

          this.loadPermissionsByType(this.type);

          const page = 1;
          this.title = this.getTitle(this.type);
          this.searchParams = {
            ...this.searchParams,
            type: this.type,
            page,
          };
          this.loadData();
          this.draw();
          this.loadSourceOptions();
        },
        () => (this.isLoading = false)
      )
    );
    this.draw();
  }

  requestProducts(): void {
    this.productService.findAll().subscribe((res) => {
      this.products = ConvertToKeyValue(res, 'name', '_id');
    });
  }

  // Check if params for filters have been changed
  checkIfParamsHasChange(): void {
    const keysToCheck = [
      'categories[]',
      'customers[]',
      'from',
      'status[]',
      'to',
    ];

    const hasChange =
      keysToCheck.some(
        (key) => this.searchParams[key] && this.searchParams[key].length > 0
      ) || this.searchParams.keyword;

    this.searchParamsHasChange = hasChange;
  }

  createBudget(): void {
    if (this.showMfDocument) {
      const queryParams = {};
      if (this.deal) {
        this.createDealService.close();
        Object.assign(queryParams, { fromDealId: this.deal });
      }
      if (this._customerId) {
        this.createFinalService.close();
        Object.assign(queryParams, { fromFinalId: this._customerId });
      }
      this.router.navigate([`/admin/documents/create/${this.type}`], {
        queryParams,
      });
      return;
    }

    const dialogConfig = {
      type: this.type,
      dealId: this.deal,
      customerId: this._customerId || '',
    };
    const event = `${this.amplitudePrefix}${this.budgetTypeAmplitude}_start`;
    this.amplitudeService.sendEvent({ event });
    this.brazeService.sendEvent({ event });
    this.createBudgetService.open(dialogConfig).subscribe((it) => {
      if (it?.event === 'refresh') {
        this.loadData();
      }
    });
  }

  // LOAD INITIAL LISTS
  // -----------------------------------------------------

  loadData() {
    this.entriesForDelete = [];
    this.deleteMessage = false;
    this.fillbudgets();
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  private async getInvoicesWithContacts(invoices) {
    const contactsIds = new Set<string>();
    const contactsObject = {};

    for (const invoice of invoices) {
      const { contact_id } = invoice.header;
      if (contact_id) contactsIds.add(contact_id);
    }

    if (contactsIds.size === 0) return;

    const contactsData = await this.finalService
      .findMany(Array.from(contactsIds))
      .toPromise();

    for (const contact of contactsData.docs) {
      contactsObject[contact._id] = {
        name: contact.name,
        lastName: contact.lastName,
      };
    }

    const dataWithContacts = invoices.map((invoice) => {
      const { contact_id } = invoice.header;
      if (!contact_id) return invoice;

      const contact = contactsObject[contact_id];
      const { name, lastName } = contact;

      return {
        ...invoice,
        header: {
          ...invoice.header,
          contact: {
            ...contact,
            id: contact_id,
          },
          final: {
            name,
            lastName,
          },
        },
      };
    });

    return dataWithContacts;
  }

  async getData() {
    const query = RemoveEmpty(this.searchParams);
    return Promise.all([
      this.invoiceService.find(this.type, query),
      this.type === TypeBudget.BILL
        ? this.invoiceService.findSummary(this.type, query)
        : null,
    ]);
  }

  _isExecuting = false;
  async fillbudgets() {
    try {
      if (this._isExecuting) {
        return;
      }
      this._isExecuting = true;
      if (
        !this.showHeader &&
        !this.searchParams.deal &&
        !this.searchParams['customers[]']
      ) {
        throw Error('No deal or customer');
      }

      this.isLoading = true;
      this.draw();

      this.list = new MatTableDataSource<IBudgetCommonData>([]);

      const [data, summaryTotals] = await this.getData();
      this.summaryTotals = summaryTotals;
      if (!data.docs?.length) {

      }

      this.resultSearch = data;
      this.totalPages = [];
      for (let i = 1; i <= this.resultSearch.countPages; i++) {
        this.totalPages.push({
          page: i,
        });
      }

      if (!data.docs) data.docs = [];

      if (this.type === TypeBudget.BUDGET) {
        this.budgetList.data = data.docs;
        for (const budget of data.docs) {
          this.budgetMap.set(budget?._id, budget);
        }
        this.list = this.budgetList;
      }

      if (this.type == TypeBudget.PROFORM) {
        this.proformsList.data = data.docs;
        for (const budget of data.docs) {
          this.proformMap.set(budget?._id, budget);
        }
        this.list = this.proformsList;
      }

      if (this.type == TypeBudget.BILL) {
        this.billList.data = await this.getInvoicesWithContacts(data.docs);
        for (const budget of data.docs) {
          this.billMap.set(budget?._id, budget);
        }
        this.list = this.billList;
      }

      if (this.type == TypeBudget.WAL) {
        this.waybillList.data = data.docs;
        for (const budget of data.docs) {
          this.waybillMap.set(budget?._id, budget);
        }
        this.list = this.waybillList;
      }

      this.calculateTotal();
      this.calcTableDistance();
      this.checkIfParamsHasChange();
      this.showEmptyState =
        Object.keys(this.searchParamsObj).length === 0 &&
        data?.docs?.length === 0;
    } catch (error) {
      //
    }
    this.isLoading = false;
    this._isExecuting = false;
    this.draw();
  }

  nextPage(): void {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page + 1,
    };

    this.loadData();
  }

  goToPage(page: number): void {
    this.searchParams = {
      ...this.searchParams,
      page: page,
    };

    this.loadData();
  }

  previousPage(): void {
    this.searchParams = {
      ...this.searchParams,
      page: this.searchParams.page - 1,
    };

    this.loadData();
  }

  createBudgetAndEdit(budget: BudgetCommonData): void {
    const serviceMethodMapping = {
      [TypeBudget.BUDGET]: this.budgetService.createOne.bind(
        this.budgetService
      ),
      [TypeBudget.PROFORM]: this.budgetService.createOneProform.bind(
        this.budgetService
      ),
      [TypeBudget.BILL]: this.budgetService.createOneBill.bind(
        this.budgetService
      ),
      [TypeBudget.WAL]: this.budgetService.createOneWaybill.bind(
        this.budgetService
      ),
    };

    const serviceMethod = serviceMethodMapping[this.type];
    if (serviceMethod) {
      this.sub$.add(
        serviceMethod(budget).subscribe((data) => {
          this.router.navigate(['/admin/budgets/edit'], {
            queryParams: { type: this.type, budget: data._id },
          });
        })
      );
    }
  }

  editBudget(budget: IBudgetCommonData): void {
    this.closeServiceCard.emit(true);

    if (this.showMfDocument) {
      this.router.navigate([
        `/admin/documents/show/${this.type}/${budget._id}`,
      ]);
    } else {
      this.router.navigate(['/admin/budgets/show'], {
        queryParams: { type: this.type, budget: budget._id },
      });
    }
  }

  closeServiceCardEvent(): void {
    this.closeServiceCard.emit(true);
  }

  async toNewTab(value: string): Promise<void> {
    const eventMapping = {
      [TypeBudget.PROFORM]: 'proform_list_expand',
      [TypeBudget.BILL]: 'bill_list_expand',
      [TypeBudget.BUDGET]: 'budget_list_expand',
      [TypeBudget.WAL]: 'waybill_list_expand',
    };

    const eventName = eventMapping[this.type];
    if (eventName) {
      this.amplitudeService.sendEvent({ event: eventName });
    }

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/admin/budgets/show/'], {
        queryParams: { type: this.type, budget: value },
      })
    );

    window.open(url, '_blank');
  }

  deleteBudget(budget: IBudgetCommonData): void {
    this.budgetService.deleteOne(budget?._id, this.type).subscribe(() => {
      this.alertService.success(
        this.i18n.instant('budgets.settings.edit.successDeleted')
      );
      this.loadData();

      const eventMapping = {
        [TypeBudget.PROFORM]: 'proform_list_delete',
        [TypeBudget.BILL]: 'bill_list_delete',
        [TypeBudget.BUDGET]: 'budget_list_delete',
        [TypeBudget.WAL]: 'waybill_list_delete',
      };

      this.amplitudeService.sendEvent({
        event: eventMapping[this.type],
      });
    });
  }

  previewBudget(budget: IBudgetCommonData): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '90vw';
    dialogConfig.height = '90vh';

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      budget: budget,
      settings: this.settings,
    };
    const dialogRef = this.dialog.open(BudgetPreviewerComponent, dialogConfig);

    dialogRef.afterClosed().subscribe();
  }

  toProformBudget(budget: IBudgetCommonData): void {
    this.convertBudget(budget, TypeBudget.PROFORM);
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.budget_view_convertToProforma,
    });
  }

  toBillBudget(budget: IBudgetCommonData): void {
    this.convertBudget(budget, TypeBudget.BILL);
  }

  convertBudget(budget: IBudgetCommonData, type: string): void {
    const budgetCopy = Object.assign({}, budget);
    budgetCopy.status = STATUSES_BY_BUDGET_TYPE[type][0].id;
    delete budgetCopy['_id'];

    if (type === TypeBudget.PROFORM) {
      budget.status = STATUS_PROFORM_ACCEPTED.id;

      this.sub$.add(
        this.budgetService.createOneProform(budgetCopy).subscribe(() => {
          this.updateBudget(budget);
          this.draw();
        })
      );
    } else if (type === TypeBudget.BILL) {
      budget.status = STATUS_BILL_PENDING.id;
      this.sub$.add(
        this.budgetService.createOneBill(budgetCopy).subscribe(() => {
          this.updateBudget(budget);
          this.draw();
        })
      );
    }
  }

  updateBudget(budget: IBudgetCommonData): void {
    this.sub$.add(
      this.budgetService.updateOne(budget._id, budget).subscribe(() => {
        this.alertService.success(
          this.i18n.instant('budgets.settings.edit.successConvert')
        );
        this.loadData();
      })
    );
  }

  getTitle(type: TypeBudget): string {
    const titleMapping = {
      [TypeBudget.BUDGET]: {
        title: 'budgets.title.budgets',
        buttonTitle: 'general.addQuote',
      },
      [TypeBudget.PROFORM]: {
        title: 'budgets.title.proforms',
        buttonTitle: 'general.addProform',
      },
      [TypeBudget.BILL]: {
        title: 'budgets.title.bills',
        buttonTitle: 'general.addBill',
      },
      [TypeBudget.WAL]: {
        title: 'budgets.title.waybills',
        buttonTitle: 'general.addWaybills',
      },
    };

    const { title, buttonTitle } =
      titleMapping[type] || titleMapping[TypeBudget.BUDGET];

    this.buttonTitle = buttonTitle;
    return title;
  }

  private set source(budgets: IBudgetT[]) {
    this.list = new MatTableDataSource(budgets);
    this.draw();
  }

  private clear() {
    this.budgetMap.clear();

    this.source = [];
  }

  changeInput(keyword: string): void {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      keyword: keyword || null,
    };
    this.clear();

    this.loadData();
  }

  changeCategory(evt: FilterItems): void {
    const categories = evt.map((category) => category.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'categories[]': categories,
    };

    this.clear();

    this.loadData();
  }

  changeStatusBudget(evt: FilterItem): void {
    const status = evt?.value;

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'status[]': status,
    };

    this.clear();

    this.loadData();
  }

  changeCustomer(evt: FilterItems): void {
    const customers = evt.map((customer) => customer.value);

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'customers[]': customers,
    };

    this.clear();

    this.loadData();
  }

  changeDate(type: 'START' | 'END', value: Date): void {
    this.searchParams = {
      ...this.searchParams,
      [type === 'START' ? 'from' : 'to']: new Date(value).toISOString(),
    };

    if (this.searchParams['from'] && this.searchParams['to']) {
      this.clear();
      this.loadData();
    }
  }

  changeCharged(evt: boolean): void {
    this.searchParams = {
      ...this.searchParams,
      page: 1,
      takeCharged: evt,
    };

    this.clear();
    this.loadData();
  }

  getSettings(): void {
    const id = StorageService.CompanyId;

    this.sub$.add(
      this.budgetService
        .findSettingsByCompany(id)
        .subscribe((resp: IBudgetsSetting) => {
          if (resp) {
            this.settings = resp.budgetPreferences;
            this.AllSettings = resp;
          }
          this.draw();
        })
    );
  }

  openVideo(type: string): void {
    this.amplitudeService.sendEvent({
      event: `${this.budgetTypeAmplitude}_tutorialVideo`,
    });
    const videoData = {
      data: {
        budgetTypeAmplitude: this.budgetTypeAmplitude,
      },
    };

    const typeOfBudget = this.getType(this.type);

    this.video.open(typeOfBudget, videoData);
  }

  getType(type: TypeBudget) {
    const finder = {
      [TypeBudget.BUDGET]: 'budgets',
      [TypeBudget.PROFORM]: 'proforms',
      [TypeBudget.BILL]: 'bills',
      [TypeBudget.WAL]: 'waybills',
      default: 'budgets',
    };
    return finder[type];
  }

  downloadExcel(): void {
    const hasSelectedItems = this.entriesForDelete.length > 0;
    this.amplitudeService.sendEvent({
      event: `${this.amplitudePrefix}${this.budgetTypeAmplitude}${
        hasSelectedItems ? '_select_download_excel' : '_excelDownload'
      }`,
    });

    const workbook = new Workbook();
    const titleMapping = {
      [TypeBudget.BUDGET]: 'Budgets',
      [TypeBudget.PROFORM]: 'Proforms',
      [TypeBudget.BILL]: 'Bills',
      [TypeBudget.WAL]: 'Waybills',
    };

    const title = titleMapping[this.type];

    const worksheet = workbook.addWorksheet(title);
    let header = [
      this.i18n.instant('table.colum.numberDoc'),
      this.i18n.instant('table.colum.status'),
      this.i18n.instant('table.colum.dateHour'),
      this.i18n.instant('table.colum.expiration'),
      this.i18n.instant('table.colum.customer'),
      this.i18n.instant('table.colum.deal'),
      this.i18n.instant('table.colum.category'),
      this.i18n.instant('table.colum.paymentMethod'),
      this.i18n.instant('table.colum.taxes'),
      this.i18n.instant('table.colum.subtotal'),
      this.i18n.instant('table.colum.total'),
    ];

    const notAvailable = this.i18n.instant('generateDocument.notAvailable');
    let idActivesModules = [];
    this.companyModuleService.idActiveModules$
      .pipe(
        take(1),
        switchMap((res) => {
          if (!res || res.length === 0) {
            throw new Error('No active modules');
          }
          header = Company.FilterColumnReports({
            columnsReports: header,
            nameColumnsReport: ['Cliente', 'Servicio'],
            idModules: [Company.IdModule.Finals, Company.IdModule.Deals],
            idActivesModules: res,
          });
          idActivesModules = res;

          const headerRow = worksheet.addRow(header);
          headerRow.font = {
            color: { argb: '003c48ec' },
            bold: true,
            size: 12,
          };
          headerRow.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
          const query = RemoveEmpty(this.searchParams);
          return hasSelectedItems
            ? of({ docs: this.entriesForDelete })
            : this.budgetService.search({
                ...query,
                limit: 999999999,
                type: this.type,
              });
        }),
        switchMap((data) => {
          data.docs = this.setTaxesField(data.docs);
          for (const x1 of data.docs) {
            const temp = [];
            temp.push(x1.header?.numberDoc);
            if (this.type === TypeBudget.WAL) {
              temp.push(
                x1.status
                  ? statusWaybill.find((f) => f.id === x1.status).name
                  : notAvailable
              );
            } else {
              const statusByType: IStatus[] =
                STATUSES_BY_BUDGET_TYPE[this.type];
              temp.push(
                x1.status
                  ? statusByType.find((f) => f.id === x1.status).name
                  : notAvailable
              );
            }
            temp.push(
              x1.header?.date
                ? this.datePipe.transform(
                    x1.header?.date.replace('Z', ''),
                    this.company_date_format
                  )
                : notAvailable
            );
            if (x1.header?.dueDate && !isNaN(Date.parse(x1.header.dueDate))) {
              temp.push(
                x1.header?.dueDate
                  ? this.datePipe.transform(
                      x1.header?.dueDate.replace('Z', ''),
                      this.company_date_format
                    )
                  : notAvailable
              );
            } else {
              temp.push(notAvailable);
            }
            if (idActivesModules.includes(Company.IdModule.Finals)) {
              temp.push(
                x1.header?.final?.name
                  ? x1.header?.final?.name +
                      (x1.header?.final?.lastName
                        ? ' ' + x1.header?.final?.lastName
                        : '')
                  : notAvailable
              );
            }
            if (idActivesModules.includes(Company.IdModule.Deals)) {
              temp.push(
                x1.header?.idDeal ? x1.header?.deal?.name : notAvailable
              );
            }
            temp.push(
              x1.addicionalInfo?.category_id
                ? x1.addicionalInfo?.category?.name
                : notAvailable
            );
            temp.push(
              x1.typePayments[0] ? x1.typePayments[0]?.name : notAvailable
            );
            temp.push(x1.taxes);
            temp.push(round(x1.subtotal, 2));
            temp.push(round(x1.total, 2));
            const row = worksheet.addRow(temp);
            row.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };
          }

          worksheet.columns.forEach(function (column) {
            column.width = 27;
          });
          return from(workbook.xlsx.writeBuffer());
        }),
        switchMap((data) => {
          const blob = new Blob([data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          return from(
            this.fileWrite.writeFile({
              path: `${title}-${this.datePipe.transform(
                new Date(),
                'dd-MM-yyyy'
              )}.xlsx`,
              blob,
            })
          );
        }),
        catchError((error) => {
          console.log('Error downloading file', error);
          return of(null);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.downloadBtnService.downloadedSuccessfully();
        } else {
          this.downloadBtnService.isLoading.next(false);
        }
        this.draw();
      });
  }

  async invoiceWaybill(): Promise<void> {
    if (this.cantInvoiceWaybill()) {
      return;
    }
    const event = `${this.amplitudePrefix}waybill_list_convertToBill`;
    this.amplitudeService.sendEvent({ event });

    const length = this.entriesForDelete.length;

    const data: ConfirmationDialogData = {
      icon: 'assets/icons/to-invoice-icon.svg',
      title:
        length > 1
          ? this.i18n
              .instant('budgets.modal.waybillToInvoiceTitle')
              .replace('_NUMBER_', length)
          : 'budgets.modal.oneWaybillToInvoiceTitle',
      body:
        length > 1
          ? this.i18n
              .instant('budgets.modal.waybillToInvoiceSubtitle')
              .replace('_NUMBER_', length)
          : 'budgets.modal.oneWaybillToInvoiceSubtitle',
      confirmLabel: 'budgets.modal.btnConfirm',
      showCancel: true,
      showCancelX: true,
      confirmClass: ConfirmationButtonClass.primary,
    };

    const confirm = await this.confirmationModalService
      .open({ data: data })
      .toPromise();

    if (
      this.entriesForDelete.length > 0 &&
      confirm === ConfirmationOutput.Execute
    ) {
      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: AmplitudeEvents.waybill_list_convertToBill_start,
      });
      const budget = new BudgetCommonData();
      budget.header.contact = this.entriesForDelete[0].header?.contact;
      budget.header.contact_id = this.entriesForDelete[0].header?.contact_id;
      budget.header.prefix = undefined;
      budget.header.numberDoc = undefined;
      budget.status = undefined;
      const budgetsItems: IItemBudget[] = [];
      for (let i = 0; i < this.entriesForDelete.length; i++) {
        const data = structuredClone(this.entriesForDelete[i]);
        const waybill = {
          type: TypeItemBudget.WAYBILL,
          description: `${data.header.prefix} - ${data.header.numberDoc}`,
        };
        data.items.splice(0, 0, waybill);
        budgetsItems.push(...data.items);
      }
      budget.items = budgetsItems.flat();
      delete budget._id;
      const data = {
        type: TypeBudget.BILL,
        mode: TypeOfCRUD.CREATE,
        budget: budget,
        entries: this.entriesForDelete,
        canEditContact: false,
        invoicing: true,
      };
      this.createBudgetService.open(data).subscribe();
    }
  }

  cantInvoiceWaybill(): boolean {
    const contact = this.entriesForDelete[0]?.header?.contact;
    let isNotEqual = false;
    for (const data of this.entriesForDelete) {
      if (contact !== data.header?.contact) {
        isNotEqual = true;
        break;
      }
    }

    if (isNotEqual && !this.displaySnackbar) {
      this.displaySnackbar = true;
      setTimeout(() => {
        this.displaySnackbar = false;
        this.draw();
      }, 4000);
    }
    this.draw();
    return isNotEqual;
  }

  changeSelected(evt) {
    this.selectedItem = evt;
    this.draw();

    if (typeof evt !== 'string') {
      this.selectedItem = evt._id;
      let params = {};
      if (this.type === TypeBudget.BUDGET) {
        if (evt !== undefined) {
          params = { idBudget: evt._id };
        } else {
          params = { idBudget: null };
        }
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_sale_budget_associate,
        });
      }

      if (this.type == TypeBudget.PROFORM) {
        if (evt !== undefined) {
          params = { idProform: evt._id };
        } else {
          params = { idProform: null };
        }
      }

      if (this.type == TypeBudget.BILL) {
        if (evt !== undefined) {
          params = { idBill: evt._id };
        } else {
          params = { idBill: null };
        }
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_sale_bill_associate,
        });
      }

      if (this.type == TypeBudget.WAL) {
        if (evt !== undefined) {
          params = { idWaybill: evt._id };
        } else {
          params = { idWaybill: null };
        }
        this.amplitudeService.sendEvent({
          event: AmplitudeEvents.deal_card_sale_waybill_associate,
        });
      }

      this.draw();
      this.dealService.updateOne(this.deal, { ...params }).subscribe((res) => {
        this.alertService.success(
          this.i18n.instant('general.updatePropertySuccess')
        );
      });
    }
  }

  private attachCurrent() {
    if (this.selectedItem) {
      this.dealSelect.select({
        preventPropagation: true,
        value: this.selectedItem._id,
        label: this.selectedItem.header.numberDoc,
      });
    }
  }

  translateStatus(items: FilterItems) {
    return items.map((item) => {
      return {
        ...item,
        name: item?.key ? this.i18n.instant(item?.key) : item.key,
      };
    });
  }
  public refresh() {
    return this.draw();
  }

  applyFilters(evt: {
    categories: Array<{ value: string }>;
    customers: Array<{ value: string }>;
    status: Array<{ value: string }>;
    startDate: string;
    endDate: string;
    customProperties: { [key: string]: any };
  }) {
    const categories = evt?.categories?.length
      ? evt.categories.map((type) => type.value)
      : [];
    const customers = evt?.customers?.length
      ? evt.customers.map((type) => type.value)
      : [];
    const status = evt?.status?.length
      ? evt.status.map((type) => type.value)
      : [];
    const startDate = evt.startDate;
    const endDate = evt.endDate;

    this.searchParams = {
      ...this.searchParams,
      page: 1,
      'categories[]': categories,
      'customers[]': customers,
      'status[]': status,
      ['from']: startDate,
      ['to']: endDate,
      customProperties: JSON.stringify(evt.customProperties),
    };

    this.clear();
    this.loadData();
  }

  calcTableDistance() {
    setTimeout(() => {
      const containerTable = document.getElementById('containerTable');
      this.distanceToTop = containerTable?.getBoundingClientRect().top - 74;
    }, 200);
  }

  checkScroll(e) {
    if (this.isResponsive) {
      this.scrollShow =
        e.currentTarget.scrollTop > this.scrollPosition ? false : true;
      this.scrollPosition = e.currentTarget.scrollTop;
      this.scrollService.scroll = this.scrollShow;
    }

    if (e.target.scrollHeight > 1080) {
      if (e.target.scrollTop <= this.distanceToTop) {
        this.tableMarginTop = '-' + e.target.scrollTop + 'px';
      } else {
        this.tableMarginTop = '-' + this.distanceToTop + 'px';
      }
    }
  }
  /**********************************SELECTION********************************* */
  entriesForDelete!: any[];
  deleteMessage = false;

  get isMobile(): boolean {
    return window.screen.width < 768;
  }

  get isMfMobile(): boolean {
    return window.screen.width < 900 || window.screen.height < 768;
  }

  unselectItems(): void {
    this.entriesForDelete = [];
    this.deleteMessage = false;
    if (this.isMobile) {
      this.mobileListComponent.clear();
    } else {
      this.listComponent.clear();
    }
  }

  deleteMany(evt: any[]) {
    this.entriesForDelete = evt;
    if (evt.length > 0) {
      this.deleteMessage = true;
    } else {
      this.deleteMessage = false;
    }
  }

  openDeleteManyDialog(): void {
    const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
      panelClass: 'delete-by-selection-modal',
      data: {
        isPlural: this.entriesForDelete?.length > 1,
        body: this.i18n.instant('deleteModal.body_final'),
      },
    });

    this.amplitudeService.sendEvent({
      event: `${this.amplitudePrefix}${this.budgetTypeAmplitude}_list_select_delete_start`,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'EXECUTE') {
        this.amplitudeService.sendEvent({
          event: `${this.amplitudePrefix}${this.budgetTypeAmplitude}_list_select_delete_delete`,
        });
        this.deleteSelection(this.entriesForDelete);
      }
    });
  }

  deleteSelection(evt: IBudgetCommonData[]): void {
    const ids = evt.map((entry) => entry._id);
    this.budgetService
      .deleteManyV2({
        ids: ids,
        type: this.type,
      })
      .pipe(
        finalize(() => {
          this.deleteMessage = false;
          this.entriesForDelete = [];
          this.totalSelectedChanges([]);
        })
      )
      .subscribe(() => {
        this.toastService.show({
          text: 'general.deleteManyCorrect',
          type: 'error',
          msDuration: 4000,
          icon: 'assets/icons/kanban/delete.svg',
        });
        let eventData;
        if (this.type == TypeBudget.PROFORM) {
          eventData = {
            event: 'proform_list_delete',
          };
        }
        if (this.type == TypeBudget.BILL) {
          eventData = {
            event: AmplitudeEvents.deal_card_sale_bill_select_delete_delete,
          };
        }
        if (this.type === TypeBudget.BUDGET) {
          eventData = {
            event: AmplitudeEvents.deal_card_sale_budget_select_delete_delete,
          };
        }
        if (this.type == TypeBudget.WAL) {
          eventData = {
            event: AmplitudeEvents.deal_card_sale_waybill_select_delete_delete,
          };
        }
        this.amplitudeService.sendEvent(eventData);
        this.loadData();
      });
  }

  changeFilters(evt: RmFilter.Changes): void {
    if (Object.keys(evt.queryParams).length > 0) {
      this.totalSelected = [];
    }

    this.totalAmount = '-';

    if (evt.type === 'CHANGE' || evt.type === 'INPUT_CHANGE') {
      const { queryParams } = evt;
      this.searchParamsObj = { ...queryParams };

      if (
        JSON.stringify(queryParams) === JSON.stringify({}) &&
        evt.type === 'INPUT_CHANGE'
      ) {
        delete this.searchParams.keyword;
      }

      this.searchParams = {
        ...INIT_BUDGETS_SEARCH_PARAMS,
        ...queryParams,
      };

      if (queryParams?.customProperties) {
        this.searchParams = {
          ...this.searchParams,
          customProperties: JSON.stringify(queryParams.customProperties),
        };
      }

      this.loadData();
    }
  }

  get downloadText(): string {
    return this.entriesForDelete.length > 1 ? 'budget.zip' : 'budget.pdf';
  }

  generateTextForMultipleDownloadButton(): string {
    const isMobile = window.screen.width < 768;
    const downloadTextKeys = {
      [TypeBudget.BUDGET]: 'budgets.downloadBudget',
      [TypeBudget.PROFORM]: 'budgets.downloadProform',
      [TypeBudget.BILL]: 'budgets.downloadBill',
      [TypeBudget.WAL]: 'budgets.downloadBill', // Assuming WAL should use the same as BILL
    };
    const downloadTextShortKeys = {
      [TypeBudget.BUDGET]: 'budgets.downloadBudgetShort',
      [TypeBudget.PROFORM]: 'budgets.downloadProformShort',
      [TypeBudget.BILL]: 'budgets.downloadBillShort',
      [TypeBudget.WAL]: 'budgets.downloadBillShort',
    };

    const textKey = isMobile
      ? downloadTextShortKeys[this.type]
      : downloadTextKeys[this.type];
    let textToDownloadMultiplePdf = this.i18n.instant(textKey);

    if (this.entriesForDelete?.length >= 2) {
      textToDownloadMultiplePdf += 's';
    }

    return textToDownloadMultiplePdf;
  }

  generateDownloadName = (): string => {
    return `${this.i18n.instant(documentName[this.type])}`;
  };

  downloadFilename(budget: BudgetCommonData): string {
    const { prefix, numberDoc } = budget.header;
    const prefixTitle = prefix || (this.isMX ? '' : '0');
    return `${prefixTitle}${numberDoc ? '-' + numberDoc : ''}`;
  }

  downloadMultiplePdfs() {
    if (!this.entriesForDelete?.length) return;

    this.draw();

    this.amplitudeService.sendEvent({
      event: `${this.amplitudePrefix}${this.budgetTypeAmplitude}_select_download_pdf`,
    });

    this.isLoadingFile = true;
    if (this.entriesForDelete?.length === 1) {
      const template =
        this.entriesForDelete[0].header?.sequence_id !== undefined
          ? 'plantilla1'
          : 'budget';
      this.sub$.add(
        this.activeUser$.subscribe((user) => {
          this.budgetService
            .downloadDocument({
              document: this.entriesForDelete[0].header?.numberDoc,
              id: this.entriesForDelete[0]._id,
              type: this.type,
              lang: this.getLang(),
              fileFormat: 'pdf',
              fileName: this.downloadFilename(this.entriesForDelete[0]),
              user: {
                _id: user._id,
                company: {
                  _id: user.company._id,
                  name: this.AllSettings?.data_account?.company_name || '',
                },
              },
              template: template,
            } as IMailSend)
            .pipe(
              finalize(() => {
                this.isLoadingFile = false;
                this.draw();
                this.downloadBtnService.downloadedSuccessfully();
              })
            )
            .subscribe();
        })
      );
    } else if (this.entriesForDelete?.length >= 2) {
      this.sub$.add(
        this.activeUser$.subscribe((user) => {
          this.budgetService
            .downloadMultiplePdfs({
              ids: this.entriesForDelete.map((i) => i._id),
              type: this.type,
              lang: this.getLang(),
              fileFormat: 'pdf',
              fileName: this.generateDownloadName(),
              user: {
                _id: user._id,
                company: {
                  _id: user.company._id,
                  name: this.AllSettings?.data_account?.company_name || '',
                },
              },
              template: 'plantilla1',
            } as IMailSend)
            .pipe(
              finalize(() => {
                this.isLoadingFile = false;
                this.draw();
                this.downloadBtnService.downloadedSuccessfully();
              })
            )
            .subscribe();
        })
      );
    }
  }

  pageLoading(value: boolean) {
    this.isLoadingPage = value;
  }

  /********************************************TOOLTIP************************************************** */
  showToolTip = false;
  iconDialog = 'assets/icons/gl_title-information-outline.svg';
  toggleTooltip(evt) {
    this.showToolTip = evt;
    if (!this.showToolTip) {
      this.iconDialog = 'assets/icons/gl_title-information-outline.svg';
    } else {
      this.iconDialog = 'assets/icons/gl_information-clicked.svg';
    }
  }

  showDialog(): void {
    const isMobile = window.screen.width < 768;
    if (!isMobile) {
      this.showToolTip = !this.showToolTip;
      this.toggleTooltip(this.showToolTip);
    } else {
      this.dialog.open(ModalMobileInfoComponent, {
        data: {
          html: this.getTooltip('tooltip'),
          buttonText: 'kanban.dialog.confirmButton',
        },
      });
    }
  }

  getTooltip(source: string): string {
    const tooltipMapping = {
      tooltip: {
        [TypeBudget.BUDGET]: 'budgets.tooltip.budget.tooltip',
        [TypeBudget.BILL]: 'budgets.tooltip.bill.tooltip',
        [TypeBudget.WAL]: 'budgets.tooltip.waybill.tooltip',
        [TypeBudget.PROFORM]: 'budgets.tooltip.proform.tooltip',
      },
      url: {
        [TypeBudget.BUDGET]: `${this.academyLink}#presupuestos`,
        [TypeBudget.BILL]: `${this.academyLink}#facturas`,
        [TypeBudget.PROFORM]: `${this.academyLink}#proformas`,
        [TypeBudget.WAL]: `${this.academyLink}#albaranes`,
      },
      'url-text': {
        [TypeBudget.BUDGET]: 'budgets.tooltip.budget.tooltipUrl',
        [TypeBudget.BILL]: 'budgets.tooltip.bill.tooltipUrl',
        [TypeBudget.PROFORM]: 'budgets.tooltip.proform.tooltipUrl',
        [TypeBudget.WAL]: 'budgets.tooltip.waybill.tooltipUrl',
      },
    };

    return tooltipMapping[source]?.[this.type] || '';
  }

  getModuleResources(): void {
    this.tutorialService
      .get(<TutorialModule>this.getType(this.type))
      .pipe(
        finalize(() => {
          this.draw();
        })
      )
      .subscribe((res) => {

        this.academyLink = res?.academy;
      });
  }

  disableButtonBill(value: boolean) {
    this.isClickedBtnBill = value;
  }

  disableButtonBudget(value: boolean) {
    this.isClickedBtnBudget = value;
    if (!value) {
      this.closeServiceCard.next(true);
    }
  }

  columnsDisplayedChanges(event: Array<string>) {
    this.columns = event;
  }

  totalSelectedChanges($event: Array<number>) {
    this.totalSelected = $event;
    this.calculateTotal();
  }

  calculateTotal(): void {
    let sum = 0;

    if (this.totalSelected.length > 0) {
      for (const total of this.totalSelected) {
        sum += total;
      }
    } else {
      sum = this.resultSearch?.totalAmount || 0;
    }

    const total = sum > 0 ? sum.toFixed(2) : '-';

    this.totalAmount = total;
  }

  sortChange(event: { active: string; direction: PaginateSort }): void {
    const e = this.budgetService.columnToProp(event);

    this.setSortColumn(e.active, e.direction);

    // To avoid reloading when there is not pagination
    if (this.totalPages.length > 1) {
      this.goToPage(1);
    }
  }

  setSortColumn(active = 'createdAt', direction = PaginateSort.DESC): void {
    this.clearSortOptions();
    const tableSort = JSON.parse(localStorage.getItem('budgetSort'));

    if (tableSort && tableSort?.direction != '') {
      const sort = this.budgetService.columnToProp(tableSort);
      active = sort.active;
      direction = sort.direction;
    }

    this.searchParams[`sortOptions[${active}]`] = direction;
  }

  clearSortOptions(): void {
    const sortOptionsKeys = Object.keys(this.searchParams).filter((k) =>
      k.includes('sortOptions')
    );

    for (const key of sortOptionsKeys) {
      delete this.searchParams[key];
    }
  }

  get isLowerThan1450px(): boolean {
    return window.innerWidth < 1450;
  }

  sendAmplitudeEvent(e: string): void {
    const eventSuffixes = {
      openFilter: '_filter',
      list_account_settings: '_list_account_settings',
    };

    let event = this.budgetTypeAmplitude as string;

    const suffix = eventSuffixes[e];
    if (suffix) {
      event += suffix;
    }

    if (event != this.budgetTypeAmplitude) {
      this.amplitudeService.sendEvent({
        event,
      });
    }
  }

  download(type: DownloadType): void {
    const downloadActions = {
      [DownloadType.EXCEL]: () => this.downloadExcel(),
      [DownloadType.PDF]: () => {
        if (!this.entriesForDelete?.length) {
          this.entriesForDelete = this.list.data;
        }
        this.downloadMultiplePdfs();
      },
    };

    const action = downloadActions[type];
    if (action) {
      action();
    }
  }
}
