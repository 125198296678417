<div class="card__hour__left">
  <roma-user-avatar
    [user]="content.user"
    width="20"
    height="20"
  ></roma-user-avatar>
</div>
<div class="card__hour__content">
  <div class="card__hour__content__title">
    {{ content.title }}
  </div>
  <div *ngIf="content?.description" class="card__hour__content__description">
    {{ content.description }}
  </div>
  <div class="card__hour__content__wrapper__time">
    <div class="card__hour__content__wrapper__time__item">
      <div class="card__hour__content__wrapper__time__item__icon">
        <img src="assets/icons/gl_calendar_fill.svg" alt="icon date" />
      </div>
      <div class="card__hour__content__wrapper__time__item__text">
        {{ content.startDate }}
      </div>
    </div>
    <div
      *ngIf="content?.endDate"
      class="card__hour__content__wrapper__time__item"
    >
      <div class="card__hour__content__wrapper__time__item__icon">
        <img src="assets/icons/gl_clock_fill.svg" alt="icon date" />
      </div>
      <div class="card__hour__content__wrapper__time__item__text">
        {{ content.endDate }}
      </div>
    </div>
  </div>

  <div class="card__hour__content__wrapper__labels">
    <div
      *ngIf="content?.hour"
      class="card__hour__content__wrapper__labels__text"
    >
      <img src="assets/icons/gl_calendar_2.svg" />
      {{ content.hour }}
    </div>
    <div
      class="roma-status-wrapper"
      [ngClass]="{
        warn: content.status === 'PENDING',
        red: content.status === 'REJECTED',
        green: content.status === 'APPROVED'
      }"
    >
      <ng-container [ngSwitch]="content.status">
        <ng-container *ngSwitchCase="'PENDING'">
          {{ 'table.content.pending' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'APPROVED'">
          {{ 'table.content.approved' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'REJECTED'">
          {{ 'table.content.rejected' | translate }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ tagNotAvailable | translate }}
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<div class="card__hour__right">
  <img
    romaClickStopPropagation
    [matMenuTriggerFor]="menu"
    class="more-actions"
    src="/assets/icons/gl_more-vertical.svg"
    (click)="$event.stopPropagation()"
    *ngIf="
      content.data && (content.data['showEdit'] || content.data['showDelete'])
    "
  />
  <img
    romaClickStopPropagation
    class="more-actions"
    src="/assets/icons/gl_more-vertical.svg"
    *ngIf="!content.data && showActions"
  />
  <mat-menu
    class="custom-template-actions actions-menu"
    #menu="matMenu"
    xPosition="before"
  >
    <button
      *ngIf="content.data && content.data['showEdit']"
      class="edit"
      mat-menu-item
      romaProFeature
      [featureUser]="featureRefUser.ClockHour.update"
      (clicked)="makeAction('EDIT')"
    >
      {{ 'general.edit' | translate }}
    </button>

    <button
      *ngIf="content.data && content.data['showDelete']"
      class="delete"
      romaClickDelete
      subtitle="deleteModal.body_final"
      mat-menu-item
      romaProFeature
      [featureUser]="featureRefUser.ClockHour.delete"
      (wantDelete)="makeAction('DELETE')"
    >
      {{ 'general.deleteAlt' | translate }}
    </button>
  </mat-menu>
</div>
