import { IUser } from '../../../../../types-legacy';

export namespace MobileClockHourAdmin {
  export interface Content {
    id: string;
    img: string;
    user?: IUser;
    status: 'PENDING' | 'APPROVED' | 'REJECTED';
    title: string;
    description?: string;
    startDate: string;
    endDate?: string;
    hour?: string;
    data?: unknown;
  }

  export interface ActionsOptions {
    allowEdit: boolean;
    allowDelete: boolean;
  }

  export interface Action {
    action: 'EDIT' | 'DELETE' | 'VIEW';
    id: string;
  }

  export const defaultActionsOptions: MobileClockHourAdmin.ActionsOptions = {
    allowEdit: true,
    allowDelete: true,
  };
}
