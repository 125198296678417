<ng-container *ngIf="!isIos">
  <roma-modal-template
    [showDefaultButtons]="false"
    [footerTemplate]="footerButtons"
    [showHeader]="false"
    [size]="mobileHeight"
    (cancel)="close()"
  >
    <div class="row d-flex justify-content-center">
      <div class="header">
        <div class="title">
          {{ 'buyModal.title' | translate }}
        </div>
        <div class="comment" [innerHtml]="'buyModal.comment' | translate"></div>
      </div>
      <div class="options">
        <div class="option">
          <img src="assets/icons/check.svg" alt="Check icon" />
          <div>{{ 'buyModal.items.item1' | translate }}</div>
        </div>
        <div class="option">
          <img src="assets/icons/check.svg" alt="Check icon" />
          <div>{{ 'buyModal.items.item2' | translate }}</div>
        </div>
        <div class="option">
          <img src="assets/icons/check.svg" alt="Check icon" />
          <div>{{ 'buyModal.items.item3' | translate }}</div>
        </div>
      </div>
      <div class="disclaimer-card" *ngIf="!isResponsive">
        <img
          src="assets/images/buyModal/buyModal_partyIcon.svg"
          alt="Check icon"
        />
        <div [innerHtml]="'buyModal.disclaimer' | translate"></div>
      </div>
      <div class="card-pro">
        <roma-loading *ngIf="isLoading"></roma-loading>

        <mat-radio-group
          *ngIf="!isLoading"
          aria-labelledby="radio-group-label"
          class="radio-group"
          [(ngModel)]="actualPlanType"
        >
          <mat-radio-button
            class="radio-button"
            *ngFor="let planType of planTypes"
            [value]="planType"
            [ngStyle]="{
              order: planType.period_unit === 'year' ? '1' : '0'
            }"
            (click)="changePlan(planType)"
          >
            <div class="name">
              {{ planType.period_unit | periodName | translate }}
              <div
                class="discountPercentage"
                *ngIf="planType.discountPercentage != null"
              >
                -{{ planType.discountPercentage }}%
              </div>
            </div>
            <div class="price">
              {{
                ['USD', 'MXN'].includes(planType.currency_code)
                  ? moneySymbol
                  : ''
              }}
              {{ twoDecimals(planType.monthly) }}
              <span
                >{{ planType.currency_code === 'EUR' ? moneySymbol : '' }}
              </span>
            </div>
            <div
              *ngIf="planType.monthly && planType.period_unit === 'year'"
              class="monthly"
            >
              {{
                isResponsive
                  ? ('pro.buyModal.willPayMobile' | translate)
                  : ('pro.buyModal.willPay' | translate)
              }}
              {{
                ['USD', 'MXN'].includes(planType.currency_code)
                  ? moneySymbol
                  : ''
              }}
              {{ twoDecimals(planType.price) }}
              {{ planType.currency_code === 'EUR' ? moneySymbol : '' }}/{{
                'pro.buyModal.year' | translate
              }}
            </div>
            <div
              *ngIf="planType.monthly && planType.period_unit !== 'year'"
              class="monthly"
            >
              {{
                isResponsive
                  ? ('pro.buyModal.willPayMobile' | translate)
                  : ('pro.buyModal.willPay' | translate)
              }}
              {{
                ['USD', 'MXN'].includes(planType.currency_code)
                  ? moneySymbol
                  : ''
              }}
              {{ twoDecimals(planType.price) }}
              {{ planType.currency_code === 'EUR' ? moneySymbol : '' }}/{{
                'pro.buyModal.month' | translate
              }}
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </roma-modal-template>
  <ng-template #footerButtons>
    <div class="modalFooter">
      <div class="fullDiv">
        <base-button
          [isLoading]="isLoading"
          size="medium-margin"
          (click)="buy()"
        >
          {{ 'buyModal.button' | translate }}
        </base-button>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-container *ngIf="isIos">
  <roma-modal-template
    [showDefaultButtons]="false"
    [showHeader]="false"
    [size]="mobileHeight"
    (cancel)="close()"
  >
    <div class="row d-flex justify-content-center">
      <img class="ios-image" src="assets/images/buyModal/buy-modal-ios.svg" />
      <div class="ios-title">
        {{ 'buyModal.ios.title' | translate }}
      </div>
      <div class="option">
        <img src="assets/icons/check.svg" alt="Check icon" />
        <div>{{ 'buyModal.ios.item1' | translate }}</div>
      </div>
      <div class="option">
        <img src="assets/icons/check.svg" alt="Check icon" />
        <div>{{ 'buyModal.ios.item2' | translate }}</div>
      </div>
      <div
        class="ios-disclaimer"
        [innerHtml]="'buyModal.ios.disclaimer' | translate"
      ></div>
    </div>
  </roma-modal-template>
</ng-container>
