import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { PlatformService } from '../platform-disable-content/platform.service';
import { User } from '../../../../types-legacy/lib/packages/clock-absence/absenceEmployee';

@Component({
  selector: 'roma-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PictureComponent implements OnInit {
  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement> | undefined;

  @Input()
  src = '';

  @Input()
  @HostBinding('class.is-xs')
  isXs = false;

  @Input()
  @HostBinding('class.is-md')
  isMd = false;

  @Input()
  @HostBinding('class.is-lg')
  isLg = false;

  @Input()
  @HostBinding('class.rounded')
  rounded = true;

  @Input()
  @HostBinding('class.can-click')
  canClick = true;

  @Input()
  @HostBinding('class.is-center')
  center = false;

  @Input()
  multiSelect = false;

  @Input()
  isLoading = false;

  @Input()
  showIcon = true;

  @Output()
  selectFiles = new EventEmitter<FileList>();

  @Input()
  user: User;

  previewFile: string | ArrayBuffer = '';

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private toastService: ToastService,
    private i18n: TranslateService,
    private platformService: PlatformService
  ) {}

  ngOnInit(): void {}

  @HostListener('click', ['$event'])
  hostClick(evt: MouseEvent) {
    if (
      !this.platformService.isMobileWebBrowser() &&
      (this.platformService.isWeb() || this.platformService.isAndroid())
    ) {
      if (this.canClick) {
        this.fileInput.nativeElement.click();
      } else {
        evt.preventDefault();
        evt.stopPropagation();
      }
    }
  }

  openInputExternal() {
    this.fileInput.nativeElement.click();
  }

  handleImageFile(files: FileList) {
    if (this.checkIfAbleToUpload(files)) {
      this.previewUrl(files);
      this.selectFiles.next(files);
    }
  }

  private previewUrl(files: FileList) {
    this.isLoading = true;
    if (!this.multiSelect) {
      const file = files.item(0);

      const reader = new FileReader();

      reader.onload = () => {
        this.previewFile = reader.result;
        this.isLoading = false;
        this.draw();
      };

      reader.readAsDataURL(file);
    }
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  checkIfAbleToUpload(files: FileList): boolean {
    const file: File = files.item(0);
    if (file) {
      // Check if file is a valid image and size is larger than 2MB
      if (!file.type.startsWith('image/') || file.size > 2 * 1024 * 1024) {
        this.toastService.show({
          text: this.i18n.instant('fileUpload.errorUploadLimitFormat'),
          type: 'error',
          msDuration: 4000,
        });
        return false;
      }
      return true;
    }
    return false;
  }
}
