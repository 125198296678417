import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Feature, PartOfServiceGlobal } from '@tacliatech/types';
import {
  AuthService,
  SandboxService,
  StorageService,
} from '@web-frontend/shared/services';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { CompanyService } from '@web-frontend/shared/services/company';
import { PartOfServiceProvider } from '@web-frontend/shared/services/part-of-services';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PreviewPartOfService } from './preview-part-of-service.types';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'roma-preview-part-of-service-report',
  templateUrl: './preview-part-of-service-report.component.html',
  styleUrls: ['./preview-part-of-service-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewPartOfServiceReportComponent implements OnInit, OnDestroy {
  reportData!: PartOfServiceGlobal.PartOfServiceReportFormat;
  loading = true;
  company_date_format = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';

  featureRef = Feature;
  hasFeatureSeeLogo$ = this.permissionService.hasFeatureFn(
    Feature.Deal.SeeLogo
  );
  showDisclaimer = true;
  trialPeriodUsed = false;
  charginTrial = false;
  private sub$ = new Subscription();
  resize$ = this.sandBoxService.screenBusChannel$;

  constructor(
    private budgetService: BudgetService,
    private dialogRef: MatDialogRef<
      PreviewPartOfServiceReportComponent,
      PreviewPartOfService.PreviewPartOfServiceResponse
    >,
    @Inject(MAT_DIALOG_DATA) public data: PreviewPartOfService.ModalParams,
    private partOfServiceProvider: PartOfServiceProvider,
    public authService: AuthService,
    private companyService: CompanyService,
    private permissionService: PermissionService,
    private sandBoxService: SandboxService,
    private cdRef: ChangeDetectorRef,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.charginTrial = true;
    this.haveUsedTrialModule().subscribe((trialPeriodUsed: boolean) => {
      this.checkTrial();
    });
    this.sub$.add(
      this.partOfServiceProvider
        .getReportData(this.data.idDeal)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe((res) => {
          this.reportData = res;
          this.loading = false;
          this.draw();
        })
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  async checkTrial(): Promise<void> {
    await this.permissionService.checkTrialSubscription(
      StorageService.CompanyId,
      'service'
    );

    this.charginTrial = false;
    this.draw();
  }

  haveUsedTrialModule(): Observable<boolean> {
    return new Observable<boolean>((data) => {
      this.sub$.add(
        this.companyService
          .findOne(StorageService.CompanyId)
          .subscribe((company: any) => {
            this.trialPeriodUsed = company.subscription?.trialPeriodUsed
              ?.map((i) => i.rootModule)
              ?.includes('service');
            data.next(this.trialPeriodUsed);
          })
      );
    });
  }

  close() {
    this.dialogRef.close();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  get companyLogo() {
    if (this.reportData?.clientLogo) {
      if (
        this.reportData?.clientLogo?.startsWith('data:image') &&
        this.reportData?.clientLogo?.includes('base64')
      ) {
        return this.sanitizer.bypassSecurityTrustUrl(
          this.reportData.clientLogo
        );
      } else {
        return this.reportData?.clientLogo;
      }
    }

    return '';
  }
}
