<div class="col-12">
  <ng-container *ngIf="loadingList; else teamList">
    <roma-loading></roma-loading>
  </ng-container>
  <ng-template #teamList>
    <div
      class="table-container"
      [ngClass]="{
        'margin-bottom-message': hasSelectedItems
      }"
    >
      <mat-table
        #table
        [dataSource]="mappedDataSource"
        matSort
        (matSortChange)="sortData($event)"
        [matSortActive]="auxSort.active || null"
        [matSortDirection]="auxSort.direction || null"
        [trackBy]="trackById"
      >
        <ng-container matColumnDef="select">
          <mat-header-cell
            *matHeaderCellDef
            class="custom-header-cell checkbox-column"
            (click)="masterToggle(); $event.stopPropagation()"
          >
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="isIndeterminate() && !isAllSelected()"
            >
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="custom-body-cell checkbox-column"
            (click)="$event?.stopPropagation()"
          >
            <mat-checkbox
              (click)="toggleSelection(row); $event.stopPropagation()"
              (change)="$event ? toggleSelection(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            mat-header-cell
            class="custom-header-cell"
          >
            {{ 'clockHour.name' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="custom-body-cell">
            <roma-user-avatar [user]="element"></roma-user-avatar>
            {{ element.name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="workedHours">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            mat-header-cell
            class="custom-header-cell"
          >
            {{ 'clockHour.workedHours' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="custom-body-cell">
            {{ element.workedHours || '-' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="remainingHours">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            mat-header-cell
            class="custom-header-cell"
          >
            {{ 'clockHour.toBeClocked' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="custom-body-cell"
            [ngClass]="{ noContract: !element.haveContractHours }"
          >
            {{
              !element.haveContractHours
                ? '--h --m'
                : element.remainingHours || '-'
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="extraHours">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            mat-header-cell
            class="custom-header-cell"
          >
            {{ 'clockHour.overTime' | translate }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="custom-body-cell"
            [ngClass]="{ noContract: !element.haveContractHours }"
          >
            {{
              !element.haveContractHours ? '--h --m' : element.extraHours || '-'
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="pendingRegisters">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            mat-header-cell
            class="custom-header-cell tooltip-container"
          >
            <div style="position: relative">
              {{ 'clockHour.pendingReview' | translate }}
              <div class="custom-tooltip bottom">
                <img width="16px" class="help-icon" [src]="iconDialog" />
                <span class="tiptext">
                  {{ 'clockHour.pendingReviewTooltip' | translate }}
                </span>
              </div>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="custom-body-cell">
            <span
              *ngIf="element.pendingRegisters; else noPendingRegisters"
              class="status-wrapper warn"
            >
              {{
                element.pendingRegisters +
                  ' ' +
                  ('clockHour.toValidate' | translate)
              }}
            </span>
            <ng-template #noPendingRegisters>
              <span>--</span>
            </ng-template>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns"
          class="custom-header-row"
        ></mat-header-row>
        <mat-row
          class="custom-body-row"
          (click)="goToUserDetail(row)"
          *matRowDef="let row; columns: displayedColumns"
        ></mat-row>
      </mat-table>
    </div>
  </ng-template>
</div>

<div
  *ngIf="hasSelectedItems"
  class="row justify-content-center justify-content-lg-start"
>
  <div class="col-12 col-md-11 container__float delete-message-modal">
    <div class="delete-message">
      <div class="container-left">
        <strong>
          ({{ selection.selected.length }})
          {{ 'general.selected' | translate }}
        </strong>
      </div>
      <roma-shared-download-btn
        [featureExcel]="featureRef.ClockHour.DownloadExcel"
        [showDownloadExcel]="true"
        [showDownloadPdf]="false"
        (download)="downloadReport()"
        [flex]="true"
      ></roma-shared-download-btn>
      <div class="vertical-line"></div>
      <div class="container-left">
        <strong>
          ({{ countItemsWithPendingRegisters }})
          {{ 'clockHour.totalEntriesToValidate' | translate }}
        </strong>
      </div>
      <div class="container-buttons-actions">
        <button
          *ngIf="hasItemsToApproveOrReject"
          class="btn info"
          romaProFeature
          [featureUser]="featureUserRef.ClockHourCompany.approve"
          (clicked)="updateStatusClockHour(statusRef.Approved)"
        >
          <img src="assets/icons/gl_check_white.svg" />
          {{ 'general.approve' | translate }}
        </button>
        <button
          *ngIf="hasItemsToApproveOrReject"
          class="btn error"
          romaProFeature
          [featureUser]="featureUserRef.ClockHourCompany.approve"
          (clicked)="updateStatusClockHour(statusRef.Rejected)"
        >
          <img src="assets/icons/gl_close_red.svg" />
          {{ 'general.decline' | translate }}
        </button>
      </div>
      <div class="btn-close" (click)="unselectItems()"></div>
    </div>
  </div>
</div>
