enum AmplitudeEvents {
  page_event = 'page_event',

  register_save = 'register_save',
  register_start = 'register_start',
  new_login = 'new_login',
  register_loginStart = 'register_loginStart',

  register_toolTimeTracking_start = 'register_toolTimeTracking_start',
  register_toolBill_start = 'register_toolBill_start',
  register_toolOnlineBooking_start = 'register_toolOnlineBooking_start',
  register_toolExpenses_start = 'register_toolExpenses_start',
  register_toolTasks_start = 'register_toolTasks_start',
  register_toolService_start = 'register_toolService_start',
  register_skip_steps = 'register_skip_steps',

  generalStart_catalogue = 'generalStart_catalogue',
  generalStart_proform = 'generalStart_proform',
  generalStart_waybill = 'generalStart_waybill',
  generalStart_bill = 'generalStart_bill',
  generalStart_budget = 'generalStart_budget',
  generalStart_tasks = 'generalStart_tasks',
  generalStart_services = 'generalStart_services',
  generalStart_user = 'generalStart_user',
  generalStart_vendor = 'generalStart_vendor',
  generalStart_equipment = 'generalStart_equipment',
  generalStart_final = 'generalStart_final',
  generalStart_expense = 'generalStart_expense',
  generalStart_project = 'generalStart_project',
  generalStart_sale = 'generalStart_sale',
  generalStart_asset = 'generalStart_asset',
  help_general_click = 'help_general_click',

  notification_alert = 'notification_alert',

  settingsPoliticsStart = 'settings_politics_start',
  settingsConditionsStart = 'settings_conditions_start',
  settingsDeleteAccount = 'settings_deleteAccount_start',
  settings_global_start = 'settings_global_start',
  settings_users_start = 'settings_users_start',
  settings_generalSettings_start = 'settings_generalSettings_start',
  settings_customFields = 'settings_customFields',
  settings_bankholidays_start = 'settings_bankholidays_start',

  bankHoliday_delete = 'bankHoliday_delete',

  profile_settings = 'profile_settings',
  profile_endSession = 'profile_endSession',
  profile_endSession_end = 'profile_endSession_end',

  personalization_header = 'personalization_header',

  share = 'share',
  share_taclia = 'share_taclia',
  share_sidebar = 'share_sidebar',
  bannerDemo_sidebar_start = 'bannerDemo_sidebar_start',

  deleteAccountStep1Contact = 'deleteAccount_step1_contact',
  deleteAccountStep1Delete = 'deleteAccount_step1_delete',
  deleteAccountStep1Close = 'deleteAccount_step1_close',

  deleteAccountStep2Conserve = 'deleteAccount_step2_conserve',
  deleteAccountStep2Delete = 'deleteAccount_step2_delete',
  deleteAccountStep2Close = 'deleteAccount_step2_close',

  bankHoliday_start = 'bankHoliday_start',
  bankHoliday_create = 'bankHoliday_create',

  userViewReturn = 'user_view_return',
  userInviteAdd = 'userInvite_add_workinghours_info',
  userInvite_add_vacations_info = 'userInvite_add_vacations_info',
  catalogueViewReturn = 'catalogue_view_return',
  user_create = 'user_create',
  equipmentCardViewReturn = 'equipment_card_view_return',
  equipment_start = 'equipment_start',
  projectCardViewReturn = 'project_card_view_return',
  addressCardViewReturn = 'address_card_view_return',
  vendorViewReturn = 'vendor_view_return',
  user_start = 'user_start',

  trackingTime_sidebar = 'trackingTime_sidebar',
  trackingTimeTeam_sidebar = 'trackingTimeTeam_sidebar',
  trackingTime_excel_download = 'trackingTime_excel_download',
  trackingTimeTeam_download = 'trackingTimeTeam_download',
  trackingTimeTeam_excel_download = 'trackingTimeTeam_excel_download',
  trackingTime_info = 'trackingTime_info',
  trackingTimeTeam_info = 'trackingTimeTeam_info',
  trackingTime_monthly = 'trackingTime_monthly',
  trackingTime_weekly = 'trackingTime_weekly',
  trackingTime_auto_create = 'trackingTime_auto_create',
  trackingTimeTeam_filter = 'trackingTimeTeam_filter',
  trackingTimeTeam_search = 'trackingTimeTeam_search',
  trackingTimeTeam_user_start = 'trackingTimeTeam_user_start',
  trackingTimeTeam_start = 'trackingTimeTeam_start',
  trackingTime_schedule_missing = 'trackingTime_workinghours_missing',
  trackingTime_schedule_missing_add = 'trackingTime_workinghours_missing_add',
  trackingTimeTeam_teamView = 'trackingTimeTeam_teamView',
  trackingTimeTeam_fullView = 'trackingTimeTeam_fullView',
  trackingTimeTeam_userView = 'trackingTimeTeam_userView',
  trackingTimeTeam_bulk_approve = 'trackingTimeTeam_bulk_approve',
  trackingTimeTeam_bulk_decline = 'trackingTimeTeam_bulk_decline',
  trackingTimeTeam_bulk_download = 'trackingTimeTeam_bulk_download',
  trackingTimeTeam_approve = 'trackingTimeTeam_approve',
  trackingTimeTeam_decline = 'trackingTimeTeam_decline',
  trackingTime_workinghours_missing_add = 'trackingTime_workinghours_missing_add',
  trackingTime_edit = 'trackingTime_edit',

  user_card_workinghours_add = 'user_card_workinghours_add',
  user_card_vacations_add = 'user_card_vacations_add',

  absence_sidebar = 'absence_sidebar',
  absenceTeam_sidebar = 'absenceTeam_sidebar',
  absenceTeam_bankholidays_start = 'absenceTeam_bankholidays_start',
  absence_play_tutorial = 'absence_play_tutorial',
  absenceTeam_play_tutorial = 'absenceTeam_play_tutorial',
  absence_excel_download = 'absence_excel_download',
  absenceTeam_excel_download = 'absenceTeam_excel_download',
  absenceTeam_excel_bulk_download = 'absenceTeam_excel_bulk_download',
  absenceTeam_bulk_delete = 'absenceTeam_bulk_delete',
  absence_filter = 'absence_filter',
  absence_search = 'absence_search',
  absenceTeam_filter = 'absenceTeam_filter',
  absenceTeam_search = 'absenceTeam_search',
  absence_info = 'absence_info',
  absenceTeam_info = 'absenceTeam_info',
  absence_create = 'absence_create',
  absenceTeam_create = 'absenceTeam_create',
  absence_edit = 'absence_edit',
  absenceTeam_edit = 'absenceTeam_edit',
  absence_deleted = 'absence_deleted',
  absenceTeam_deleted = 'absenceTeam_deleted',
  absenceTeam_approved = 'absenceTeam_approved',
  absenceTeam_declined = 'absenceTeam_declined',
  absence_calendarView = 'absence_calendarView',
  absence_fullView = 'absence_fullView',
  calendar_absence_activate = 'calendar_absence_activate',
  absenceTeam_fullView = 'absenceTeam_fullView',
  absenceTeam_teamView = 'absenceTeam_teamView',
  absence_pending_approval = 'absence_pending_approval',

  billDraftGenerateBill = 'bill_draft_generate_bill',
  billCardGenerateBill = 'bill_card_generate_bill',
  billCardSaveDraft = 'bill_card_saveDraft',
  bill_certificateRejected_tryAgain = 'bill_certificateRejected_tryAgain',
  bill_certificateRejected_whatToDo = 'bill_certificateRejected_whatToDo',
  bill_certificateApproved_createBill = 'bill_certificateApproved_createBill',
  bill_certificateExpired_tryAgain = 'bill_certificateExpired_tryAgain',
  bill_certificateExpired_whatToDo = 'bill_certificateExpired_whatToDo',
  bill_certificateExpired_contactUs = 'bill_certificateExpired_contactUs',
  bill_certificateApproved_contactUs = 'bill_certificateApproved_contactUs',
  bill_certificateRejected_contactUs = 'bill_certificateRejected_contactUs',
  bill_send_document = 'bill_send_document',

  budget_view_convertToProforma = 'budget_view_convertToProforma',
  budget_edit = 'budget_edit',
  budget_start = 'budget_start',

  proform_edit = 'proform_edit',
  proform_send_document = 'proform_send_document',

  settingsAddPayMethodStart = 'settings_addPayMethod_start',
  settingsAddPayMethodAdd = 'settings_addPayMethod_add',
  settingsAddPayMethodCancel = 'settings_addPayMethod_cancel',
  settingsAddPayMethodClose = 'settings_addPayMethod_close',
  settings_editLogo = 'settings_editLogo',

  sale_start = 'sale_start',
  sale_card_deal_add = 'sale_card_deal_add',
  sale_card_deal_cancel = 'sale_card_deal_cancel',
  sale_card_deal = 'sale_card_deal',
  sale_card_payment_activate = 'sale_card_payment_activate',
  sale_tooltip_start = 'sale_tooltip_start',
  sale_TutorialVideo = 'sale_play_tutorial',
  sale_list_pageChangeLast = 'sale_list_pageChangeLast',
  sale_list_pageChange = 'sale_list_pageChange',
  sale_list_select = 'sale_list_select',
  sale_list_view = 'sale_list_view',
  sale_excelDownload = 'sale_excelDownload',
  sale_list_select_download_excel = 'sale_list_select_download_excel',
  sale_list_downloadTicket = 'sale_list_downloadTicket',
  sale_card_final_start = 'sale_card_final_start',
  sale_card_addFinal = 'sale_card_addFinal',
  sale_card_addCatalogue = 'sale_card_addCatalogue',
  sale_card_addDiscount = 'sale_card_addDiscount',
  sale_card_addTax = 'sale_card_addTax',
  sale_cardCompleted_download = 'sale_cardCompleted_download',
  sale_card_removeDiscount = 'sale_card_removeDiscount',
  sale_card_payment_cash = 'sale_card_payment_cash',
  sale_card_payment_card = 'sale_card_payment_card',
  sale_card_payment_other = 'sale_card_payment_other',
  sale_card_payment_link = 'sale_card_payment_link',
  sale_card_payment_transfer = 'sale_card_payment_transfer',
  sale_card_payment = 'sale_card_payment',
  sale_card_payment_collect = 'sale_card_payment_collect',
  sale_view_charge = 'sale_view_charge',
  sale_cardCompleted_cancel = 'sale_cardCompleted_cancel',
  sale_cardCompleted_email = 'sale_cardCompleted_email',
  sale_card_catalogue_start = 'sale_card_catalogue_start',
  sale_card_catalogue_remove = 'sale_card_catalogue_remove',
  sale_list_open = 'sale_list_open',
  sale_list_download = 'sale_list_download',

  deal_card_deleteComments = 'deal_card_deleteComments',
  deal_card_editComments = 'deal_card_editComments',
  deal_card_editUser = 'deal_card_editUser',
  deal_card_editAsset = 'deal_card_editAsset',
  deal_card_editUser_create = 'deal_card_editUser_create',
  deal_date_changed = 'deal_date_changed',
  deal_card_sale_bill_select_delete_delete = 'deal_card_sale_bill_select_delete_delete',
  deal_card_sale_budget_select_delete_delete = 'deal_card_sale_budget_select_delete_delete',
  deal_card_sale_waybill_select_delete_delete = 'deal_card_sale_waybill_select_delete_delete',
  calendar_deal_start = 'calendar_deal_start',
  deal_calendar_view = 'deal_calendar_view',
  calendar_absence_enable = 'calendar_absence_enable',
  calendar_absence_disable = 'calendar_absence_disable',
  deal_list_edit = 'deal_list_edit',
  deal_list_pageChangeNext = 'deal_list_pageChangeNext',
  deal_list_pageChange = 'deal_list_pageChange',
  deal_list_pageChangePrevious = 'deal_list_pageChangePrevious',
  deal_list_delete_delete = 'deal_list_delete_delete',
  deal_list_bulk = 'deal_list_bulk',
  deal_list_select = 'deal_list_select',
  deal_list_delete_start = 'deal_list_delete_start',
  deal_board_header_rename_color = 'deal_board_header_rename_color',
  deal_board_header_delete_start = 'deal_board_header_delete_start',
  deal_board_header_delete_delete = 'deal_board_header_delete_delete',
  deal_board_delete_delete = 'deal_board_delete_delete',
  deal_board_select_delete_delete = 'deal_board_select_delete_delete',
  deal_card_editFinal_create = 'deal_card_editFinal_create',
  deal_card_exit = 'deal_card_exit',
  deal_card_removeAttachments = 'deal_card_removeAttachments',
  deal_card_sale_bill_start = 'deal_card_sale_bill_start',
  sale_card_deal_start = 'sale_card_deal_start',
  deal_card_proof_editInternalNotes = 'deal_card_proof_editInternalNotes',
  deal_card_proof_addInternalNotes = 'deal_card_proof_addInternalNotes',
  deal_card_proof_fileInternalNotes = 'deal_card_proof_fileInternalNotes',
  deal_card_proof_deleteInternalNotes = 'deal_card_proof_deleteInternalNotes',
  deal_card_proof_signDate = 'deal_card_proof_signDate',
  deal_card_proof_finalSign_create = 'deal_card_proof_finalSign_create',
  deal_card_proof_finalSign_delete = 'deal_card_proof_finalSign_delete',
  deal_card_proof_companySign_create = 'deal_card_proof_companySign_create',
  deal_card_proof_companySign_delete = 'deal_card_proof_companySign_delete',
  deal_card_proof_date = 'deal_card_proof_date',
  deal_card_proof_description = 'deal_card_proof_description',
  deal_card_proof_internalNotes = 'deal_card_proof_internalNotes',
  deal_card_proof_show_customGroup = 'deal_card_proof_show_customGroup',
  deal_card_proof_show_customOFF = 'deal_card_proof_show_customOFF',
  deal_card_proof_show_customON = 'deal_card_proof_show_customON',
  final_card_deal_start = 'final_card_deal_start',
  deal_create = 'deal_create',
  deal_edit = 'deal_edit',
  deal_card_editFinal_start = 'deal_card_editFinal_start',
  deal_card_editOwner_start = 'deal_card_editOwner_start',
  deal_card_editSupervisor_start = 'deal_card_editSupervisor_start',
  deal_card_editUser_start = 'deal_card_editUser_start',
  generalStart_deal = 'generalStart_deal',
  deal_board = 'deal_board',
  deal_list = 'deal_list',
  deal_sidebar = 'deal_sidebar',
  deal_listAddCustomField = 'deal_listAddCustomField',
  deal_listAddField = 'deal_listAddField',
  deal_listConfiguration = 'deal_listConfiguration',
  deal_template_delete = 'deal_template_delete',
  deal_filter_apply = 'deal_filter_apply',
  deal_start = 'deal_start',
  deal_filter_today = 'deal_filter_today',
  deal_filter_mine = 'deal_filter_mine',
  deal_filter_finished = 'deal_filter_finished',
  deal_filter_cancelled = 'deal_filter_cancelled',
  deal_board_options_move = 'deal_board_options_move',
  deal_board_select_move = 'deal_board_select_move',
  deal_board_move = 'deal_board_move',
  deal_board_newStatus_save = 'deal_board_newStatus_save',
  deal_board_edit = 'deal_board_edit',
  deal_board_header_create = 'deal_board_header_create',
  deal_board_header_moveAll = 'deal_board_header_moveAll',
  deal_board_header_edit_save = 'deal_board_header_edit_save',
  deal_board_start = 'deal_board_start',
  deal_board_finalize = 'deal_board_finalize',
  deal_board_select_finalize = 'deal_board_select_finalize',
  deal_play_tutorial = 'deal_play_tutorial',
  deal_tooltip_start = 'deal_tooltip_start',
  deal_tooltip_academy = 'deal_tooltip_academy',
  deal_excelDownload = 'deal_excelDownload',
  deal_card_sale_waybill_tab = 'deal_card_sale_waybill_tab',
  deal_card_sale_tab = 'deal_card_sale_tab',
  deal_card_sale_budget_tab = 'deal_card_sale_budget_tab',
  deal_card_sale_bill_tab = 'deal_card_sale_bill_tab',
  deal_card_share = 'deal_card_share',
  deal_card_proof_download = 'deal_card_proof_download',
  deal_card_proof_emailSend = 'deal_card_proof_emailSend',
  deal_card_proof_emailStart = 'deal_card_proof_emailStart',
  deal_card_proof_preview = 'deal_card_proof_preview',
  deal_card_proof_tab = 'deal_card_proof_tab',
  deal_calendar_move = 'deal_calendar_move',
  deal_list_helpButton = 'deal_list_helpButton',
  deal_card_expense_tab = 'deal_card_expense_tab',
  deal_list_select_move = 'deal_list_select_move',
  deal_list_select_finalize = 'deal_list_select_finalize',
  deal_list_select_cancel = 'deal_list_select_cancel',
  deal_list_options_move = 'deal_list_options_move',
  deal_list_options_finalize = 'deal_list_options_finalize',
  deal_card_sale_waybill_start = 'deal_card_sale_waybill_start',
  deal_card_sale_waybill_list_select_download_pdf = 'deal_card_sale_waybill_list_select_download_pdf',
  deal_card_sale_waybill_list_select_download_excel = 'deal_card_sale_waybill_list_select_download_excel',
  deal_card_expense_start = 'deal_card_expense_start',
  deal_card_detail = 'deal_card_detail',
  deal_card_sale_waybill_list_convertToBill = 'deal_card_sale_waybill_list_convertToBill',
  deal_card_sale_waybill_excelDownload = 'deal_card_sale_waybill_excelDownload',
  deal_card_sale_waybill_associate = 'deal_card_sale_waybill_associate',
  deal_card_sale_bill_associate = 'deal_card_sale_bill_associate',
  deal_card_sale_budget_associate = 'deal_card_sale_budget_associate',
  deal_card_cancel = 'deal_card_cancel',
  deal_card_editFinal = 'deal_card_editFinal',
  deal_card_editResponsible = 'deal_card_editResponsible',
  deal_card_editProject = 'deal_card_editProject',
  deal_card_editStatus = 'deal_card_editStatus',
  deal_card_editSaleCategory = 'deal_card_editSaleCategory',
  deal_card_editTemplate = 'deal_card_editTemplate',
  deal_editEquipment = 'deal_editEquipment',
  deal_card_editOwner_create = 'deal_card_editOwner_create',
  deal_card_editSupervisor_create = 'deal_card_editSupervisor_create',
  deal_card_delete_start = 'deal_card_delete_start',
  deal_card_editName = 'deal_card_editName',
  deal_card_delete_delete = 'deal_card_delete_delete',
  deal_card_editDescription = 'deal_card_editDescription',
  deal_card_editCustomField = 'deal_card_editCustomField',
  deal_card_proof_file = 'deal_card_proof_file',
  deal_card_sale_budget_view = 'deal_card_sale_budget_view',
  deal_calendarMove = 'deal_calendarMove',
  deal_card_addCatalogue_edit = 'deal_card_addCatalogue_edit',
  deal_card_delete = 'deal_card_delete',
  deal_boardMove = 'deal_boardMove',
  deal_card_customField = 'deal_card_customField',
  deal_card_message_create = 'deal_card_message_create',
  deal_board_newStatus = 'deal_board_newStatus',
  deal_board_newStatus_cancel = 'deal_board_newStatus_cancel',
  deal_board_select = 'deal_board_select',
  deal_board_select_delete_start = 'deal_board_select_delete_start',
  deal_board_newStatus_color = 'deal_board_newStatus_color',
  deal_board_delete_start = 'deal_board_delete_start',
  deal_board_header_rename_cancel = 'deal_board_header_rename_cancel',
  deal_board_header_start = 'deal_board_header_start',
  deal_card_addAttachments = 'deal_card_addAttachments',
  deal_card_sale_budget_start = 'deal_card_sale_budget_start',
  deal_canceled = 'deal_canceled',

  catalogue_start = 'catalogue_start',
  catalogue_create = 'catalogue_create',
  catalogue_edit = 'catalogue_edit',
  catalogue_import = 'catalogue_import',
  catalogue_list_import = 'catalogue_list_import',
  onlineBooking_catalogue_create = 'onlineBooking_catalogue_create',

  register_sector_next = 'register_sector_next',
  register_sector_exit = 'register_sector_exit',
  register_tools_exit = 'register_tools_exit',

  expense_start = 'expense_start',
  expense_list_delete = 'expense_list_delete',
  expense_TutorialVideo = 'expense_TutorialVideo',
  expense_excelDownload = 'expense_excelDownload',
  expense_list_select_excelDownload = 'expense_list_select_excelDownload',

  payment_play_tutorial = 'payment_play_tutorial',
  payment_help = 'payment_help',

  account_reactivate_start = 'account_reactivate_start',
  account_notReactivate_logOut = 'account_notReactivate_logOut',
  account_notReactivate_deleteAccount = 'account_notReactivate_deleteAccount',
  account_becomePro_banner = 'account_becomePro_banner',
  account_access_blocked_trial_ended = 'account_access_blocked_trial_ended',
  account_access_blocked_suscription_canceled = 'account_access_blocked_suscription_canceled',
  account_access_blocked_suscription_failed = 'account_access_blocked_suscription_failed',

  final_edit = 'final_edit',
  final_start = 'final_start',
  final_create = 'final_create',
  card_final_start = 'card_final_start',
  final_card_detail_tab = 'final_card_detail_tab',
  final_card_deal_tab = 'final_card_deal_tab',
  final_card_task_tab = 'final_card_task_tab',
  final_card_sale_tab = 'final_card_sale_tab',
  final_card_noEditName_create = 'final_card_noEditName_create',
  final_import_list_start = 'import_final_list_start',
  final_card_CustomFields = 'final_card_CustomFields',
  setting_customFields_start = 'setting_customFields_start',
  final_card_task_start = 'final_card_task_start',
  final_card_share = 'final_card_share',
  final_card_exit = 'final_card_exit',
  final_card_deleteComments = 'final_card_deleteComments',
  final_card_editComments = 'final_card_editComments',
  final_card_addComments = 'final_card_addComments',
  final_card_removeAttachments = 'final_card_removeAttachments',
  final_card_addAttachments = 'final_card_addAttachments',
  final_card_editCatchment = 'final_card_editCatchment',
  final_user_assigned = 'final_user_assigned',
  final_user_unassigned = 'final_user_unassigned',
  final_owner_assigned = 'final_owner_assigned',
  final_owner_unassigned = 'final_owner_unassigned',
  final_card_editFinalType = 'final_card_editFinalType',
  final_card_seeMore = 'final_card_seeMore',
  final_card_addPhone = 'final_card_addPhone',
  final_card_seeMap = 'final_card_seeMap',
  final_card_editName = 'final_card_editName',
  final_card_editTaxName = 'final_card_editTaxName',
  final_card_editNif = 'final_card_editNif',
  final_card_editEmail = 'final_card_editEmail',
  final_card_editLocation = 'final_card_editLocation',
  final_card_editDetailLocation = 'final_card_editDetailLocation',
  final_card_addEmail = 'final_card_addEmail',
  final_card_delete = 'final_card_delete',
  final_card_sale_bill_view = 'final_card_sale_bill_view',
  final_card_waybill_tab = 'final_card_waybill_tab',
  final_card_editPhoneNumber = 'final_card_editPhoneNumber',
  final_card_editPrefix = 'final_card_editPrefix',
  final_card_bill_start = 'final_card_bill_start',
  final_card_budget_start = 'final_card_budget_start',

  final_import_exit_start = 'final_import_exit_start',
  final_import_exit_cancel = 'final_import_exit_cancel',
  final_import_exit_accept = 'final_import_exit_accept',
  final_import_exit = 'final_import_exit',
  final_import_customFieldsStep_continue = 'final_import_customFieldsStep_continue',
  final_import_templateStep_continue = 'final_import_templateStep_continue',
  final_import_uploadStep_import = 'final_import_uploadStep_import',
  final_import_finalStep_fail_upload = 'final_import_finalStep_fail_upload',
  final_import_finalStep_success_customers = 'final_import_finalStep_success_customers',
  final_import_templateStep_back = 'final_import_templateStep_back',
  final_import_uploadStep_back = 'final_import_uploadStep_back',
  final_import_uploadStep_uploadTemplate = 'final_import_uploadStep_uploadTemplate',
  final_import_uploadStep_deleteFile = 'final_import_uploadStep_deleteFile',
  final_import_templateStep_downloadTemplate = 'final_import_templateStep_downloadTemplate',
  final_import_customFieldsStep_customField = 'final_import_customFieldsStep_customField',
  final_import_customFieldsStep_help = 'final_import_customFieldsStep_help',
  final_import_templateStep_help = 'final_import_templateStep_help',
  final_import_uploadStep_help = 'final_import_uploadStep_help',
  final_import_finalStep_fail_help = 'final_import_finalStep_fail_help',
  final_import_finalStep_success_help = 'final_import_finalStep_success_help',
  final_import_uploadStep_fail_x = 'final_import_uploadStep_fail_x',

  customization_TutorialVideo = 'customization_TutorialVideo',

  waybill_list_convertToBill_start = 'waybill_list_convertToBill_start',

  task_start = 'task_start',
  task_calendar = 'task_calendar',
  task_list = 'task_list',
  task_board = 'task_board',

  location_request = 'location_request',
  location_send = 'location_send',
  location_play_tutorial = 'location_play_tutorial',

  report_deal_view = 'report_deal_view',
  report_address_view = 'report_address_view',
  report_category_view = 'report_category_view',
  report_sidebar = 'report_sidebar',

  notifications_return = 'notifications_return',
  notifications_info_start = 'notifications_info_start',
  myAppointmentsNotificationsOn = 'myAppointmentsNotificationsOn',
  myOnlineBookingNotificationsOn = 'myOnlineBookingNotificationsOn',
  settings_notifications_start = 'settings_notifications_start',

  catalogue_newCatalogue_view_more = 'catalogue_newCatalogue_view_more',

  utm_bill = 'utm_bill',
  utm_timeTracking = 'utm_timeTracking',
  utm_onlineBooking = 'utm_onlineBooking',
  utm_brand = 'utm_brand',
  utm_absence = 'utm_absence',
  utm_tasks = 'utm_tasks',

  settings_addSerie_start = 'settings_addSerie_start',

  pricingModal_contact = 'pricingModal_contact',
}
export default AmplitudeEvents;
