import { finalize, map } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TypeOfCRUD } from '@web-frontend/shared/enums/crud.enum';
import { RmSelect } from '../globals/rm-select/rm-select.types';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { BudgetCategoryFields } from './budget.enum';
import { IBdgCategFieldToDisplay } from './budget.interface';
import {
  INVOICED_STATUS_BY_BUDGET_TYPE,
  RemoveEmpty,
} from '@web-frontend/shared/utils';
import { INIT_RATES_SEARCH_PARAMS } from '@web-frontend/core/admin/rates/rates.types';
import {
  FinalService,
  StorageService,
  UserService,
} from '@web-frontend/shared/services';
import { CatalogueSearchService } from '@web-frontend/shared/services/catalogue';
import { PaginateResponse } from '@web-frontend/shared/interfaces';
import { TaxService } from '@web-frontend/shared/services/tax';
import {
  BudgetCommonData,
  Company,
  Feature,
  FeatureUser,
  FreeLimitType,
  IBudgetCommonData,
  IBudgetsSetting,
  ICatalogue,
  ItemBudget,
  PaymentInfoBudget,
  Tax,
  TypeAmplitudeBudget,
  TypeBudget,
  TypeItemBudget,
  WaybillStatus,
} from '@tacliatech/types';
import { BudgetPreviewerComponent } from '../budget-previewer/budget-previewer.component';
import { BudgetUtilisService } from './budget-util.service';
import { SenderEmail } from '@web-frontend/core/admin/budgets/budget-edit/budgets-edit.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { CreateSidenavService } from './budget-sidenav/create-budget-sidenav.service';
import {
  BudgetService,
  BudgetValidationService,
} from '@web-frontend/shared/services/budgets';
import { Router } from '@angular/router';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

import { BudgetCalculationsService } from './budget-calculations.service';
import {
  fileData,
  ImageSelectorComponent,
} from '@web-frontend/shared/components/image-selector-v2';
import { AlertService } from '@web-frontend/shared/helpers/alert';
import { DeleteBySelectionModalComponent } from '../delete-by-selection-modal/delete-by-selection-modal.component';
import { FreeLimitService } from '@web-frontend/shared/services/free-limit';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { ModalMobileInfoComponent } from '../modal-mobile-info';
import { CreateBudgetService } from './create-budget.service';
import { InvoiceService } from '@web-frontend/invoice/invoice.service';
import { v4 as uuidv4 } from 'uuid';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import InvoiceAdapter from '../../../invoice/adapter/invoice-adapter';
import { BudgetFooterComponent } from './budget-footer/budget-footer.component';
import { BudgetCategoryComponent } from './budget-category/budget-category.component';
import { MsSalesService } from '../../../sales/sales.service';

@Component({
  selector: 'roma-create-budget',
  templateUrl: './create-budget.component.html',
  styleUrls: ['./create-budget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateBudgetComponent implements OnInit, OnDestroy {
  private sub$ = new Subscription();

  @ViewChild(ImageSelectorComponent)
  imageSelector: ImageSelectorComponent;
  @ViewChild(BudgetFooterComponent)
  budgetFooterComponent: BudgetFooterComponent;
  @ViewChild(BudgetCategoryComponent)
  budgetCategoryComponent: BudgetCategoryComponent;

  featureRef = Feature;
  featureRefUser = FeatureUser;
  isLoading = false;
  imagesParsed: fileData[] = [];
  private imagesParsedCopy: fileData[] = [];
  idModuleRef = Company.IdModule;

  @Input()
  type: TypeBudget = this.data.type;

  public innerWidth = 0;
  isResponsive = false;
  isResponsive992 = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.resize992();
    this.isResponsive = this.innerWidth <= 768;
  }

  resize992() {
    this.innerWidth = window.innerWidth;
    this.isResponsive992 = this.innerWidth <= 992;
  }

  public get CategoryFields(): typeof BudgetCategoryFields {
    return BudgetCategoryFields;
  }

  public get typesItem(): typeof TypeItemBudget {
    return TypeItemBudget;
  }

  get previewIcon(): string | null {
    return window.innerWidth > 385 ? 'assets/icons/gl_eye-on.svg' : null;
  }

  conceptDescriptionPlaceholder =
    'budgets.settings.create.form.conceptAndDescription';
  quantityPlaceholder = 'budgets.settings.create.form.quantityPlaceholder';
  unitPlaceholder = 'budgets.settings.create.form.unit';
  discountPlaceholder = 'budgets.settings.create.form.discountPlaceholder';
  pricePlaceholder = 'budgets.settings.create.form.pricePlaceholder';
  taxPlaceholder = 'budgets.settings.create.form.taxPlaceholder';
  totalPlaceholder = 'budgets.settings.create.form.totalPlaceholder';

  catalogue$: Observable<ICatalogue[]>;

  allTaxes: Tax[];
  invoiceTaxes: Tax[];
  taxes: RmSelect.Items = [];

  fieldsToDisplay: IBdgCategFieldToDisplay[] = [
    {
      name: BudgetCategoryFields.UNIT,
      nameRef: 'budgets.settings.create.form.unit',
      checked: this.isMX,
      showFieldAsActivable: !this.isMX,
    },
    {
      name: BudgetCategoryFields.QUANTITY,
      nameRef: 'budgets.settings.create.form.quantity',
      checked: true,
      showFieldAsActivable: true,
    },
    {
      name: BudgetCategoryFields.DISCOUNT,
      nameRef: 'budgets.settings.create.form.discount',
      checked: false,
      showFieldAsActivable: true,
    },
  ];

  checkboxItems = {
    showTotal: true,
    showUnit: false,
    showDiscount: false,
  };

  private storeSearchParams = INIT_RATES_SEARCH_PARAMS;
  budget: BudgetCommonData = new BudgetCommonData();
  AllSettings: IBudgetsSetting;
  isLoadingSettings = true;
  showTotal = false;
  mode: TypeOfCRUD;
  TYPE_OF_CRUD = TypeOfCRUD;
  TYPE_OF_BUDGET = TypeBudget;
  finalsEmail: SenderEmail[] = [];
  title: string;
  showToolTip = false;
  formIsInvalid = false;
  isMobile = false;
  showFieldsList = false;
  showAddLineList = false;
  showMoreMenu = false;
  waybillsForUpdate: IBudgetCommonData[];
  units: RmSelect.Items = [];
  isLoadingUnits = false;

  paymentsMethods: PaymentInfoBudget[] = [];

  budgetTypeAmplitude: TypeAmplitudeBudget;

  form = new FormGroup({
    budgetHeader: new FormGroup({
      contact: new FormControl('', [Validators.required]),
      contact_id: new FormControl('', [Validators.required]),
      prefix: new FormControl(''),
      numberDoc: new FormControl('', [Validators.required]),
      sequence_id: new FormControl('', [Validators.required]),
      date: new FormControl(new Date(), [Validators.required]),
      dueDate: new FormControl(''),
    }),
    budgetFooter: new FormGroup({
      messages: new FormControl(''),
      payment_method: new FormControl('', [Validators.required]),
      category_id: new FormControl(''),
      project_id: new FormControl(''),
      deal_id: new FormControl(''),
      showPrices: new FormControl(true),
      showSignatures: new FormControl(true),
      purposeUse: new FormControl(null, [Validators.required]),
      invoiceType: new FormControl(null, [Validators.required]),
    }),
    budgetCategories: new FormArray([
      new FormGroup({
        validCategory: new FormControl(true, [Validators.requiredTrue]),
        idConcept: new FormControl(''),
        concept: new FormControl('', [Validators.required]),
        description: new FormControl(''),
        quantity: new FormControl(''),
        unit: new FormControl(''),
        price: new FormControl(''),
        taxes: new FormArray([]),
        discount: new FormControl('', [Validators.max(100), Validators.min(0)]),
        total: new FormControl(''),
        productKey: new FormControl(''),
      }),
    ]),
  });

  duplicate = false;
  convert = false;
  freeLimit: FreeLimitType.Response;
  isLoadingFreeLimits = false;
  invoicing = false;
  canEditContact = true;

  get budgetCategories(): FormArray {
    return this.form.get('budgetCategories') as FormArray;
  }

  get headerGroup(): FormArray {
    return this.form.controls['budgetHeader'] as FormArray;
  }

  _haveChanges = false;
  get haveChanges(): boolean {
    return (
      this.form.dirty ||
      !this.form.pristine ||
      this.form.touched ||
      this._haveChanges
    );
  }

  isPOV = false;

  featuresProEnabled$ = this.permissionService.hasFeatureFn(
    Feature.Sale.SendBudgetEmail
  );
  isProUser: boolean;

  dataSnackbar: {
    type: string;
    message?: string;
    textLink?: string;
    error: 'data' | 'cert';
  } = {
    type: 'warning',
    error: 'data',
  };
  showSnackbarMexico = false;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CreateBudgetComponent>,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _catalogueSearchService: CatalogueSearchService,
    private readonly _taxService: TaxService,
    private budgetService: BudgetService,
    private budgetUtilisService: BudgetUtilisService,
    private finalService: FinalService,
    private amplitudeService: AmplitudeService,
    private createSidenavService: CreateSidenavService,
    private readonly _settingsService: BudgetService,
    private router: Router,
    private toastService: ToastService,
    private i18n: TranslateService,

    @Inject(MAT_DIALOG_DATA) public data: any,
    private budgetCalculationsService: BudgetCalculationsService,
    private alertService: AlertService,
    private readonly freeLimitService: FreeLimitService,
    public readonly permissionService: PermissionService,
    private readonly createBudgetService: CreateBudgetService,
    private readonly invoiceService: InvoiceService,
    private brazeService: BrazeService,
    private readonly userService: UserService,
    private budgetValidationService: BudgetValidationService,
    private readonly invoiceAdapter: InvoiceAdapter,
    private readonly msSalesService: MsSalesService
  ) {
    this.validateBDGData();
  }

  get isMX(): boolean {
    return this.userService.validateCountryMX();
  }

  get isMXandTypeBill(): boolean {
    return this.isMX && this.type === TypeBudget.BILL;
  }

  get validForBillLegacy(): boolean {
    return (
      this.type !== TypeBudget.BILL ||
      (this.type === TypeBudget.BILL && !this.isMX)
    );
  }

  validateBDGData() {
    if (this.type === TypeBudget.BILL) {
      const result = this.budgetValidationService.validateBDGData(
        this.showSnackbarMexico,
        this.dataSnackbar
      );
      this.showSnackbarMexico = result.showSnackbarMexico;
      this.dataSnackbar = result.showSnackbarMexico
        ? result.dataSnackbar
        : this.dataSnackbar;
    }
  }

  onLinkClickSnackbar() {
    if (this.dataSnackbar.error === 'data') {
      this.closeDialog();
      this.router.navigate(['admin/settings'], {
        queryParams: { type: 'general-settings' },
      });
    }
  }

  changeFileArray(evt: fileData[]) {
    this.imagesParsed = evt?.length ? evt : [];
    if (this.imagesParsedCopy && this.imagesParsedCopy.length > evt?.length) {
      this.amplitudeService.sendEvent({
        event: 'waybill_card_removeImages',
      });
    } else if (evt?.length > 0) {
      this.amplitudeService.sendEvent({
        event: 'waybill_card_addImages',
      });
    }
    this.imagesParsedCopy = this.imagesParsed;
    this.draw();
  }

  getCompanyDistribution(): string {
    const documentTemplates = JSON.parse(StorageService.companyData)
      .personalization?.documentTemplates;

    const distributionMap = {
      [TypeBudget.BILL]: documentTemplates?.bill,
    };

    return distributionMap[this.type] || 'template1';
  }

  getCompanyColor(): string {
    const documentTemplates = JSON.parse(StorageService.companyData)
      .personalization?.colors?.primary;

    return documentTemplates;
  }

  ngOnInit(): void {
    this.getSettings();

    if ('mode' in this.data) {
      this.mode = this.data.mode;
      this.duplicate = this.data.duplicate;
      this.convert = this.data.convert;
      this.canEditContact =
        this.data.canEditContact !== undefined
          ? this.data.canEditContact
          : true;
      this.invoicing = this.data.invoicing;
      this.waybillsForUpdate = this.data.entries;

      if ('budget' in this.data) {
        this.budget._id = this.data.budget._id;
        this.budget.signClient = this.data.budget.signClient;
        this.budget.signCompany = this.data.budget.signCompany;
        this.fillHeaderForm(this.data.budget);
        this.fillCategoryForm(this.data.budget);
        this.fillFooterForm(this.data.budget);

        if (this.duplicate) {
          this.modifyForDuplication();
        }

        if (this.convert) {
          if (this.type === TypeBudget.BILL) {
            this.budget.quoteId = this.data.budget.convertFromId;
          }
          this.modifyForConvert();
        }
      }
    } else {
      this.mode = TypeOfCRUD.CREATE;

      if (this.data?.dealId) {
        this.form.get('budgetFooter').get('deal_id').setValue(this.data.dealId);
        this.isPOV = true;
      }

      if (this.data?.customerId) {
        this.form
          .get('budgetHeader')
          .get('contact')
          .setValue(this.data?.customerId);
        this.form
          .get('budgetHeader')
          .get('contact_id')
          .setValue(this.data?.customerId);
        this.isPOV = true;
      }
    }

    if (this.type === TypeBudget.BILL)
      this.budget.personalization = {
        distribution:
          this.data?.budget?.personalization?.distribution ||
          this.getCompanyDistribution(),
        color:
          this.data?.budget?.personalization?.color || this.getCompanyColor(),
      };

    if (this.type === TypeBudget.WAL) {
      this.watchWaybillFreeLimit();
    }

    this.onResize();
    this.resolveTaxes();
    this.requestCatalogues();
    this.watchItems();
    if (this.isMX) this.resolveUnits();
    this.title = this.getTitle(this.type);
    this.createSidenavService.refreshList$.subscribe((res) => {
      if (res) {
        this.closeDialog();
      }
    });

    this.sub$.add(
      this.createBudgetService.closeModal$.subscribe((close) => {
        if (close) this.closeDialog();
      })
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  getTitle(type: TypeBudget): string {
    let title = 'budgets.title.';
    let creating = true;

    if (this.mode === TypeOfCRUD.UPDATE) {
      if (!this.convert && !this.duplicate) {
        creating = false;
      }
    }

    switch (type) {
      case TypeBudget.BUDGET:
        this.budgetTypeAmplitude = TypeAmplitudeBudget.BUDGET;
        title += creating ? 'newBudget' : 'editBudget';
        break;
      case TypeBudget.PROFORM:
        this.budgetTypeAmplitude = TypeAmplitudeBudget.PROFORM;
        title += creating ? 'newProform' : 'editProform';
        break;
      case TypeBudget.BILL:
        this.budgetTypeAmplitude = TypeAmplitudeBudget.BILL;
        title += creating ? 'newBill' : 'editBill';
        break;
      case TypeBudget.WAL:
        this.budgetTypeAmplitude = TypeAmplitudeBudget.WAL;
        title += creating ? 'newWaybill' : 'editWaybill';
        break;
      default:
        title += 'new';
        break;
    }

    return title;
  }

  clickSettings(): void {
    this.sendAmplitudeEvent('card_account_settings');
  }

  refreshSettings(): void {
    this.getSettings();
    this.resolveTaxes();
    this.budgetFooterComponent?.fillLists();
    this.budgetCategoryComponent?.loadTaxes();
    this.draw();
  }

  getSettings(): void {
    this.sub$.add(
      this.budgetService.settings$.subscribe((resp: IBudgetsSetting) => {
        if (resp) {
          this.AllSettings = resp;
          this.isLoadingSettings = false;
          this.draw();
        }
      })
    );
  }

  requestCatalogues(): void {
    // this.isLoadingRates = true;
    // this.draw();

    let query = RemoveEmpty(this.storeSearchParams);

    query = {
      ...query,
      company_id: StorageService.CompanyId,
      applyPaginate: false,
    };
    this._catalogueSearchService
      .search({
        ...query,
      })
      .pipe(
        map((res: PaginateResponse<ICatalogue[]>) => {
          const catalogue = res.docs as ICatalogue[];
          return catalogue;
        }),
        finalize(() => {
          // this.isLoadingRates = false;
          this.draw();
        })
      )
      .subscribe((catalogue) => {
        this.catalogue$ = of(catalogue);
      });
  }

  public resolveUnits(): void {
    this.isLoadingUnits = true;
    this.invoiceAdapter
      .getUnits()
      .then((res) => {
        this.units = res.map((unit) => {
          return { title: unit.description, value: unit.id, id: unit.id };
        });
      })
      .catch(() => {
        this.units = [];
      })
      .finally(() => {
        this.isLoadingUnits = false;
      });
  }

  settingIsNull = true;
  resolveTaxes(): void {
    this.sub$.add(
      this._settingsService.settings$.subscribe((settings) => {
        if (settings) {
          this.settingIsNull = false;
        }
        this.allTaxes = this._taxService.findAll();
        if (settings?.invoiceSettings?.defaultTaxesList?.length) {
          this.invoiceTaxes = this._taxService.sort(
            settings.invoiceSettings.defaultTaxesList
          );
        } else {
          this.invoiceTaxes = this._taxService.findAll();
        }
        this.draw();
      })
    );
  }

  clearFormValidator(
    footerKey: 'budgetFooter' | 'budgetHeader',
    key: string
  ): void {
    this.form.get(footerKey).get(key).clearValidators();
    this.form.controls[footerKey].get(key).updateValueAndValidity();
  }

  addFormValidator(
    footerKey: 'budgetFooter' | 'budgetHeader',
    key: string
  ): void {
    this.form.get(footerKey).get(key).setValidators([Validators.required]);
    this.form.controls[footerKey].get(key).updateValueAndValidity();
  }

  handleExtraValidators({
    removeMxValidators,
    restoreMXValidators,
    removeContactValidators,
  }: {
    removeMxValidators?: boolean;
    restoreMXValidators?: boolean;
    removeContactValidators?: boolean;
  }): void {
    if (removeMxValidators) {
      // if not MX clear required
      this.clearFormValidator('budgetFooter', 'purposeUse');
      this.clearFormValidator('budgetFooter', 'invoiceType');
      this.clearFormValidator('budgetFooter', 'payment_method');

      (this.form.get('budgetCategories') as FormArray).controls.forEach(
        (control) => {
          if (control.get('concept')) {
            control.get('concept').clearValidators();
            control.get('concept').updateValueAndValidity();
          }
          if (control.get('validCategory')) {
            control.get('validCategory').clearValidators();
            control.get('validCategory').updateValueAndValidity();
          }
        }
      );
    } else if (restoreMXValidators) {
      this.addFormValidator('budgetFooter', 'purposeUse');
      this.addFormValidator('budgetFooter', 'invoiceType');
      this.addFormValidator('budgetFooter', 'payment_method');

      (this.form.get('budgetCategories') as FormArray).controls.forEach(
        (control) => {
          if (control.get('concept')) {
            control.get('concept').setValidators([Validators.required]);
            control.get('concept').updateValueAndValidity();
          }
          if (control.get('validCategory')) {
            control
              .get('validCategory')
              .setValidators([Validators.requiredTrue]);
            control.get('validCategory').updateValueAndValidity();
          }
        }
      );
    }
    if (removeContactValidators) {
      if (this.form.value?.budgetHeader?.contact_id) {
        this.clearFormValidator('budgetHeader', 'contact');
      }
    }
  }

  validateForm(): void {
    this.formIsInvalid = true;
    this.form.markAllAsTouched();
    this.form.controls['budgetHeader'].updateValueAndValidity();
    this.form.controls['budgetFooter'].updateValueAndValidity();
    (this.form.controls[
      'budgetCategories'
    ] as FormArray).controls.forEach((control) =>
      control.updateValueAndValidity()
    );
    if (this.headerGroup.controls['contact'].invalid) {
      this.sendAmplitudeEvent('card_noEditFinal_Create');
    }

    this.isLoading = false;
  }

  fillBudget(): void {
    const totals = this.budgetCalculationsService.calculateTotalsRealTime(
      this.form.value.budgetCategories.flat(),
      this.AllSettings
    );

    const { date, dueDate } = this.budgetUtilisService.formatDates(
      this.form.controls['budgetHeader'] as FormGroup
    );

    const { budgetFooter, budgetHeader, budgetCategories } = this.form?.value;

    this.budget.company = StorageService.CompanyId;
    this.budget.iva_total = totals.ivas;
    this.budget.subtotal = totals.subTotal;
    this.budget.total = totals.total;
    this.budget.discount = totals.discount;
    if (budgetFooter?.deal_id)
      this.budget.header.idDeal = budgetFooter?.deal_id;
    const contact = budgetHeader?.contact ? budgetHeader?.contact : undefined;
    this.budget.header.contact = contact;
    this.budget.header.contact_id = contact;
    this.budget.header.numberDoc = budgetHeader?.numberDoc;
    this.budget.header.prefix =
      budgetHeader?.prefix === '__00__'
        ? this.mode === TypeOfCRUD.CREATE
          ? ''
          : '0'
        : budgetHeader?.prefix;
    this.budget.header.sequence_id = budgetHeader?.sequence_id;
    this.budget.header.date = date;
    this.budget.header.dueDate = dueDate;
    if (this.type === TypeBudget.WAL) {
      this.budget.header.showPrices = budgetFooter?.showPrices;
      this.budget.header.showSignatures = budgetFooter?.showSignatures;
    }
    this.budget.items = budgetCategories.flat();
    this.removeFieldFromArray(this.budget.items, 'isEditing');
    this.budget.messages.line1 = budgetFooter?.messages;
    if (budgetFooter?.category_id)
      this.budget.addicionalInfo.category_id = budgetFooter.category_id;
    if (budgetFooter?.project_id)
      this.budget.addicionalInfo.project_id = budgetFooter?.project_id;

    const payment_method = budgetFooter.payment_method || [];
    if (this.isMXandTypeBill) {
      this.budget.typePayments = [
        {
          textShow: payment_method[0]?.title,
          name: payment_method[0]?.id || payment_method[0]?._id,
        },
      ];
    } else {
      const paymentId =
        payment_method?.length > 0
          ? payment_method[0]?.id || payment_method[0]?._id
          : null;
      this.budget.typePayments = this.paymentsMethods.filter(
        (p) => p._id === paymentId
      );
    }

    this.budget.status = budgetHeader?.status;

    this.budget.deleted = false;
    if (this.isMXandTypeBill) {
      this.budget.invoiceType = budgetFooter?.invoiceType;
      this.budget.purposeUse = budgetFooter?.purposeUse;
    }

    if (this.type !== TypeBudget.BILL) delete this.budget.personalization;
    this.budget = this.removeEmptyItems(this.budget);
  }

  submitBudget(params: {
    goToList?: boolean;
    isDraft?: boolean;
    forceCreate?: boolean;
    isDraftAndIssue?: boolean;
  }): Promise<boolean> {
    const {
      goToList = true,
      isDraft = true,
      forceCreate = false,
      isDraftAndIssue = false,
    } = params;

    const errMsgForm = 'invalid_form';
    return new Promise(async (resolve) => {
      try {
        this.isLoading = true;
        this.handleExtraValidators({
          removeMxValidators: !this.isMXandTypeBill || isDraft,
          removeContactValidators: isDraft,
        });

        this.validateForm();
        if (this.form.invalid) {
          if (this.isResponsive) {
            this.triggerMobileModal({
              html: 'budgets.settings.create.form.tooltip-invalid',
              buttonText: 'kanban.dialog.confirmButton',
            });
          }
          this.isLoading = false;
          this.draw();
          throw new Error(errMsgForm);
        }

        this.fillBudget();

        delete this.budget.status;

        if (
          this.mode === TypeOfCRUD.CREATE ||
          (this.mode === TypeOfCRUD.UPDATE && this.convert) ||
          forceCreate
        ) {
          const amplitudeEvents = {
            [TypeBudget.BUDGET]: 'budget_create',
            [TypeBudget.PROFORM]: 'proform_create',
            [TypeBudget.BILL]:
              this.isMX && isDraft
                ? AmplitudeEvents.billCardSaveDraft
                : 'bill_create',
            [TypeBudget.WAL]: 'waybill_create',
          };

          const BrazeEvents = {
            ...amplitudeEvents,
            [TypeBudget.BILL]:
              this.isMX && isDraft
                ? AmplitudeEvents.billCardSaveDraft
                : 'bill_create',
          };
          if (!this.budget.header.sequence_id && this.convert) {
            delete this.budget.header.sequence_id;
            delete this.budget.header.prefix;
            delete this.budget.header.numberDoc;
          }

          const data = await this.invoiceService
            .createV2({
              ...this.budget,
              type: this.type,
              files:
                this.type === TypeBudget.WAL
                  ? (await this.imageSelector.uploadFiles2()).map(
                      (fileObj) => fileObj.fileFirebaseUrl
                    )
                  : [],
            })
            .then((res) => res.body);

          if (this.convert && this.type === TypeBudget.BUDGET) {
            this.amplitudeService.sendEvent({
              event: `${TypeAmplitudeBudget.BUDGET}_view_convertToProforma`,
            });
          }

          if (this.type === TypeBudget.BILL) {
            if (this.invoicing) {
              const waybills = (data.items || data.lines || []).filter(
                (i) => i.type === TypeItemBudget.WAYBILL
              );

              this.invoiceWaybill({
                invoiced: true,
                waybills: waybills.map((i) =>
                  i?.description?.replaceAll(' ', '')
                ),
                waybillsForUpdate: this.waybillsForUpdate,
                id: data._id,
              });
            } else if (this.convert) {
              let event;
              let updateStatus: string;
              const id = this.data?.id_from;
              const type = this.data?.type_from;
              const status = INVOICED_STATUS_BY_BUDGET_TYPE[type].id;

              switch (type) {
                case TypeBudget.BUDGET:
                  event = `${TypeAmplitudeBudget.BUDGET}_view_convertToBill`;
                  break;
                case TypeBudget.PROFORM:
                  event = `${TypeAmplitudeBudget.PROFORM}_view_convertToBill`;
                  updateStatus = 'updateStatusProform';
                  break;
                default:
                  break;
              }

              if (event) {
                this.amplitudeService.sendEvent({ event });
              }
              if (updateStatus) {
                this.budgetService[updateStatus](id, { status }).subscribe(
                  () => {
                    this.goToList(data._id);
                  }
                );
              }
            }

            this.showMessageCreated();
          }

          this.amplitudeService.sendEvent({
            event: amplitudeEvents[this.type],
          });

          this.brazeService.sendEvent({
            event: amplitudeEvents[this.type],
          });
          if (goToList) {
            this.goToList(data._id || data.id || this.budget._id);
          }
        } else if (this.mode === TypeOfCRUD.UPDATE && !this.convert) {
          const amplitudeEvents = {
            [TypeBudget.BUDGET]: this.duplicate
              ? 'budget_view_options_duplicate'
              : 'budget_edit',
            [TypeBudget.PROFORM]: this.duplicate
              ? 'proform_view_options_duplicate'
              : 'proform_edit',
            [TypeBudget.BILL]: this.duplicate
              ? 'bill_view_options_duplicate'
              : this.isMX
              ? AmplitudeEvents.billCardGenerateBill
              : 'bill_edit',
            [TypeBudget.WAL]: this.duplicate
              ? 'waybill_view_options_duplicate'
              : 'waybill_edit',
          };

          const data = await this.invoiceService.updateV2({
            ...this.budget,
            files:
              this.type === TypeBudget.WAL
                ? (await this.imageSelector.uploadFiles2()).map(
                    (fileObj) => fileObj.fileFirebaseUrl
                  )
                : [],
            type: this.type,
            duplicate: this.duplicate,
          });
          this.showMessageEdited();
          this.goToList(
            data._id || data.body?._id || data.id || this.budget._id
          );

          this.amplitudeService.sendEvent({
            event: amplitudeEvents[this.type],
          });
        }
        if (!isDraftAndIssue) {
          this.closeDialog('refresh');
        }
        this.isLoading = false;
        resolve(true);
      } catch (err) {
        if (err.message !== errMsgForm) {
          const errorMessage = err?.error?.message;
          let messageText = 'billing.error';
          if (errorMessage === 'Invoice must contain at least one line.') {
            messageText = 'budgets.error.noLinesError';
          }

          this.toastService.show({
            text: this.i18n.instant(messageText),
            type: 'error',
            msDuration: 4000,
          });
        }
        this.isLoading = false;
        resolve(false);
      }
    });
  }

  get duplicateMXBill(): boolean {
    return this.duplicate && this.isMXandTypeBill;
  }

  async submitDraft(): Promise<void> {
    delete this.budget.issueAt;
    await this.submitBudget({
      forceCreate: this.duplicateMXBill,
    });
  }

  async submitDraftAndIssue(): Promise<void> {
    const id = this.budget._id || uuidv4();
    let created = false;
    this.handleExtraValidators({ restoreMXValidators: true });

    if (this.mode === TypeOfCRUD.CREATE || this.duplicateMXBill) {
      this.budget._id = id;
      created = await this.submitBudget({
        goToList: false,
        isDraft: false,
        forceCreate: this.duplicateMXBill,
        isDraftAndIssue: true,
      });
      if (created) {
        this.mode = TypeOfCRUD.UPDATE;
      } else {
        this.mode = TypeOfCRUD.CREATE;
        delete this.budget._id;
      }
    }
    if (created || this.mode == TypeOfCRUD.UPDATE) {
      this.convert = false;
      this.budget.issueAt = new Date().toISOString();
      await this.submitBudget({ isDraft: false });
    }
  }

  removeEmptyItems(budget: IBudgetCommonData): IBudgetCommonData {
    for (let i = 0; i < budget.items.length; i++) {
      const element = budget.items[i];
      let emptyCount = 0;
      const fieldsCount = Object.keys(element).length;
      for (const [key, value] of Object.entries(element)) {
        emptyCount +=
          !value || value === undefined || value.length === 0 ? 1 : 0;
      }
      if (emptyCount >= fieldsCount) {
        budget.items[i] = null;
      }
    }
    budget.items = budget.items.filter((i) => i);
    return budget;
  }

  removeFieldFromArray(array: any[], field: string) {
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      delete element[field];
    }
  }

  goToList(id: string): void {
    if (!this.isPOV) {
      this.router.navigate(['/admin/budgets/show'], {
        queryParams: { type: this.type, budget: id },
      });
      this.isLoading = false;
      this.closeDialog();
    } else {
      this.isLoading = false;
      this.closeDialog('refresh');
    }
  }

  sendAndClose(event) {
    this.router.navigate([event.url], {
      queryParams: { type: event.type },
    });
    this.closeDialog();
  }

  goTo(event): void {
    if (event.changes) {
      const data = {
        ...event,
        title: 'budgets.modal.wantExit',
        showBody: false,
        confirmLabel: 'budgets.modal.btnConfirmExit',
      };

      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'EXECUTE') {
          this.sendAndClose(event);
        }
      });
    } else {
      this.sendAndClose(event);
    }
  }

  modifyForDuplication(): void {
    this.budget._id = '';

    // Header
    this.form.controls['budgetHeader'].patchValue({
      numberDoc: '',
      prefix: '',
      sequence_id: '',
      contact: '',
      contact_id: '',
      date: new Date(),
      dueDate: '',
    });

    // Footer
    this.form.controls['budgetFooter'].patchValue({
      category_id: '',
      project_id: '',
      deal_id: '',
    });
  }

  modifyForConvert(): void {
    // Header
    this.form.controls['budgetHeader'].patchValue({
      numberDoc: '',
      prefix: '',
      sequence_id: '',
      date: new Date(),
      contact: this.data.budget.header.contact,
      contact_id: this.data.budget.header.contact_id,
    });

    // Footer
    this.form.controls['budgetFooter'].patchValue({
      category_id: '',
      project_id: '',
      deal_id: '',
    });
  }

  showMessageCreated(): void {
    const text = this.convert
      ? 'budgets.settings.edit.successConvert'
      : 'budgets.settings.create.successCreated';
    this.toastService.show({
      text: this.i18n.instant(text),
      type: 'success',
      msDuration: 4000,
    });
  }

  showMessageEdited(): void {
    this.toastService.show({
      text: this.i18n.instant('budgets.settings.create.successEdit'),
      type: 'success',
      msDuration: 4000,
    });
  }

  showMessageDuplicated() {
    this.toastService.show({
      text: this.i18n.instant('budgets.duplicateDocument'),
      type: 'success',
      msDuration: 4000,
    });
  }

  closeDialog(e?: string): void {
    let event = {};
    if (e) {
      event = { event: e };
    } else {
      this.sendAmplitudeEvent('card_exit');
    }
    this.dialogRef.close(event);
  }

  addLineWithoutEvent(): void {
    this.budgetCategories.push(
      new FormGroup({
        validCategory: new FormControl(true, [Validators.requiredTrue]),
        idConcept: new FormControl(''),
        concept: new FormControl('', [Validators.required]),
        description: new FormControl(''),
        quantity: new FormControl(''),
        unit: new FormControl(''),
        price: new FormControl(''),
        taxes: new FormArray([]),
        discount: new FormControl('', [Validators.max(100), Validators.min(0)]),
        total: new FormControl(''),
      })
    );
  }

  addLine(e = 'addLine'): void {
    this._haveChanges = true;
    let event;

    this.addLineWithoutEvent();

    switch (e) {
      case 'addLine_addLine':
        event = 'card_addLine_addLine';
        break;
      default:
        event = 'card_addLine';
        break;
    }

    this.sendAmplitudeEvent(event);
  }

  onChange() {
    this._haveChanges = true;
  }

  addCategoryGroup(): void {
    this.budgetCategories.push(
      new FormGroup({
        description: new FormControl(''),
        type: new FormControl(TypeItemBudget.CATEGORY),
        isEditing: new FormControl(true),
      })
    );
    this.draw();
    this.sendAmplitudeEvent('card_addLine_addCaption');
  }

  // setCategoryGroupName(
  //   event: FocusEvent | string | any,
  //   groupIndex: number
  // ): void {
  //   if (!event?.target?.value && typeof event !== 'string') {
  //     return;
  //   }
  //   const value = event?.target?.value || event;
  //   this.categoryList.splice(groupIndex, 1, value);
  //   const editableIndex = this.categoryNameEditIndex.indexOf(groupIndex);
  //   this.categoryNameEditIndex.splice(editableIndex, 1);
  //   this.updateCategoryGroupName(value, groupIndex);
  // }

  updateEditCategory(index: number, value: boolean) {
    const category = this.budgetCategories.controls[index];
    if (category.value?.description === '' || !category.value?.description) {
      return;
    }
    if (category.value.isEditing !== undefined) {
      category.patchValue({
        isEditing: value,
      });
    }
  }

  editCategoryGroupName(value: string, index: number): void {
    this.budgetCategories.controls[index].patchValue({
      description: value,
    });
    // Patch rawValue because child control doesnt change parent value
    this.budgetCategories.patchValue(this.budgetCategories.getRawValue());
    this.draw();
  }

  removeLine(index: number): void {
    if (
      index > 0 ||
      (index === 0 && this.budgetCategories?.controls?.length > 1)
    ) {
      const prevLine = this.budgetCategories.at(index - 1) as FormGroup;
      const nextLine = this.budgetCategories.at(index + 1) as FormGroup;
      if (
        prevLine?.controls['type']?.value === TypeItemBudget.WAYBILL &&
        (nextLine === undefined ||
          nextLine?.controls['type']?.value === TypeItemBudget.WAYBILL)
      ) {
        this.budgetCategories.removeAt(index);
        this.budgetCategories.removeAt(index - 1);
      } else {
        this.budgetCategories.removeAt(index);
      }
      this.draw();
      this.sendAmplitudeEvent('card_deleteLine');
      return;
    }

    if (index === 0 && this.budgetCategories?.controls?.length === 1) {
      this.budgetCategories.removeAt(index);
      this.addLineWithoutEvent();
      this.draw();
      this.sendAmplitudeEvent('card_deleteLine');
    }
  }

  invoiceWaybill(data: any) {
    const length = data.waybills?.length;
    let waybills = data.waybillsForUpdate;
    if (length !== data.waybillsForUpdate?.length) {
      waybills = [];

      for (const waybillNumber of data.waybills) {
        const numDoc = waybillNumber?.split('-');
        const waybill = data.waybillsForUpdate.find(
          (w) =>
            w?.header?.prefix === numDoc[0] &&
            w?.header?.numberDoc === numDoc[1]
        );
        if (waybill) {
          waybills.push(waybill);
        }
      }
    }

    this.updateWaybillStatus(waybills);
    this.router.navigate(['/admin/budgets/show'], {
      queryParams: { type: TypeBudget.BILL, budget: data.id },
    });
  }

  updateWaybillStatus(budgets: IBudgetCommonData[] = this.waybillsForUpdate) {
    const ids = budgets.map((e: IBudgetCommonData) => {
      e.status = WaybillStatus.Invoiced;
      return e._id;
    });
    const data = {
      ids: ids,
      docs: budgets,
    };
    this.budgetService.updateManyWaybills(data).subscribe();
  }

  displayCategoryNameInput(index: number): boolean {
    const line = this.budgetCategories.controls[index].value;
    return !!line.isEditing;
  }

  changeInputsCheckBox(evt: boolean, type: IBdgCategFieldToDisplay): void {
    type.checked = evt;
    this.draw();
    const event = evt
      ? 'lineConfiguration_addField'
      : 'lineConfiguration_removeField';
    this.sendAmplitudeEvent(event);
  }

  resolveShowField(fieldName: BudgetCategoryFields): boolean {
    const fieldObj = this.fieldsToDisplay.find((f) => f.name === fieldName);
    return fieldObj.checked;
  }

  goToSettings(): void {
    this.router.navigate(['admin', 'settings'], {
      queryParams: { type: 'general-settings' },
    });
    this.dialogRef.close();
  }

  watchItems(): void {
    this.sub$.add(
      this.budgetUtilisService.refreshBudget.subscribe(
        (data: IBudgetCommonData) => {
          this.budget = data;
        }
      )
    );

    this.budgetUtilisService.refreshItems.subscribe((items: ItemBudget[]) => {
      this.budget.items = items;
    });
  }

  cantUploadMoreFiles($event: void) {
    this.amplitudeService.sendEvent({
      event: 'waybill_card_addImages',
    });
    this.alertService.customAlert(
      this.i18n.instant('budgets.settings.create.form.sectionFilesWarning'),
      'warning'
    );
  }

  showPreview(screen: string): void {
    this.sendAmplitudeEvent('card_preview');

    this.handleExtraValidators({
      removeMxValidators: true,
    });
    this.validateForm();
    if (this.form.invalid) {
      if (this.isResponsive) {
        this.triggerMobileModal({
          html: 'budgets.settings.create.form.tooltip-invalid-preview',
          buttonText: 'kanban.dialog.confirmButton',
        });
      }
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    if (screen === 'mobile') {
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxWidth = '100vw';
      dialogConfig.maxHeight = '100vh';
    } else {
      dialogConfig.width = '660px';
      dialogConfig.height = '84vh';
      dialogConfig.minWidth = '660px';
    }
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'previewer-modal';
    dialogConfig.data = {
      form: this.form,
      settings: this.AllSettings,
      type: this.type,
      mode: this.mode,
      files: this.imagesParsed.map((it) => it.fileFirebaseUrl),
      signClient: this.budget.signClient,
      signCompany: this.budget.signCompany,
      fieldsToDisplay: this.buildFieldsToDisplay(),
      distribution: this.getCompanyDistribution(),
    };

    this.showTotal = false;

    const dialogRef = this.dialog.open(BudgetPreviewerComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      this.showTotal = true;
      this.draw();
    });
  }

  showCustomization(): void {
    let event;

    if (this.type === TypeBudget.BILL) {
      event = 'budget_card_personalize';
    } else if (this.type === TypeBudget.WAL) {
      event = 'waybill_card_personalize';
    }

    this.amplitudeService.sendEvent({ event });
    this.createSidenavService
      .open({
        panelClass: 'sidenav-modal',
        data: { type: this.type, haveChanges: this.haveChanges },
      })
      .pipe(map((res) => !res))
      .subscribe();
  }

  showCustomizationMobile() {
    this.sendAmplitudeEvent('card_personalize');
    this.createSidenavService
      .open({
        maxWidth: '100vw',
        maxHeight: '100vh',
        width: '100%',
        height: '100%',
        panelClass: 'full-screen-modal',
        data: { isResponsive: true },
      })
      .pipe(map((res) => !res))
      .subscribe();
  }

  changedModel($event, type: string): void {
    if (type === 'header') {
      //update sender email list with new finals ,
      this.requestFinals();

      this.budget.header = $event.header;
      if (this.budget.header.date == '') {
        this.budget.header.date = null;
      }
      if (this.budget.header.dueDate == '') {
        this.budget.header.dueDate = null;
      }
    }
    if (type === 'items') {
      this.budget.items = $event.header;
    }
    if (type === 'footer') {
      this.budget.addicionalInfo = $event.addicionalInfo;
      this.budget.messages = $event.messages;
      this.budget.typePayments = $event.typePayments;
    }
  }

  drop(event: CdkDragDrop<FormGroup[]>): void {
    if (event.previousContainer === event.container) {
      this.moveItemInFormArray(
        this.budgetCategories,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  isWaybill(item: FormGroup): boolean {
    if (!item || item?.value?.type === undefined) return false;
    return item?.value?.type === TypeItemBudget.WAYBILL;
  }

  displayCategoriesHeader() {
    return !this.isWaybill(this.budgetCategories.at(0) as FormGroup);
  }

  moveItemInFormArray(
    formArray: FormArray,
    fromIndex: number,
    toIndex: number
  ): void {
    const dir = toIndex > fromIndex ? 1 : -1;

    const item = formArray.at(fromIndex);
    for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(toIndex, item);
  }

  displayTooltip(canDisplay = true): void {
    if (!this.isResponsive && this.form.invalid) {
      this.showToolTip = canDisplay;
    } else if (
      this.freeLimitService.firstWayBillCreated$.value &&
      !this.isProUser &&
      this.mode !== TypeOfCRUD.UPDATE
    ) {
      this.showToolTip = canDisplay;
    }
  }

  private requestFinals() {
    this.finalService
      .search({ applyPaginate: false, limit: 1 })
      .subscribe((res) => {
        this.finalsEmail = res?.docs.map((final) => {
          return new SenderEmail(final._id, final?.email);
        });
      });
  }

  onShowFieldsList() {
    this.showFieldsList = !this.showFieldsList;
  }

  onShowAddLineList() {
    this.showAddLineList = !this.showAddLineList;
  }

  private fillHeaderForm(budget: BudgetCommonData): void {
    let date = budget.header?.date || new Date();
    date = new Date(date).toISOString().split('T')[0];

    const budgetHeader = {
      prefix: budget.header.prefix,
      numberDoc: budget.header.numberDoc,
      date: new Date(date.replaceAll('-', '/')),
      dueDate:
        budget.header.dueDate && !this.duplicate
          ? new Date(budget.header.dueDate.replaceAll('-', '/'))
          : '',
      contact: budget.header?.contact,
      contact_id: budget.header?.contact_id
        ? budget.header?.contact_id
        : budget.header?.contact,
      sequence_id: budget.header?.sequence_id,
    };

    if (this.type === TypeBudget.WAL) {
      if (budget.files.length) {
        const images = budget?.files;
        this.imagesParsed = images?.map((e) => {
          const file = e;
          return {
            extension: file
              .substring(0, file.indexOf('?'))
              .slice(file.indexOf('.'))
              .replaceAll('.', ''),
            fileFirebaseUrl: file,
            fileName: file.substring(0, file.indexOf('.')),
          };
        });
      }
    }

    // if (this.canEditContact === false) {
    //   this.headerGroup.controls['contact']?.disable();
    // }

    this.form.patchValue({
      budgetHeader,
    });
    this.draw();
  }

  private fillCategoryForm(budget: BudgetCommonData): void {
    let index = -1;
    const items = budget.items.map((i) => {
      const taxes = new FormArray(
        i?.taxes?.map(
          (tax) =>
            new FormGroup({
              _id: new FormControl(tax?._id),
              value: new FormControl(tax?.value),
              name: new FormControl(tax?.name),
              translate: new FormControl(tax?.translate),
              type: new FormControl(tax?.type),
            })
        ) || []
      );

      if (i.type !== undefined) {
        const group = new FormGroup({
          description: new FormControl(i?.description || ''),
          type: new FormControl(i?.type),
        });

        if (i.type === TypeItemBudget.CATEGORY) {
          group.addControl('isEditing', new FormControl(false));
        }
        return group;
      }

      if (i?.unit) {
        index = this.fieldsToDisplay.findIndex(
          (f) => f.name === BudgetCategoryFields.UNIT
        );
        this.fieldsToDisplay[index].checked = true;
      }

      if (i?.discount) {
        index = this.fieldsToDisplay.findIndex(
          (f) => f.name === BudgetCategoryFields.DISCOUNT
        );
        this.fieldsToDisplay[index].checked = true;
      }
      return new FormGroup({
        validCategory: new FormControl(true, [Validators.requiredTrue]),
        idConcept: new FormControl(i?.idConcept),
        concept: new FormControl(i?.concept, [Validators.required]),
        description: new FormControl(i?.description || ''),
        quantity: new FormControl(i?.quantity || ''),
        unit: new FormControl(i?.unit),
        productKey: new FormControl(i?.productKey),
        price: new FormControl(i?.price || ''),
        taxes,
        discount: new FormControl(i?.discount || ''),
        total: new FormControl(i?.total || ''),
      });
    });

    if (items.length > 0) {
      this.budgetCategories.clear();
      for (const item of items) {
        this.budgetCategories.push(item);
      }
    }

    this.draw();
  }

  private fillFooterForm(budget: BudgetCommonData): void {
    const payment_method = budget.typePayments?.[0]?._id
      ? budget.typePayments
      : null;
    const messages = budget.messages || {};

    const budgetFooter = {
      category_id: budget?.addicionalInfo?.category_id || '',
      deal_id: budget?.header?.idDeal || '',
      messages: Object.keys(messages)
        .map((k) => messages[k])
        .join('/n'),
      payment_method,
      project_id: budget?.addicionalInfo?.project_id,
      showPrices: budget.header?.showPrices,
      showSignatures: budget.header?.showSignatures,
      invoiceType: budget.invoiceType,
      purposeUse: budget.purposeUse,
    };

    this.form.patchValue({
      budgetFooter,
    });
    this.draw();
  }

  getPaymentsMethods(payments: PaymentInfoBudget[]): void {
    this.paymentsMethods = payments;
  }

  onShowMoreMenu() {
    this.showMoreMenu = !this.showMoreMenu;
  }

  private draw() {
    this._changeDetector.detectChanges();
  }

  sendAmplitudeEvent(e: string): void {
    let event: string = this.budgetTypeAmplitude;

    const eventSuffixes = {
      card_addLine_addCaption: '_card_addLine_addCaption',
      card_noEditFinal_Create: '_card_noEditFinal_Create',
      card_exit: '_card_exit',
      card_addLine_addLine: '_card_addLine_addLine',
      card_addLine: '_card_addLine',
      card_preview: '_card_preview',
      card_personalize: '_card_personalize',
      card_deleteLine: '_card_deleteLine',
      lineConfiguration: '_card_lineConfiguration',
      lineConfiguration_addField: '_card_lineConfiguration_addField',
      lineConfiguration_removeField: '_card_lineConfiguration_removeField',
      card_account_settings: '_card_account_settings',
    };
    const suffix = eventSuffixes[e];
    if (suffix) {
      event += suffix;
    }
    if (event != this.budgetTypeAmplitude) {
      this.amplitudeService.sendEvent({
        event,
      });
    }
  }

  protected readonly Feature = Feature;

  watchWaybillFreeLimit(): void {
    if (!this.freeLimitService.firstWayBillCreated$.value) {
      this.isLoadingFreeLimits = true;

      this.sub$
        .add(
          this.freeLimitService
            .getLimit(FreeLimitType.AllowedTypes.FirstWayBillCreated)
            .pipe(
              finalize(() => {
                this.isLoadingFreeLimits = false;
                this.draw();
              })
            )
            .subscribe((res) => {
              if (FreeLimitType.AllowedTypes.FirstWayBillCreated in res) {
                this.freeLimitService.firstWayBillCreated$.next(
                  res.firstWayBillCreated
                );
              }
            })
        )
        .add(
          this.featuresProEnabled$.subscribe((res) => {
            this.isProUser = res;
          })
        );
    }
  }

  buildFieldsToDisplay(): {
    [name: string]: boolean;
  } {
    return this.fieldsToDisplay.reduce((obj, item) => {
      obj[item.name] = item.checked;
      return obj;
    }, {});
  }

  triggerMobileModal(data: { html: string; buttonText: string }) {
    this.dialog.open(ModalMobileInfoComponent, {
      data,
    });
  }

  get canIssueInvoice() {
    return this.budgetService.validateBDGSettings();
  }
}
