<roma-modal-template
  [showDefaultButtons]="false"
  [showHeader]="false"
  [size]="mobileHeight"
  (cancel)="onClose()"
>
  <div class="row d-flex justify-content-center">
    <img class="ios-image" src="assets/images/buyModal/buy-modal-ios.svg" />
    <div class="ios-title">
      {{ 'buyModal.ios.title' | translate }}
    </div>
    <div class="option">
      <img src="assets/icons/check.svg" alt="Check icon" />
      <div>{{ 'buyModal.ios.item1' | translate }}</div>
    </div>
    <div class="option">
      <img src="assets/icons/check.svg" alt="Check icon" />
      <div>{{ 'buyModal.ios.item2' | translate }}</div>
    </div>
    <div
      class="ios-disclaimer"
      [innerHtml]="'buyModal.ios.disclaimer' | translate"
    ></div>
  </div>
</roma-modal-template>
