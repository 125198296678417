<roma-modal-template
  size="md"
  [title]="
    type === 'ADD'
      ? ('person.create.title' | translate)
      : ('person.edit.title' | translate)
  "
  [headerBottomMargin]="'24px'"
  [footerTemplate]="footerButtons"
  [showDefaultButtons]="false"
  (success)="submit()"
  (cancel)="close()"
>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
              <roma-picture
                [src]="user?.img ? (user.img | image: 'user') : ''"
                [user]="user"
                (selectFiles)="handleImageFile($event)"
              >
              </roma-picture>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="name">
                {{ 'person.create.form.name' | translate }}
              </label>
              <input
                type="text"
                class="form-control"
                id="name"
                aria-describedby="emailHelp"
                formControlName="name"
                maxLength="50"
                [placeholder]="'person.create.form.namePlaceholder' | translate"
                [ngClass]="{ 'is-invalid': fieldName?.displayError || false }"
              />
              <roma-field-error-display
                #fieldName
                fieldName="name"
                errorMsg="person.create.form.nameError"
                [form]="form"
              >
              </roma-field-error-display>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="name">
                {{ 'person.create.form.position' | translate }}
              </label>
              <input
                type="text"
                class="form-control"
                id="position"
                aria-describedby="emailHelp"
                formControlName="position"
                maxLength="70"
                [placeholder]="'person.create.form.position' | translate"
              />
              <roma-field-error-display
                #fieldName
                fieldName="position"
                errorMsg="person.create.form.positionError"
                [form]="form"
              >
              </roma-field-error-display>
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label for="email">
                {{ 'person.create.form.email' | translate }}
              </label>
              <input
                *ngIf="type === 'ADD'"
                type="email"
                class="form-control"
                id="email"
                inputmode="email"
                aria-describedby="emailHelp"
                formControlName="email"
                maxLength="50"
                [placeholder]="
                  'person.create.form.emailPlaceholder' | translate
                "
                [ngClass]="{
                  'is-invalid':
                    emailRequiredField?.displayError ||
                    emailFormatField?.displayError ||
                    emailAsyncField?.displayError ||
                    emailInvalidDomain?.displayError
                }"
              />

              <input
                *ngIf="type !== 'ADD'"
                type="email"
                class="form-control"
                id="email"
                aria-describedby="emailHelp"
                formControlName="email"
                maxLength="50"
                [placeholder]="
                  'person.create.form.emailPlaceholder' | translate
                "
                -
                [ngClass]="{
                  'is-invalid': emailRequiredField?.displayError || false
                }"
                disabled="true"
                style="width: 95%"
              />

              <mat-icon
                *ngIf="type !== 'ADD'"
                matSuffix
                class="md-24 pointer"
                matTooltip="{{ 'profile.info_email' | translate }}"
                matTooltipPosition="right"
                style="float: right; margin-top: -30px"
              >
                infocu
              </mat-icon>

              <roma-field-error-display
                #emailInvalidDomain
                fieldName="email"
                errorMsg="internalVendor.create.emailInvalid"
                errorName="invalidDomain"
                [form]="form"
              >
              </roma-field-error-display>

              <roma-field-error-display
                #emailRequiredField
                fieldName="email"
                errorMsg="internalVendor.create.emailError"
                [form]="form"
              >
              </roma-field-error-display>

              <roma-field-error-display
                #emailFormatField
                fieldName="email"
                errorMsg="internalVendor.create.emailInvalid"
                errorName="email"
                [form]="form"
              >
              </roma-field-error-display>

              <roma-field-error-display
                #emailAsyncField
                fieldName="email"
                errorMsg="internalVendor.create.invalidAsync"
                errorName="invalidAsync"
                [form]="form"
              >
              </roma-field-error-display>
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label for="phone">
                {{ 'person.create.form.phone' | translate }}
              </label>
              <roma-rm-phone-select-input
                [ngClass]="{
                  error: phoneErrors
                }"
                [phoneValue]="initialPhoneValue"
                [autoPrefix]="true"
                (phoneChanges)="phoneChange($event)"
              ></roma-rm-phone-select-input>

              <ng-container *ngIf="f?.phone?.errors">
                <div class="invalid-feedback" *ngIf="f.phone.errors?.required">
                  {{ 'register.phone_required' | translate }}
                </div>
                <div class="invalid-feedback" *ngIf="f.phone.errors?.minlength">
                  {{ 'register.number-valid' | translate }}
                </div>
              </ng-container>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group">
              <label for="contractHours">
                {{ 'person.create.form.contractHours' | translate }}
              </label>
              <roma-hour-select
                (workingHoursChange)="onWorkingHoursChange($event)"
                (workingHoursPeriodChange)="onWorkingHoursPeriodChange($event)"
                (validationError)="handleValidationHoursError($event)"
                (updateErrorHoursMin)="updateErrorHoursMin($event)"
                [hasExternalError]="!!workingHoursError"
                [errorHoursMin]="errorHoursMin"
                maxLength="6"
                maxDecimals="2"
              ></roma-hour-select>
              <ng-container *ngIf="!!workingHoursError">
                <div class="error-message">
                  {{ workingHoursError | translate }}
                </div>
              </ng-container>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="form-group" formGroupName="contract">
              <label for="vacationDays">
                {{ 'person.create.form.holidayDays' | translate }}
              </label>
              <div class="input-number-days">
                <div class="input-wrapper">
                  <input
                    romaOnlyNumber
                    inputmode="numeric"
                    [maxDecimals]="2"
                    [allowDecimalSeparators]="true"
                    class="input-days"
                    id="vacationDays"
                    [maxLength]="5"
                    [placeholder]="0"
                    min="0"
                    [max]="365"
                    [value]="form.get('contract.vacationDays').value"
                    (input)="onChangeDays($event)"
                    (blur)="onBlurDays($event)"
                  />

                  <span class="input-append">
                    {{ 'person.create.form.days' | translate }}
                  </span>
                </div>
              </div>
              <div
                *ngIf="
                  form.get('contract.vacationDays').invalid &&
                  (form.get('contract.vacationDays').dirty ||
                    form.get('contract.vacationDays').touched)
                "
                class="text-danger"
              >
                <div
                  *ngIf="form.get('contract.vacationDays').errors"
                  class="error-message"
                >
                  <ng-container
                    *ngFor="
                      let errorKey of getErrorKeys(
                        form.get('contract.vacationDays').errors
                      )
                    "
                  >
                    <ng-container
                      *ngIf="form.get('contract.vacationDays').errors[errorKey]"
                    >
                      {{ errorMessagesVacation[errorKey] | translate }}
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label for="workingDays">
                {{ 'person.create.form.workingDays' | translate }}
              </label>
              <roma-day-select
                (workingDaysChange)="onWorkdaysChange($event)"
              ></roma-day-select>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="role">
                {{ 'person.create.form.role' | translate }}
              </label>

              <div class="radio-wrapper">
                <mat-radio-group
                  id="role"
                  formControlName="role"
                  [(ngModel)]="roleSelected"
                  class="radio-group"
                  [ngClass]="{ 'is-invalid': fieldRole?.displayError || false }"
                >
                  <mat-radio-button
                    *ngFor="let item of roles"
                    [value]="item.id"
                  >
                    {{ item.translateValue | translate | titlecase }}
                  </mat-radio-button>

                  <span class="custom-tooltip bottom textLeft">
                    <img
                      class="info__icon"
                      src="assets/icons/gl_information-fill-gray.svg"
                    />
                    <div class="tiptext left" style="width: 200px; left: -33px">
                      {{ 'permissions.createAdminTooltip' | translate }}
                      <hr />
                      {{ 'permissions.createUserTooltip' | translate }}
                    </div>
                  </span>
                </mat-radio-group>

                <roma-field-error-display
                  #fieldRole
                  fieldName="role"
                  errorMsg="person.create.form.roleError"
                  [form]="form"
                >
                </roma-field-error-display>
              </div>
            </div>

            <h4
              *ngIf="roleSelected !== 'ADMIN_ROLE'"
              class="subtitle"
              (click)="showPermissions = !showPermissions"
              style="cursor: pointer; font-weight: bold"
            >
              <img
                *ngIf="!showPermissions"
                src="assets/icons/gl_up-arrow.svg"
              />
              <img
                *ngIf="showPermissions"
                src="assets/icons/gl_down_arrow.svg"
              />
              {{ 'permissions.editPermissions' | translate }}
            </h4>

            <div
              class="col-lg-12 col-md-12 col-sm-12"
              *ngIf="
                roleSelected && roleSelected !== 'ADMIN_ROLE' && showPermissions
              "
            >
              <roma-manage-permission
                [adjust]="true"
                (change)="onPermissionChange($event)"
              ></roma-manage-permission>
            </div>
          </div>
          <roma-append-custom-properties
            [typeRef]="typePropertyRef.Users"
            [control]="form.get('customProperties')"
            [parentForm]="form"
            [initForm]="initChildForm"
            [parentFormSubmit]="parentFormSubmit"
            (customPropertiesForm)="customPropertiesFormChange($event)"
            (propertiesLoading)="propertiesLoading($event)"
            (closeModal)="close()"
          ></roma-append-custom-properties>
        </div>
      </div>
    </div>
  </form>
</roma-modal-template>

<ng-template #footerButtons>
  <div class="modalFooter">
    <label class="invited" *ngIf="dataModal?.hasBeenInvited">
      {{ 'internalVendor.create.invited' | translate }}
    </label>
    <div class="groupEndDiv">
      <base-button type="submit" buttonClass="tertiary" (click)="close()">
        {{ 'activity.create.cancel' | translate }}
      </base-button>
      <base-button type="submit" [isLoading]="formSubmitted" (click)="submit()">
        {{
          type === 'ADD'
            ? ('person.create.btnsuccess' | translate)
            : ('activity.edit.submit' | translate)
        }}
      </base-button>
    </div>
  </div>
</ng-template>
