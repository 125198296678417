import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ClockAbsence,
  COMPANY_ID_KEY,
  FeatureUser,
  TypeRol,
  USER_ID_KEY,
} from '@tacliatech/types';
import { environment } from '@web-frontend/environments';
import { User } from '@web-frontend/shared/class';
import {
  AuthService,
  ClockAbsenceService,
  StorageService,
} from '@web-frontend/shared/services';
import { filter } from 'rxjs/operators';
import { AddClockAbsence, AddClockAbsenceService } from '../add-clock-absence';
import { ShowDetailClockAbsence } from './show-detail-clock-absence.types';

import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';

import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';

import { PermissionService } from '@web-frontend/shared/services/permissions';

@Component({
  selector: 'roma-show-detail-clock-absence',
  templateUrl: './show-detail-clock-absence.component.html',
  styleUrls: ['./show-detail-clock-absence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowDetailClockAbsenceComponent implements OnInit, OnDestroy {
  clockAbsence!: ClockAbsence.Output;
  typeRolRef = TypeRol;
  isAdminAbsences = false;
  userIsAdmin = false;
  permissions = {};
  showButtonsOptions = {
    showEdit: false,
    showReject: false,
    showApproval: false,
    showDelete: false,
  };
  showFooter = false;

  featureUserRef = FeatureUser;
  showOptionsReasonDescription = false;
  reasonRejectDescription = '';

  private forceRefresh = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private clockAbsenceService: ClockAbsenceService,
    private addClockAbsenceService: AddClockAbsenceService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<
      ShowDetailClockAbsenceComponent,
      ShowDetailClockAbsence.Result
    >,
    @Inject(MAT_DIALOG_DATA)
    private data: ShowDetailClockAbsence.Params,
    private activateRoute: ActivatedRoute,
    private i18n: TranslateService,
    private toastService: ToastService,
    private analyticsService: AnalyticsService,
    private permissionService: PermissionService
  ) {}

  get params() {
    return this.data;
  }

  ngOnInit(): void {
    this.authService.user$.subscribe((res: User) => {
      this.userIsAdmin = res.role.includes(TypeRol.ADMIN_ROLE);
    });
    this.patchParams();
  }

  ngOnDestroy(): void {}

  getEventLocation(): string {
    return this.params?.calendarView ? 'team_list' : 'all_list';
  }

  approved() {
    this.clockAbsenceService
      .updateOne(this.clockAbsence._id, {
        status: ClockAbsence.Status.Approved,
      })
      .subscribe(() => {
        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: AmplitudeEvents.absenceTeam_approved,
          eventProperties: {
            event_location: this.getEventLocation(),
          },
        });

        this.analyticsService.trackEvent({
          sources: ['braze'],
          eventName: BrazeEventType.absence_approved,
        });
        this.close(true);
      });
  }

  reject() {
    this.showOptionsReasonDescription = true;

    this.draw();
  }

  delete() {
    this.clockAbsenceService.deleteOne(this.clockAbsence._id).subscribe(() => {
      this.toastService.show({
        text: this.i18n.instant('clockHour.deleteAbsence'),
        type: 'success',
        msDuration: 4000,
      });
      this.analyticsService.trackEvent({
        sources: ['amplitude', 'braze'],
        eventName: this.data.isAdminAbsences
          ? AmplitudeEvents.absenceTeam_deleted
          : AmplitudeEvents.absence_deleted,
        eventProperties: {
          event_location: this.getEventLocation(),
        },
      });

      this.close(true);
    });
  }

  cancel() {
    this.showOptionsReasonDescription = false;
    this.draw();
  }

  continue() {
    this.clockAbsenceService
      .updateOne(this.clockAbsence._id, {
        status: ClockAbsence.Status.Rejected,
        reasonRejected: {
          description: this.reasonRejectDescription,
          idCreatedBy: StorageService.GetItem(USER_ID_KEY),
        },
      })
      .subscribe(() => {
        this.analyticsService.trackEvent({
          sources: ['braze'],
          eventName: BrazeEventType.absence_rejected,
        });

        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: AmplitudeEvents.absenceTeam_declined,
          eventProperties: {
            event_location: this.getEventLocation(),
          },
        });
        this.close(true);
      });
  }

  edit() {
    this.addClockAbsenceService
      .open({
        data: {
          mode: 'EDIT',
          clockAbsence: this.clockAbsence,
          isAdminAbsences: this.data.isAdminAbsences,
        },
      })
      .pipe(filter((res) => !!res))
      .subscribe((res) => {
        if (res?.forceClose) {
          this.close(true);
        } else {
          this.updateDataClockAbsence();
        }
      });
  }

  getTotalDays(): number {
    if (!this.clockAbsence) {
      return 0;
    }
    const { startDate, endDate } = this.clockAbsence.dateDetail;
    const type = this.clockAbsence.typeDetail.type;
    return ClockAbsence.GetTotalDays({
      startDate: new Date(startDate).toString(),
      endDate: new Date(endDate).toString(),
      type,
    });
  }

  getNameTypeAbsence(): string {
    return (
      AddClockAbsence.ClockAbsenceTypes[this.clockAbsence?.type]?.title ?? ''
    );
  }

  changeReasonDescription(evt) {
    this.reasonRejectDescription = evt;
    this.draw();
  }

  private updateDataClockAbsence() {
    this.clockAbsenceService
      .search({
        'idCompanies[]': [StorageService.GetItem(COMPANY_ID_KEY)],
        'ids[]': [this.clockAbsence._id],
      })
      .subscribe((res) => {
        this.setSource(res.docs[0]);
      });
  }

  private patchParams() {
    this.setSource(this.data.clockAbsence);
  }

  private setSource(value: ClockAbsence.Output) {
    this.clockAbsence = value;
    this.handleShowButtonsOptions();
    this.draw();
  }

  private handleShowButtonsOptions() {
    if (this.clockAbsence) {
      let getPermissions = null;

      if (this.data.isAdminAbsences) {
        getPermissions = this.permissionService.getTeamViewAbsencePermissions(
          this.userIsAdmin,
          this.clockAbsence?.dateDetail?.startDate
        );
      } else {
        getPermissions = this.permissionService.getMyViewAbsencePermissions(
          this.userIsAdmin,
          this.clockAbsence?.dateDetail?.startDate
        );
      }

      if (getPermissions) {
        getPermissions.then((res) => {
          const { status } = this.clockAbsence;
          if (res[+status]) {
            this.showButtonsOptions = res[+status];
          }
          this.showFooter = Object.values(this.showButtonsOptions).some(
            (item) => item
          );
          this.draw();
        });
      }
    }
  }

  close(reload = false) {
    if (reload || this.forceRefresh) {
      this.dialogRef.close({
        reload: true,
      });
    } else {
      this.dialogRef.close();
    }
  }

  showFile(file: string) {
    const downloadUrl =
      environment.firebaseConfig.storageUrl + `/uploads%2Fabsence%2F${file}`;

    window.open(downloadUrl, '_blank');
  }

  deleteFile(file: string) {
    const filesFiltered = this.clockAbsence.typeDetail.files.filter(
      (el) => el !== file
    );

    this.clockAbsenceService
      .updateOne(this.clockAbsence._id, {
        typeDetail: {
          ...this.clockAbsence.typeDetail,
          files: filesFiltered,
        },
      })
      .subscribe(() => {
        this.forceRefresh = true;

        this.clockAbsence = {
          ...this.clockAbsence,
          typeDetail: {
            ...this.clockAbsence.typeDetail,
            files: filesFiltered,
          },
        };

        this.draw();
      });
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  get showDeductTime(): boolean {
    return this.clockAbsence?.type === ClockAbsence.AbsenceType.Vacation;
  }
}
