import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { ClockHour } from '@tacliatech/types';
import { MobileClockHour } from '@web-frontend/shared/components/clock-hours';
import { isAfter, isToday } from 'date-fns';
import * as moment from 'moment-timezone';
import { ListMobileClockHourAction } from './list-mobile-clock-hour.types';

@Component({
  selector: 'roma-list-mobile-clock-hour',
  templateUrl: './list-mobile-clock-hour.component.html',
  styleUrls: ['./list-mobile-clock-hour.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListMobileClockHourComponent {
  _data: ClockHour.Calendar[];
  @Input()
  set data(value: ClockHour.Calendar[]) {
    this._data = value;
    this.buildData();
    this.draw();
  }

  get data(): ClockHour.Calendar[] {
    return this._data;
  }

  source: MobileClockHour.Content[] = [];

  @Output()
  actions = new EventEmitter<ListMobileClockHourAction>();

  isGreaterThanToday = (date: ClockHour.ClockDate): boolean => {
    const instance = new Date(date.year, date.month, date.day);

    return isAfter(instance, new Date());
  };

  constructor(private cdRef: ChangeDetectorRef) {}

  buildData(): void {
    this.source = this.data.map((item) => {
      const date = new Date(item.date.year, item.date.month, item.date.day);

      return {
        id: item._id,
        title: moment(date).format('dddd'),
        description: moment(date).format('DD MMMM'),
        addTitleLabel: isToday(date),
        date: item.date,
        hour: item?.histories?.length
          ? this.getClockHoursDuration(item as ClockHour.Output)
          : null,
        status: item.status,
        data: item,
        absence: item.absence,
        hasHoliday: item.hasHoliday,
      };
    });
  }

  makeActions(
    action: MobileClockHour.Action,
    content: MobileClockHour.Content
  ) {
    const item = (content?.data || null) as ClockHour.Output;
    this.actions.next({ ...action, item });
  }

  private getClockHoursDuration(clockHour: ClockHour.Output) {
    let hourDuration = ClockHour.getTotalHourFromClockHours([clockHour], 0);
    while (hourDuration.charAt(0) === '0' && hourDuration.charAt(2) === ':') {
      hourDuration = hourDuration.substring(1);
    }
    return hourDuration.replace(':', 'hr ') + 'm';
  }

  private draw() {
    this.cdRef.detectChanges();
  }
}
