import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'roma-ios-activation',
  templateUrl: './ios-activation.component.html',
  styleUrls: ['./ios-activation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IosActivationComponent {
  constructor(private dialogRef: MatDialogRef<IosActivationComponent>) {}

  get mobileHeight(): string {
    return window.innerHeight < 820 ? 'mobile-70h' : 'mobile-50h';
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
