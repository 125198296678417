<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<!-- header start -->

<!-- Stripe Snackbar -->
<roma-shared-stripe-snackbar></roma-shared-stripe-snackbar>

<!-- Trial Disclaimer -->
<roma-pro-disclaimer-banner
  *ngIf="isLowerThan1450px"
  (emitRefresh)="refresh()"
  [rootModule]="'timetracking2'"
></roma-pro-disclaimer-banner>

<roma-back-button
  *ngIf="isFromTeamView"
  (backClicked)="goBack()"
></roma-back-button>
<div class="container-page-header">
  <h1 class="page-title">
    <ng-container *ngIf="!isAdminAbsences">
      {{ userAbsenceTitle }}
      <div class="tooltip-container">
        <img
          width="32px"
          class="help-icon"
          [src]="iconDialog"
          (click)="showDialog()"
        />
        <div
          *ngIf="showToolTip"
          class="title-tooltip-wrapper"
          (click)="toggleTooltip(false)"
        ></div>
        <div
          class="help-tooltip 22"
          *ngIf="showToolTip"
          romaRomaToolTip
          [showToolTip]="showToolTip"
          (clicked)="toggleTooltip($event)"
        >
          <div [innerHTML]="'clockAbsence.tooltip' | translate"></div>
          <a *ngIf="academyLink?.length" [href]="academyLink" target="_blank">{{
            'clockAbsence.tooltipUrl' | translate
          }}</a>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isAdminAbsences">
      {{
        (resize$ | async)?.payload?.mobile
          ? ('clockHour.absences' | translate)
          : ('clockHourAdmin.title' | translate)
      }}
      <div class="tooltip-container">
        <img
          width="32px"
          class="help-icon"
          [src]="iconDialog"
          (click)="showDialog()"
        />
        <div
          *ngIf="showToolTip"
          class="title-tooltip-wrapper"
          (click)="toggleTooltip(false)"
        ></div>
        <div
          class="help-tooltip 22"
          *ngIf="showToolTip"
          romaRomaToolTip
          [showToolTip]="showToolTip"
          (clicked)="toggleTooltip($event)"
        >
          <div [innerHTML]="'adminClockAbsence.tooltip' | translate"></div>
          <a *ngIf="academyLink?.length" [href]="academyLink" target="_blank">{{
            'adminClockAbsence.tooltipUrl' | translate
          }}</a>
        </div>
      </div>
    </ng-container>

    <mat-icon
      *ngIf="
        isAdminAbsences &&
        viewTypeActive === absenceViews?.CALENDAR &&
        (resize$ | async)?.payload?.mobile
      "
      class="material-icons mr-1 float-end"
      (click)="openMobileTeamActions = true"
    >
      more_vert
    </mat-icon>
  </h1>
  <div class="container-options">
    <!-- Videotutorial button -->
    <button
      *ngIf="getLang() === 'es' && !(resize$ | async)?.payload?.mobile"
      class="tutorial btn-margins"
      (click)="openVideo(abscenseType)"
    >
      <strong>{{ 'tutorial.tutorial' | translate }}</strong>
      <img class="video" src="assets/icons/gl_play-tutorial.svg" />
    </button>
    <!-- Videotutorial button -->

    <!-- download button -->
    <roma-shared-download-btn
      *ngIf="
        resultSearch?.docs &&
        !(resize$ | async)?.payload?.mobile &&
        isAdminAbsences
      "
      [featureExcel]="featureRef.ClockHour.DownloadExcel"
      [showDownloadExcel]="true"
      [showDownloadPdf]="false"
      (download)="download($event)"
      style="margin-right: 12px"
    ></roma-shared-download-btn>

    <button
      romaProFeature
      [featureUserHide]="{
        type: 'some',
        features: [
          featureUserRef.CompanyAdjust.update,
          featureUserRef.ClockHourAbsenceCompany.approve
        ]
      }"
      class="btn-download btn-holiday"
      *ngIf="!(resize$ | async)?.payload?.mobile"
      (click)="navigateToHolidaysSettings(abscenseType)"
    >
      <mat-icon class="holiday-icon" svgIcon="celebration_icon"> </mat-icon>

      {{ 'clockAbsence.goToHolidays' | translate }}
    </button>

    <button
      *ngIf="!(resize$ | async)?.payload?.mobile"
      class="roma-button mobile-small"
      romaProFeature
      [featureUser]="featureUserRef.ClockHourAbsence.create"
      (clicked)="launchModalAbsence()"
    >
      <img
        *ngIf="!(hasAddClockAbsenceFeature$ | async)"
        class="icon-star-pro-feature"
        src="assets/icons/star.svg"
      />
      <ng-container *ngIf="!(resize$ | async)?.payload?.mobile">
        {{ 'clockAbsence.addAbsence' | translate }}
      </ng-container>
      <img class="icon-option" src="assets/icons/plus.svg" />
    </button>
  </div>
</div>

<div class="page-options row align-items-start" *ngIf="!isAdminAbsences">
  <div class="col-8 options p-0">
    <div class="d-inline-flex">
      <div *ngIf="!isFromTeamView">
        <div class="filter-wrapper mobile-hidden">
          <button
            class="btn btn-align"
            (click)="changeViewToCalendar()"
            [ngClass]="{ active: viewTypeActive === absenceViews?.CALENDAR }"
          >
            <mat-icon
              class="align-icon"
              svgIcon="activity_calendar_icon"
            ></mat-icon>
            <span class="align-text">{{
              'dashboard.calendar' | translate
            }}</span>
          </button>

          <button
            class="btn btn-align"
            (click)="changeViewToList()"
            [ngClass]="{ active: viewTypeActive === absenceViews?.LIST }"
          >
            <mat-icon class="align-icon" svgIcon="table_icon"></mat-icon>
            <span class="align-text">{{ 'dashboard.list' | translate }}</span>
          </button>
        </div>
      </div>

      <div *ngIf="(resize$ | async)?.payload?.mobile && !isFromTeamView">
        <button
          class="d-inline-block d-flex align-items-center mobile-view-selector gap-1"
          (click)="openMobileViewType = true"
        >
          <ng-container *ngIf="viewTypeActive === absenceViews?.CALENDAR">
            <mat-icon class="material-icons mr-1"> calendar_today </mat-icon>
            <span class="icon-text">
              {{ 'dashboard.calendar' | translate }}
            </span>
          </ng-container>
          <ng-container *ngIf="viewTypeActive === absenceViews?.LIST">
            <mat-icon class="material-icons mr-1" size="sm"> menu </mat-icon>
            <span class="icon-text"> {{ 'dashboard.list' | translate }}</span>
          </ng-container>
          <mat-icon class="material-icons arrow ml-1"> expand_more </mat-icon>
        </button>
      </div>

      <div
        class="absence-calendar-toolbar {{ isFromTeamView && 'border-left-0' }}"
        *ngIf="viewTypeActive === absenceViews?.CALENDAR"
      >
        <button (click)="goToPrevYear()" class="go_back">
          <mat-icon class="material-icons"> chevron_left </mat-icon>
        </button>
        <button (click)="goToCurrentYear()" class="go_today">
          {{ calendarYear }}
        </button>
        <button (click)="goToNextYear()" class="go_next">
          <mat-icon class="material-icons"> chevron_right </mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-4 text-right p-0">
    <roma-shared-download-btn
      *ngIf="resultSearch?.docs && !(resize$ | async)?.payload?.mobile"
      [featureExcel]="featureRef.ClockHour.DownloadExcel"
      [showDownloadExcel]="true"
      [showDownloadPdf]="false"
      (download)="download($event)"
    ></roma-shared-download-btn>
  </div>
</div>

<div class="page-options row align-items-start" *ngIf="isAdminAbsences">
  <div class="col-7 col-md-12 p-0">
    <div class="d-md-inline-flex">
      <div>
        <div class="filter-wrapper mobile-hidden">
          <button
            class="btn btn-align"
            (click)="changeViewToCalendar()"
            [ngClass]="{ active: viewTypeActive === absenceViews?.CALENDAR }"
          >
            <mat-icon
              class="align-icon"
              svgIcon="user_icon"
              *ngIf="isAdminAbsences"
            ></mat-icon>

            <span class="align-text">
              {{ 'clockHour.viewTeam' | translate }}
            </span>
          </button>

          <button
            class="btn btn-align"
            (click)="changeViewToList()"
            [ngClass]="{ active: viewTypeActive === absenceViews?.LIST }"
          >
            <mat-icon class="align-icon" svgIcon="table_icon"></mat-icon>
            <span class="align-text">
              {{ 'adminClockAbsence.viewAll' | translate }}
            </span>
          </button>
        </div>
      </div>

      <div *ngIf="(resize$ | async)?.payload?.mobile">
        <button
          class="d-inline-block d-flex align-items-center mobile-view-selector gap-1"
          (click)="openMobileViewType = true"
        >
          <ng-container *ngIf="viewTypeActive === absenceViews?.CALENDAR">
            <mat-icon class="material-icons mr-1"> person </mat-icon>
            <span class="icon-text">
              {{
                isAdminAbsences
                  ? ('clockHour.viewTeam' | translate)
                  : ('dashboard.calendar' | translate)
              }}
            </span>
          </ng-container>
          <ng-container *ngIf="viewTypeActive === absenceViews?.LIST">
            <mat-icon class="material-icons mr-1" size="sm"> menu </mat-icon>
            <span class="icon-text">
              {{
                isAdminAbsences
                  ? ('adminClockAbsence.viewAll' | translate)
                  : ('dashboard.list' | translate)
              }}
            </span>
          </ng-container>
          <mat-icon class="material-icons arrow ml-1"> expand_more </mat-icon>
        </button>
      </div>

      <div
        class="absence-calendar-toolbar is-admin-absences"
        *ngIf="
          viewTypeActive === absenceViews?.CALENDAR &&
          !(resize$ | async)?.payload?.mobile
        "
      >
        <roma-monthly-weekly-filter
          (changePeriodRange)="changePeriodTeamView($event)"
          [defaultValues]="defaultValues"
          [showPeriodSwitch]="false"
          [dateFormat]="(resize$ | async)?.payload?.mobile ? 'short' : 'long'"
          [clickOnCurrentPeriod]="true"
        ></roma-monthly-weekly-filter>

        <roma-rm-filter-button
          [source]="sourceUserFilter"
          (changes)="rmFilterCalendarChanges($event)"
          [title]="'general.filters' | translate"
          [matIcon]="'keyboard_arrow_down'"
        ></roma-rm-filter-button>
      </div>
    </div>
  </div>
  <div
    class="col-5 text-right"
    *ngIf="
      (resize$ | async)?.payload?.mobile &&
      viewTypeActive === absenceViews?.CALENDAR
    "
  >
    <roma-rm-filter-button
      [source]="sourceMobileFilter"
      (changes)="rmFilterCoreChanges($event)"
      [title]="'general.filters' | translate"
      [matIcon]="'keyboard_arrow_down'"
    ></roma-rm-filter-button>
  </div>
</div>

<div
  class="monthly-weekly-filter-mobile"
  *ngIf="
    isAdminAbsences &&
    (resize$ | async)?.payload?.mobile &&
    viewTypeActive === absenceViews?.CALENDAR
  "
>
  <roma-monthly-weekly-filter
    (changePeriodRange)="changePeriodTeamView($event)"
    [defaultValues]="defaultValues"
    [showPeriodSwitch]="false"
    [dateFormat]="(resize$ | async)?.payload?.mobile ? 'short' : 'long'"
    [clickOnCurrentPeriod]="true"
  ></roma-monthly-weekly-filter>
</div>

<div *ngIf="openMobileViewType" class="mobile-viewtype-wrapper">
  <div class="viewtype-list">
    <h4>{{ 'general.view' | translate }}</h4>
    <div class="link-list">
      <div
        class="list-item"
        (click)="changeViewToCalendar(); openMobileViewType = false"
      >
        <span class="icon-text">
          {{
            isAdminAbsences
              ? ('clockHour.viewTeam' | translate)
              : ('dashboard.calendar' | translate)
          }}
        </span>
        <img
          *ngIf="viewTypeActive === absenceViews?.CALENDAR"
          class="prev-icon"
          src="/assets/icons/ic_check.svg"
        />
      </div>
      <div
        class="list-item"
        (click)="changeViewToList(); openMobileViewType = false"
      >
        <span class="icon-text">
          {{
            isAdminAbsences
              ? ('adminClockAbsence.viewAll' | translate)
              : ('dashboard.list' | translate)
          }}
        </span>
        <img
          *ngIf="viewTypeActive === absenceViews?.LIST"
          class="prev-icon"
          src="/assets/icons/ic_check.svg"
        />
      </div>
      <div class="text-center close-btn-wrapper">
        <img
          (click)="openMobileViewType = false"
          class="close-btn"
          src="/assets/icons/btn_close.svg"
          alt="close"
        />
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="openMobileTeamActions && isAdminAbsences"
  class="mobile-viewtype-wrapper mobile-actions"
>
  <div class="viewtype-list">
    <h4>{{ 'general.actions' | translate }}</h4>
    <div class="link-list">
      <div
        *ngIf="getLang() === 'es'"
        class="list-item"
        (click)="openVideo(abscenseType); openMobileTeamActions = false"
      >
        <span class="icon-text">
          {{ 'tutorial.tutorial' | translate }}
        </span>
        <mat-icon class="material-icons mr-1 prev-icon"> play_circle </mat-icon>
      </div>
      <div
        class="list-item"
        (click)="showCreateUser(); openMobileTeamActions = false"
      >
        <span class="icon-text">
          {{ 'general.addUserBtn' | translate }}
        </span>
        <mat-icon class="material-icons mr-1 prev-icon"> person_add </mat-icon>
      </div>
      <div
        romaProFeature
        [featureUserHide]="{
          type: 'some',
          features: [
            featureUserRef.CompanyAdjust.update,
            featureUserRef.ClockHourAbsenceCompany.approve
          ]
        }"
        class="list-item"
        (click)="navigateToHolidaysSettings(abscenseType)"
      >
        <span class="icon-text">
          {{ 'clockAbsence.goToHolidays_mobile' | translate }}
        </span>
        <mat-icon class="material-icons mr-1 prev-icon"> celebration </mat-icon>
      </div>
      <div
        class="list-item"
        (click)="download('excel'); openMobileTeamActions = false"
      >
        <span class="icon-text">
          {{ 'general.downloadExcel' | translate }}
        </span>
        <mat-icon class="material-icons mr-1 prev-icon">
          download_alt
        </mat-icon>
      </div>
      <div class="text-center close-btn-wrapper">
        <img
          (click)="openMobileTeamActions = false"
          class="close-btn"
          src="/assets/icons/btn_close.svg"
          alt="close"
        />
      </div>
    </div>
  </div>
</div>

<!-- header end -->

<!--📲 mobile condition -->
<ng-container
  *ngIf="
    (resize$ | async)?.payload?.mobile && viewTypeActive === absenceViews?.LIST
  "
>
  <div class="container-options">
    <!-- Videotutorial button -->
    <button
      *ngIf="getLang() === 'es'"
      class="tutorial btn-margins"
      (click)="openVideo(abscenseType)"
    >
      <strong>{{ 'tutorial.tutorial' | translate }}</strong>
      <img class="video" src="assets/icons/gl_play-tutorial.svg" />
    </button>
    <!-- Videotutorial button -->

    <roma-shared-download-btn
      *ngIf="resultSearch?.docs"
      [featureExcel]="featureRef.ClockHour.DownloadExcel"
      [showDownloadExcel]="true"
      [showDownloadPdf]="false"
      (download)="download($event)"
      style="margin-left: 12px"
      [ngClass]="{ 'is-hidden': isLoading }"
    ></roma-shared-download-btn>
  </div>

  <!-- ✅ has feature -->
  <!-- <ng-container *ngIf="hasAddClockAbsenceFeature$ | async"> -->
  <div class="container__filters container__filters--right">
    <roma-rm-filter-button
      *ngIf="sourceSecondaryMobileFilter"
      [source]="sourceSecondaryMobileFilter"
      (changes)="rmFilterCoreChanges($event)"
    ></roma-rm-filter-button>
  </div>

  <!-- Start results amount -->
  <div class="table-total">
    {{ resultSearch?.docs?.length || '0' }}
    {{ 'general.results' | translate }}
  </div>
  <!-- End results amount -->

  <roma-list-mobile-clock-absence
    *ngIf="!isLoading && resultSearch?.docs"
    [data]="resultSearch?.docs"
    (actions)="makeMobileActions($event)"
    style="margin-top: 1rem"
  >
  </roma-list-mobile-clock-absence>

  <!-- Start Pagination -->
  <div class="text-center">
    <div *ngIf="resultSearch?.docs?.length" class="pagination">
      <button
        class="arrow prev"
        [attr.disabled]="resultSearch.page === 1 ? true : null"
        (click)="previousPage()"
      >
        <img src="assets/icons/gl_arrow_left.svg" />
      </button>
      <ng-container *ngFor="let page of totalPages">
        <div
          class="page-item"
          *ngIf="page.page < 6"
          [ngClass]="{ 'current-page': page.page == resultSearch.page }"
          (click)="goToPage(page.page)"
        >
          {{ page.page }}
        </div>
        <div
          class="page-item"
          *ngIf="
            resultSearch.countPages > 5 && page.page == resultSearch.countPages
          "
          [ngClass]="{ 'current-page': page.page == resultSearch.page }"
          (click)="goToPage(resultSearch.countPages)"
        >
          ...
          {{ resultSearch.countPages }}
        </div>
      </ng-container>
      <button
        class="arrow next"
        [attr.disabled]="!resultSearch.hasNextPage ? true : null"
        (click)="nextPage()"
      >
        <img src="assets/icons/gl_arrow_right-pagination.svg" />
      </button>
    </div>
  </div>
  <!-- End Pagination -->
  <!-- </ng-container> -->
</ng-container>

<!-- 🖥️ web-condition -->
<ng-container
  *ngIf="
    !(resize$ | async)?.payload?.mobile && viewTypeActive === absenceViews?.LIST
  "
>
  <!-- ✅ have feature -->
  <!-- <ng-container *ngIf="hasAddClockAbsenceFeature$ | async"> -->
  <!-- filters start -->
  <div class="row clock-hours-filters">
    <div class="container__filters">
      <roma-rm-filter-core
        *ngIf="sourceFilter && sourceSecondaryWebFilter"
        [source]="sourceFilter"
        (changes)="rmFilterCoreChanges($event)"
      ></roma-rm-filter-core>
      <roma-rm-filter-button
        [source]="sourceSecondaryWebFilter"
        (changes)="rmFilterCoreChanges($event)"
      ></roma-rm-filter-button>
      <roma-rm-filter-clear
        *ngIf="sourceFilter"
        [sources]="[sourceFilter, sourceSecondaryWebFilter]"
        (changes)="rmFilterCoreChanges($event)"
      ></roma-rm-filter-clear>
    </div>
  </div>
  <!-- filters end -->

  <div class="hours-info">
    <div class="table-total">
      {{ resultSearch?.docs?.length }} {{ 'general.results' | translate }}
    </div>
    <div *ngIf="resultSearch?.docs?.length" class="pagination">
      <button
        class="arrow prev"
        [attr.disabled]="resultSearch.page === 1 ? true : null"
        (click)="previousPage()"
      >
        <img src="assets/icons/gl_arrow_left.svg" />
      </button>
      <ng-container *ngFor="let page of totalPages">
        <div
          class="page-item"
          *ngIf="page.page < 6"
          [ngClass]="{ 'current-page': page.page == resultSearch.page }"
          (click)="goToPage(page.page)"
        >
          {{ page.page }}
        </div>
        <div
          class="page-item"
          *ngIf="
            resultSearch.countPages > 5 && page.page == resultSearch.countPages
          "
          [ngClass]="{ 'current-page': page.page == resultSearch.page }"
          (click)="goToPage(resultSearch.countPages)"
        >
          ...
          {{ resultSearch.countPages }}
        </div>
      </ng-container>
      <button
        class="arrow next"
        [attr.disabled]="!resultSearch.hasNextPage ? true : null"
        (click)="nextPage()"
      >
        <img src="assets/icons/gl_arrow_right-pagination.svg" />
      </button>
    </div>
  </div>

  <!-- table start -->
  <div
    id="containerTable"
    class="containerTable"
    [ngStyle]="{ 'margin-top': tableMarginTop }"
    (scroll)="checkScroll($event)"
  >
    <div class="my-4">
      <div *ngIf="isLoading" class="col-12">
        <roma-loading></roma-loading>
      </div>
      <div
        *ngIf="!resultSearch?.docs.length && !isLoading"
        class="no-results-wrapper text-center"
      >
        <img
          *ngIf="activeFilters"
          class="no-results-ilu"
          src="assets/icons/no-results-ilu.svg"
        />
        <img
          *ngIf="!activeFilters"
          class="no-results-ilu"
          src="assets/icons/no-results-ilu.svg"
        />
        <h4 *ngIf="activeFilters">{{ 'general.ohNo' | translate }}</h4>
        <p *ngIf="activeFilters">{{ 'general.noResults' | translate }}</p>
        <p *ngIf="!activeFilters">
          {{ 'clockAbsence.noResults' | translate }}
        </p>
        <button
          *ngIf="activeFilters"
          class="btn-secondary clean-filters"
          (click)="cleanFilters()"
        >
          {{ 'general.cleanFilters' | translate }}
          <img src="assets/icons/gl_trash-blue.svg" />
        </button>
      </div>
      <div
        *ngIf="!isLoading"
        class="col-12"
        [ngClass]="{
          'is-hidden': isLoading || !resultSearch?.docs?.length,
          'margin-bottom-message': deleteMessage
        }"
      >
        <roma-table
          class="table"
          type="clockHoursAbsence"
          idModule="CLOCK_ABSENCE_MODULE"
          [appendSettings]="'NO'"
          [appendInfo]="'NO'"
          [showActions]="true"
          [tagNotAvailable]="'activity.notAvailable' | translate"
          [data]="resultSearch?.docs"
          [displayedColumns]="displayedColumns"
          (wantDelete)="deleteAbsence([$event])"
          (wantApprove)="launchModalAbsenceEdit($event)"
          (wantEdit)="launchModalAbsenceDetails($event)"
          (checkRowChanges)="deleteMany($event)"
        >
        </roma-table>
      </div>
    </div>
  </div>
  <!-- table end -->
  <div *ngIf="deleteMessage" class="row">
    <div
      class="col-12 col-md-11 delete-message-modal"
      style="position: relative"
    >
      <div class="delete-message">
        <strong>
          ({{ entriesForDelete.length }})
          {{ 'general.selected' | translate }}
        </strong>
        <roma-shared-download-btn
          *ngIf="resultSearch?.docs"
          [featureExcel]="featureRef.ClockHour.DownloadExcel"
          [showDownloadExcel]="true"
          [showDownloadPdf]="false"
          (download)="download($event)"
          style="margin-left: 12px"
          [ngClass]="{ 'is-hidden': isLoading }"
        ></roma-shared-download-btn>
        <button
          class="btn"
          (click)="openDeleteManyDialog()"
          [disabled]="disableBulkDelete"
        >
          <img src="assets/icons/gl_trash_red.svg" />
          {{ 'clockAbsence.deleteAbsence' | translate }}
        </button>
        <div class="btn-close" (click)="unselectItems()"></div>
      </div>
    </div>
  </div>
  <!-- </ng-container> -->
</ng-container>

<ng-container
  *ngIf="
    viewTypeActive === absenceViews?.CALENDAR &&
    resultSearch?.docs &&
    !isAdminAbsences
  "
>
  <div class="row calendar-container full-height gx-md-5">
    <div class="col-md-12 col-lg-5 col-xl-3 scrollable-column">
      <div [ngClass]="{ 'mb-3 mb-md-0': isAdmin }">
        <roma-absence-counter
          [vacationReport]="vacationReport"
        ></roma-absence-counter>
      </div>
      <roma-absence-summary
        [absenceItems]="pendingEvents"
        [title]="'clockAbsence.pendingAbsences' | translate"
        *ngIf="!isAdmin || isFromTeamView"
      ></roma-absence-summary>
      <roma-absence-summary-counter
        *ngIf="!(resize$ | async)?.payload?.mobile"
        [absenceCounts]="totalSummary"
        [title]="'clockAbsence.validatedAbsences' | translate"
      ></roma-absence-summary-counter>
    </div>
    <div class="col-md-12 col-lg-7 col-xl-9 pb-5">
      <roma-mf-calendar
        [calendarEvents]="calendarEvents"
        [onEventClick]="showAbsenceDetailFromCalendar"
        [onDayClick]="launchModalAbsence"
        [year]="calendarYear"
      ></roma-mf-calendar>
      <roma-absence-summary-counter
        *ngIf="(resize$ | async)?.payload?.mobile"
        [absenceCounts]="totalSummary"
        [title]="'clockAbsence.validatedAbsences' | translate"
      ></roma-absence-summary-counter>
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="viewTypeActive === absenceViews?.CALENDAR && isAdminAbsences"
>
  <div class="row">
    <div class="col employee-calendar-table">
      <roma-mf-employee-calendar
        [absencesEmployees]="filteredAbsencesEmployees"
        [filterDate]="filterDate"
        [onEventClick]="showAbsenceDetailFromEmployeeCalendar"
        [onCreateUser]="showCreateUser"
        [onUserDetails]="showUserDetail"
        [onCreateAbsence]="createAbsenceFromEmployeeCalendar"
      ></roma-mf-employee-calendar>
    </div>
  </div>
</ng-container>

<button
  class="mobile-button-add"
  (click)="launchModalAbsence()"
  *ngIf="(resize$ | async)?.payload?.mobile"
>
  <img
    src="/assets/icons/gl_plus-1.svg"
    alt="{{ 'deals.createDeals' | translate }}"
  />
</button>
