import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';

import {
  ClockAbsence,
  COMPANY_ID_KEY,
  Feature,
  FeatureUser,
  MembershipSchema,
  TypeRol,
  USER_ID_KEY,
} from '@tacliatech/types';

import {
  AuthService,
  ClockAbsenceService,
  StorageService,
  UserSearchService,
  UserService,
} from '@web-frontend/shared/services';

import { CreatePersonService } from '../../create-persons';
import { RmSelect } from '../../globals/rm-select/rm-select.types';
import { AddClockAbsence } from './add-clock-absence.types';
import { ActivatedRoute } from '@angular/router';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  APP_DATE_FORMATS,
  AppDateAdapter,
} from '../../filter/filter.component';
import {
  getDateStrResum,
  getDateStrResumStr,
} from '@web-frontend/shared/utils';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { environment } from '@web-frontend/environments';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { DeleteBySelectionModalComponent } from '../../delete-by-selection-modal/delete-by-selection-modal.component';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import { VacationReport } from '../../../../../types-legacy/lib/interfaces/vacation-report.interface';
import ApiErrors from '../../../../../types/api-errors.enum';
import { IErrorApi } from '../../../../../types-legacy/lib/interfaces/errorApi.interface';
import { PlanService } from '../../permissions/plans/plans.service';

enum daysSelectedError {
  ABSENCE_ALREADY_EXISTS = 'clockAbsence.create.form.absenceExists',
  NO_VACATIONS_AVAILABLE = 'clockAbsence.create.form.notEnoughVacationDays',
}

@Component({
  selector: 'roma-add-clock-absence',
  templateUrl: './add-clock-absence.component.html',
  styleUrls: ['./add-clock-absence.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddClockAbsenceComponent implements OnInit {
  loadingAuthors = false;
  showSelectionFiles = false;
  blockAuthorsSelection = true;

  authors: RmSelect.Items = [];
  authorsCharged = false;
  activeAuthors: RmSelect.Items = [];
  activeType = [];
  absenceTypeRef = ClockAbsence.Type;
  halfDayTypeRef = ClockAbsence.HalfDayType;
  onSubmit = false;
  formSubmitted = false;
  typeAbsences = AddClockAbsence.ClockAbsenceTypes.map((el) => {
    return { ...el, title: this.i18n.instant(el.title) };
  });
  featureRef = Feature;
  featureUserRef = FeatureUser;
  selectedAbsense: ClockAbsence.AbsenceType = undefined;
  initialForm: any = {};

  getUserId(): string {
    let ownerId = '';
    if (this.params.userId) {
      ownerId = this.params.userId;
    } else {
      ownerId = this.activateRoute.snapshot.queryParamMap?.get('idOwner');
    }
    return ownerId || StorageService.GetItem(USER_ID_KEY);
  }

  form = this.fb.group({
    type: new FormControl('', [Validators.required]),
    typeDetail: this.fb.group({
      type: [ClockAbsence.Type.OneDay],
      halfDayType: [''],
      files: [[]],
    }),
    dateDetail: this.fb.group({
      startDate: new FormControl(new Date().toISOString().substring(0, 10)),
      endDate: [''],
    }),
    status: [ClockAbsence.Status.Pending],
    note: [''],
    idCreatedBy: this.getUserId(),
    idOwner: [''],
    idCompany: [StorageService.GetItem(COMPANY_ID_KEY)],
  });

  get haveChanges(): boolean {
    return Object.entries(this.form.value).some(
      ([key, value]) => this.initialForm[key] !== value
    );
  }

  get featureUser() {
    if (this.data.mode == 'EDIT') {
      return this.featureUserRef.ClockHourAbsence.update;
    }
    return this.featureUserRef.ClockHourAbsence.create;
  }

  canSubmit(hasFeature: any) {
    if (!hasFeature) {
      if (this.data.mode == 'EDIT') {
        return true;
      }
      return false;
    }
    return true;
  }

  private sub$ = new Subscription();
  currentLang = 'en-US';
  hasAddClockAbsenceFeature$ = this.permissionService.hasFeatureFn(
    Feature.ClockAbsence.AddNewClockAbsenceMine
  );
  isAdminAbsences = false;
  vacationReport: VacationReport;
  overlappingDates = false;
  private messageErrorDaysSubject = new BehaviorSubject<string | null>(null);
  messageErrorDays$: Observable<
    string | null
  > = this.messageErrorDaysSubject.asObservable();
  isAdmin = false;

  constructor(
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private authService: AuthService,
    private createPersonService: CreatePersonService,
    private userSearchService: UserSearchService,
    private clockAbsenceService: ClockAbsenceService,
    private i18n: TranslateService,
    private permissionService: PermissionService,
    private dialogRef: MatDialogRef<
      AddClockAbsenceComponent,
      AddClockAbsence.Result
    >,
    @Inject(MAT_DIALOG_DATA) private data: AddClockAbsence.Params,
    private activateRoute: ActivatedRoute,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private dialog: MatDialog,
    private toastService: ToastService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private planService: PlanService
  ) {
    i18n.onLangChange.subscribe((lang) => {
      this.setCalendarsLang(lang.lang);
    });
  }

  get params() {
    this.isAdminAbsences = this.data.isAdminAbsences;
    return this.data;
  }

  ngOnInit(): void {
    this.setCalendarsLang(this.i18n.currentLang);
    this.patchParams();
    this.initialForm = { ...this.form.value };
    this.dialogRef.disableClose = true;
    this.dialogRef
      .backdropClick()
      .subscribe(async () => await this.onBackdropClick());

    this.validateUserFromQueryParams();
    this.handleDefaultDate();
  }

  private handleDefaultDate() {
    this.data.mode === 'ADD' &&
      this.data?.defaultStartDate &&
      this.form
        .get('dateDetail')
        .get('startDate')
        .setValue(this.data.defaultStartDate);
  }

  private validateUserFromQueryParams(): void {
    const idOwner = this.activateRoute.snapshot.queryParamMap.get('idOwner');
    if (idOwner) {
      this.validateUserAbsence(idOwner);
    }
  }

  public async onBackdropClick(result?: string): Promise<void> {
    if (this.haveChanges) {
      const dialogRef = this.dialog.open(DeleteBySelectionModalComponent, {
        panelClass: 'delete-by-selection-modal',
        data: {
          title: this.i18n.instant('general.withoutSave'),
          confirmLabel: this.i18n.instant('general.buttonExit'),
          showBody: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'EXECUTE') {
          this.close();
        }
      });
    } else {
      this.close();
    }
  }

  onClickSelect(item: string) {
    switch (item) {
      case 'authors':
        if (!this.authorsCharged) {
          this.authorsCharged = true;
          this.requestAuthors();
        }
        break;
    }
  }

  submit() {
    this.onSubmit = true;
    if (
      this.form.get('idOwner').value != '' &&
      this.form.get('type').value.toString() != ''
    ) {
      if (this.data.mode === 'ADD') {
        this.handleAddSubmit();
      } else if (this.data.mode === 'EDIT') {
        this.handleEditSubmit();
      }
    }
  }

  addAuthors() {
    this.createPersonService.open().subscribe();
  }

  selectAbsenceType(type: ClockAbsence.Type) {
    this.form.get('typeDetail').get('type').setValue(type);
  }

  selectHalfDayType(type: ClockAbsence.HalfDayType) {
    this.form.get('typeDetail').get('halfDayType').setValue(type);
  }

  private handleAddSubmit() {
    if (!this.formSubmitted && this.form.valid) {
      this.formSubmitted = true;
      if (this.form.get('typeDetail').get('type').value != 2) {
        this.form
          .get('dateDetail')
          .get('endDate')
          .setValue(this.form.get('dateDetail').get('startDate').value);
      }

      const value = this.transformDateSubmit();

      this.clockAbsenceService.create(value).subscribe(
        () => {
          this.onSubmit = false;

          this.toastService.show({
            text: this.i18n.instant('clockHour.createdAbsence'),
            type: 'success',
            msDuration: 4000,
          });

          this.analyticsService.trackEvent({
            sources: ['amplitude', 'braze'],
            eventName: this.isAdminAbsences
              ? AmplitudeEvents.absenceTeam_create
              : AmplitudeEvents.absence_create,
            eventProperties: {
              event_location: this.getEventLocation(),
            },
          });

          this.close({ reload: true });
        },
        (error: IErrorApi) => this.handleError(error)
      );
    }
  }

  private handleEditSubmit() {
    if (!this.formSubmitted && this.form.valid) {
      if (this.form.get('typeDetail').get('type').value != 2) {
        this.form
          .get('dateDetail')
          .get('endDate')
          .setValue(this.form.get('dateDetail').get('startDate').value);
      }

      const value = this.transformDateSubmit();

      if (!this.isAdmin) {
        delete value.status;
      }

      this.clockAbsenceService
        .updateOne(this.data.clockAbsence._id, value)
        .subscribe(
          () => {
            this.onSubmit = false;
            this.toastService.show({
              text: this.i18n.instant('clockHour.editedClock'),
              type: 'success',
              msDuration: 4000,
            });

            this.analyticsService.trackEvent({
              sources: ['amplitude', 'braze'],
              eventName: this.isAdminAbsences
                ? AmplitudeEvents.absenceTeam_edit
                : AmplitudeEvents.absence_edit,
              eventProperties: {
                event_location: this.getEventLocation(),
              },
            });

            this.close({ reload: true });
          },
          (error: IErrorApi) => this.handleError(error)
        );
    }
  }
  private handleError(error: IErrorApi) {
    this.formSubmitted = false;
    this.onSubmit = false;
    if (error?.error?.code === ApiErrors.absenceDateCannotBeOverlappingError) {
      this.messageErrorDaysSubject.next(
        daysSelectedError.ABSENCE_ALREADY_EXISTS
      );
      this.overlappingDates = true;
    }
    this.draw();
  }

  private transformDateSubmit() {
    const value: ClockAbsence.Schema = {
      ...this.form.value,
      dateDetail: {
        ...this.form.value.dateDetail,
        startDate: getDateStrResum(
          new Date(this.form.value.dateDetail.startDate)
        ).formattedDate,
        startDateUTC: new Date(this.form.value.dateDetail.startDate),
        endDate: this.form.value?.dateDetail?.endDate
          ? getDateStrResum(new Date(this.form.value.dateDetail.endDate))
              .formattedDate
          : getDateStrResum(new Date(this.form.value.dateDetail.startDate))
              .formattedDate,
        endDateUTC: this.form.value?.dateDetail?.endDate
          ? new Date(this.form.value?.dateDetail?.endDate)
          : new Date(this.form.value.dateDetail.startDate),
      },
    };
    return value;
  }

  public formatDate(): void {
    this.overlappingDates = false;
    const dateDetail = this.form.value.dateDetail;

    const startDate = dateDetail.startDate;
    const endDate = dateDetail.endDate;

    const currentYear = dateDetail.startDate.getFullYear();

    this.validateUserVacations(this.form.get('idOwner').value, currentYear);

    if (this.form.value.typeDetail.type == this.absenceTypeRef.SeveralDays) {
      const isInvalid =
        startDate && endDate && new Date(startDate) > new Date(endDate);
      this.form
        .get('dateDetail')
        .get('endDate')
        .setErrors(isInvalid ? { invalidDate: true } : null);
    } else {
      this.form.get('dateDetail').get('endDate').setErrors(null);
    }
    this.draw();
  }

  private requestAuthors() {
    this.loadingAuthors = true;
    this.draw();

    this.sub$.add(
      this.userSearchService
        .search({ applyPaginate: false })
        .pipe(
          finalize(() => {
            this.loadingAuthors = false;
            this.draw();
          })
        )
        .subscribe((res) => {
          this.authors = res.docs.map((res) => {
            return {
              title: res.name,
              value: res._id,
              id: res._id,
              img: res.img,
            };
          });
          this.draw();
        })
    );
  }

  private patchParams() {
    if (this.data.mode === 'EDIT') {
      this.form.patchValue({
        ...this.data.clockAbsence,
        // Setting the date in the correct format
        dateDetail: {
          startDate: getDateStrResumStr(
            this.data.clockAbsence.dateDetail.startDate
          ).parseToISOStringLocal,
          endDate: getDateStrResumStr(this.data.clockAbsence.dateDetail.endDate)
            .parseToISOStringLocal,
        },
      });

      if (this.data.clockAbsence.idOwner) {
        const item = {
          title: this.data.clockAbsence.owner.name,
          value: this.data.clockAbsence.owner._id,
          id: this.data.clockAbsence.owner._id,
        };

        this.activeAuthors = [item];

        this.form.patchValue({
          idOwner: item.value,
        });
      }

      if (this.data.clockAbsence.type || this.data.clockAbsence.type >= 0) {
        const index = this.typeAbsences.findIndex(
          (item) => item.value === this.data.clockAbsence.type
        );
        this.activeType = [this.typeAbsences[index]];
        this.selectedAbsense = this.activeType[0].value;
      }
    }

    this.handleUserPermissions();
    this.draw();
  }

  private handleUserPermissions() {
    const getParams = () => {
      const userId =
        this.params.userId ||
        this.activateRoute.snapshot.queryParamMap.get('idOwner');

      const hasOwner = !!userId;

      return {
        blockAuthorsSelection: hasOwner,
        userId: userId,
      };
    };

    const setActiveAuthor = (userId: string) => {
      this.userService.findById(userId).subscribe((res) => {
        const item = {
          title: res.name,
          value: res._id,
          id: res._id,
        };

        this.activeAuthors = [item];

        this.form.patchValue({
          idOwner: userId,
        });
        this.draw();
      });
    };

    const handleParams = (params: {
      blockAuthorsSelection: boolean;
      userId: string;
    }) => {
      this.blockAuthorsSelection = params.blockAuthorsSelection;

      if (params.userId) {
        setActiveAuthor(params.userId);
      } else if (this.blockAuthorsSelection) {
        const item = {
          title: this.authService?.user?.name,
          value: this.getUserId(),
          id: this.getUserId(),
        };

        this.activeAuthors = [item];

        this.form.patchValue({
          idOwner: params.userId,
        });
        this.draw();
      } else {
        this.draw();
      }
    };

    this.sub$.add(
      this.authService.isAdmin$.pipe(filter((res) => !!res)).subscribe(() => {
        this.isAdmin = true;
        const params = getParams();
        handleParams(params);
      })
    );

    this.sub$.add(
      this.authService.user$
        .pipe(
          filter((res) => !!res),
          filter((res) => res.role.includes(TypeRol.USER_ROLE))
        )
        .subscribe((res) => {
          const params = getParams();
          setActiveAuthor(params.userId);
        })
    );
  }

  changeAuthors(evt: RmSelect.Items) {
    const ids = evt.map((el) => el.value);

    if (ids.length) {
      const [first] = ids;
      this.form.patchValue({
        idOwner: first,
      });
      this.validateUserAbsence(first as string);
    }
    this.draw();
  }

  validateUserAbsence = (id: string): void => {
    if (this.data.defaultStartDate) {
      const currentYear = new Date(this.data.defaultStartDate).getFullYear();
      this.validateUserVacations(id, currentYear);
    } else {
      this.validateUserVacations(id);
    }
  };

  validateUserVacations(id: string, year: number = new Date().getFullYear()) {
    this.clockAbsenceService.getVacationReport(id, year).subscribe((res) => {
      this.vacationReport = res;
      this.draw();
    });
  }

  changeTypeAbsence(evt: { title: string; value: ClockAbsence.AbsenceType }) {
    this.selectedAbsense = evt[0].value;
    this.form.patchValue({
      type: evt[0].value,
    });
    this.draw();
  }

  get userHasEnoughVacations() {
    if (this.overlappingDates) {
      return false;
    }

    if (this.selectedAbsense !== 0) {
      this.messageErrorDaysSubject.next(null);
      return false;
    }

    if (this.vacationReport) {
      if (typeof this.vacationReport.available !== 'number') {
        return true;
      }
      const hasEnough = this.vacationReport.available >= this.getTotalDays();
      if (!hasEnough) {
        this.messageErrorDaysSubject.next(
          daysSelectedError.NO_VACATIONS_AVAILABLE
        );
      } else {
        this.messageErrorDaysSubject.next(null);
      }
      return hasEnough;
    }

    this.messageErrorDaysSubject.next(null);
    return false;
  }

  get hasErrorWithDates() {
    return this.messageErrorDaysSubject.getValue();
  }

  changeResources(evt: string[]) {
    const filesFormArray = this.form
      .get('typeDetail')
      .get('files') as FormArray;

    filesFormArray.setValue(evt);
  }

  close(result?: AddClockAbsence.Result) {
    this.dialogRef.close(result);
  }

  getTotalDays() {
    const { startDate, endDate } = this.form.value.dateDetail;
    const type = this.form.value.typeDetail.type;
    return ClockAbsence.GetTotalDays({ startDate, endDate, type });
  }

  getEventLocation(): string {
    return this.params?.calendarView ? 'team_list' : 'all_list';
  }

  delete() {
    this.clockAbsenceService
      .deleteOne(this.data.clockAbsence?._id)
      .subscribe(() => {
        this.analyticsService.trackEvent({
          sources: ['amplitude', 'braze'],
          eventName: this.isAdminAbsences
            ? AmplitudeEvents.absenceTeam_deleted
            : AmplitudeEvents.absence_deleted,
          eventProperties: {
            event_location: this.getEventLocation(),
          },
        });
        this.close({ reload: true, forceClose: true });
        this.toastService.show({
          text: this.i18n.instant('clockHour.deleteAbsence'),
          type: 'success',
          msDuration: 4000,
        });
      });
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  setCalendarsLang(lang: string) {
    if (lang === 'en') {
      this.currentLang = 'en-US';
    } else {
      this.currentLang = 'es-ES';
    }
    localStorage.setItem('currentLang', this.currentLang);
    this._adapter.setLocale(this.currentLang);
  }

  openBuyModel(): void {
    this.planService.openPlansModal({
      data: {
        feature: this.featureRef.ClockAbsence.AddNewClockAbsenceMine,
        showTrialBtn: false,
      },
      onClose: () => {
        this.draw();
      },
    });
  }

  determineCopy(copy: string): string {
    if (environment.MembershipSchema === MembershipSchema.OneProPlan) {
      return `${copy}-new`;
    } else {
      return copy;
    }
  }

  get showInputInformation(): boolean {
    return this.selectedAbsense === ClockAbsence.AbsenceType.Vacation;
  }
}
