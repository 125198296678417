import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LoadingModule } from '../loading';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollContainerModule } from '../scroll-container';
import { SplitModule } from '@web-frontend/shared/pipes/split';
import { KanbanColumnComponent } from './kanban-column/kanban-column.component';
import { KanbanCardComponent } from './kanban-card/kanban-card.component';
import { KanbanComponent } from './kanban.component';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { ClickDeleteModule } from '@web-frontend/shared/directives/click-delete';
import { RmBadgeModule } from '../globals/rm-badge';
import { ChooseColorModule } from '../deals/deal-status-manager/choose-color/choose-color.module';
import { DealStatusManagerModule } from '../deals/deal-status-manager';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ClickDeleteDealModule } from '@web-frontend/shared/directives/click-delete-deal';
import { TranslateModule } from '@ngx-translate/core';
import { PermissionsModule } from '../permissions';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';

@NgModule({
  declarations: [KanbanComponent, KanbanColumnComponent, KanbanCardComponent],
  imports: [
    CommonModule,
    DragDropModule,
    LoadingModule,
    MatTooltipModule,
    ScrollContainerModule,
    TranslateModule.forChild(),
    SplitModule,
    MatMenuModule,
    FormsModule,
    ClickDeleteModule,
    RmBadgeModule,
    ChooseColorModule,
    DealStatusManagerModule,
    MatProgressSpinnerModule,
    ClickDeleteDealModule,
    PermissionsModule,
    UserAvatarModule,
  ],
  exports: [KanbanComponent, KanbanColumnComponent, KanbanCardComponent],
})
export class KanbanModule {}
