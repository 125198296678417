import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

import { NgSelectModule } from '@ng-select/ng-select';

import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { SelectSearchModule } from '../select-search/select-search.module';
import { EquipmentNotesComponent } from './equipment-notes.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';

@NgModule({
  declarations: [EquipmentNotesComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatCheckboxModule,
    MatButtonModule,
    MatGoogleMapsAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    SelectSearchModule,
    UserAvatarModule,
  ],
  exports: [EquipmentNotesComponent],
  providers: [],
})
export class EquipmentNotesModule {}
