<div class="list-item-card" *ngFor="let item of data">
  <div>
    <mat-checkbox
      [checked]="isChecked(item)"
      (click)="onCheckboxChange(item._id)"
    >
    </mat-checkbox>
  </div>
  <div style="width: 100%" (click)="eventClickRow(item)">
    <div class="title-container">
      <h4>{{ item.name }}</h4>
    </div>

    <div class="item-info">
      <div class="owner">
        <div class="client">
          <p>
            {{ item.final ? item.final.name : (tagNotAvailable | translate) }}
            {{ item.final ? item.final.lastName : '' }}
          </p>
        </div>

        <div class="tasker-wrapper">
          <ng-container *ngIf="item.taskerTeam">
            <div
              *ngIf="isArray(item?.taskerTeam) && item.taskerTeam.length > 0"
            >
              <roma-user-avatar
                [user]="item.taskerTeam?.[0] ?? null"
                width="20"
                height="20"
              ></roma-user-avatar>
              <roma-user-avatar
                *ngIf="item?.taskerTeam?.length > 1"
                [user]="item.taskerTeam?.[1] ?? null"
                class="more-owners"
                width="20"
                height="20"
              ></roma-user-avatar>
              {{
                item.taskerTeam.length > 1
                  ? '+' + item.taskerTeam.length
                  : item.taskerTeam[0].name
              }}
            </div>
            <div
              *ngIf="isArray(item?.taskerTeam) && item.taskerTeam.length === 0"
            >
              --
            </div>
            <div *ngIf="!isArray(item?.taskerTeam)">
              <img src="/assets/icons/table-user-icon.svg" />
              {{ item.taskerTeam?.name }}
            </div>
          </ng-container>

          <div class="date">
            <img src="/assets/icons/gl_calendar_fill.svg" />
            {{ item.dates ? (item.dates.initTimestamp | date: 'dd/MM') : '--' }}

            <img
              src="/assets/icons/gl_clock_fill.svg"
              style="margin-left: 5px"
              *ngIf="!item.isAllDay"
            />
            {{
              item.dates && !item.isAllDay
                ? (item.dates.initTimestamp | date: 'H:mm')
                : ''
            }}
          </div>
        </div>

        <!-- {{ item | json }} -->
      </div>
    </div>

    <ng-container *ngIf="item?.status?.name">
      <roma-rm-badge
        [statusName]="item?.status?.name"
        [color]="item?.status?.color"
      ></roma-rm-badge>
    </ng-container>

    <ng-container *ngIf="!item?.status?.name">
      <roma-rm-badge
        [statusName]="'general.notAssigned' | translate"
        [color]="'#E3EBFA'"
      ></roma-rm-badge>
    </ng-container>

    <span
      *ngIf="item?.cancelled"
      class="status-wrapper red"
      style="padding: 4.5px 8px"
    >
      {{ 'status.canceled' | translate }}
    </span>
  </div>
</div>
