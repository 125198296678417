import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'roma-capacitor-update',
  templateUrl: './capacitor-update.component.html',
  styleUrls: ['./capacitor-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CapacitorUpdateComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<CapacitorUpdateComponent>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
