import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClockHourRoutingModule } from './clock-hour-routing.module';
import { ClockHourComponent } from './clock-hour.component';
import { I18nChild } from '@web-frontend/shared/i18n';
import { FilterModule } from '@web-frontend/shared/components/filter';
import { TableModule } from '@web-frontend/shared/components/table';
import { LoadingModule } from '@web-frontend/shared/components/loading';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { CreatePersonModule } from '@web-frontend/shared/components/create-person';
import { AdminClockHourComponent } from './admin-clock-hour/admin-clock-hour.component';
import { FormsModule } from '@angular/forms';
import { RmSelectModule } from '@web-frontend/shared/components/globals/rm-select';
import { DeleteBySelectionModalModule } from '@web-frontend/shared/components/delete-by-selection-modal';
import { PermissionsModule } from '@web-frontend/shared/components/permissions';
import {
  MobileClockHourAdminModule,
  MobileClockHourModule,
  ShowDetailClockHourModule,
} from '@web-frontend/shared/components/clock-hours';
import { ListMobileClockHourAdminComponent } from './admin-clock-hour/list-mobile-clock-hour-admin/list-mobile-clock-hour-admin.component';
import { ListMobileClockHourComponent } from './list-mobile-clock-hour/list-mobile-clock-hour.component';
import { RmFilterModule } from '@web-frontend/shared/components/globals/rm-filter';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StripeSnackbarSharedModule } from '@web-frontend/shared/components/snackbar/stripe-snackbar.module';
import { DownloadBtnModule } from '@web-frontend/shared/components/download-btn/download-btn.module';
import { TimeDisplayModule } from '@web-frontend/shared/components/time-display';
import { MatIconModule } from '@angular/material/icon';
import { ListTeamViewComponent } from './list-team-view/list-team-view.component';
import { ClockTableHeaderComponent } from './clock-table-header/clock-table-header.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClockHourUserComponent } from './clock-hour-user/clock-hour-user.component';
import { MonthlyWeeklyFilterModule } from '../../../shared/components/monthly-weekly-filter/monthly-weekly-filter.module';
import { BackButtonModule } from '../../../shared/components/back-button/back-button.module';
import { UserAvatarModule } from '../../../shared/components/user-avatar/user-avatar.module';
import { BaseButtonModule } from '../../../common/components/button/baseButton.module';

@NgModule({
  declarations: [
    ClockHourComponent,
    AdminClockHourComponent,
    ListMobileClockHourAdminComponent,
    ListMobileClockHourComponent,
    ListTeamViewComponent,
    ClockTableHeaderComponent,
    ClockHourUserComponent,
  ],
  imports: [
    CommonModule,
    ClockHourRoutingModule,
    I18nChild,
    FilterModule,
    TableModule,
    LoadingModule,
    MatButtonModule,
    MatMenuModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatIconModule,
    FormsModule,
    CreatePersonModule,
    RmSelectModule,
    DeleteBySelectionModalModule,
    PermissionsModule,
    ShowDetailClockHourModule,
    MobileClockHourAdminModule,
    MobileClockHourModule,
    RmFilterModule,
    RomaToolTipModule,
    StripeSnackbarSharedModule,
    DownloadBtnModule,
    TimeDisplayModule,
    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    MatTooltipModule,
    MonthlyWeeklyFilterModule,
    BackButtonModule,
    UserAvatarModule,
    BaseButtonModule,
  ],
  exports: [
    ClockHourComponent,
    ListMobileClockHourAdminComponent,
    ListMobileClockHourComponent,
    ListTeamViewComponent,
    ClockTableHeaderComponent,
    ClockHourUserComponent,
  ],
})
export class ClockHourModule {}
