/* eslint-disable @typescript-eslint/member-ordering */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  BudgetCommonData,
  BudgetPreferences,
  IItemBudget,
  TotalBudget,
  TypeTax,
} from '@tacliatech/types';
import { BudgetService } from '@web-frontend/shared/services/budgets';

import { Subscription } from 'rxjs';
import { BudgetUtilisService } from '../../../core/admin/budgets/budget-edit/budgets-edit.component';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'roma-total-footer',
  templateUrl: './budget-total.component.html',
  styleUrls: ['./budget-total.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetTotalComponent implements OnInit {
  /*   INPUTS
 ..............................................*/

  @Input()
  items: IItemBudget[];

  @Input()
  settings: BudgetPreferences = new BudgetPreferences();

  @Input()
  budget: BudgetCommonData = new BudgetCommonData();

  @Input()
  mode: 'ADD' | 'EDIT' = 'ADD';

  @Input()
  show = false;

  @HostListener('window:resize', [])
  private onResize() {
    this.detectScreenSize();
  }

  /*   PROPERTIES
 ..............................................*/

  private sub$ = new Subscription();
  canEditBasic = true;

  total = 0;
  subtotal = 0;
  iva = 0;
  retention = 0;
  reqEq = 0;
  mobile = false;
  discount = 0;

  zones = this.budgetService.findAllZones();
  currencys = this.budgetService.findAllCurrencys();
  numbers_format = this.budgetService.findAllNumberFormats();
  decimals = this.budgetService.findAllDecimals();
  dates_formats = this.budgetService.findAllDateFormats();
  langs = this.budgetService.findAllLangs();

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private budgetService: BudgetService,
    private i18n: TranslateService,
    private budgetUtilisService: BudgetUtilisService
  ) {}

  set format_decimals(num) {
    num;
  }
  get format_decimals() {
    const format = this.numbers_format.find(
      (format) => format.id === this.settings?.number_format
    );
    return format;
  }

  get currencySimbol() {
    const format = this.currencys.find(
      (format) => format.id === this.settings?.currency
    );
    return format.value;
  }

  ngOnInit(): void {
    this.detectScreenSize();
    this.checkMode();
    this.watchRefreshList();
    this.watchRefreshItems();
    // this.calculateTotals();
    this.calculateTotalsRealTime(this.budget?.items || []);
  }

  detectScreenSize() {
    if (window.screen.width < 768) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  checkMode() {
    if (this.mode === 'ADD') {
      this.budget = new BudgetCommonData();
    }
  }

  // calculateTotals() {
  //   let subTotal = 0;
  //   let rEq = 0;
  //   let rEt = 0;
  //   let ivas = 0;
  //   let total = 0;
  //   let discount = 0;

  //   this.budget?.items.forEach((item) => {
  //     const totalItem = item.total;

  //     subTotal += item.quantity * item.price;
  //     total += totalItem;

  //     if (item?.discount && item?.discount !== null) {
  //       discount += (item.quantity * item.price * item?.discount) / 100;
  //       subTotal -= (item.quantity * item.price * item?.discount) / 100;
  //     }
  //     //(IMPORTE X valor descuento / 100 )

  //     if (item.taxes?.length > 0) {
  //       item.taxes.forEach((tax) => {
  //         if (tax !== null) {
  //           const value = (this.calculateItemDiscount(item) * tax.value) / 100;
  //           //console.log('value', value);

  //           if (tax.type === TypeTax.IVA) {
  //             ivas += value;
  //             //console.log('ivas: ', ivas);
  //           }

  //           if (tax.type === TypeTax.REC) {
  //             rEq += value;
  //           }

  //           if (tax.type === TypeTax.RET) {
  //             rEt += value;
  //           }
  //         }
  //       });
  //     }
  //   });

  //   this.retention = rEt;
  //   this.reqEq = rEq;
  //   this.iva = ivas;
  //   this.subtotal = subTotal;
  //   this.total = total;
  //   this.discount = discount;

  //   this.budget.total = total;
  //   this.budget.iva_total = this.iva;
  //   this.budget.subtotal = this.subtotal;
  //   this.budget.discount = this.discount;

  //   const t = new TotalBudget();

  //   t.total = this.total;
  //   t.subtotal = this.subtotal;
  //   t.ivatotal = this.iva;
  //   t.discount = this.discount;
  //   this.budgetUtilisService.updateTotal.emit(t);

  //   if (!this.budget?.proFeatures) {
  //     this.budgetUtilisService.refreshBudget.emit(this.budget);
  //   }

  //   this.draw();
  // }

  formatCustomNumber(paramNumber: number): string {
    let locale = '';

    if (this.format_decimals?.decimal_separator == ',') {
      locale = 'es-AR';
    } else {
      locale = 'en-US';
    }
    const decimals = (this.settings?.decimals || 1).toString();

    let digitsInfo = '1.';

    digitsInfo = digitsInfo + decimals + '-' + decimals;

    const formattedNumber = formatNumber(paramNumber, locale, digitsInfo);
    return formattedNumber;
  }

  private watchRefreshList() {
    this.sub$.add(
      this.budgetUtilisService.refreshBudget.subscribe((data: any) => {
        this.budget = data;
      })
    );

    this.sub$.add(
      this.budgetUtilisService.refreshBudgetSettings.subscribe((data: any) => {
        if (data?.budgetPreferences) {
          this.settings = data.budgetPreferences;
        }
      })
    );
  }

  private watchRefreshItems() {
    this.sub$.add(
      this.budgetUtilisService.refreshTotals.subscribe((items: any) => {
        this.calculateTotalsRealTime(items);
      })
    );
  }

  calculateTotalsRealTime(itemsParam: IItemBudget[]) {
    let subTotal = 0;
    let rEq = 0;
    let rEt = 0;
    let ivas = 0;
    let total = 0;
    let discount = 0;

    // console.log({ itemsParam });
    itemsParam.forEach((item) => {
      const totalItem = item.total;
      subTotal += item.quantity * item.price;
      total += totalItem;

      if (item?.discount && item?.discount !== null) {
        const itemDiscount =
          (item.quantity * item.price * item?.discount) / 100;
        discount += itemDiscount;
        subTotal -= itemDiscount;
        total -= itemDiscount;
        item.total -= itemDiscount;
      }

      if (item?.taxes?.length > 0) {
        // console.log({ item });
        item?.taxes.forEach((tax) => {
          if (tax !== null) {
            const value = (this.calculateItemDiscount(item) * tax.value) / 100;

            if (tax.type === TypeTax.IVA) {
              ivas += value;
            }

            if (tax.type === TypeTax.REC) {
              rEq += value;
            }

            if (tax.type === TypeTax.RET) {
              rEt += value;
            }
          }
        });
      }
    });

    this.retention = rEt;
    this.reqEq = rEq;
    this.iva = ivas;
    this.subtotal = subTotal;
    this.total = subTotal - rEt + rEq + ivas;
    this.discount = discount;

    this.budget.total = total;
    this.budget.iva_total = this.iva;
    this.budget.subtotal = this.subtotal;
    this.budget.discount = this.discount;
    //this.budgetUtilisService.refreshTotals.emit({total: this.total,subtotal:this.subtotal })
    // this.budgetUtilisService.refreshBudget.emit(this.budget);
    const t = new TotalBudget();
    t.total = this.total;
    t.subtotal = this.subtotal;
    t.ivatotal = this.iva;
    t.discount = this.discount;
    this.budgetUtilisService.updateTotal.emit(t);
    this.draw();
  }

  calculateItemDiscount($item) {
    let total = $item.quantity * $item.price;

    if ($item?.discount && $item?.discount !== null) {
      total = $item.quantity * $item.price * (1 - $item?.discount / 100);
    }
    return total;
  }
}
