export * from './status';
export * from './deal-types';
export * from './city';
export * from './pof-columns';
export * from './statusBudget';
export * from './statusSales';
export * from './statusWaybill';
export * from './statusBill';
export * from './statusProform';
export * from './settings';
export * from './client-register';
export * from './budget';
export * from './country/countryES';
export * from './country/countryEN';

import { IConfig } from 'ngx-mask';

export const TOKEN_KEY = 'TOKEN';
export const IMPERSONATED = 'IMPERSONATED';
export const USER_ID_KEY = 'USER_ID';
export const CUSTOMER_ID_KEY = 'CUSTOMER_ID';
export const COMPANY_ID_KEY = ' COMPANY_ID';
export const VENDOR_ID_KEY = 'VENDOR_ID';
export const USER_MODE_KEY = 'USER_MODE';
export const USER_EXTRAS = 'USER_EXTRAS';
export const PROCESS_ID_GEOLOCATION = 'PROCESS_ID_GEOLOCATION';
export const DATETIME_LOGOUT = 'DATETIME_LOGOUT';
export const CUSTOM_PROPERTIES_KEY = 'CUSTOM_PROPERTIES_KEY';
export const USER_DATA = 'userData';
export const COMPANY_DATA = 'companyData';
export const CUSTOMER_DATA = 'customerData';
export const DATE_FORMAT = 'date_format';
export const FIRST_DAY_CALENDAR = 'first_day';
export const USER_KEY = 'USER';
export const APP_VERSION = 'APP_VERSION';
export const BDG_PREFERENCES = 'BDG_PREFERENCES';
export const BDG_SETTINGS = 'BDG_SETTINGS';
export const BILL_DROPDOWN_OPEN = 'BILL_DROPDOWN_OPEN';
export const HIDE_PAYMENTS_BANNER = 'HIDE_PAYMENTS_BANNER';
export const COMPANY_PRICES = 'companyPrices';
export const SECTOR_MODULE = 'sectorModule';
export const CAMPAIGN = 'campaign';
export const USER_INFO = 'userInfo';

export const HIRING_FORM_KEY = 'HIRING_FORM';

export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';

export const TAXES_EUR = 0.8;

export const TACLIA_PHONE = '(+34) 919 035 882';

export const ITEMS_BY_ROW = 50;

export const GLOBAL_TAX = 21;

export const MaskConfig: Partial<IConfig> | (() => Partial<IConfig>) = null;

export const DefaultTaxes = [0, 4, 7, 21];

export const TIMES = [
  { time: 'de 09:00 a 11:00' },
  { time: 'de 11:00 a 13:00' },
  { time: 'de 13:00 a 16:00' },
  { time: 'de 16:00 a 18:00' },
  { time: 'de 18:00 a 20:00' },
];

export const DROP_DEFAULT_SETTINGS = {
  singleSelection: false,
  idField: 'time',
  textField: 'time',
  selectAllText: 'Seleccionar Todas',
  unSelectAllText: 'Deseleccionar Todas',
  allowSearchFilter: true,
  searchPlaceholderText: 'Buscar',
  enableCheckAll: false,
  itemsShowLimit: 3,
};

export const StatusExcludes = [1, 11, 3, 0, 7];

export const EVENTS_NOTIFICATIONS = {
  NEW_MESSAGE: {
    key: 'NEW_MESSAGE',
    value: 0,
    description: 'new message',
  },
  STATUS_UPDATED: {
    key: 'STATUS_UPDATED',
    value: 1,
    description: 'status updated',
  },
  BUDGET_EDITED: {
    key: 'BUDGET_EDITED',
    value: 2,
    description: 'budget was edited',
  },
  NEW_DEAL_ASSIGNED: {
    key: 'NEW_DEAL_ASSIGNED',
    value: 3,
    description: 'deal assigned',
  },
  TASKER_ASSIGNED: {
    key: 'TASKER_ASSIGNED',
    value: 4,
    description: 'tasker assigned',
  },
  DEAL_REMINDER: {
    key: 'DEAL_REMINDER',
    value: 5,
    description: 'deal reminder',
  },
  USER_REGISTER: {
    key: 'USER_REGISTER',
    value: 6,
    description: 'deal assigned',
  },
  INVITATION_SYSTEM: {
    key: 'INVITATION_SYSTEM',
    value: 7,
    description: 'user create another person',
  },
  CUSTOMER_REGISTER_DEAL: {
    key: 'CUSTOMER_REGISTER_DEAL',
    value: 8,
    description: 'deal created',
  },
  APPROVAL_PENDING_DEAL: {
    key: 'APPROVAL_PENDING_DEAL',
    value: 9,
    description: 'deal approval pending',
  },
  CANCEL_DEAL_VENDOR: {
    key: 'CANCEL_DEAL_VENDOR',
    value: 10,
    description: 'cancel deal by vendor',
  },
  CANCEL_DEAL_CUSTOMER: {
    key: 'CANCEL_DEAL_CUSTOMER',
    value: 11,
    description: 'cancel deal by vendor',
  },
  RESTORE_PASSWORD: {
    key: 'RESTORE_PASSWORD',
    value: 12,
    description: 'restored password',
  },
  BUDGET_ACCEPTED: {
    key: 'BUDGET_ACCEPTED',
    value: 13,
    description: 'budget accepted',
  },
  DEAL_EDITED: {
    key: 'DEAL_EDITED',
    value: 14,
    description: 'deal was edited',
  },
  DOCUMENT_SEND: {
    key: 'DOCUMENT_SEND',
    value: 15,
    description: 'pdf budget send',
  },
};
