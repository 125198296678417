<roma-mobile-clock-hour-admin
  *ngFor="let item of source"
  [content]="{
    id: item.id,
    img: item.img,
    user: item.user,
    status: item.status,
    title: item?.title,
    description: item.description,
    startDate: item.startDate,
    endDate: item.endDate,
    hour: item.hour,
    data: item.data
  }"
  [actionOptions]="{
    allowEdit: false,
    allowDelete: true
  }"
  (actions)="makeActions($event, item)"
>
</roma-mobile-clock-hour-admin>
