import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AddClockHourAdminComponent } from './add-clock-hour-admin.component';
import { AddClockHour } from './add-clock-hour.types';

@Injectable({
  providedIn: 'root',
})
export class AddClockHourAdminService {
  private refreshListSource = new Subject<AddClockHour.Result>();

  refreshList$ = this.refreshListSource.asObservable();

  constructor(private dialog: MatDialog) {}

  open(config: MatDialogConfig<AddClockHour.Params> = new MatDialogConfig()) {
    const dialogRef = this.dialog.open<
      AddClockHourAdminComponent,
      AddClockHour.Params,
      AddClockHour.Result
    >(AddClockHourAdminComponent, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        if (res?.closePreviousModal) {
          this.dialog.closeAll();
        }
        this.refreshListSource.next(res);
      })
    );
  }
}
