export default {
  alpha: ['deals', 'booking', 'online-booking', 'reports', 'auth', 'rates'],
  beta: [
    'budgets',
    'sales',
    'finals',
    'payments',
    'documents',
    'auth/onboarding',
  ],
  omega: ['clock-hours', 'activities', 'clock-absence'],
};
