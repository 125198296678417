import { formatNumber } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';

import {
  BudgetPreferences,
  BudgetsSetting,
  Feature,
  FeatureUser,
  IBudgetCommonData,
  IFinalOut,
  IProduct,
  Lang,
  numbers_format,
  STATUS_BUDGET_SORT,
  STATUS_WAYBILL_SORT,
  TypeAmplitudeBudget,
  TypeBudget,
} from '@tacliatech/types';

import { StorageService, UserService } from '@web-frontend/shared/services';

import { ProductService } from '@web-frontend/shared/services/products';
import { Subscription } from 'rxjs';
import { FilterItems } from '../filter';
import { Sort } from '@angular/material/sort';
import { BudgetService } from '@web-frontend/shared/services/budgets';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { STATUSES_BY_BUDGET_TYPE } from '@web-frontend/shared/utils';
import { BudgetCalculationsService } from '../create-budget/budget-calculations.service';

@Component({
  selector: 'roma-budget-table',
  templateUrl: './budget-table.component.html',
  styleUrls: ['./budget-table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetTableComponent implements OnInit {
  currencys = this.budgetService.findAllCurrencys();
  DEFAULT_CURRENCY_SYMBOL = '€';
  company_date_format = this.budgetService.date_format
    ? this.budgetService.date_format
    : 'dd/MM/yyyy';

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  editingRow = null;

  @Input() datasource: MatTableDataSource<any>;

  @Input() set _datasource(data: MatTableDataSource<any>) {
    this.datasource = data;
    const sort = JSON.parse(this.budgetSort);
    if (sort) {
      this.activeSort = JSON.parse(this.budgetSort);
    }
  }

  _type: string;

  budgetTypeAmplitude: TypeAmplitudeBudget; // Prefix to use in amplitude events

  @Input() set type(type: string) {
    this.unsortedData = this.datasource?.data;
    if (type) {
      this._type = type;
      this.budgetTypeAmplitude = {
        [TypeBudget.PROFORM]: TypeAmplitudeBudget.PROFORM,
        [TypeBudget.BILL]: TypeAmplitudeBudget.BILL,
        [TypeBudget.BUDGET]: TypeAmplitudeBudget.BUDGET,
        [TypeBudget.WAL]: TypeAmplitudeBudget.WAL,
      }[type];
    }
  }
  @Input()
  selectedItem = null;

  @Input() settings: BudgetPreferences = null;
  defaultSettings = new BudgetsSetting();
  defaultPreferences = this.defaultSettings.budgetPreferences;

  @Input()
  showOpenIcon: boolean;
  set showOpenIcons(value: boolean) {
    this.showIcon = value;
  }

  @Input()
  data;

  @Input()
  totalPages: any[];

  @Input()
  amplitudePrefix = '';

  @Output()
  rowClick = new EventEmitter<unknown>();

  @Output()
  wantEdit = new EventEmitter<unknown>();

  @Output()
  wantDelete = new EventEmitter<unknown>();

  @Output()
  wantRestore = new EventEmitter<unknown>();

  @Output()
  wantWatch = new EventEmitter<unknown>();

  @Output()
  wantConvertBill = new EventEmitter<unknown>();

  @Output()
  wantConvertProform = new EventEmitter<unknown>();

  @Output()
  openNewTab = new EventEmitter<unknown>();

  @Output()
  checkRowChanges = new EventEmitter<unknown>();

  @Output()
  sortChange = new EventEmitter<unknown>();

  @Output()
  columnsChange = new EventEmitter<Array<string>>();

  @Output()
  totalSelected = new EventEmitter<Array<number>>();

  columns: Array<string> = [];
  optionalsColumns = {
    category: false,
    dueDate: false,
    taxes: false,
  };
  additionalColumns: Array<string>;
  addMoreColumns = false;

  finals: IFinalOut[];
  showIcon = false;

  private columnsDisplayed: Array<string> = [];

  statusBudgetList: FilterItems;

  private lang = StorageService.GetItem('USER_LANG') as Lang;
  products: IProduct[];

  //frontend ordination
  firstOrdination = true;
  unsortedData;
  featureRef = Feature;
  featureRefUser = FeatureUser;
  activeSort: Sort = {
    direction: '',
    active: '',
  };
  localSort = false;
  budgetSort = localStorage.getItem('budgetSort') || null;
  currentLang = 'es';

  @Input()
  set displayedColumns(value: Array<string>) {
    this.columnsDisplayed = value;
    const columns = [...this.columnsDisplayed];
    this.columns = columns;
  }

  get displayedColumns() {
    return this.columnsDisplayed;
  }

  private sub$ = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public productService: ProductService,
    private budgetService: BudgetService,
    private i18n: TranslateService,
    private amplitudeService: AmplitudeService,
    private readonly userService: UserService,
    private budgetCalculationsService: BudgetCalculationsService
  ) {
    const currentSort: Sort = null;
    if (currentSort) {
      this.activeSort = currentSort;
    }
    this.requestProducts();
  }

  get isMXandTypeBill(): boolean {
    return (
      this.userService.validateCountryMX() && this._type === TypeBudget.BILL
    );
  }

  ngOnInit(): void {
    this.setTaxesField();
    this.getLang();
    const pages = this.totalPages.length;

    if (this._type === TypeBudget.WAL) {
      this.additionalColumns = ['category', 'taxes'];
    } else {
      const columns = Object.keys(this.optionalsColumns);
      this.additionalColumns = this.isMXandTypeBill
        ? columns.filter((c) => c !== 'category')
        : columns;
    }

    for (const column of Object.keys(this.optionalsColumns)) {
      if (this.columnsDisplayed.indexOf(column) >= 0) {
        this.optionalsColumns[column] = true;
      }
    }

    this.unsortedData = this.datasource?.data;
    if (this.activeSort && pages <= 1) {
      this.localSort = true;
      this.sortData(this.activeSort);
    } else {
      this.localSort = false;
    }
  }

  get featureUsedDeleteByType(): string {
    const typeMap = {
      bill: this.featureRefUser.Bill.delete,
      bdg: this.featureRefUser.Quote.delete,
      profm: this.featureRefUser.Proform.delete,
      wal: this.featureRefUser.Waybills.delete,
    };
    return (
      typeMap[this._type?.toLowerCase()] ||
      this.featureRef.SystemPermission.DefaultAllow
    );
  }

  private setTaxesField(): void {
    this.data.filteredData = this.data?.filteredData?.map((doc) => {
      const {
        totalTaxes,
      } = this.budgetCalculationsService.calculateTotalsRealTime(
        doc.items,
        this.defaultSettings
      );
      doc.taxes = totalTaxes;
      return doc;
    });
  }

  get featureUsedUpdateByType(): string {
    const typeMap = {
      bill: this.featureRefUser.Bill.update,
      bdg: this.featureRefUser.Quote.update,
      profm: this.featureRefUser.Proform.update,
      wal: this.featureRefUser.Waybills.update,
    };
    return (
      typeMap[this._type?.toLowerCase()] ||
      this.featureRef.SystemPermission.DefaultAllow
    );
  }

  private draw() {
    this.changeDetectorRef.detectChanges();
  }

  setRow(row: any) {
    this.editingRow = row;
  }

  setSort(sort: Sort) {
    this.activeSort = sort;
    localStorage.setItem('budgetSort', JSON.stringify(sort));
  }

  mostrarColumns(c) {
    this.optionalsColumns[c] = !this.optionalsColumns[c];
    this.checkColumnsDisplayed();
  }

  checkColumnsDisplayed(): void {
    const actionsIndex = this.columns.indexOf('more');
    let columnsDisplayed = this.columns.slice(0, actionsIndex);
    const actionsColumns = this.columns.slice(actionsIndex);
    let event: string = this.budgetTypeAmplitude;
    let adding = false;

    for (const column of Object.keys(this.optionalsColumns)) {
      const index = columnsDisplayed.indexOf(column);
      if (index >= 0 && !this.optionalsColumns[column]) {
        columnsDisplayed = columnsDisplayed.filter((c) => c !== column);
      } else {
        if (this.optionalsColumns[column] && index < 0) {
          const dueDateIndex = columnsDisplayed.indexOf('category');
          if (column === 'dueDate' && dueDateIndex >= 0) {
            columnsDisplayed.pop();
            columnsDisplayed.push(column, 'category');
          } else {
            columnsDisplayed.push(column);
          }
          adding = true;
        }
      }
    }

    event += adding ? '_listAddField' : '_listRemoveField';

    if (event != this.budgetTypeAmplitude) {
      this.amplitudeService.sendEvent({
        event,
      });
    }

    this.columns = [...columnsDisplayed, ...actionsColumns];
    this.columnsChange.emit(this.columns);
  }

  addMoreColumnsMenu(): void {
    this.addMoreColumns = true;
    this.amplitudeService.sendEvent({
      event: `${this.amplitudePrefix}${this.budgetTypeAmplitude}_listConfiguration`,
    });
  }

  addMoreColumnsMenuClosed() {
    this.addMoreColumns = false;
  }

  sortData(sort: Sort) {
    if (this.activeSort) {
      if (
        this.activeSort.active === sort.active &&
        this.activeSort.direction === sort.direction
      ) {
        if (!this.firstOrdination) {
          return;
        }
        this.firstOrdination = false;
      }
    }

    this.setSort(sort);

    const data = this.datasource?.data?.slice();
    const regex = new RegExp('[-/.]');

    if (sort.direction === '') {
      // An empty object will sort by createdAt
      if (this.localSort && !this.isMX) {
        sort.active = 'createdAt';
      } else {
        this.sortChange.emit({});
        return;
      }
    }
    this.sortChange.emit(sort);
    this.datasource.data = data?.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'numDoc':
          const prefixPad = '000000000000' + a.header?.prefix;
          const prefix = prefixPad.substring(prefixPad.length - 10);

          const prefixPadb = '000000000000' + b.header?.prefix;
          const prefixb = prefixPad.substring(prefixPadb.length - 10);

          const numberDocPad = '000000000000' + a.header?.numberDoc;
          const numberDoc = numberDocPad.substring(numberDocPad.length - 10);

          const numberDocPadb = '000000000000' + b.header?.numberDoc;
          const numberDocb = numberDocPadb.substring(numberDocPadb.length - 10);

          const a_numDoc = `${prefix}-${numberDoc}`.toLowerCase();
          const b_numDoc = `${prefixb}-${numberDocb}`.toLowerCase();

          return this.compare(a_numDoc, b_numDoc, isAsc);
        case 'status_budget':
          if (this._type === 'WAL') {
            return this.compare(
              STATUS_WAYBILL_SORT[a.status] || 1000,
              STATUS_WAYBILL_SORT[b.status] || 1000,
              isAsc
            );
          } else {
            return this.compare(
              STATUS_BUDGET_SORT[a.status] || 1000,
              STATUS_BUDGET_SORT[b.status] || 1000,
              isAsc
            );
          }
        case 'date':
          // the 'zzzzz' value send's emptys value to the final of the column
          const a_date = a.header.date ? a.header.date : '1900-01-01';
          const b_date = b.header.date ? b.header.date : '1900-01-01';
          return this.compare(a_date, b_date, isAsc);
        case 'createdAt':
          const a_createDate = a.createdAt ? a.createdAt : '1900-01-01';
          const b_createDate = b.createdAt ? b.createdAt : '1900-01-01';
          return this.compare(a_createDate, b_createDate, isAsc);
        case 'dueDate':
          const a_dueDate = a.header.dueDate ? a.header.dueDate : '1900-01-01';
          const b_dueDate = b.header.dueDate ? b.header.dueDate : '1900-01-01';
          return this.compare(a_dueDate, b_dueDate, isAsc);
        case 'customerName':
          const a_final = a.header.final?.name ? a.header.final?.name : 'zzzzz';
          const b_final = b.header.final?.name ? b.header.final?.name : 'zzzzz';
          return this.compare(
            a_final.toLowerCase(),
            b_final.toLowerCase(),
            isAsc
          );
        case 'dealName':
          const a_deal = a.header.deal?.name ? a.header.deal?.name : 'zzzzz';
          const b_deal = b.header.deal?.name ? b.header.deal?.name : 'zzzzz';
          return this.compare(
            a_deal.toLowerCase(),
            b_deal.toLowerCase(),
            isAsc
          );
        case 'category':
          const a_category = a.addicionalInfo.category?.name
            ? a.addicionalInfo.category?.name
            : 'zzzzz';
          const b_category = b.addicionalInfo.category?.name
            ? b.addicionalInfo.category?.name
            : 'zzzzz';
          return this.compare(
            a_category.toLowerCase(),
            b_category.toLowerCase(),
            isAsc
          );
        case 'paymentMethod':
          const a_payments = a.typePayments[0]?.name
            ? a.typePayments[0]?.name
            : 'zzzzz';
          const b_payments = b.typePayments[0]?.name
            ? b.typePayments[0]?.name
            : 'zzzzz';
          return this.compare(
            a_payments.toLowerCase(),
            b_payments.toLowerCase(),
            isAsc
          );
        case 'total':
          const a_total = a.total ? a.total : 0;
          const b_total = b.total ? b.total : 0;
          return this.compare(a_total, b_total, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a, b, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  isLetter(str) {
    return str.match(/[a-z]/i);
  }

  editClick(event, row: any) {
    if (event?.srcElement?.className?.includes('icon-padlock-feature')) {
      return;
    }
    this.wantEdit.next(row);
  }

  deleteClick(ev: any) {
    this.wantDelete.next(ev);
  }

  restoreClick<T = any>(value: T) {
    this.wantRestore.next(value);
  }

  watchClick(ev: MouseEvent) {
    this.wantWatch.next(this.editingRow);
  }

  convertToPreform() {
    this.wantConvertProform.next(this.editingRow);
  }

  convertToBill() {
    this.wantConvertBill.next(this.editingRow);
  }

  getLang(): void {
    this.currentLang = this.i18n.currentLang || 'es';
  }

  getStatus(id: number): string {
    const statusText = STATUSES_BY_BUDGET_TYPE[this._type].find(
      (status) => status.id === id
    )?.translate;
    return statusText ? this.i18n.instant(statusText) : '';
  }

  getStatusColor(id: number): string {
    return (
      STATUSES_BY_BUDGET_TYPE[this._type].find((status) => status.id === id)
        ?.color || '#E3EBFA'
    );
  }

  private requestProducts() {
    this.productService.findAll().subscribe((res) => {
      this.products = res;
    });
  }

  getFormatedCustomerNumber(num: number) {
    if (!this.settings) {
      this.settings = this.defaultPreferences;
    }
    const number_format = numbers_format.find(
      (item) => item.id === this.settings.number_format
    )?.decimal_separator;
    const decimals = (this.settings?.decimals || 1).toString();
    let locale = '';

    if (number_format === ',') {
      locale = 'es-AR';
    } else {
      locale = 'en-US';
    }

    const digitsInfo = '1.' + decimals + '-' + decimals;

    return formatNumber(num, locale, digitsInfo);
  }

  openNewTabClick(ev, id) {
    ev.stopPropagation();
    this.openNewTab.next(id);
  }

  getCurrencySimbol() {
    const format = this.currencys.find(
      (format) => format.id === this.settings?.currency
    );

    return format ? format.value : this.DEFAULT_CURRENCY_SYMBOL;
  }

  get isMX(): boolean {
    return this.userService.validateCountryMX();
  }

  getNumberDoc(itemHeader) {
    if (this.isMX) {
      if (!itemHeader.prefix) {
        return this.i18n.instant('billing.notAvailable');
      }
      return `${itemHeader.prefix}${
        itemHeader?.numberDoc ? '-' + itemHeader?.numberDoc : ''
      }`;
    } else {
      return `${itemHeader.prefix ? itemHeader.prefix : '0'}${
        itemHeader?.numberDoc ? '-' + itemHeader?.numberDoc : ''
      }`;
    }
  }

  selection = new SelectionModel(true, []);

  clearChecks() {
    this.selection.clear();
    this.checkRowChanges.next(this.selection.selected);
    this.emitTotal();
    return;
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection?.selected?.length;
    const numRows = this.data?.filteredData
      ? this.data?.filteredData?.length
      : this.data?.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.clearChecks();
      return;
    }

    const event = `${this.budgetTypeAmplitude}_list_bulk`;

    this.selection.select(
      ...(<any>this.data.filteredData ? this.data.filteredData : this.data)
    );
    this.checkRowChanges.next(this.selection.selected);
    this.emitTotal();
    this.amplitudeService.sendEvent({ event });
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  selectRow(row: IBudgetCommonData): void {
    let event = `${this.amplitudePrefix}${this.budgetTypeAmplitude}_list_select`;

    this.selection.toggle(row);
    this.checkRowChanges.next(this.selection.selected);
    this.emitTotal();

    const check = this.selection.selected.filter((i) => i?._id === row?._id);

    if (check.length === 0) {
      event = null;
    }

    if (event) {
      this.amplitudeService.sendEvent({ event });
    }
  }

  emitTotal() {
    const totalSelected = this.selection.selected.map((s) => s.total);
    this.totalSelected.emit(totalSelected);
  }
}
