import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';

import { App, IUser } from '@tacliatech/types';
import { GenericService } from '../generic';
import { map } from 'rxjs/operators';
import { environment } from '@web-frontend/environments';
import { checkCountryCodeInLocalStorage } from '@web-frontend/shared/utils/country-validator';

@Injectable({
  providedIn: 'root',
})
export class UserService extends GenericService<IUser> {
  private sub$ = new Subscription();

  private usersCache = new BehaviorSubject<App.ResourceCached<IUser[]>>([
    'INIT',
    [],
  ]);

  users$ = this.usersCache.asObservable();

  constructor(http: HttpClient) {
    super(http, 'users');
  }

  init(id) {
    this.usersCache.next(['LOADING', []]);
    this.sub$.add(
      this.findAllByCompany(id).subscribe((res) => {
        this.usersCache.next(['LOADED', res]);
      })
    );
  }

  public get users() {
    return this.usersCache.value[1];
  }

  register(data: { email: string; password: string; country: string }) {
    return this.client.post(`:API_URL/users/register`, data);
  }

  newRegister(data: {
    email: string;
    password: string;
    country: string;
    token: string;
  }) {
    return this.http.post(`:API_URL/auth/register`, data);
  }

  veryfyPassword(data: { password: string }) {
    return this.client.post(`:API_URL/users/verify-password`, data);
  }

  findAll() {
    return this.client.get<IUser[]>(`:API_URL/users/`);
  }

  findAllUsers() {
    return this.client.get<{ docs: IUser[] }>(`:API_URL/users/`);
  }

  findAllByCompany(
    id: string,
    options: { takeDeleted: boolean } = { takeDeleted: false }
  ) {
    return this.client.get<IUser[]>(`:API_URL/users/company/${id}`, {
      params: {
        takeDeleted: options.takeDeleted,
      },
    });
  }

  findByEmail(email: string) {
    return this.client.post<IUser>(`:API_URL/users/get-by-email`, { email });
  }

  findById(id: string) {
    return this.client.get<IUser>(`:API_URL/users/${id}`);
  }

  findByVendor(idVendor: string) {
    return this.client.post<IUser[]>(`:API_URL/users/get-by-vendor`, {
      vendor: idVendor,
    });
  }

  public recoverPassword(data: { [key: string]: string }) {
    return this.client.post<any>(':API_URL/auth/restore-password', data);
  }

  public updatePassword(data: { [key: string]: string }) {
    return this.client.post<any>(':API_URL/auth/update-password', data);
  }

  sendNotifResetPassword(
    name: string,
    email: string,
    companyName: string,
    _id: string,
    token: any
  ) {
    const data = {
      name: name,
      email: email,
      companyName: companyName,
      _id: _id,
      token: token,
    };

    return this.client.post<any>(
      ':API_URL/users/send-notif-reset-password',
      data
    );
  }

  submitRequestToEmitPosition(users: string[]) {
    return this.client.post<unknown>(
      `:API_URL/users/send-notification-to-emit-current-position`,
      {
        users,
      }
    );
  }

  //
  findAllUsersByCompany_SELECT() {
    return this.client.get<IUser[]>(`:API_URL/users/company/select`).pipe(
      map((res) => {
        return res.map((el) => {
          return {
            ...el,
          };
        });
      })
    );
  }

  update(id: string, params = {}) {
    return this.client.put<unknown>(`:API_URL/users/${id}`, { ...params });
  }

  validateCountryES(): boolean {
    const country = 'ES';
    return checkCountryCodeInLocalStorage(country);
  }

  validateCountryMX(): boolean {
    const country = 'MX';
    return checkCountryCodeInLocalStorage(country);
  }

  resendInvitationEmail(id: string) {
    const body = { id };
    return this.client.post(`:API_URL/users/invite`, body);
  }

  clearUserStorage() {
    this.usersCache.next(['INIT', []]);
  }
}
