<roma-modal-template
  size="md"
  [showDefaultButtons]="false"
  [showHeader]="false"
  [footerTemplate]="footerCustomTemplate"
  (cancel)="close(false)"
>
  <h2 class="owner-name">
    <roma-user-avatar
      [user]="params.clockHour.owner"
      width="28"
      height="28"
    ></roma-user-avatar>
    {{ clockAbsence.owner?.name }}
  </h2>

  <div class="data-flex-container">
    <div class="item item-name">
      {{ 'general.date' | translate }}
    </div>
    <div class="item item-info">
      {{ clockAbsence?.date | dateDayMonthConvert }}
    </div>
  </div>
  <div class="data-flex-container">
    <div class="item item-name">
      {{ 'table.colum.duration' | translate }}
    </div>
    <div class="item item-info">
      {{ getClockHoursDuration(clockAbsence) }}
    </div>
  </div>
  <div class="data-flex-container">
    <div class="item item-name">
      {{ 'clockHour.turns' | translate }}
    </div>
    <div class="item item-info">
      {{ clockAbsence?.histories?.length }}
    </div>
  </div>
  <div class="data-flex-container">
    <div class="item item-name">
      {{ 'general.status' | translate }}
    </div>
    <div class="item item-info">
      <ng-container [ngSwitch]="clockAbsence?.status">
        <span
          *ngSwitchCase="clockHourStatusRef.Pending"
          class="status-wrapper warn"
        >
          {{ 'table.content.pending' | translate }}
        </span>
        <span
          *ngSwitchCase="clockHourStatusRef.Approved"
          class="status-wrapper green"
        >
          {{ 'table.content.approved' | translate }}
        </span>
        <span
          *ngSwitchCase="clockHourStatusRef.Rejected"
          class="status-wrapper red"
        >
          {{ 'table.content.rejected' | translate }}
        </span>
        <ng-container *ngSwitchDefault>
          {{ tagNotAvailable | translate }}
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="data-flex-container">
    <div class="item item-name">
      {{ 'general.notes' | translate }}
    </div>
    <div class="item item-info">
      <ng-container *ngIf="clockAbsence?.notes?.length > 0">
        {{ clockAbsence.notes[0].text || clockAbsence.notes[0] }}
      </ng-container>

      <ng-container *ngIf="!clockAbsence?.notes?.length"> - </ng-container>
    </div>
  </div>

  <div
    *ngIf="clockAbsence.status === statusRef.Rejected"
    class="data-flex-container"
  >
    <div class="item item-name">
      {{ 'general.reason' | translate }}
    </div>
    <div class="item item-info">
      <ng-container *ngIf="clockAbsence?.reasonRejected?.description">
        {{ clockAbsence?.reasonRejected?.description }}
      </ng-container>

      <ng-container *ngIf="!clockAbsence?.reasonRejected?.description">
        -
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="showOptionsReasonDescription">
    <div class="container__reason">
      <label>{{ 'clockAbsence.addReason' | translate }}</label>
      <textarea
        name=""
        id=""
        [value]="''"
        cols="30"
        rows="10"
        [placeholder]="'clockAbsence.enterReason' | translate"
        (keyup)="changeReasonDescription($event.target.value)"
      >
      </textarea>
      <div class="text-danger" *ngIf="showReasonError">
        {{ 'clockAbsence.reasonError' | translate }}
      </div>
    </div>
  </ng-container>
</roma-modal-template>

<ng-template #footerCustomTemplate>
  <div class="footer">
    <ng-container *ngIf="showOptionsReasonDescription">
      <base-button (click)="continue()">
        {{ 'general.continue' | translate }}
      </base-button>
      <base-button
        buttonClass="tertiary"
        (click)="cancel()"
        style="color: #15152e !important"
      >
        {{ 'general.cancel' | translate }}
      </base-button>
    </ng-container>

    <ng-container *ngIf="!showOptionsReasonDescription">
      <div class="leftDiv">
        <base-button
          *ngIf="showButtonsOptions.showEdit"
          buttonClass="secondary-text-link"
          romaProFeature
          [featureUser]="featureUserRef.ClockHour.update"
          (clicked)="edit()"
        >
          <img src="assets/icons/gl_edit-fill.svg" />
          {{ 'general.edit' | translate }}
        </base-button>
      </div>
      <base-button
        *ngIf="showButtonsOptions.showDelete"
        buttonClass="tertiary-danger"
        romaClickDelete
        romaProFeature
        subtitle="deleteModal.body_final"
        [featureUser]="featureUserRef.ClockHour.delete"
        (wantDelete)="delete()"
      >
        {{ 'general.remove' | translate }}
      </base-button>
      <div
        class="groupDiv"
        *ngIf="showButtonsOptions.showReject && showButtonsOptions.showApproval"
      >
        <base-button
          *ngIf="showButtonsOptions.showReject"
          buttonClass="tertiary-danger"
          romaProFeature
          [featureUser]="featureUserRef.ClockHourCompany.approve"
          (clicked)="reject()"
        >
          {{ 'general.decline' | translate }}
        </base-button>
        <base-button
          *ngIf="showButtonsOptions.showApproval"
          romaProFeature
          [featureUser]="featureUserRef.ClockHourCompany.approve"
          (clicked)="approved()"
        >
          {{ 'general.approve' | translate }}
        </base-button>
      </div>
    </ng-container>
  </div>
</ng-template>
