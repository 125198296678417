import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import * as moment from 'moment-timezone';
import { forkJoin, Subscription } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';

import {
  ClockHour,
  COMPANY_ID_KEY,
  Feature,
  FeatureUser,
  TypeRol,
  USER_ID_KEY,
} from '@tacliatech/types';
import { AddClockHourService } from '@web-frontend/shared/components/add-clock-hour';
import { ChronoLongtimeService } from '@web-frontend/shared/components/chrono-longtime';
import { FilterComponent } from '@web-frontend/shared/components/filter/filter.component';
import {
  AuthService,
  CalendarResponse,
  ClockHourMetrics,
  ClockHourService,
  StorageService,
} from '@web-frontend/shared/services';
import { getDateStrResum, RemoveEmpty } from '@web-frontend/shared/utils';

import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { AddClockHour } from '@web-frontend/shared/components/add-clock-hour/add-clock-hour.types';
import { ModalMobileInfoComponent } from '@web-frontend/shared/components/modal-mobile-info';
import { VideoService } from '@web-frontend/shared/components/modal-video/video.service';
import { BrazeEventType } from '@web-frontend/shared/services/braze/braze-event-type.enum';
import { BrazeService } from '@web-frontend/shared/services/braze/braze.service';
import { PermissionService } from '@web-frontend/shared/services/permissions';
import { TutorialService } from '@web-frontend/shared/services/tutorial';
import { SandboxService } from '@web-frontend/shared/services/sandbox/sandbox.service';
import { ToastService } from '@web-frontend/shared/services/toast/toast.service';
import { ClockHourView } from './clock-hour.type';
import { ListMobileClockHourAction } from './list-mobile-clock-hour';
import { DownloadBtnService } from '@web-frontend/shared/components/download-btn/download-btn.service';
import { AnalyticsService } from '@web-frontend/shared/services/analytics/analytics.service';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { formatClockDate } from '@web-frontend/shared/utils/format-clock-date';
import { downloadSheet } from '@web-frontend/shared/utils/download-file';
import { formatToCalendar } from './clock-hour-calendar-mapper';

interface OpenTrackingResponse {
  current: string;
  startDate: string;
  timezone: string;
  duration: {
    hour: number;
    minute: number;
    second: number;
  };
}

@Component({
  selector: 'roma-clock-hour',
  templateUrl: './clock-hour.component.html',
  styleUrls: ['./clock-hour.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClockHourComponent implements OnInit, AfterViewInit, OnDestroy {
  tableMarginTop = '0px';
  distanceToTop = 0;
  openMobileActions = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.tableMarginTop = '0px';
    this.calcTableDistance();
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange(): void {
    if (this.start && document.visibilityState === 'visible') {
      this.calcCurrentChronometer();
    }
  }

  @ViewChild(FilterComponent)
  filterComponent: FilterComponent;

  columns = ['date', 'entrance', 'durationAverage', 'status', 'note'];
  user$ = this.authService.user$;
  typeRolRef = TypeRol;

  isLoading = true;
  isLoadingMetrics = true;
  clockHours: ClockHour.Calendar[] = [];

  monthlyDays = [];

  currentMonth: number;
  currentDay: number;
  currentYear: number;
  selectedMonth: number;
  remainingMonth: number;

  stringMonth: string;
  stringRemainingMonth: string;

  weekLimit: number;
  weekRemaining: number;

  firstWeekDay: number;
  lastWeekDay: number;
  firstMonthDay: number;
  lastMonthDay: number;
  remainingWeekLength: number;

  addButtonLoading = false;
  view: ClockHourView = ClockHourView.Week;
  clockHourViewRef = ClockHourView;
  featureRef = Feature;
  featureUserRef = FeatureUser;
  userMetrics: ClockHourMetrics = {
    workedHours: null,
    contractHours: null,
    remainingHours: null,
    extraHours: null,
    pendingRegisters: null,
  };

  private hybridRequest = false;

  private searchParams = {
    'idCompanies[]': [StorageService.GetItem(COMPANY_ID_KEY)],
    'idOwners[]': [StorageService.GetItem(USER_ID_KEY)],
    limit: 7,
    from: null,
    to: null,
  };

  private sub$ = new Subscription();
  private flightRequest = new Subscription();

  resize$ = this.sandBoxService.screenBusChannel$;
  //timeTracking variables
  timeTrackingError = false;
  start = false;
  chronoLoading = false;
  timer;
  seconds = 0;
  currentTimeTracked = this.timeTrackedFormatter(this.seconds);
  openEntry;
  openEntryIndex: number;
  matchDateEntries;

  isAdmin = false;
  showDisclaimer = false;
  allowProRegister = true;
  featureManual: string = Feature.SystemPermission.DefaultAllow;

  showVideoTutorialButton: boolean;
  private academyLink: string;
  private calendarId: string;

  constructor(
    private cdRef: ChangeDetectorRef,
    private i18n: TranslateService,
    private authService: AuthService,
    private clockHourService: ClockHourService,
    private addClockHourService: AddClockHourService,
    private amplitudeService: AmplitudeService,
    private chronoLongtimeService: ChronoLongtimeService,
    private sandBoxService: SandboxService,
    private permissionService: PermissionService,
    private dialog: MatDialog,
    private video: VideoService,
    private tutorialService: TutorialService,
    private brazeService: BrazeService,
    private toastService: ToastService,
    private readonly downloadBtnService: DownloadBtnService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.isAdmin =
      JSON.parse(StorageService.userData)?.role?.toLowerCase() == 'admin_role';
    this.haveFeature();
    this.resolveDateCurrent();
    this.watchRefreshList();
    this.watchTranslate();
    this.getOpenTimeTracking();

    this.sub$.add(
      this.tutorialService
        .get('clock-hours-mine')
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .subscribe((res) => {
          this.academyLink = res?.academy;
          if (res?.source) {
            this.showVideoTutorialButton = !!res.source;
          } else {
            this.showVideoTutorialButton = false;
          }
        })
    );
  }

  ngAfterViewInit() {
    this.calcTableDistance();
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  private getHolidayCalendars(): void {
    this.sub$.add(
      this.clockHourService
        .getBankHolidaysCalendars()
        .pipe(
          finalize(() => {
            if (this.calendarId) {
              this.getHolidays();
            }
            this.draw();
          })
        )
        .subscribe((res) => {
          if (Array.isArray(res) && res.length > 0) {
            this.calendarId = res[0].id;
          }
        })
    );
  }

  private getYearsFromClockHours() {
    return this.clockHours
      .map((c) => c.date.year)
      .filter((year, index, self) => self.indexOf(year) === index);
  }

  private getHolidays(): void {
    const years = this.getYearsFromClockHours();
    const holidayRequests = years.map((year) =>
      this.clockHourService.getBankHolidays(this.calendarId, year)
    );

    this.sub$.add(
      forkJoin(holidayRequests)
        .pipe(
          finalize(() => {
            this.draw();
          })
        )
        .subscribe((responses) => {
          const holidays = responses.flat();
          this.clockHours = this.clockHours.map((c) => {
            const holidayDate = holidays.map((h: { day: string }) => {
              const [year, month, day] = h.day.split('-').map(Number);
              return { year, month: month - 1, day };
            });

            return {
              ...c,
              hasHoliday: holidayDate.some(
                (h) =>
                  h.day === c.date.day &&
                  h.month === c.date.month &&
                  h.year === c.date.year
              ),
            };
          });
          this.draw();
        })
    );
  }

  isAvailableFeature = true;

  haveFeature() {
    this.sub$.add(
      this.permissionService
        .hasFeatureFn(Feature.ClockHour.ViewTimer)
        .pipe(take(1))
        .subscribe((res) => {
          this.isAvailableFeature = res;
          if (!this.isAvailableFeature) {
            if (this.isAdmin) {
              this.showDisclaimer = true;
              this.draw();
            }
            this.getTodayRegisters();
          }
        })
    );
  }

  private formatDDMMYYYY(date: { month: number; year: number; day: number }) {
    const { month, year, day } = date;
    return `${(month + 1)
      .toString()
      .padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
  }

  private formatHHMM({
    hour,
    minutes,
  }: {
    hour: number;
    minutes: number | string;
  }) {
    return `${hour.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  getTodayRegisters() {
    const date = getDateStrResum(new Date());
    const parms = {
      idCompany: StorageService.CompanyId,
      date: {
        year: parseInt(date.year),
        month: parseInt(date.month),
        day: parseInt(date.day),
      },
    };

    this.sub$.add(
      this.clockHourService.findTodayRegisters(parms).subscribe((res) => {
        this.allowProRegister = res.registers?.length < res.maxRegisters;
        if (!this.allowProRegister) {
          this.featureManual = Feature.ClockHour.ViewTimer;
        }
      })
    );
  }

  getOpenTimeTracking() {
    this.clockHourService
      .getOpenTimeTracking()
      .subscribe((res: OpenTrackingResponse) => {
        this.resolveLoadData();
        if (res?.duration?.hour > 15) {
          this.brazeService.sendEvent({
            event: BrazeEventType.time_tracking_user_missing,
          });

          this.chronoLongtimeService.open({
            data: {
              hour: res?.duration?.hour,
              showFooter: false,
            },
          });
        } else {
          this.checkOpenEntry();
        }
      });
  }

  async launchModalClockHour() {
    const today = new Date();

    this.analyticsService.trackEvent({
      eventName: 'trackingTime_start',
      sources: ['amplitude'],
    });

    const date = {
      year: today.getFullYear(),
      month: today.getMonth(),
      day: today.getDate(),
    };

    this.addButtonLoading = true;
    this.draw();

    const search = await this.clockHourService
      .search({
        ...this.searchParams,
        from: JSON.stringify(date),
        to: JSON.stringify(date),
        limit: 1,
      })
      .toPromise();

    this.addButtonLoading = false;
    this.draw();

    const mode = search.docs.length ? 'EDIT' : 'ADD';

    this.addClockHourService
      .open({
        data: {
          mode,
          idCompany: StorageService.CompanyId,
          idOwner: StorageService.UserId,
          params: search.docs?.[0],
        },
      })
      .subscribe();
  }

  isToday(evt: ClockHour.Output): boolean {
    const today = new Date().toDateString();
    const eventDate = new Date(
      evt.date.year,
      evt.date.month - 1,
      evt.date.day
    ).toDateString();
    return today === eventDate;
  }

  launchModalClockHourEdit(evt: ClockHour.Output): void {
    this.analyticsService.trackEvent({
      eventName: 'trackingTime_list_start',
      sources: ['amplitude'],
      eventProperties: { today: this.isToday(evt) },
    });

    const mode: AddClockHour.Mode = evt?._id ? 'EDIT' : 'ADD';

    this.addClockHourService
      .open({
        data: {
          mode,
          idCompany: StorageService.CompanyId,
          idOwner: StorageService.UserId,
          params: evt,
        },
      })
      .subscribe();
  }

  isUserAdmin(): boolean {
    return (
      JSON.parse(StorageService.userData).role?.toLowerCase() == 'admin_role'
    );
  }

  deleteClockHour(evt: { _id: string; status?: string }): void {
    if (evt?.status == 'APPROVED' && this.isUserAdmin() == false) {
      this.toastService.show({
        text: this.i18n.instant('clockHour.errorDeleteAccess'),
        type: 'error',
        msDuration: 4000,
      });
      return;
    }

    this.sub$.add(
      this.clockHourService
        .updateOne(evt._id, { deleted: true })
        .subscribe(() => {
          this.requestClockHours({ loadingGlobal: true });
          const eventData = {
            event: 'trackingTime_delete',
          };
          this.amplitudeService.sendEvent(eventData);
          this.toastService.show({
            text: this.i18n.instant('clockHour.deleteSuccess'),
            type: 'success',
            msDuration: 4000,
          });
          if (evt._id === this.openEntry?._id) {
            clearInterval(this.timer);
            this.start = false;
            this.seconds = 0;
            this.currentTimeTracked = this.timeTrackedFormatter(this.seconds);
          }
        })
    );
  }

  changeMonth(month: number, action: 'next' | 'prev') {
    if (action === 'next') {
      if (this.selectedMonth == 11) {
        this.selectedMonth = 0;
        this.currentYear = this.currentYear + 1;
      } else {
        this.selectedMonth = month;
      }
    } else {
      if (this.selectedMonth == 0) {
        this.selectedMonth = 11;
        this.currentYear = this.currentYear - 1;
      } else {
        this.selectedMonth = month;
      }
    }
    this.getMonthString();
    this.resolveLoadData();
  }

  changeWeek(day: number, action: 'next' | 'prev') {
    if (action === 'next') {
      if (day > this.lastMonthDay) {
        if (this.selectedMonth == 12) {
          this.selectedMonth = 0;
          this.currentYear = this.currentYear + 1;
        } else {
          this.selectedMonth = this.selectedMonth + 1;
        }
        this.getMonthString();
        this.resolveWeekFilter(1);
      } else {
        if (this.selectedMonth == 12) {
          this.selectedMonth = 0;
          this.currentYear = this.currentYear + 1;
        }
        this.resolveWeekFilter(day);
      }
    } else {
      if (this.hybridRequest) {
        if (this.selectedMonth == 0) {
          this.selectedMonth = 11;
          this.currentYear = this.currentYear - 1;
        } else {
          this.selectedMonth = this.selectedMonth - 1;
          this.getMonthString();
        }
        this.resolveWeekFilter(Math.abs(day - (this.weekLimit + 1)));
      } else {
        if (day <= this.weekLimit + 1) {
          if (day == 1) {
            this.resolveWeekFilter(-6);
          } else {
            this.resolveWeekFilter(this.firstMonthDay);
          }
        } else {
          this.resolveWeekFilter(Math.abs(day - (this.weekLimit + 1)));
        }
      }
    }
  }

  changeClockHourView(view: ClockHourView) {
    if (this.view !== view) {
      this.view = view;
      this.searchParams = {
        ...this.searchParams,
        limit: this.view === ClockHourView.Week ? 7 : 31,
      };
      this.resolveLoadData();

      const eventName =
        this.view === ClockHourView.Week
          ? AmplitudeEvents.trackingTime_weekly
          : AmplitudeEvents.trackingTime_monthly;

      this.analyticsService.trackEvent({
        sources: ['amplitude'],
        eventName: eventName,
      });
    }
  }

  private resolveLoadData() {
    const firstDay = new Date(this.currentYear, this.selectedMonth, 1);
    const lastDay = new Date(this.currentYear, this.selectedMonth + 1, 0);

    this.getMonthString();
    this.firstMonthDay = firstDay.getDate();
    this.lastMonthDay = lastDay.getDate();

    if (this.view === ClockHourView.Week) {
      const d = new Date(this.currentYear, this.selectedMonth, this.currentDay);
      const day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1);
      const firstDay = new Date(d.setDate(diff));
      this.resolveWeekFilter(this.currentDay);
    }
    if (this.view === ClockHourView.Month) {
      this.resolveMonthFilter();
    }
  }

  private getMonthString() {
    moment.locale(this.i18n.currentLang);
    this.stringMonth = moment(
      new Date(this.currentYear, this.selectedMonth + 1, 0)
    )
      .format('MMM')
      .replace('.', '');
    this.stringRemainingMonth = moment(
      new Date(this.currentYear, this.remainingMonth + 1, 0)
    )
      .format('MMM')
      .replace('.', '');
  }

  private changeDate(startDate: Date, endDate: Date) {
    const formattedStartDate = startDate.toLocaleDateString('en-CA');
    const formattedEndDate = endDate.toLocaleDateString('en-CA');

    this.searchParams = {
      ...this.searchParams,
      from: formattedStartDate,
      to: formattedEndDate,
    };

    if (this.searchParams['from'] && this.searchParams['to']) {
      this.requestClockHours({ loadingGlobal: true });
    }
  }

  private requestClockHours({ loadingGlobal = false }) {
    if (loadingGlobal) {
      this.isLoading = true;
      this.draw();
    }

    const query = RemoveEmpty(this.searchParams);
    const userId = StorageService.GetItem(USER_ID_KEY);

    const obs$ = this.clockHourService
      .getUserCalendar(userId, {
        ...query,
      })
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.updateMetrics();
          this.calcTableDistance();
          this.getHolidayCalendars();
          this.draw();
        })
      );

    if (this.flightRequest) {
      this.flightRequest.unsubscribe();
      this.flightRequest = new Subscription();
    }

    this.flightRequest = obs$.subscribe((res) => {
      this.setClockHours(res);
    });
  }

  private watchTranslate() {
    this.sub$.add(
      this.i18n.onLangChange.subscribe(() => {
        moment.locale(this.i18n.currentLang);
        this.stringMonth = moment(
          new Date(this.currentYear, this.selectedMonth + 1, 0)
        )
          .format('MMM')
          .replace('.', '');
      })
    );
  }

  private resolveDateCurrent() {
    const currentDate = new Date();
    this.currentMonth = currentDate.getMonth();
    this.currentDay = currentDate.getDate();
    this.currentYear = currentDate.getFullYear();
    this.selectedMonth = this.currentMonth;
    this.remainingMonth = this.selectedMonth;
  }

  private getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  private setClockHours(response: Record<string, CalendarResponse[]>) {
    this.clockHours = formatToCalendar(response);

    this.matchDateEntries = this.clockHours;
    this.getClockHoursMonthEntries();
  }

  private getClockHoursMonthEntries() {
    if (this.view === ClockHourView.Week) {
      this.monthlyDays = [];
      if (this.firstWeekDay > this.lastWeekDay) {
        let i = 0;
        let weekDay = this.firstWeekDay;
        const lastMonthDay: number = this.firstWeekDay + 6 - this.lastWeekDay;
        let day;
        const arr = [];
        for (i; i < 7; i++) {
          if (weekDay <= lastMonthDay) {
            day = this.clockHours.find(
              (clockHour) => clockHour.date.day === weekDay
            );
            if (!day) {
              day = {
                date: {
                  day: weekDay,
                  month: this.selectedMonth - 1,
                  year: this.currentYear,
                },
              };
            }
          } else {
            day = this.clockHours.find(
              (clockHour) => clockHour.date.day === weekDay - lastMonthDay
            );
            if (!day) {
              day = {
                date: {
                  day: weekDay - lastMonthDay,
                  month: this.selectedMonth,
                  year: this.currentYear,
                },
              };
            }
          }

          arr.push(day);
          weekDay++;
        }
        this.monthlyDays = arr;
      } else {
        const monthlyDays = this.clockHours.filter(
          (clockHour) => clockHour.date.month === this.selectedMonth
        );
        this.monthlyDays = monthlyDays;
      }
    } else {
      const monthlyDays = this.clockHours.filter(
        (clockHour) => clockHour.date.month === this.selectedMonth
      );
      this.monthlyDays = monthlyDays;
    }
    this.setTableEntries();
  }

  private setTableEntries() {
    let i: number;
    let tableLength: number;
    let media: number;
    if (this.view === ClockHourView.Week) {
      tableLength = 7;
      media = 5;
      i = 0;
    }
    if (this.view === ClockHourView.Month) {
      i = 1;
      tableLength = this.lastMonthDay + 1;
      media = 20;
    }

    const lastMonthDay: number = this.firstWeekDay + 6 - this.lastWeekDay;

    for (i; i < tableLength; i++) {
      let obj;
      if (this.view === ClockHourView.Week) {
        if (
          this.firstWeekDay > this.lastWeekDay &&
          this.firstWeekDay + i > lastMonthDay
        ) {
          obj = this.monthlyDays.find(
            (o) => o.date.day === this.firstWeekDay + i - lastMonthDay
          );
        } else {
          obj = this.monthlyDays.find(
            (o) => o.date.day === this.firstWeekDay + i
          );
        }
      }
      if (this.view === ClockHourView.Month) {
        obj = this.monthlyDays.find((o) => o.date.day === i);
      }
      if (!obj) {
        if (this.view === ClockHourView.Week) {
          if (this.remainingWeekLength >= 0 && this.hybridRequest) {
            if (i <= this.remainingWeekLength) {
              this.monthlyDays.push({
                date: {
                  year:
                    this.selectedMonth == 0
                      ? this.currentYear - 1
                      : this.currentYear,
                  month: this.selectedMonth == 0 ? 11 : this.remainingMonth,
                  day: this.firstWeekDay + i,
                },
              });
            } else {
              this.monthlyDays.push({
                date: {
                  year:
                    this.selectedMonth == 12
                      ? this.currentYear + 1
                      : this.currentYear,
                  month: this.selectedMonth == 12 ? 0 : this.selectedMonth,
                  day: i - this.remainingWeekLength,
                },
              });
            }
          } else {
            this.monthlyDays.push({
              date: {
                year: this.currentYear,
                month: this.selectedMonth,
                day: this.firstWeekDay + i,
              },
            });
          }
        }
        if (this.view === ClockHourView.Month) {
          this.monthlyDays.push({
            date: { year: this.currentYear, month: this.selectedMonth, day: i },
          });
        }
      }
    }
  }

  private resolveWeekFilter(currentDay: number) {
    if (currentDay == -6) {
      currentDay = new Date(
        this.currentYear,
        this.selectedMonth,
        currentDay
      ).getDate();
      if (this.selectedMonth == 0) {
        this.selectedMonth = 11;
        this.currentYear = this.currentYear - 1;
      } else {
        this.selectedMonth = this.selectedMonth - 1;
        this.getMonthString();
      }
    }

    const weekDate = new Date(this.currentYear, this.selectedMonth, currentDay);
    const weekDay = weekDate.toLocaleDateString('en-US', { weekday: 'long' });

    const weekDayMapping = {
      Monday: { weekLimit: 6, weekRemaining: 0 },
      Tuesday: { weekLimit: 5, weekRemaining: 1 },
      Wednesday: { weekLimit: 4, weekRemaining: 2 },
      Thursday: { weekLimit: 3, weekRemaining: 3 },
      Friday: { weekLimit: 2, weekRemaining: 4 },
      Saturday: { weekLimit: 1, weekRemaining: 5 },
      Sunday: { weekLimit: 0, weekRemaining: 6 },
    };
    const { weekLimit = 0, weekRemaining = 0 } = weekDayMapping[weekDay] || {};
    this.weekLimit = weekLimit;
    this.weekRemaining = weekRemaining;

    if (currentDay - this.weekRemaining < 1) {
      this.firstWeekDay = new Date(
        this.currentYear,
        this.selectedMonth,
        currentDay - this.weekRemaining
      ).getDate();
      this.lastWeekDay = this.weekLimit + currentDay;

      const remainingLastDay = new Date(
        this.currentYear,
        this.selectedMonth,
        0
      ).getDate();
      this.remainingMonth = new Date(
        this.currentYear,
        this.selectedMonth,
        currentDay - this.weekRemaining
      ).getMonth();
      this.remainingWeekLength = remainingLastDay - this.firstWeekDay;
      this.hybridRequest = true;

      this.changeDate(
        new Date(
          this.selectedMonth == 0 ? this.currentYear - 1 : this.currentYear,
          this.remainingMonth,
          this.firstWeekDay
        ),
        new Date(this.currentYear, this.selectedMonth, this.lastWeekDay)
      );
    } else {
      this.firstWeekDay = currentDay - this.weekRemaining;
      this.lastWeekDay = currentDay + this.weekLimit;
      const remainingLastDay = new Date(
        this.currentYear,
        this.selectedMonth + 1,
        0
      ).getDate();
      if (this.lastWeekDay > remainingLastDay) {
        this.hybridRequest = true;

        this.remainingMonth = this.selectedMonth;
        this.remainingWeekLength = remainingLastDay - this.firstWeekDay;
        this.lastWeekDay = this.lastWeekDay - remainingLastDay;

        this.changeDate(
          new Date(
            this.currentYear,
            this.selectedMonth,
            currentDay - this.weekRemaining
          ),
          new Date(this.currentYear, this.selectedMonth + 1, this.lastWeekDay)
        );
        this.selectedMonth = this.selectedMonth + 1;
        this.lastMonthDay = new Date(
          this.currentYear,
          this.selectedMonth + 1,
          0
        ).getDate();
      } else {
        this.remainingWeekLength = 0;
        this.hybridRequest = false;
        this.changeDate(
          new Date(
            this.currentYear,
            this.selectedMonth,
            currentDay - this.weekRemaining
          ),
          new Date(this.currentYear, this.selectedMonth, this.lastWeekDay)
        );
      }
    }
    this.getMonthString();
  }

  private resolveMonthFilter() {
    this.changeDate(
      new Date(this.currentYear, this.selectedMonth, this.firstMonthDay),
      new Date(this.currentYear, this.selectedMonth, this.lastMonthDay)
    );
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private watchRefreshList() {
    this.sub$.add(
      this.addClockHourService.refreshList$
        .pipe(filter((res) => Boolean(res)))
        .subscribe(() => {
          this.requestClockHours({ loadingGlobal: true });
        })
    );
  }

  // TimeTracking start's
  createNewEntry(evt: PointerEvent) {
    // THIS LINE IS IMPORTANT TO PRO FEATURES
    if (this.start) {
      this.stopTimeTracking();
      return;
    }

    const eventData = {
      event: AmplitudeEvents.trackingTime_auto_create,
    };
    this.chronoLoading = true;

    const date = new Date();
    const hour = date.getHours();
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const seconds = date.getSeconds();
    const matchDate = this.matchDateEntries.find(
      (entry) => entry.date.day === date.getDate() && entry.histories
    );

    if (!matchDate) {
      const data = {
        idCreatedBy: StorageService.GetItem(USER_ID_KEY),
        idOwner: StorageService.GetItem(USER_ID_KEY),
        idCompany: StorageService.GetItem(COMPANY_ID_KEY),
        date: {
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate(),
        },
        histories: [
          {
            startDate: this.formatHHMM({ hour, minutes }),
            endDate: null,
            duration: { hour: 0, minute: 0, seconds: 0 },
            startHour: {
              hour: hour,
              minute: Number(minutes),
              second: seconds,
            },
            endHour: null,
          },
        ],
        notes: [],
        deleted: false,
        approved: false,
        status: ClockHour.Status.Pending,
      };
      this.clockHourService.create(data).subscribe((res) => {
        this.openEntry = res;
        this.openEntryIndex = this.openEntry.histories.findIndex(
          (entry) => entry.endHour === null
        );
        this.chronoLoading = false;
        this.amplitudeService.sendEvent(eventData);
        this.brazeService.sendEvent(eventData);
        this.startTimeTracking();
      });
      return null;
    }

    const notes = this.formatNotes(matchDate.notes);

    const data = {
      idCreatedBy: StorageService.GetItem(USER_ID_KEY),
      idOwner: StorageService.GetItem(USER_ID_KEY),
      idCompany: StorageService.GetItem(COMPANY_ID_KEY),
      date: matchDate.date,
      histories: matchDate.histories?.concat([
        {
          startDate: this.formatHHMM({ hour, minutes }),
          endDate: null,
          duration: { hour: 0, minute: 0 },
          startHour: { hour: hour, minute: Number(minutes), second: seconds },
          endHour: null,
        },
      ]),
      notes: notes,
      deleted: false,
      approved: matchDate.approved,
    };

    this.currentTimeTracked = this.timeTrackedFormatter(this.seconds);
    this.clockHourService.updateOne(matchDate._id, data).subscribe((res) => {
      this.openEntry = res;
      this.openEntryIndex = this.openEntry.histories.findIndex(
        (entry) => entry.endHour === null
      );
      this.amplitudeService.sendEvent(eventData);
      this.brazeService.sendEvent(eventData);
      this.startTimeTracking();
    });
    this.chronoLoading = false;
    return null;
  }

  startTimeTracking() {
    this.timeTrackingError = null;

    if (!this.start) {
      this.start = true;
      this.currentTimeTracked = '00:00:00';
      this.draw();
    }

    this.timer = setInterval(() => {
      if (!this.start) {
        this.start = true;
      }
      this.seconds++;
      this.currentTimeTracked = this.timeTrackedFormatter(this.seconds);
      this.draw();
    }, 1000);
    this.requestClockHours({ loadingGlobal: false });
  }

  deleteLessThanOneMinuteTracking() {
    let data;
    if (this.openEntry?.histories?.length < 2) {
      //if only 1 record delete all
      data = { deleted: true };
    } else {
      //if more than 1 record only delete the records without endDate
      const itemsForDelete = this.openEntry.histories.filter(
        (fil) => !fil.endDate
      );
      if (itemsForDelete.length > 0) {
        for (let i = 0; i < itemsForDelete.length; i++) {
          this.openEntry.histories.splice(
            this.openEntry.histories.indexOf(itemsForDelete[i]),
            1
          );
        }
      }

      const notes = this.formatNotes(this.openEntry.notes);

      data = {
        idCreatedBy: StorageService.GetItem(USER_ID_KEY),
        idOwner: StorageService.GetItem(USER_ID_KEY),
        idCompany: StorageService.GetItem(COMPANY_ID_KEY),
        date: this.openEntry.date,
        histories: this.openEntry.histories,
        notes: notes,
        deleted: false,
        approved: this.openEntry.approved,
      };
    }
    this.clockHourService.updateOne(this.openEntry._id, data).subscribe(() => {
      this.requestClockHours({ loadingGlobal: false });
      const eventData = {
        event: 'trackingTime_auto_delete',
      };
      this.amplitudeService.sendEvent(eventData);
      this.timeTrackingError = true;
    });
  }

  getDaysArray = function (start, end) {
    const arr = [];

    for (
      let dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }

    return arr;
  };

  stopTimeTracking(): void {
    clearInterval(this.timer);

    const totalSeconds = this.seconds;
    this.start = false;
    this.seconds = 0;
    this.currentTimeTracked = this.timeTrackedFormatter(this.seconds);
    const date = new Date();
    const hour = date.getHours();
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const seconds = date.getSeconds();
    const time =
      this.openEntry?.histories[this.openEntryIndex].startHour.hour +
      ':' +
      this.openEntry?.histories[this.openEntryIndex].startHour.minute +
      ':' +
      (this.openEntry?.histories[this.openEntryIndex].startHour.second
        ? this.openEntry?.histories[this.openEntryIndex].startHour.second
        : '00');

    const dat = this.formatDDMMYYYY(this.openEntry.date);
    const currentDat = this.formatDDMMYYYY({
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
    });

    const arrayDates = this.getDaysArray(dat, currentDat);

    const duration = ClockHour.getDifferenceHours(
      (hour + ':' + minutes + ':' + seconds) as string,
      time as string,
      'SUB',
      dat,
      currentDat
    );
    if (duration.hour === 0 && duration.minute < 1 && totalSeconds < 60) {
      this.deleteLessThanOneMinuteTracking();
      return null;
    }

    if (duration.hour > 9) {
      this.longTimeTrackingLogic(duration, arrayDates, hour, minutes, seconds);
    } else {
      this.updateTimeTracking(arrayDates, hour, minutes, duration, seconds);
      const eventData = {
        event: 'trackingTime_create',
      };
      this.amplitudeService.sendEvent(eventData);
      this.brazeService.sendEvent(eventData);
    }
  }

  private longTimeTrackingLogic(
    duration: ClockHour.Hour,
    arrayDates: any[],
    hour: number,
    minutes: string,
    seconds: number
  ) {
    this.sub$.add(
      this.chronoLongtimeService
        .open({
          data: {
            hour: duration.hour,
            showFooter: true,
          },
        })
        .subscribe((res) => {
          if (res == true) {
            this.updateTimeTracking(
              arrayDates,
              hour,
              minutes,
              duration,
              seconds
            );
          } else {
            this.deleteLastTimeTracking();

            let clockHour: ClockHour.Output;
            this.launchModalClockHourEdit(clockHour);
          }
        })
    );
  }

  private deleteLastTimeTracking() {
    if (this.openEntry) {
      this.openEntry.histories = this.openEntry.histories.filter(
        (it) => it.endDate != null
      );

      const data: ClockHour.Schema = {
        idCreatedBy: StorageService.GetItem(USER_ID_KEY),
        idOwner: StorageService.GetItem(USER_ID_KEY),
        idCompany: StorageService.GetItem(COMPANY_ID_KEY),
        date: this.openEntry.date,
        histories: this.openEntry.histories,
        notes: this.openEntry.notes,
        deleted: this.openEntry.histories?.length > 0 ? false : true,
        approved: this.openEntry.approved,
        status: ClockHour.Status.Pending,
      };

      this.clockHourService
        .updateOne(this.openEntry._id, data)
        .subscribe((res) => {
          this.requestClockHours({ loadingGlobal: false });
        });
    }
  }

  private updateTimeTracking(
    arrayDates: any,
    hour: number,
    minutes: string,
    duration: ClockHour.Hour,
    seconds: number
  ) {
    for (let i = 0; i < arrayDates.length; i++) {
      const bucleDate = new Date(arrayDates[i]);

      let histories = [];
      if (i == 0 && this.openEntry?.histories) {
        histories = this.openEntry.histories;
        histories[this.openEntryIndex].startDate = this.openEntry.histories[
          this.openEntryIndex
        ].startDate;
        histories[this.openEntryIndex].startHour = this.openEntry.histories[
          this.openEntryIndex
        ].startHour;
        if (arrayDates.length > 1) {
          const { startHour } = this.openEntry.histories[this.openEntryIndex];
          const time = `${startHour.hour}:${startHour.minute}:${
            startHour.second || '00'
          }`;
          const dat = this.formatDDMMYYYY(this.openEntry.date);
          const currentDat = dat;

          const dur = ClockHour.getDifferenceHours(
            (23 + ':' + 59 + ':' + 59) as string,
            time as string,
            'SUB',
            dat,
            currentDat
          );
          histories[this.openEntryIndex].duration = dur;
          histories[this.openEntryIndex].endHour = {
            hour: 23,
            minute: 59,
            second: 59,
          };
          histories[this.openEntryIndex].endDate = '23:59';
        } else {
          histories[this.openEntryIndex].endDate = this.formatHHMM({
            hour,
            minutes,
          });

          histories[this.openEntryIndex].duration = duration;
          histories[this.openEntryIndex].endHour = {
            hour: hour,
            minute: Number(minutes),
            second: seconds,
          };
        }
      } else {
        const history: any = {};
        history.startDate = '00:00';
        history.startHour = { hour: 0, minute: 0, second: 0 };
        if (i == arrayDates.length - 1) {
          history.endDate = this.formatHHMM({ hour, minutes });
          history.endHour = {
            hour: hour,
            minute: Number(minutes),
            second: seconds,
          };
        } else {
          history.endDate = this.formatHHMM({ hour: 23, minutes: 59 });
          history.endHour = {
            hour: 23,
            minute: 59,
            second: 59,
          };
        }
        const dat = this.formatDDMMYYYY({
          year: bucleDate.getFullYear(),
          month: bucleDate.getMonth(),
          day: bucleDate.getDate(),
        });

        const dur = ClockHour.getDifferenceHours(
          history.endDate as string,
          '00:00:00' as string,
          'SUB',
          dat,
          dat
        );
        history.duration = dur;
        histories.push(history);
      }

      const date: ClockHour.ClockDate = {
        day: bucleDate.getDate(),
        month: bucleDate.getMonth(),
        year: bucleDate.getFullYear(),
      };

      const notes = this.formatNotes(this.openEntry.notes);

      const data: ClockHour.Schema = {
        idCreatedBy: StorageService.GetItem(USER_ID_KEY),
        idOwner: StorageService.GetItem(USER_ID_KEY),
        idCompany: StorageService.GetItem(COMPANY_ID_KEY),
        date: date,
        histories: histories,
        notes: notes,
        deleted: false,
        approved: this.openEntry.approved,
        status: ClockHour.Status.Pending,
      };

      if (i == 0) {
        this.clockHourService
          .updateOne(this.openEntry._id, data)
          .subscribe((res) => {
            this.toastService.show({
              text: this.i18n.instant('clockHour.editedClock'),
              type: 'success',
              msDuration: 4000,
            });
            const isMobile = window.screen.width < 768;
            this.requestClockHours({ loadingGlobal: isMobile });
          });
      } else {
        const existsRegister = this.clockHours.filter(
          (fil) =>
            new Date(fil.dateFormatted).getDate() ==
              new Date(bucleDate).getDate() &&
            new Date(fil.dateFormatted).getMonth() ==
              new Date(bucleDate).getMonth() &&
            new Date(fil.dateFormatted).getFullYear() ==
              new Date(bucleDate).getFullYear()
        );
        if (existsRegister.length > 0) {
          this.clockHourService
            .updateOne(existsRegister[0]._id, { deleted: true })
            .subscribe();
        }
        this.clockHourService.create(data).subscribe((res) => {});
      }
    }
  }

  timeTrackedFormatter(seconds) {
    let minutes = 0;
    let hours = 0;
    while (seconds >= 3600) {
      seconds -= 3600;
      hours++;
    }
    while (seconds >= 60) {
      seconds -= 60;
      minutes++;
    }
    return (
      (hours < 10 ? '0' + hours : hours) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds)
    );
  }

  checkOpenEntry() {
    this.clockHourService
      .searchOpenEntry({
        idOwner: StorageService.GetItem(USER_ID_KEY),
      })
      .subscribe((res: any) => {
        if (res.payload) {
          this.openEntry = res.payload;
          this.openEntryIndex = this.openEntry?.histories.findIndex(
            (entry) => !entry.endHour || entry.endHour.hour === null
          );
          this.calcCurrentChronometer();
        }
      });
  }

  calcCurrentChronometer() {
    if (!this.openEntry) return;
    const date = new Date();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const time =
      this.openEntry?.histories[this.openEntryIndex].startHour.hour
        .toString()
        .padStart(2, '0') +
      ':' +
      this.openEntry?.histories[this.openEntryIndex].startHour.minute
        .toString()
        .padStart(2, '0') +
      ':' +
      (this.openEntry?.histories[this.openEntryIndex].startHour.second
        ? this.openEntry?.histories[this.openEntryIndex].startHour.second
            .toString()
            .padStart(2, '0')
        : '00');
    const dat = this.formatDDMMYYYY(this.openEntry.date);
    const currentDat = this.formatDDMMYYYY({
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
    });

    const currentDuration = ClockHour.getDifferenceHours(
      (hour + ':' + minutes + ':' + seconds) as string,
      time as string,
      'SUB',
      dat,
      currentDat
    );

    const chronoSeconds =
      currentDuration.hour * 3600 +
      currentDuration.minute * 60 +
      currentDuration.seconds;
    this.seconds = Math.trunc(chronoSeconds);
    if (!this.start) {
      this.startTimeTracking();
    }
  }

  makeMobileActions(evt: ListMobileClockHourAction) {
    if (evt.action === 'SIGN') {
      const params: any = {
        date: evt.date,
      };

      const mode = evt?.id ? 'EDIT' : 'ADD';

      this.addClockHourService
        .open({
          data: {
            mode,
            idCompany: StorageService.CompanyId,
            idOwner: StorageService.UserId,
            params: {
              ...params,
              ...evt?.item,
            },
          },
        })
        .subscribe();
    }

    if (evt.action === 'DELETE') {
      this.deleteClockHour({ _id: evt?.id });
    }
  }

  public refresh() {
    return this.draw();
  }

  calcTableDistance() {
    const containerTable = document.getElementById('containerTable');
    this.distanceToTop = containerTable?.getBoundingClientRect().top - 74;
  }

  checkScroll(e) {
    if (e.target.scrollHeight > 1080) {
      if (e.target.scrollTop <= this.distanceToTop) {
        this.tableMarginTop = '-' + e.target.scrollTop + 'px';
      } else {
        this.tableMarginTop = '-' + this.distanceToTop + 'px';
      }
    }
  }

  getLang() {
    return this.i18n.currentLang;
  }

  openVideo(type: string) {
    const data = {
      event: 'trackingTime_play_tutorial',
    };
    this.amplitudeService.sendTutorialEvent(data, type);
    this.video.open(type);
  }

  showToolTip = false;
  iconDialog = 'assets/icons/gl_title-information-outline.svg';
  toggleTooltip(evt) {
    this.showToolTip = evt;
    if (!this.showToolTip) {
      this.iconDialog = 'assets/icons/gl_title-information-outline.svg';
    } else {
      this.iconDialog = 'assets/icons/gl_information-clicked.svg';
    }
  }

  showDialog(): void {
    const isMobile = window.screen.width < 768;
    if (!isMobile) {
      this.showToolTip = !this.showToolTip;
      this.toggleTooltip(this.showToolTip);
    } else {
      this.dialog.open(ModalMobileInfoComponent, {
        data: {
          html: 'clockHour.tooltip',
          buttonText: 'kanban.dialog.confirmButton',
        },
      });
    }
    this.amplitudeService.sendEvent({
      event: AmplitudeEvents.trackingTime_info,
    });
  }

  get isLowerThan1450px() {
    return window.innerWidth < 1450;
  }

  downloadReport(): void {
    const userData = JSON.parse(StorageService.userData);
    const formattedDate = {
      startDate: formatClockDate(this.searchParams.from),
      endDate: formatClockDate(this.searchParams.to),
    };
    const filename = `${userData.name}-${formattedDate.startDate}_${formattedDate.endDate}`;

    this.clockHourService
      .getUserReport(
        StorageService.UserId,
        formattedDate.startDate,
        formattedDate.endDate
      )
      .subscribe(
        (response) => {
          downloadSheet(response, `${filename}.xlsx`);
          this.downloadBtnService.downloadedSuccessfully();
          this.analyticsService.trackEvent({
            sources: ['amplitude', 'braze'],
            eventName: AmplitudeEvents.trackingTime_excel_download,
          });
        },
        (error) => {
          console.warn('Error downloading the file', error);
        }
      );
  }

  parseDate = (dateStr: string): string => {
    const dateObj = JSON.parse(dateStr);
    const year = dateObj.year;
    const month = String(dateObj.month + 1).padStart(2, '0');
    const day = String(dateObj.day).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  updateMetrics(): void {
    this.isLoadingMetrics = true;
    this.cdRef.detectChanges();
    const dateFrom = this.searchParams.from;
    const dateTo = this.searchParams.to;
    const userId = StorageService.UserId;

    this.clockHourService.getUserMetrics(userId, dateFrom, dateTo).subscribe({
      next: (res) => {
        this.userMetrics = res;
        this.isLoadingMetrics = false;
        this.cdRef.detectChanges();
      },
      error: () => {
        this.isLoadingMetrics = false;
        this.cdRef.detectChanges();
      },
    });
  }

  formatNotes(notesArray) {
    const notes = notesArray?.map((note) => {
      return {
        text: note?.text ?? note,
        idAuthor: StorageService.GetItem(USER_ID_KEY),
      };
    });
    return notes || [];
  }
}
