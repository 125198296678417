<div class="col-12" class="body-container relative">
  <roma-back-button (backClicked)="goBack()"></roma-back-button>
  <div class="page-header">
    <h1 class="page-title">{{ userName }}</h1>
    <div class="page-options">
      <div class="button-container">
        <roma-shared-download-btn
          [featureExcel]="featureRef.ClockHour.DownloadExcel"
          [showDownloadExcel]="true"
          [showDownloadPdf]="false"
          (download)="downloadReport()"
          class="download-button"
          [ngClass]="{ 'is-hidden': isLoading }"
        ></roma-shared-download-btn>

        <button
          *ngIf="!isLoading"
          class="roma-button"
          romaProFeature
          [feature]="featureManual"
          [featureUser]="featureUserRef.ClockHour.create"
          [disabled]="addButtonLoading"
          [ngClass]="{ mt10: (resize$ | async)?.payload?.mobile }"
          (clicked)="launchModalClockHour()"
        >
          {{ 'clockHour.create.form.title' | translate }}
          <img
            *ngIf="!addButtonLoading"
            src="assets/icons/gl_plus-btn.svg"
            class="icon-option"
          />
          <mat-spinner
            *ngIf="addButtonLoading"
            class="spinner__added"
            [diameter]="20"
            [color]="'white'"
          ></mat-spinner>
        </button>
      </div>
    </div>
  </div>
  <roma-monthly-weekly-filter
    (changePeriodRange)="changePeriod($event)"
    [defaultValues]="defaultQueryParams"
  ></roma-monthly-weekly-filter>
  <div class="row">
    <div class="col--12">
      <div class="total-info-header">
        <div *ngIf="isLoading; else showMetrics" class="col-12">
          <roma-loading></roma-loading>
        </div>
        <ng-template #showMetrics class="wrapper-total-hours">
          <div class="row">
            <div class="col-md-3 col-6 pr-6">
              <roma-time-display
                [clockHours]="userMetrics.workedHours"
                [clockHoursLabel]="'clockHour.totalHours' | translate"
                [icon]="'clock-fill'"
                [clickable]="false"
                [totalHours]="userMetrics.contractHours"
              ></roma-time-display>
            </div>
            <div class="col-md-3 col-6 pl-6">
              <roma-time-display
                [clockHours]="userMetrics.remainingHours"
                [clockHoursLabel]="
                  'person.create.form.hoursToClockIn' | translate
                "
                [icon]="'to-clock-in'"
                [clickable]="!userMetrics.contractHours"
                (updateMetrics)="loadData()"
                [userId]="userId"
              ></roma-time-display>
            </div>
            <div class="col-md-3 col-12">
              <roma-time-display
                [clockHours]="userMetrics.extraHours"
                [clockHoursLabel]="'person.create.form.overtime' | translate"
                [icon]="'clock-overtime'"
                [clickable]="!userMetrics.contractHours"
                [fullWidthMobile]="true"
                (updateMetrics)="loadData()"
                [userId]="userId"
              ></roma-time-display>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="isLoading; else showTable" class="col-12">
      <roma-loading></roma-loading>
    </div>
    <ng-template #showTable>
      <ng-container *ngIf="(resize$ | async)?.payload?.mobile">
        <roma-list-mobile-clock-hour
          *ngIf="!isLoading && clockHours"
          [data]="clockHours"
          (actions)="makeMobileActions($event)"
        ></roma-list-mobile-clock-hour>
      </ng-container>

      <div
        id="containerTable"
        class="containerTable"
        [ngStyle]="{ 'margin-top': tableMarginTop }"
        (scroll)="checkScroll($event)"
      >
        <div class="my-4">
          <div
            *ngIf="!(resize$ | async)?.payload?.mobile"
            class="col-12"
            [ngClass]="{
              'is-hidden': isLoading || !clockHours?.length,
              'margin-bottom-message': deleteMessage
            }"
          >
            <roma-table
              class="table"
              type="clockHoursDetail"
              idModule="CLOCK_HOUR_MODULE"
              [appendSettings]="'NO'"
              [tagNotAvailable]="'activity.notAvailable' | translate"
              [data]="clockHours"
              [displayedColumns]="columns"
              [showActions]="false"
              (wantEdit)="launchModalClockHourEdit($event)"
              (wantDelete)="deleteClockHour([$event])"
              (checkRowChanges)="deleteMany($event)"
              (wantApprove)="launchModalClockHourEdit($event)"
            >
            </roma-table>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div
    *ngIf="deleteMessage"
    class="row justify-content-center justify-content-lg-start"
  >
    <div class="col-12 col-md-11 container__float delete-message-modal">
      <div class="delete-message" [ngClass]="{ alt: !canApprovalOrRejectAll }">
        <div class="container-left">
          <strong>
            ({{ entriesForDelete.length }})
            {{ 'general.selected' | translate }}
          </strong>
        </div>
        <div class="container-buttons-actions">
          <button
            *ngIf="canApprovalOrRejectAll"
            class="btn info"
            romaProFeature
            [featureUser]="featureUserRef.ClockHourCompany.approve"
            (clicked)="updateStatusClockHour(statusRef.Approved)"
          >
            <img src="assets/icons/gl_check_white.svg" />
            {{ 'general.approve' | translate }}
          </button>
          <button
            *ngIf="canApprovalOrRejectAll"
            class="btn error"
            romaProFeature
            [featureUser]="featureUserRef.ClockHourCompany.approve"
            (clicked)="updateStatusClockHour(statusRef.Rejected)"
          >
            <img src="assets/icons/gl_close_red.svg" />
            {{ 'general.decline' | translate }}
          </button>
        </div>
        <button
          class="btn"
          romaProFeature
          [featureUser]="featureUserRef.ClockHour.delete"
          (clicked)="openDeleteManyDialog()"
        >
          <img src="assets/icons/gl_trash_red.svg" />
          {{ 'general.deleteAlt' | translate }}
        </button>
        <div class="btn-close" (click)="unselectItems()"></div>
      </div>
    </div>
  </div>
</div>
