import { Injectable } from '@angular/core';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClickLogoutService } from '@web-frontend/shared/directives/click-logout';

import * as Sentry from '@sentry/angular';

@Injectable()
export class TimeoutInterceptorService implements HttpInterceptor {
  constructor(private clickLogoutService: ClickLogoutService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        Sentry.captureException(err, {
          level: 'warning',
        });
        if (
          err instanceof HttpErrorResponse &&
          err.status === 401 &&
          err.error.message === 'Unauthorized'
        ) {
          this.clickLogoutService.logout();
        }
        return throwError(err);
      })
    );
  }
}
