import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { contentMap, EmptyStateContent } from './empty-state.map';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent implements OnInit {
  @Input()
  contentType: string;

  content: EmptyStateContent;

  @Output()
  firstButtonClick = new EventEmitter();

  @Output()
  secondButtonClick = new EventEmitter();

  isLoading = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.draw();
  }

  constructor(private readonly changeDetectionRef: ChangeDetectorRef) {}

  get isMobile(): boolean {
    return window.innerWidth < 768;
  }

  get buttonSize(): string {
    return this.isMobile ? 'small' : 'medium';
  }

  loadYTScript(): void {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://www.youtube.com/iframe_api';
    script.async = true;
    script.id = 'youtube-iframe-api';
    script.defer = true;
    body.appendChild(script);
  }

  videoSize(): { height: string; width: string } {
    const width = window.innerWidth > 500 ? 510 : window.innerWidth;
    const height = (width * 9) / 16;
    return { height: `${height}px`, width: `${width}px` };
  }

  private draw() {
    this.changeDetectionRef.detectChanges();
  }

  ngOnInit(): void {
    this.loadYTScript();
    this.content = contentMap[this.contentType?.toLowerCase()];
  }

  showSecondaryButton(content: EmptyStateContent): boolean {
    if (!content?.secondButton) {
      return false;
    }
    if (!content?.hideSecondaryOnMobile) {
      return true;
    }
    return content?.hideSecondaryOnMobile && !this.isMobile;
  }

  onButton1Click(): void {
    this.firstButtonClick.emit();
  }

  onButton2Click(): void {
    this.secondButtonClick.emit();
  }
}
