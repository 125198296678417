import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ClockHour,
  COMPANY_ID_KEY,
  FeatureUser,
  IUser,
  TypeRol,
  USER_ID_KEY,
} from '@tacliatech/types';
import { environment } from '@web-frontend/environments';
import { AmplitudeService } from '@web-frontend/shared/amplitude.service';
import { User } from '@web-frontend/shared/class';
import {
  AuthService,
  ClockHourService,
  StorageService,
} from '@web-frontend/shared/services';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AddClockHourAdminService } from '../add-clock-hour-admin';
import { ShowDetailClockHours } from './show-detail-clock-hours.types';
import AmplitudeEvents from 'src/types/amplitude.enum';
import { AnalyticsService } from '../../../services/analytics/analytics.service';

@Component({
  selector: 'roma-show-detail-clock-hours',
  templateUrl: './show-detail-clock-hours.component.html',
  styleUrls: ['./show-detail-clock-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowDetailClockHourComponent implements OnInit, OnDestroy {
  clockAbsence!: ClockHour.Output;
  typeRolRef = TypeRol;
  statusRef = ClockHour.Status;
  isAdmin = false;
  showButtonsOptions = {
    showEdit: false,
    showReject: false,
    showApproval: false,
    showDelete: false,
  };

  featureUserRef = FeatureUser;

  showOptionsReasonDescription = false;
  reasonRejectDescription = '';
  clockHourStatusRef = ClockHour.Status;
  showReasonError = false;

  private forceRefresh = false;
  private sub$ = new Subscription();

  constructor(
    private cdRef: ChangeDetectorRef,
    private clockHourService: ClockHourService,
    private addClockHourService: AddClockHourAdminService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<
      ShowDetailClockHourComponent,
      ShowDetailClockHours.Result
    >,
    @Inject(MAT_DIALOG_DATA)
    private data: ShowDetailClockHours.Params,
    private amplitudeService: AmplitudeService,
    private readonly analyticsService: AnalyticsService
  ) {}

  get params() {
    return this.data;
  }

  user: IUser;

  async ngOnInit(): Promise<void> {
    this.patchParams();
    this.watchRefreshList();
    this.sub$.add(
      this.authService.user$.subscribe((res: User) => {
        const user = res;
        if (user.role.includes(this.typeRolRef.ADMIN_ROLE)) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
        this.draw();
      })
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  approved(): void {
    this.clockHourService
      .updateOne(this.clockAbsence._id, {
        status: ClockHour.Status.Approved,
      })
      .subscribe(() => {
        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: AmplitudeEvents.trackingTimeTeam_approve,
          eventProperties: {
            event_location: 'user_list',
          },
        });
        this.close(true);
      });
  }

  reject(): void {
    this.showOptionsReasonDescription = true;

    this.draw();
  }

  delete(): void {
    this.clockHourService
      .deleteMany({ ids: [this.clockAbsence._id] })
      .subscribe(() => {
        this.amplitudeService.sendEvent({
          event: this.isAdmin
            ? AmplitudeEvents.absenceTeam_edit
            : AmplitudeEvents.absence_edit,
        });
        this.close(true);
      });
  }

  cancel(): void {
    this.showOptionsReasonDescription = false;
    this.draw();
  }

  continue(): void {
    if (!this.reasonRejectDescription.trim()) {
      this.showReasonError = true;
      return;
    }

    this.clockHourService
      .updateOne(this.clockAbsence._id, {
        status: ClockHour.Status.Rejected,
        reasonRejected: {
          description: this.reasonRejectDescription,
          idCreatedBy: StorageService.GetItem(USER_ID_KEY),
        },
      })
      .subscribe(() => {
        this.analyticsService.trackEvent({
          sources: ['amplitude'],
          eventName: AmplitudeEvents.trackingTimeTeam_decline,
          eventProperties: {
            event_location: 'user_list',
          },
        });
        this.close(true);
      });
  }

  edit(): void {
    this.addClockHourService
      .open({
        data: {
          mode: 'EDIT',
          params: this.clockAbsence,
        },
      })
      .subscribe();
  }

  changeReasonDescription(evt): void {
    this.reasonRejectDescription = evt;
    this.draw();
  }

  private updateDataClockAbsence(): void {
    this.clockHourService
      .search({
        'idCompanies[]': [StorageService.GetItem(COMPANY_ID_KEY)],
        'ids[]': [this.clockAbsence._id],
      })
      .subscribe((res) => {
        this.setSource(res.docs[0]);
      });
  }

  private patchParams() {
    this.setSource(this.data.clockHour);
  }

  private setSource(value: ClockHour.Output) {
    this.clockAbsence = value;
    this.handleShowButtonsOptions();
    this.draw();
  }

  private handleShowButtonsOptions(): void {
    if (this.clockAbsence) {
      const { status } = this.clockAbsence;

      switch (status) {
        case ClockHour.Status.Pending:
          this.showButtonsOptions = {
            showReject: true,
            showApproval: true,
            showEdit: true,
            showDelete: false,
          };
          break;
        case ClockHour.Status.Approved:
          this.showButtonsOptions = {
            showReject: false,
            showApproval: false,
            showEdit: true,
            showDelete: true,
          };
          break;
        case ClockHour.Status.Rejected:
          const hasReason = this.clockAbsence?.reasonRejected?.description
            ? true
            : false;

          this.showButtonsOptions = {
            showReject: false,
            showApproval: false,
            showEdit: true,
            showDelete: hasReason,
          };

          break;
        default:
          break;
      }

      this.draw();
    }
  }

  close(reload = false): void {
    if (reload || this.forceRefresh) {
      this.dialogRef.close({
        reload: true,
      });
    } else {
      this.dialogRef.close({ reload: false });
    }
  }

  showFile(file: string): void {
    const downloadUrl =
      environment.firebaseConfig.storageUrl + `/uploads%2Fabsence%2F${file}`;

    window.open(downloadUrl, '_blank');
  }

  getClockHoursDuration(clockHour: ClockHour.Output): string {
    try {
      let hourDuration = ClockHour.getTotalHourFromClockHours([clockHour], 0);
      while (hourDuration.charAt(0) === '0' && hourDuration.charAt(2) === ':') {
        hourDuration = hourDuration.substring(1);
      }
      return hourDuration.replace(':', 'hr ') + 'm';
    } catch (e) {
      return '0hr 00m';
    }
  }

  private watchRefreshList(): void {
    this.sub$.add(
      this.addClockHourService.refreshList$
        .pipe(filter((res) => res.reload))
        .subscribe(this.updateDataClockAbsence.bind(this))
    );
  }

  private draw(): void {
    this.cdRef.detectChanges();
  }
}
