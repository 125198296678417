<roma-modal-template
  size="md"
  [title]="
    params.mode === 'ADD'
      ? ('clockAbsence.addAbsence' | translate)
      : ('clockAbsence.editAbsence' | translate)
  "
  (success)="submit()"
  (cancel)="close()"
  [askBeforeClose]="haveChanges"
  [showCancelBtn]="false"
  [showDefaultButtons]="false"
  [footerTemplate]="customFooter"
>
  <form
    [formGroup]="form"
    romaProSelect
    class="wrapper-pro-feature-user"
    [featureUser]="featureUser"
  >
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="user">{{
            'clockHour.create.form.user' | translate
          }}</label>
          <rm-select
            [placeholderText]="'general.select' | translate"
            [items]="authors"
            [isLoading]="loadingAuthors"
            [activeItems]="activeAuthors"
            [disabled]="blockAuthorsSelection"
            [hasError]="onSubmit && form.controls['idOwner'].value === ''"
            inputHeight="44px"
            type="users"
            (addElement)="addAuthors()"
            (changeItems)="changeAuthors($event)"
            (click)="!isAdminAbsences ? null : onClickSelect('authors')"
          >
          </rm-select>
          <div
            *ngIf="onSubmit && form.controls['idOwner'].value === ''"
            class="invalid-feedback"
          >
            {{ 'clockAbsence.create.form.absenceOwnerError' | translate }}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="user">{{
            'clockAbsence.create.form.typeAbsence' | translate
          }}</label>
          <rm-select
            [placeholderText]="'general.select' | translate"
            [items]="typeAbsences"
            [activeItems]="activeType"
            [hasError]="onSubmit && form.controls['type'].value === ''"
            inputHeight="44px"
            (changeItems)="changeTypeAbsence($event)"
          >
          </rm-select>
          <div
            *ngIf="onSubmit && form.controls['type'].value === ''"
            class="invalid-feedback"
          >
            {{ 'clockAbsence.create.form.typeAbsenceError' | translate }}
          </div>
          <p *ngIf="showInputInformation" class="input-information">
            <img src="assets/icons/gl_information-fill.svg" />
            {{ 'clockAbsence.create.form.inputInformation' | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="roma-choose">
      <div
        class="roma-choose__item"
        [ngClass]="{
          'roma-choose__item--active':
            form.get('typeDetail').get('type').value === absenceTypeRef.HalfDay
        }"
        (click)="selectAbsenceType(absenceTypeRef.HalfDay)"
      >
        {{ 'clockAbsence.halfDay' | translate }}
      </div>
      <div
        class="roma-choose__item"
        [ngClass]="{
          'roma-choose__item--active':
            form.get('typeDetail').get('type').value === absenceTypeRef.OneDay
        }"
        (click)="selectAbsenceType(absenceTypeRef.OneDay)"
      >
        {{ 'clockAbsence.oneDay' | translate }}
      </div>
      <div
        class="roma-choose__item"
        [ngClass]="{
          'roma-choose__item--active':
            form.get('typeDetail').get('type').value ===
            absenceTypeRef.SeveralDays
        }"
        (click)="selectAbsenceType(absenceTypeRef.SeveralDays)"
      >
        {{ 'clockAbsence.severalDays' | translate }}
      </div>
    </div>
    <div
      class="form-group"
      formGroupName="dateDetail"
      style="margin-bottom: 24px !important"
    >
      <div class="row">
        <div class="col-12 col-md-6">
          <label class="dateLabel">
            {{
              form.get('typeDetail').get('type').value ===
                absenceTypeRef.OneDay ||
              form.get('typeDetail').get('type').value ===
                absenceTypeRef.HalfDay
                ? ('general.date' | translate)
                : ('general.init' | translate)
            }}
          </label>

          <mat-form-field appearance="outline">
            <input
              matInput
              id="startDate"
              [matDatepicker]="pickerStartDate"
              formControlName="startDate"
              aria-describedby="dateHelp"
              (dateChange)="formatDate()"
              (click)="pickerStartDate.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerStartDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerStartDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
          <label
            *ngIf="form.value.typeDetail.type === absenceTypeRef.SeveralDays"
            class="dateLabel"
            >{{ 'general.end' | translate }}</label
          >

          <mat-form-field
            *ngIf="form.value.typeDetail.type === absenceTypeRef.SeveralDays"
            appearance="outline"
          >
            <input
              matInput
              id="endDate"
              [matDatepicker]="pickerEndDate"
              formControlName="endDate"
              aria-describedby="dateHelp"
              (dateChange)="formatDate()"
              (click)="pickerEndDate.open()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerEndDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerEndDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div
          *ngIf="form.get('dateDetail').get('endDate').hasError('invalidDate')"
          class="invalid-feedback"
        >
          {{ 'clockAbsence.errorEndDate' | translate }}
        </div>
      </div>
      <div
        *ngIf="
          ((!userHasEnoughVacations && selectedAbsense === 0) ||
            overlappingDates) &&
          form.get('idOwner').value
        "
      >
        <div
          class="custom-alert"
          *ngIf="messageErrorDaysSubject.getValue() || overlappingDates"
        >
          <div class="icon">
            <img src="assets/icons/gl_danger-icon.svg" />
          </div>
          {{ messageErrorDaysSubject.getValue() | translate }}
        </div>
      </div>
    </div>

    <div
      *ngIf="form.value.typeDetail.type === absenceTypeRef.HalfDay"
      class="selectors"
    >
      <div
        class="selectors__item"
        [ngClass]="{
          'selectors__item--active':
            form.value.typeDetail.halfDayType ===
            halfDayTypeRef.FirstHalfOfTheDay
        }"
        (click)="selectHalfDayType(halfDayTypeRef.FirstHalfOfTheDay)"
      >
        <span class="radio"></span>
        {{ 'clockAbsence.firstHalf' | translate }}
      </div>
      <div
        class="selectors__item"
        [ngClass]="{
          'selectors__item--active':
            form.value.typeDetail.halfDayType ===
            halfDayTypeRef.SecondHalfOfTheDay
        }"
        (click)="selectHalfDayType(halfDayTypeRef.SecondHalfOfTheDay)"
      >
        <span class="radio"></span>
        {{ 'clockAbsence.secondHalf' | translate }}
      </div>
    </div>

    <roma-rm-file-selector
      *ngIf="selectedAbsense !== undefined && selectedAbsense !== 0"
      [maxMbSize]="10"
      [resources]="form.get('typeDetail').get('files').value"
      (newResources)="changeResources($event)"
    >
    </roma-rm-file-selector>

    <p class="note-label">
      {{ 'general.notes' | translate }}
    </p>

    <textarea
      cols="30"
      rows="1"
      class="note-input"
      formControlName="note"
      maxlength="255"
      [placeholder]="'equipments.create.notePh' | translate"
    >
    </textarea>
  </form>

  <ng-template #customFooter>
    <div class="custom-modal__footer mobile-row">
      <div
        *ngIf="!canSubmit(hasAddClockAbsenceFeature$ | async)"
        class="container-buttons"
      >
        <div class="roma-disclaimer warning">
          <div class="icon">
            <img src="assets/icons/circle-with-star.svg" />
          </div>
          <div class="body">
            {{ 'pro.clockAbsence.disclaimer.title' | translate }}&nbsp;
            <span class="highlight">
              {{
                determineCopy('pro.clockAbsence.disclaimer.highlight')
                  | translate
              }}
            </span>
          </div>
        </div>

        <div class="container-buttons-footer">
          <div class="total-hours" *ngIf="hasAddClockAbsenceFeature$ | async">
            TOTAL
            <span class="hours">
              {{ getTotalDays() }}
              <ng-container *ngIf="getTotalDays() === 1">
                {{ 'general.day' | translate }}
              </ng-container>
              <ng-container *ngIf="getTotalDays() !== 1">
                {{ 'general.days' | translate }}
              </ng-container>
            </span>
          </div>
          <!-- ⭐️ pro feature -->
          <!-- romaProFeature
          [feature]="featureRef.ClockAbsence.AddNewClockAbsenceMine" -->
          <button
            class="roma-button dark big full-width"
            romaProFeature
            [feature]="featureRef.ClockAbsence.AddNewClockAbsenceMine"
          >
            <img class="icon-star-pro-feature" src="assets/icons/star.svg" />
            {{ 'pro.buyModal.buttonBuyPro' | translate }}
          </button>
          <!-- ⭐️ pro feature -->
        </div>
      </div>

      <div *ngIf="!data?.previewMode" class="container-primary-elements">
        <div
          class="total-hours"
          *ngIf="canSubmit(hasAddClockAbsenceFeature$ | async)"
        >
          TOTAL
          <span class="hours">
            {{ getTotalDays() }}
            <ng-container *ngIf="getTotalDays() === 1">
              {{ 'general.day' | translate }}
            </ng-container>
            <ng-container *ngIf="getTotalDays() !== 1">
              {{ 'general.days' | translate }}
            </ng-container>
          </span>
        </div>

        <div class="modalFooter">
          <base-button
            *ngIf="params.mode === 'EDIT'"
            buttonClass="tertiary-danger"
            romaClickDelete
            subtitle="deleteModal.body_final"
            (wantDelete)="delete()"
          >
            <img src="/assets/icons/gl_trash-button.svg" />
            {{ 'general.deleteAlt' | translate }}
          </base-button>

          <base-button
            *ngIf="canSubmit(hasAddClockAbsenceFeature$ | async)"
            type="submit"
            (click)="submit()"
            [isLoading]="formSubmitted"
            [disabled]="formSubmitted || hasErrorWithDates"
          >
            {{
              params.mode === 'ADD'
                ? ('activity.create.submit' | translate)
                : ('activity.edit.submit' | translate)
            }}
          </base-button>
        </div>
      </div>
    </div>
  </ng-template>
</roma-modal-template>
