import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SubscriptionService } from './subscription.service';
import { ChargebeeService } from '../chargebee.service';
import {
  currencies,
  Feature,
  ModuleType,
  PlanType,
} from '../../../../../types-legacy/lib/types';
import { StorageService } from '../../../services';
import { modulesInfo } from '../plan-selection/modulesInfo';
import { MatDialogRef } from '@angular/material/dialog';
import { PermissionService } from '../../../services/permissions';

interface PlanSelection {
  id: string;
  name: string;
  price: string;
  pricePerYear: string;
  description?: string;
  features?: Array<{
    name: string;
    available?: boolean;
    description?: string;
  }>;
}

interface ModulePlans {
  [key: string]: {
    name: string;
    icon: string;
    type: ModuleType;
    description: string;
    startingPrice: string;
    isFullPlan: boolean;
    currency: string;
    plans: Array<PlanSelection>;
  };
}

@Component({
  selector: 'roma-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlansComponent implements OnInit {
  @Input()
  feature: Feature.Purchasable;

  public isLoading = true;
  public modulePlansMonthly: ModulePlans;
  public modulePlansYearly: ModulePlans;
  public isSubscripting = false;
  public periodType: 'monthly' | 'yearly' = 'yearly';
  public sortedModules = [
    ModuleType.SALES,
    ModuleType.TIME_TRACKING,
    ModuleType.CRM,
    ModuleType.FULL,
  ];
  public discountValue = 15;
  private userEmail = JSON.parse(StorageService.userData ?? '{}')?.email;
  private basicPlanString = 'BASIC';
  private currenciesAllowed = currencies.map(({ text }) => text);

  constructor(
    private readonly subscriptionService: SubscriptionService,
    private cdRef: ChangeDetectorRef,
    private readonly chargebee: ChargebeeService,
    private dialogRef: MatDialogRef<PlansComponent>,
    private permissionService: PermissionService
  ) {}

  get modules(): ModulePlans {
    return this.periodType === 'monthly'
      ? this.modulePlansMonthly
      : this.modulePlansYearly;
  }

  async ngOnInit(): Promise<void> {
    const response = await this.subscriptionService.getItemPrices();
    if (response && response.planTypes) {
      this.preparePlans(response.planTypes);
      this.draw();
    }
  }

  public subscribe(plan: PlanSelection): void {
    if (!this.userEmail) {
      throw new Error('User email not found');
    }
    const planTypeId = plan.id;
    this.isSubscripting = true;

    this.chargebee
      .openCheckout(this.userEmail, planTypeId)
      .then((res) => {
        if (!res?.success) return;
        const PurchasableList = Feature.getPurchasableListByFeature(
          this.feature
        ).map((el) => {
          return {
            feature: el,
          };
        });

        this.permissionService.setPermissions(PurchasableList);
      })
      .finally(() => {
        this.isSubscripting = false;
        this.draw();
      });
  }

  public changePeriodType(type: 'monthly' | 'yearly'): void {
    this.periodType = type;
    this.draw();
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  private draw() {
    this.cdRef.detectChanges();
  }

  private preparePlans(responsePlans: PlanType[]): void {
    this.sortedModules.forEach((moduleType: ModuleType) => {
      const subscriptionsMonthly = responsePlans.filter(
        ({ id, period_unit }) => {
          return id.includes(moduleType) && period_unit === 'month';
        }
      );
      const subscriptionsYearly = responsePlans.filter(
        ({ id, period_unit }) => {
          return id.includes(moduleType) && period_unit === 'year';
        }
      );

      const plansMonthly = this.preparePlan(subscriptionsMonthly);
      const plansYearly = this.preparePlan(subscriptionsYearly);
      const moduleInfo = modulesInfo.find(
        (module) => module.type === moduleType
      );

      const moduleBase = {
        name: moduleInfo.name,
        icon: moduleInfo.icon,
        type: moduleType,
        description: moduleInfo.description,
        isFullPlan: moduleType === ModuleType.FULL,
        currency: this.getCurrencySymbol(subscriptionsMonthly[0].currency_code),
      };

      this.modulePlansMonthly = {
        ...this.modulePlansMonthly,
        [moduleType]: {
          ...moduleBase,
          plans: plansMonthly,
          startingPrice: plansMonthly[0].price,
          features: moduleInfo.features,
        },
      } as ModulePlans;

      this.modulePlansYearly = {
        ...this.modulePlansYearly,
        [moduleType]: {
          ...moduleBase,
          plans: plansYearly,
          startingPrice: plansYearly[0].price,
          features: moduleInfo.features,
        },
      } as ModulePlans;
    });
    this.isLoading = false;
  }

  private preparePlan(plan: PlanType[]): Array<PlanSelection> {
    return plan
      .map((planType) => {
        return {
          id: planType.id,
          name: this.getPlanName(planType.id),
          price: `${planType.monthly}`,
          pricePerYear: `${planType.price}`,
        };
      })
      .sort((a, b) => {
        return a.id.includes(this.basicPlanString) &&
          !b.id.includes(this.basicPlanString)
          ? -1
          : 1;
      });
  }

  private getPlanName(planId: string): string {
    if (planId.includes(this.basicPlanString)) {
      return 'basic';
    }
    return 'pro';
  }

  private getCurrencySymbol(currencyCode: string): string {
    if (!currencyCode || !this.currenciesAllowed.includes(currencyCode)) {
      return '';
    }

    return currencies.find(
      ({ text }: { text: string }) => text === currencyCode
    )?.value;
  }
}
