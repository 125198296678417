<div class="list-item-card" *ngFor="let item of data">
  <div>
    <mat-checkbox
      [checked]="isChecked(item)"
      (click)="onCheckboxChange(item._id)"
    >
    </mat-checkbox>
  </div>
  <div style="width: 100%" (click)="eventClickRow(item)">
    <h4>{{ item.title }}</h4>
    <div class="item-info">
      <div class="owner">
        <ng-container *ngIf="item.ownerObj">
          <div *ngIf="isArray(item?.ownerObj) && item.ownerObj.length > 0">
            <roma-user-avatar
              class="single-owner"
              [user]="item.ownerObj?.[0] ?? null"
              width="20"
              height="20"
            ></roma-user-avatar>
            <roma-user-avatar
              *ngIf="item.ownerObj.length > 1"
              class="more-owners"
              [user]="item.ownerObj?.[1] ?? null"
              width="20"
              height="20"
            ></roma-user-avatar>
            ({{
              item.ownerObj.length > 1
                ? '+' + item.ownerObj.length
                : item.ownerObj[0].name
            }})
            <ng-container *ngIf="item.ownerObj.length > 1">
              <div
                class="owners-link"
                (click)="
                  $event.stopPropagation();
                  openActivityOwnersDialog(item.ownerObj)
                "
              >
                {{ 'task.owners' | translate }}
              </div>
            </ng-container>
          </div>
          <div *ngIf="!isArray(item?.ownerObj)">
            <img src="/assets/icons/table-user-icon.svg" />
            {{ item.ownerObj?.name }}
          </div>
        </ng-container>
        <!-- {{ item | json }} -->
      </div>
      <div class="date" *ngIf="item.dateTime">
        <img src="/assets/icons/gl_calendar_fill.svg" />
        {{ item.dateTime ? (item.dateTime | date: 'dd/MM') : '' }}
      </div>
    </div>
    <ng-container *ngIf="item?.statusObj?.name">
      <roma-rm-badge
        [statusName]="item?.statusObj?.name"
        [color]="item?.statusObj?.color"
      ></roma-rm-badge>
    </ng-container>
    <ng-container *ngIf="!item?.statusObj?.name">
      <roma-rm-badge
        [statusName]="'general.notAssigned' | translate"
        [color]="'#E3EBFA'"
      ></roma-rm-badge>
    </ng-container>
    <ng-container *ngIf="!item?.statusObj?.name"> -- </ng-container>
  </div>
</div>
