import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecoverComponent } from './login/recover/recover.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ResetPasswordComponent } from './login/recover/reset-password/reset-password.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { WithoutSessionGuard } from '@web-frontend/shared/guards/auth/without-session.guard';
import { NewRegisterComponent } from './login/new-register/new-register.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';

const internalRoutes = {
  recover: 'recover',
  resetPassword: 'reset-password',
  clientOnboarding: 'client-onboarding',
  onboarding: 'onboarding',
  welcome: 'welcome',
  maintenance: 'maintenance',
  impersonate: 'impersonate',
};

const routes: Routes = [
  {
    path: internalRoutes.recover,
    canActivate: [WithoutSessionGuard],
    component: RecoverComponent,
  },
  {
    path: `${internalRoutes.resetPassword}/:token`,
    canActivate: [WithoutSessionGuard],
    component: ResetPasswordComponent,
  },
  {
    path: internalRoutes.clientOnboarding,
    canActivate: [WithoutSessionGuard],
    component: NewRegisterComponent,
  },
  {
    path: internalRoutes.onboarding,
    canActivate: [WithoutSessionGuard],
    loadChildren: () =>
      import('../../microfrontends/mf-onboarding/mf-onboarding.module').then(
        (m) => m.MfOnboardingModule
      ),
  },
  {
    path: internalRoutes.welcome,
    canActivate: [WithoutSessionGuard],
    component: WelcomeComponent,
  },
  {
    path: internalRoutes.maintenance,
    component: MaintenanceComponent,
  },
  {
    path: internalRoutes.impersonate,
    component: ImpersonateComponent,
  },
  {
    path: '',
    canActivate: [WithoutSessionGuard],
    loadChildren: () =>
      import('../../microfrontends/mf-auth/mf-auth.module').then(
        (m) => m.MfAuthModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [WithoutSessionGuard],
  exports: [RouterModule],
})
export class AuthRoutingModule {}

export const authRoutes = {
  recover: `/auth/${internalRoutes.recover}`,
  resetPassword: `/auth/${internalRoutes.resetPassword}`,
  clientOnboarding: `/auth/${internalRoutes.clientOnboarding}`,
  onboarding: `/auth/${internalRoutes.onboarding}`,
  welcome: `/auth/${internalRoutes.welcome}`,
  maintenance: `/auth/${internalRoutes.maintenance}`,
  impersonate: `/auth/${internalRoutes.impersonate}`,
  login: '/auth/login',
  register: '/auth/client-register',
};
