import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { TableModule } from '@web-frontend/shared/components/table/table.module';
import { LoadingModule } from '@web-frontend/shared/components/loading';
import { BasicMessageModule } from '@web-frontend/shared/components/basic-message';
import { FilterModule } from '@web-frontend/shared/components/filter';
import { MaterialModule } from '@web-frontend/shared/helpers/material/material.module';
import { DealCancelModule } from '@web-frontend/shared/components/deal-cancel/deal-cancel.module';
import { DealPipelineModule } from '@web-frontend/shared/components/deal-pipeline';
import { DealCalendarModule } from '@web-frontend/shared/components/deal-calendar';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

import { DealsComponent } from './deals.component';
import { TrackingObjectModule } from '@web-frontend/shared/components/tracking-objects';
import { FilterTrackingModule } from '@web-frontend/shared/components/filter-tracking';
import { ImageFirebasePipe } from '@web-frontend/shared/pipes/image/image-firebase.pipe';
import { PermissionsModule } from '@web-frontend/shared/components/permissions';
import { RomaToolTipModule } from '@web-frontend/shared/directives/roma-tooltip';
import { DeleteBySelectionModalModule } from '@web-frontend/shared/components/delete-by-selection-modal';
import { NotResultFoundModule } from '@web-frontend/shared/components/not-result-found';
import { RmFilterModule } from '@web-frontend/shared/components/globals/rm-filter';
import { KanbanModule } from '@web-frontend/shared/components/kanban';
import { ClickStopPropagationModule } from '@web-frontend/shared/directives/click-prevent-default';

import {
  DealOwnersDialog,
  ListMobileDealsComponent,
} from './roma-list-mobile-deals/list-mobile-deals.component';
import { RmBadgeModule } from '@web-frontend/shared/components/globals/rm-badge';
import { StripeSnackbarSharedModule } from '@web-frontend/shared/components/snackbar/stripe-snackbar.module';
import { TranslateModule } from '@ngx-translate/core';
import { DownloadBtnModule } from '@web-frontend/shared/components/download-btn/download-btn.module';
import { UserAvatarModule } from '../../../shared/components/user-avatar/user-avatar.module';

@NgModule({
  declarations: [DealsComponent, ListMobileDealsComponent, DealOwnersDialog],
  imports: [
    CommonModule,
    FilterModule,
    TranslateModule.forChild(),
    LoadingModule,
    BasicMessageModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatChipsModule,
    MatSelectModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MaterialModule,
    MatNativeDateModule,
    TableModule,
    DealCancelModule,
    MatButtonToggleModule,
    DealPipelineModule,
    DealCalendarModule,
    TrackingObjectModule,
    FilterTrackingModule,
    PermissionsModule,
    RomaToolTipModule,
    DeleteBySelectionModalModule,
    NotResultFoundModule,
    RmFilterModule,
    KanbanModule,
    RmBadgeModule,
    MatMenuModule,
    NotResultFoundModule,
    RmFilterModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRadioModule,
    ClickStopPropagationModule,
    StripeSnackbarSharedModule,
    DownloadBtnModule,
    UserAvatarModule,
  ],
  exports: [DealsComponent, ListMobileDealsComponent, DealOwnersDialog],
  providers: [DatePipe, ImageFirebasePipe],
})
export class DealsModule {}
