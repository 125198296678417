import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GenericModalService {
  private refreshListSource = new Subject<boolean>();

  componentRef: ComponentRef<any>;

  refreshList$ = this.refreshListSource.asObservable();

  constructor(
    private dialog: MatDialog,
    private resolver: ComponentFactoryResolver
  ) {}

  open(component?: any, config?: MatDialogConfig) {
    const dialogRef = this.dialog.open(component, config);

    return dialogRef.afterClosed().pipe(
      tap((res) => {
        this.refreshListSource.next(res);
      })
    );
  }
}
