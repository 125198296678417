<div class="list-dropdown-select">
  <div *ngIf="activeItems.length" class="active-items-list">
    <div
      *ngFor="let item of activeItems"
      class="active-item"
      style="display: flex; align-items: center; gap: 6px"
    >
      <div class="active-item-image">
        <roma-user-avatar
          [user]="item"
          width="20"
          height="20"
        ></roma-user-avatar>
      </div>
      {{ item?.title }}
      <img
        class="clean-input"
        src="assets/icons/gl_close-input.svg"
        (click)="clearInput(item)"
      />
    </div>
  </div>
  <!-- desktop-display -->
  <ng-container *ngIf="!isResponsive">
    <div class="desktop-display">
      <div *ngIf="menuIsOpen" class="search-input secondary">
        <input
          #inputFilterSearch
          class="filter-input"
          type="text"
          [placeholder]="placeholderText | translate"
        />
      </div>
      <button
        type="button"
        class="add-field-btn"
        [ngClass]="{
          'btn-hidden': hideButton()
        }"
        [matMenuTriggerFor]="listMenu"
        (menuClosed)="menuClosed()"
        (menuOpened)="menuOpened()"
      >
        {{ buttonTitle | translate }}
        <img src="assets/icons/gl_plus.svg" style="margin-top: -5px" />
      </button>
      <mat-menu #listMenu="matMenu" class="menu__responsive__select">
        <div
          *ngIf="isLoading"
          style="text-align: center"
          [ngStyle]="listWidth !== '' && { width: listWidth }"
        >
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="25"
            style="margin: auto"
          ></mat-progress-spinner>
        </div>
        <ng-container *ngIf="!isLoading">
          <div
            class="list__wrapper"
            [ngStyle]="listWidth !== '' && { width: listWidth }"
          >
            <div
              *ngFor="
                let item of items | searchFilter: filterArgs;
                let i = index
              "
              class="list__item"
              [ngClass]="{ active: isInActiveItems(item) }"
              (click)="$event.stopPropagation(); changeSelect(item)"
            >
              <ng-container *ngIf="mode === 'MULTIPLE'">
                <mat-checkbox
                  *ngIf="searchCheckFilters(item) === true"
                  class="list__item__checkbox"
                  [checked]="true"
                  (click)="$event.stopPropagation(); changeSelect(item)"
                >
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="searchCheckFilters(item) === false"
                  class="list__item__checkbox"
                  [checked]="false"
                  (click)="$event.stopPropagation(); changeSelect(item)"
                >
                </mat-checkbox>
              </ng-container>
              <ng-container *ngIf="mode === 'SINGLE'">
                <img
                  class="list__item__checkicon"
                  [ngClass]="{
                    'list__item__checkicon--active':
                      searchCheckFilters(item) === true
                  }"
                  src="assets/icons/gl_check.svg"
                />
              </ng-container>
              <div class="list__item__image">
                <roma-user-avatar
                  [user]="item"
                  width="20"
                  height="20"
                ></roma-user-avatar>
              </div>
              <p
                class="list__item__text"
                [title]="item.title | translate"
                (click)="$event.stopPropagation(); changeSelect(item)"
              >
                {{ type }} {{ item.title | translate }}
              </p>
            </div>
          </div>
          <div
            *ngIf="isAvailableUserFeature"
            class="add-user"
            (click)="$event.stopPropagation(); addElementHandle()"
          >
            <img src="/assets/icons/gl_plus.svg" />
            {{ addButtonText | translate }}
          </div>
        </ng-container>
      </mat-menu>
    </div>
  </ng-container>
  <!-- mobile display -->
  <ng-container *ngIf="isResponsive">
    <div class="mobile-display">
      <button
        type="button"
        class="add-field-btn"
        [ngClass]="{
          'btn-hidden': menuIsOpen
        }"
        (click)="menuIsOpen = !menuIsOpen"
      >
        {{ 'general.add2' | translate }}
        <img src="assets/icons/gl_plus.svg" style="margin-top: -5px" />
      </button>
      <div
        *ngIf="menuIsOpen"
        class="mobile-list-wrapper"
        (click)="menuIsOpen = !menuIsOpen"
      ></div>
      <div *ngIf="menuIsOpen" class="list-box">
        <h4>{{ placeholderText | translate }}</h4>
        <div *ngIf="isLoading" style="text-align: center; width: 100%">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="60"
            style="margin: 40px auto"
          ></mat-progress-spinner>
        </div>
        <div *ngIf="!isLoading" class="search-input secondary">
          <input
            #inputFilterSearch
            class="filter-input"
            type="text"
            [placeholder]="'general.search' | translate"
          />
        </div>
        <ng-container *ngIf="!isLoading">
          <div class="list-wrapper">
            <div
              class="list-item"
              *ngFor="
                let item of items | searchFilter: filterArgs;
                let i = index
              "
              [ngClass]="{ active: activeItems[0]?.value === item.value }"
              (click)="changeSelect(item)"
            >
              <img
                *ngIf="isInActiveItems(item)"
                class="active-check"
                style="visibility: visible"
                src="assets/icons/gl_check.svg"
              />
              {{ item.title | translate }}
            </div>
          </div>
          <div
            *ngIf="isAvailableUserFeature"
            class="add-user-mobile"
            (click)="$event.stopPropagation(); addElementHandle()"
          >
            <img src="/assets/icons/gl_plus.svg" />
            {{ addButtonText | translate }}
          </div>
        </ng-container>

        <div class="text-center close-btn-wrapper">
          <img
            (click)="menuIsOpen = !menuIsOpen"
            class="close-btn"
            src="/assets/icons/btn_close.svg"
            alt="close"
          />
        </div>
      </div>
    </div>
  </ng-container>
</div>
